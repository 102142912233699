import { Extension, Plugin } from "tiptap";

export default class Limit extends Extension {
  get name() {
    return "limit";
  }

  get defaultOptions() {
    return {
      limit: 200
    }
  }

  get plugins() {
    const { limit } = this.options
    return [
      new Plugin({
        filterTransaction(transaction, state) {
          if (!transaction.docChanged || !limit) {
            return true
          }
          const size = transaction.doc && transaction.doc.textContent.length
          if(size > limit) return false
          return true
        }
      })
    ]
  }
}
