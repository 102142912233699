var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isAuthorized
    ? _c("page", [
        _c(
          "div",
          { staticClass: "full-width" },
          [
            _c("observe", {
              attrs: {
                name: "myCheckers",
                what: ["urlChecker", "myCheckers", {}]
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(ref) {
                      var checkers = ref.value
                      return _vm._l(checkers, function(checker) {
                        return _c("UrlCard", {
                          key: checker.to,
                          attrs: { checker: checker }
                        })
                      })
                    }
                  }
                ],
                null,
                false,
                4097329993
              )
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card main-card full-width" },
          [
            _c("h3", [_vm._v(_vm._s(_vm.i18n.addUrlTitle))]),
            _vm._v(" "),
            _c(
              "command-form",
              {
                ref: "form",
                attrs: {
                  service: "urlChecker",
                  action: "createChecker",
                  keepOnDone: true,
                  fieldValidators: {
                    url: function(v) {
                      return _vm.validateUrl(v)
                    }
                  },
                  "reset-on-done": ""
                },
                on: { done: _vm.handleCheckerAdded }
              },
              [
                _c("text-field", {
                  attrs: {
                    name: "url",
                    label: _vm.i18n.url,
                    fieldValidators: {
                      url: function(v) {
                        return _vm.validateUrl(v)
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button",
                    attrs: { type: "submit", role: "button" }
                  },
                  [
                    _c("img", { attrs: { src: "/static/icons/add_task.svg" } }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.i18n.createCheckerSubmit))])
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ])
    : _c("page", [
        _c("div", {
          staticClass: "full-width",
          domProps: {
            innerHTML: _vm._s(_vm.partial && _vm.partial.data.content)
          }
        })
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }