class PubSub {
  constructor() {
    this.subscribers = []
  }
  subscribe(callback) {
    this.subscribers.push(callback)
  }
  unsubscribe(callback) {
    const index = this.subscribers.indexOf(callback)
    if(index != -1) this.subscribers.splice(index, 1)
  }
  publish(...args) {
    for(const subscriber of this.subscribers) subscriber(...args)
  }
}

class DataChannel {
  constructor(label, options) {
    this.label = label
    this.options = options
    this.receive = new PubSub()
    this.send = new PubSub()
  }
}

export default DataChannel