var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("page", [
    _c(
      "div",
      { staticClass: "card half-width" },
      [
        _c("h2", [_vm._v(_vm._s(_vm.i18n.title))]),
        _vm._v(" "),
        _c("command-form", {
          attrs: {
            service: "users",
            action: "completeUserData",
            initialValues: _vm.me
          },
          scopedSlots: _vm._u([
            {
              key: "done",
              fn: function() {
                return [_c("p", [_vm._v(_vm._s(_vm.i18n.dataCompleted))])]
              },
              proxy: true
            },
            {
              key: "default",
              fn: function() {
                return [
                  _c("text-field", {
                    attrs: {
                      name: "name",
                      label: _vm.i18n.name,
                      placeholder: _vm.i18n.namePlaceholder,
                      inputClass: "form-control-lg"
                    }
                  }),
                  _vm._v(" "),
                  _c("boolean-field", {
                    attrs: { name: "agreement1" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function() {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.i18n.agreement1) +
                                " "
                            ),
                            _c("a", { attrs: { href: _vm.i18n.policy } }, [
                              _vm._v("Privacy Policy")
                            ])
                          ]
                        },
                        proxy: true
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("boolean-field", {
                    attrs: { name: "agreement2" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function() {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.i18n.agreement2) +
                                "\n          "
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("phone-field-with-confirm", {
                    key: "phoneField",
                    attrs: {
                      name: "phone",
                      label: _vm.i18n.phone,
                      confirmName: "phoneConfirm",
                      confirmTexts: _vm.i18n.confirmSms,
                      comment: _vm.i18n.phoneComment
                    }
                  }),
                  _vm._v(" "),
                  _c("form-field-bind", {
                    attrs: { name: "phone" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var phone = ref.value
                          return [
                            phone
                              ? _c("div", { staticClass: "buttons" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "button",
                                      attrs: { type: "submit", role: "button" }
                                    },
                                    [_vm._v(_vm._s(_vm.i18n.save))]
                                  )
                                ])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }