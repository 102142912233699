var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "command-form",
    {
      staticClass: "message-input-form",
      attrs: {
        service: _vm.csp ? "clientMessages" : "messages",
        serviceDefinitionSource: "messages",
        action: _vm.action,
        parameters: Object.assign({}, _vm.messageParameters, {
          messageType: "text"
        }),
        resetOnDone: !_vm.onlyOne
      },
      on: {
        done: function($event) {
          return _vm.$emit("sent")
        }
      }
    },
    [
      _c("multi-line-text-field", {
        attrs: {
          name: "text",
          label: "",
          "submit-on-enter": !(_vm.isMobile || _vm.onlyOne),
          placeholder: _vm.placeholder
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "buttons" }, [
        _vm.sendButton
          ? _c("button", { staticClass: "button", attrs: { type: "submit" } }, [
              _vm._v(_vm._s(_vm.sendButton))
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.isMobile && _vm.mobileSendButton
        ? _c(
            "button",
            {
              staticClass: "button mobile-send-button",
              attrs: { type: "submit" }
            },
            [_vm._v(_vm._s(_vm.mobileSendButton))]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }