<template>
  <form-field-bind :name="name" v-slot="{ value, setValue, error }"
                   class="multi-file-upload-field">
    <FieldTemplate :errorText="errorText" :error="error" :label="label" :name="name"
                   :groupClass="groupClass" :inputId="uid">

      <FileUploadField v-for="(upload, fileIndex) in uploads" :key="upload.id"
                       :file="upload.file" :name="name + '.' + fileIndex"
                       @delete="handleFileDeleted(fileIndex)">
      </FileUploadField>

      <div class="buttons">
        <FileInput multiple :name="name" :accept="accept" @input="handleFiles" :class="inputClass || 'button'">
          {{ button }}
        </FileInput>
      </div>

    </FieldTemplate>
  </form-field-bind>
</template>

<script>
  import FieldTemplate from "./FieldTemplate.vue"
  import FileInput from "../inputs/FileInput.vue"
  import FileUploadField from "./FileUploadField.vue"

  export default {
    name: 'MultiFileField',
    components: { FieldTemplate, FileInput, FileUploadField },
    props: {
      name: {
        type: String,
        required: true
      },
      accept: {
        type: String
      },
      multiple: {
        type: Boolean,
        default: false
      },
      button: {
        type: String,
        default: "Upload files"
      },
      label: {
        type: String
      },
      errorText: {
        type: Object
      },
      inputClass: {
        type: String
      },
      groupClass: {
        type: String
      },
    },
    inject: ['form'],
    data () {
      return {
        uploads: []
      }
    },
    computed: {
      uid() { return '' + this._uid }
    },
    methods: {
      handleFiles(files) {
        console.log("HANDLE FILES", files)
        for(const file of files) {
          console.log("FILE", file)
          this.uploads.push({
            id: (new Date()).toISOString() + (Math.random() * 10000).toFixed(),
            file
          })
          this.form.addElementToArray(this.name, null)
        }
      },
      handleFileDeleted(index) {
        this.uploads.splice(index, 1)
        this.form.removeElementFromArray(this.name, index)
      }
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    }
  }
</script>
