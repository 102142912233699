import { Editor, EditorContent, EditorMenuBar, Extension, Paragraph } from 'tiptap'
import { baseKeymap } from 'tiptap-commands'

class OnEnter extends Extension {
  constructor(action) {
    super()
    this.action = action
  }
  keys() {
    return {
      'Shift-Enter': baseKeymap.Enter,
      'Ctrl-Enter': baseKeymap.Enter,
      Enter: (state, dispatch, view) => {
        //console.log("ONENTER", state, dispatch)
        this.action()
        return true
      },
    }
  }
}

export default OnEnter
