module.exports = function(DOMPurify) {

  DOMPurify.addHook('beforeSanitizeAttributes', function(node, event, config) {
    if(!config.TARGET_BLANK) return
    if (node.nodeName && node.nodeName === '#text') {
      node.textContent = node.textContent.toUpperCase()
    }
  })

}
