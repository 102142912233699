<template>
  <TagsListInput :i18n="i18n" :tagsType="tagsType"
                 :value="selectedTags" @selected="select" @deselected="deselect"></TagsListInput>
</template>

<script>
  import i18n from "i18n"
  import api from "@/api"
  import TagsListInput from "./TagsListInput.vue"

  export default {
    name: "UserTagsEditor",
    props: {
      tagsType: {
        type: String,
        required: true
      },
      i18n: {
        type: Object,
        required: true
      }
    },
    components: { TagsListInput },
    inject: ['workingZone'],
    reactive: {
      selectedTags() { return ['tags',"MySelectedTags", { tagsType: this.tagsType } ] }
    },
    data() {
      return {
      }
    },
    computed: {
      session() {
        return api.session.session
      }
    },
    watch: {
      selectedTag(tags) {
        this.$emit("input", tags)
      }
    },
    mounted() {
      this.$emit("input", this.tags)
    },
    methods: {
      select(id) {
        console.log("SELECT", id)
        return api.command(['tags', 'SelectTag'], { tagType: this.tagsType, tag: id }).catch( err => {
          const task = this.workingZone.started({ name: 'selectTag' })
          this.workingZone.failed(task)
        })
      },
      deselect(id) {
        console.log("DESELECT", id)
        api.command(['tags', 'DeselectTag'], { tagType: this.tagsType, tag: id }).catch( err => {
          const task = this.workingZone.started({ name: 'deselectTag' })
          this.workingZone.failed(task)
        })
      }
    }
  }
</script>

<style scoped>

</style>