<template>
  <uploaded-picture-img :picture="data" class="picture-field-picture" :reactive="true"></uploaded-picture-img>
</template>

<script>
  export default {
    name: "JsonView",
    props: {
      data: {
        required: true
      }
    }
  }
</script>

<style scoped>

</style>