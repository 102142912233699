var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form-field-bind", {
    attrs: { name: _vm.name },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(ref) {
            var value = ref.value
            var setValue = ref.setValue
            var error = ref.error
            return [
              _c(
                "FieldTemplate",
                {
                  attrs: {
                    errorText: _vm.errorText,
                    error: error,
                    label: _vm.label,
                    name: _vm.name,
                    groupClass: _vm.groupClass
                  }
                },
                _vm._l(_vm.options, function(option, index) {
                  return _c(
                    "div",
                    { staticClass: "checkbox-field form-field" },
                    [
                      _c("span", { staticClass: "checkbox" }, [
                        _c("input", {
                          staticClass: "checkbox-input",
                          attrs: {
                            type: "checkbox",
                            id: _vm.uid + "_" + index
                          },
                          domProps: {
                            checked: value.includes(_vm.valueFunc(option))
                          },
                          on: {
                            input: function(ev) {
                              return setValue(
                                value
                                  .filter(function(o) {
                                    return o != _vm.valueFunc(option)
                                  })
                                  .concat(
                                    ev.target.checked
                                      ? [_vm.valueFunc(option)]
                                      : []
                                  )
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkbox-mark" })
                      ]),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "custom-control-label",
                          attrs: { for: _vm.uid + "_" + index }
                        },
                        [
                          _vm._t("label", function() {
                            return [_vm._v(_vm._s(_vm.text(option)))]
                          })
                        ],
                        2
                      )
                    ]
                  )
                }),
                0
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }