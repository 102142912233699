var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "card half-width" },
    [
      _c(
        "loading",
        { attrs: { what: _vm.user, name: "user data" } },
        [
          _vm.user
            ? _c("command-form", {
                attrs: {
                  name: "user",
                  service: "users",
                  action: "updatePhone"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "done",
                      fn: function() {
                        return [
                          _c("h4", [_vm._v(_vm._s(_vm.i18n.title))]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(_vm.i18n.done.text))])
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "default",
                      fn: function() {
                        return [
                          _c("h4", [_vm._v(_vm._s(_vm.i18n.title))]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(_vm._s(_vm.i18n.currentPhone) + " "),
                            _c("b", [
                              _vm._v(
                                _vm._s(_vm.user && _vm.user.userData.phone)
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("phone-field-with-confirm", {
                                key: "phoneField",
                                attrs: {
                                  name: "phone",
                                  label: _vm.i18n.phone,
                                  confirmName: "phoneConfirm",
                                  confirmTexts: _vm.i18n.confirmSms
                                }
                              }),
                              _vm._v(" "),
                              _c("form-field-bind", {
                                staticClass: "form-group",
                                attrs: { name: "phone" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var phone = ref.value
                                        return [
                                          phone
                                            ? _c(
                                                "div",
                                                { staticClass: "buttons" },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass: "button",
                                                      attrs: {
                                                        type: "submit",
                                                        role: "button"
                                                      }
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(_vm.i18n.save)
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  4235870281
                                )
                              })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  3978198950
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }