var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "permissions-modal-holder" }, [
    _c(
      "div",
      {
        staticClass: "card permissions-modal",
        style: _vm.maxDimensions,
        attrs: { ef: "box" }
      },
      [
        _c("div", { staticClass: "card-header" }, [
          _c("h3", [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _vm.closeable
            ? _c(
                "span",
                {
                  staticClass: "close-icon",
                  attrs: { "data-effect": "fadeOut" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_c("img", { attrs: { src: "/static/icons/clear.svg" } })]
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.introduction
          ? _c("div", {
              staticClass: "permissions-introduction",
              domProps: { innerHTML: _vm._s(_vm.introduction) }
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "permissions-state" },
          _vm._l(_vm.permissionsState, function(permissionState) {
            return _c("div", { staticClass: "permission-state card" }, [
              _c("div", { staticClass: "permission-state-icon" }, [
                _c("img", {
                  attrs: {
                    src:
                      _vm.permissionIcons[permissionState.name][
                        +(permissionState.state != "denied")
                      ]
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "permission-description" }, [
                _c("p", { staticClass: "permission-name" }, [
                  _vm._v(_vm._s(_vm.i18n.permissions[permissionState.name]))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.i18n.state) + " "),
                  _c("span", { staticClass: "permission-state-name" }, [
                    _vm._v(_vm._s(_vm.i18n.states[permissionState.state]))
                  ])
                ])
              ])
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "buttons permissions-buttons" },
          _vm._l(_vm.buttons, function(button) {
            return _c(
              "button",
              {
                staticClass: "button",
                attrs: {
                  type: "button",
                  disabled: button.hasOwnProperty("needPermissions")
                    ? (button.needPermissions && !_vm.isOk) ||
                      (!button.needPermissions && _vm.isOk)
                    : false
                },
                on: {
                  click: function() {
                    _vm.$emit(button.event)
                    _vm.$emit("close")
                  }
                }
              },
              [_c("span", [_vm._v(_vm._s(button.name))])]
            )
          }),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }