import guid from "./guid.js"
import ReactiveDao from "@live-change/dao"

function uploadFile(purpose, fileName, blob) {
  const id = guid()

  const observable = new ReactiveDao.ObservableValue({
    id,
    state: "starting",
    transferred: 0,
    percent: 0,
    size: blob.size
  })

  const xhr = new XMLHttpRequest();

  function progressHandler(evt) {
    const percent = evt.loaded/evt.total*100;
    observable.set({
      ...observable.value,
      percent,
      transferred: evt.loaded
    })
  }

  function readyStateHandler() {
    if (xhr.readyState == 4) {
      if(xhr.status == 200) {
        observable.set({
          ...observable.value,
          state: "done"
        })
      } else {
        observable.set({
          ...observable.value,
          state: "failed",
          error: xhr.status + " " + xhr.responseText
        })
      }
    }
  }

  xhr.upload.addEventListener('progress', progressHandler, false)
  xhr.addEventListener("readystatechange", readyStateHandler)
  xhr.addEventListener( "error", () => {
    observable.set({
      ...observable.value,
      state: "failed",
      error: "XHR error"
    })
  })
  const url = `${document.location.protocol}//${document.location.host}/upload/${purpose}/${fileName}/${id}`
  console.log("URL", url)
  xhr.open('POST', url, true)
  xhr.send(blob)

  return observable
}

export default uploadFile