var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.deleting
    ? _c("form-field-bind", {
        staticClass: "or-block",
        attrs: { name: _vm.name },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function(ref) {
                var value = ref.value
                return _vm._l(value, function(item, index) {
                  return _c(
                    "div",
                    [
                      index > 0
                        ? _c("div", { staticClass: "or-separator" }, [
                            _vm._v("OR")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("SingleFilter", {
                        key: _vm.name + "." + index,
                        attrs: {
                          name: _vm.name + "." + index,
                          onlyOne: _vm.onlyOne && value.length <= 1,
                          dataSet: _vm.dataSet,
                          disabled: _vm.disabled,
                          last: index == value.length - 1,
                          "last-and": _vm.last
                        },
                        on: {
                          or: function() {
                            return _vm.handleOr(index)
                          },
                          delete: function() {
                            return _vm.handleDelete(index)
                          },
                          and: function($event) {
                            return _vm.$emit("and")
                          },
                          more: function($event) {
                            return _vm.$emit("more")
                          }
                        }
                      })
                    ],
                    1
                  )
                })
              }
            }
          ],
          null,
          false,
          206933625
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }