var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.placeText,
        expression: "placeText"
      }
    ],
    ref: "input",
    class: _vm.inputClass,
    attrs: { placeholder: "Enter place", type: "text" },
    domProps: { value: _vm.placeText },
    on: {
      input: function($event) {
        if ($event.target.composing) {
          return
        }
        _vm.placeText = $event.target.value
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }