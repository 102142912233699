import XmlAlertPage from "./XmlAlertPage.vue"
import ScanPage from "./ScanPage.vue"
import ItemPage from "./ItemPage.vue"

export default function(prefix) {
  return [
    {
      path: prefix,
      name: 'xml:alert',
      component: XmlAlertPage,
      props: true,
      meta: { requiresLogin: true }
    },
/*    {
      path: prefix + '/:scanner',
      name: 'xml:alert:scanner',
      component: ScannerPage,
      props: true,
      meta: { saveForLogin: true }
    }, */
    {
      path: prefix + '/:scanner/:scan',
      name: 'xml:alert:scan',
      component: ScanPage,
      props: true,
      meta: { saveForLogin: true }
    },
    {
      path: prefix + '/:scanner/:scan/i/:position',
      name: 'xml:alert:scan:item',
      component: ItemPage,
      props: true,
      meta: { saveForLogin: true }
    }
  ]
}
