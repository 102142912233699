<template>
  <ol>
    <li v-for="element of data">
      <ObjectValue class="property-value" :data="element"
                   :viewDefinition="viewDefinition.of" :type="type">
      </ObjectValue>
    </li>
  </ol>
</template>

<script>
  import ObjectValue from "./ObjectValue.vue"

  export default {
    name: "JsonView",
    components: { ObjectValue },
    props: {
      viewDefinition: {
        type: Object,
        required: true
      },
      data: {
        required: true
      },
      type: {
        type: String,
        default: 'view'
      }
    }
  }
</script>

<style scoped>

</style>