<template>
  <button type="button" class="button" role="button" @click="handleClick"
          :disabled="disabled">
    <slot></slot>
  </button>
</template>

<script>
  export default {
    name: "SubmitButton",
    inject: ['form'],
    props: {
      parameters: {
        type: Object,
        default: () => {}
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      handleClick() {
        this.form.submit(this.parameters);
      }
    }
  }
</script>

<style scoped>

</style>