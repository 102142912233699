var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "confirm-holder" }, [
    _c("div", { staticClass: "card text-center" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "card-title" }, [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c("p", { staticClass: "card-text" }, [_vm._v(_vm._s(_vm.text))]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "buttons" },
          _vm._l(_vm.buttons, function(button) {
            return _c(
              "button",
              {
                staticClass: "button",
                attrs: { type: "button" },
                on: {
                  click: function() {
                    _vm.$emit(button.event)
                    _vm.$emit("close")
                  }
                }
              },
              [_vm._v(_vm._s(button.text))]
            )
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }