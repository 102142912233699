<template>
  <page>
    <div class="card main-card full-width">
      <command-form service="echo" action="echo" :keepOnDone="true"
                    :initialValues="{ data:'test', delay:500 }"
                    @done="handleDone">
        <text-area-field name="data"></text-area-field>
        <text-field name="delay"></text-field>
        <div class="buttons">
          <button type="submit" class="button">Send</button>
        </div>
      </command-form>
      <hr>
      <pre>{{ JSON.stringify(result, null, "  ") }}</pre>
    </div>
  </page>
</template>

<script>
  export default {
    name: "Echo",
    data() {
      return {
        result: null
      }
    },
    methods: {
      handleDone(res) {
        this.result = res
      }
    }
  }
</script>

<style scoped>

</style>