<template>
  <span class="file-input">
    <slot></slot>
    <input class="hidden-file" :name="name" type="file" :multiple="multiple" :accept="accept"
           v-on:change="handleInputChange" ref="file">
  </span>
</template>

<script>
  import { dropZoneState } from "./GlobalDropZone.js";

  export default {
    name: 'FileInput',
    props: {
      accept: {
        type: String
      },
      multiple: {
        type: Boolean,
        default: false
      },
      name: {
        type: String
      }
    },
    data () {
      return {
      }
    },
    methods: {
      handleInputChange(e) {
        let files = e.target.files || e.dataTransfer.files
        if (!files.length) return
        if(this.multiple) {
          this.handleFileSelected(files)
        } else {
          this.handleFileSelected(files[0])
        }
        this.$refs.file.value = null
      },
      handleFileSelected(file) {
        this.$emit('input', file)
      }
    },
    mounted() {
      dropZoneState.dropReceiver = (files) => {
        if(this.multiple) {
          this.handleFileSelected(files)
        } else {
          this.handleFileSelected(files[0])
        }
      }
    },
    beforeDestroy() {
      dropZoneState.dropReceiver = null
    }
  }
</script>

<style scoped>
  span.file-input {
    position: relative;
    /*display: inline-block;*/
    display: flex;
    height: auto;
  }

  input.hidden-file {
    border: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom:0;
    width: 100%;
    height: 100%;
    pointer-events: all;
    cursor: pointer;
    opacity: 0;
  }
</style>