var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tags-picker" },
    [
      _c("overlay-anchor", {
        staticClass: "card card-inverse",
        attrs: { anchor: _vm.anchor, directions: _vm.directions },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var position = ref.position
              var anchor = ref.anchor
              return [
                _c(
                  "div",
                  {
                    staticClass: "select-box mb-3",
                    style: {
                      "min-width": anchor.w + "px",
                      "max-width":
                        _vm.windowDimensions.width - position.x - 20 + "px",
                      "max-height":
                        _vm.windowDimensions.height - position.y - 20 + "px"
                    },
                    attrs: { ef: "box" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "sidebar--tags-picker" },
                      [
                        _vm._l(_vm.filteredPropositions, function(select) {
                          return _c(
                            "div",
                            {
                              staticClass: "select",
                              on: {
                                click: function($event) {
                                  return _vm.selectSelected(select.id)
                                }
                              }
                            },
                            [
                              _c("h5", { staticClass: "selectName m-2 " }, [
                                _vm._v(_vm._s(select.name))
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "selectDescription" }, [
                                _vm._v(_vm._s(select.description))
                              ])
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _vm.canAddNew
                          ? _c("div", { staticClass: "select m-2" }, [
                              _c(
                                "p",
                                {
                                  staticClass: "addDescription",
                                  on: { click: _vm.addNew }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.i18n.addNew) +
                                      ' "' +
                                      _vm._s(_vm.searchQuery.trim()) +
                                      '"\n          '
                                  )
                                ]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.filteredPropositions.length == 0 && !_vm.canAddNew
                          ? _c("div", { staticClass: "select m-2" }, [
                              _c("p", { staticClass: "alreadyAdded" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.i18n.alreadyExists(
                                        _vm.propositions[0].name
                                      )
                                    ) +
                                    "\n          "
                                )
                              ])
                            ])
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }