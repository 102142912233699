var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page",
    { attrs: { multiRow: true } },
    [
      _c("loading", { attrs: { what: _vm.user, name: "user profile data" } }, [
        _c(
          "div",
          {
            staticClass:
              "callout-wrapper callout-register callout-register-mentor"
          },
          [
            _c("div", { staticClass: "callout" }, [
              _c("h2", [_vm._v("Usuwam swoje konto")])
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content-wrapper main-card " },
          [
            _c("div", { staticClass: "card" }, [
              _c("p", [
                _vm._v(
                  "Twoje dane osobowe są przechowywane przez rok od dnia ostatniego logowania. Po tym czasie zostają\n        automatycznie wykasowane z bazy danych"
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "O ile nie otrzymywałeś od nas faktury w każdej chwili możesz żądać usunięcia Twoich danych z bazy danych\n        portalu"
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Jeśli otrzymywałeś faktury Twoje dane przechowywane będą przez 5 lat kalendarzowych od roku następującego\n        po wystawieniu ostatniej faktury. Jeśli się wyrejestrujesz, wykasujemy Twój profil z wyjątkiem danych\n        niezbędnych do obsługi faktur"
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Cofnięcie zgody na przetwarzania Twoich danych w celach marketingowych przez administratora portalu\n        jest równoznaczne z wyrejestrowaniem z portalu i usunięciem Twojego profilu"
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Zawsze możesz cofnąć udzieloną zgodę na otrzymywanie materiałów marketingowych od stron trzecich poprzez\n        odznaczenie okna w zakładce „Moje dane”. Nie spowoduje to wyrejestrowania z portalu"
                )
              ])
            ]),
            _vm._v(" "),
            _c("DeleteUser")
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }