<template>
  <div :class="cssClasses">
    <slot></slot>
    <ReadStateToggles :notification="notification" />
  </div>
</template>

<script>
  import ReadStateToggles from "./ReadStateToggles.vue"

  export default {
    name: "Notification",
    props: {
      notification: {
        type: Object,
        required: true
      }
    },
    components: {
      ReadStateToggles
    },
    computed: {
      cssClasses() {
        return [
          'notification',
          'notification-read-'+this.notification.readState,
          'notification-state-'+this.notification.state,
          'notification-type-'+this.notification.notificationType
        ]
      }
    },
    methods: {
      async mark(state) {
        return await api.command(["notifications", "mark"], { notification: this.notification.to, state })
      }
    }
  }
</script>

<style scoped>

</style>