var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "conversations-menu" },
    [
      _c(
        "overlay-anchor",
        {
          attrs: { anchor: _vm.anchor, directions: _vm.directions },
          on: { positioned: _vm.updatePosition }
        },
        [
          _c(
            "loading-zone",
            {
              staticClass: "conversations-menu-card",
              style: Object.assign({}, _vm.maxDimensions)
            },
            [_c("ConversationsList")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }