<template>
  <div class="search-result card main-card">
    <vue-code-highlight v-if="viewAsXml" language="xml" class="xml-code-view" :key="rawXml ? 'raw' : 'xml'">
      <pre>{{ rawXml ? cleanedXml : formattedXml }}</pre>
    </vue-code-highlight>
    <SearchResultData v-else :data="preparedData" ></SearchResultData>
  </div>
</template>

<script>
  import SearchResultData from "./SearchResultData.vue"
  import parseXml from "./parseXml.js"
  import xmlFormatter from 'xml-formatter'
  import { component as VueCodeHighlight } from 'vue-code-highlight'
  import 'prism-es6/components/prism-markup'
  import "vue-code-highlight/themes/prism-coy.css"

  export default {
    name: "SearchResult",
    components: { SearchResultData, VueCodeHighlight },
    props: {
      data: {
        type: Object,
        required: true
      },
      viewAsXml: {
        type: Boolean,
        default: false
      },
      rawXml: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      preparedData() {
        //console.log('DATA', JSON.stringify(this.data))
        let items = parseXml(this.data.xml)
        //console.log('ITEMS', items)
        return items[0]
      },
      cleanedXml() {
        const xml = this.data.xml
        const indentMatch = xml.match(/[\r\n]([ \t]*)<\/item>/i)
        const indent = indentMatch && indentMatch[1] || ''
        //console.log("INDENT", indentMatch, indent)
        return indent + xml
      },
      formattedXml() {
        return xmlFormatter(this.data.xml, {
          indentation: ' ',
          collapseContent: true,
          whiteSpaceAtEndOfSelfclosingTag: true
        }) || ''
      }
    },
  }
</script>

<style scoped lang="scss">

</style>