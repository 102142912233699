import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import analytics from "analytics"

import api from "api"
import i18n from "i18n"

Vue.use(Router)
Vue.use(Meta)

import PageNotFound from '../views/errors/PageNotFound'
import autoScrollHash from "common/mixins/autoScrollHash.js"
import userRoutes from "../modules/user/routes.js"

//import blogRoutes from "../modules/blog/routes.js"

import xmlSearchRoutes from "../views/xmlSearch/routes.js"
import xmlAlertRoutes from "../views/xmlAlert/routes.js"
import xmlExclusionsRoutes from "../views/xmlExclusions/routes.js"
import urlsCheckerRoutes from "../views/urls/routes.js"
import videoChatRoutes from "../modules/video-chat/routes.js"
import messengerRoutes from "../modules/messenger/routes.js"
import peerConnectionRoutes from "../modules/peer-connection/routes.js"
import adsApiRoutes from "../modules/adsapi/routes.js"

import IndexPage from '../views/IndexPage.vue'

import Echo from "../views/Echo.vue"

function delay(ms) {
  return new Promise((resolve, reject) => setTimeout(resolve, ms))
}

const routes = [
  { name: 'index', path: '/', component: IndexPage  },
  { name: 'userIndex', path: '/home', redirect: { name: 'urls' } },
  { name: 'echo', path: '/echo', component: Echo  },

  ...userRoutes("/user"),
 // ...blogRoutes("/blog"),
  ...xmlSearchRoutes("/xml/search"),
  ...xmlAlertRoutes("/xml/alert"),
  ...xmlExclusionsRoutes("/xml/exclusions"),
  ...urlsCheckerRoutes('/urls'),
  ...videoChatRoutes("/videoChat"),
  ...messengerRoutes("/messenger"),
  ...peerConnectionRoutes('/peer-connection'),
  ...adsApiRoutes('/ads-api'),

  { name: 'pageNotFound', path: '*', component: PageNotFound }
]

const sitemap = async (route, api) => {
  route({ name: 'index' })

  //await blogRoutes('/blog').sitemap(route, api)
  //await userRoutes('/user').sitemap(route, api)

  //route({ name: "contact" }, { changefreq: 'monthly', priority: 0.1 })
}

function createRouter() {

  const router = new Router({
    mode: 'history',
    routes
  })
  router.sitemap = sitemap

  router.redirectAfterCompleteData = null
  router.redirectAfterLogout = null
  router.redirectAfterLogin = null

  if(typeof window != 'undefined') window.router = router

  router.beforeEach((to, from, next) => {

    if(api.session.session.user) {
      if(to.matched && to.matched[0] && to.matched[0].meta && !to.matched[0].meta.ignoreUserDataCompletion) {
        if(to.matched[0].meta && to.matched[0].meta.userDataCompletion) { // Data completion pages
          if(!api.session.isCompletionNeeded) {
            if(router.redirectAfterCompleteData && typeof window != 'undefined') {
              next(router.redirectAfterCompleteData)
              router.redirectAfterCompleteData = null
              return
            } else {
              console.log("REDIRECT TO USER INDEX FROM COMPLETION!")
              next({ name: 'userIndex' })
              return
            }
          }
        } else { // Other pages
          if(api.session.isCompletionNeeded) {
            console.log("REDIRECT TO COMPLETION!")
            router.redirectAfterCompleteData = to.fullPath
            console.log("REDIRECT TO COMPLETION!", api.session.user)
            router.redirectAfterCompleteData = to.fullPath
            next({ name: 'user:completeData' })
            return
          }
        }
      }
    }

    if (to.matched && to.matched[0] && to.matched[0].meta) {
      if (to.matched[0].meta.requiresLogin) {
        if (!api.session.loggedIn) {
          console.log("REDIRECT TO LOGIN BECAUSE PAGE REQUIRES LOGIN!")
          router.redirectAfterLogin = to.fullPath
          next({ name: 'user:login' })
          return
        }
      }
      if (to.matched[0].meta.requiresLogout) {
        if (api.session.loggedIn) {
          console.log("REDIRECT TO USER INDEX BECAUSE PAGE REQUIRES LOGOUT!")
          next({ name: 'userIndex' })
          return
        }
      }
    }
    if(to && to.name == 'user:login' && from && from.matched[0] && from.matched[0].meta
        && from.matched[0].meta.saveForLogin) {
      console.log("SAVE FOR LOGIN", from.fullPath)
      router.redirectAfterLogin = from.fullPath
    }
    next()
  })

  let routerReactions = new Vue({
    data() {
      return {}
    },
    computed: {
      loggedIn() {
        return api.session.loggedIn
      },
      isCompletionNeeded() {
        return api.session.isCompletionNeeded
      }
    },
    watch: {
      loggedIn(v) {
        if (v) {
          console.log("LOGGED IN!")
          if (router.currentRoute.meta.requiresLogout) {
            if (router.redirectAfterLogin && typeof window != 'undefined') {
              router.replace(router.redirectAfterLogin)
              router.redirectAfterLogin = null
            } else {
              if (api.session.isCompletionNeeded) {
                router.replace({ name: 'user:completeData' })
              } else {
                router.replace({ name: 'userIndex' })
              }
            }
          }
        } else {
          console.log("LOGGED OUT!")
          if (router.currentRoute.meta.requiresLogin) {
            router.redirectAfterLogin = router.currentRoute.fullPath
            router.replace({
              name: 'user:login'
            })
            if (router.redirectAfterLogout && typeof window != 'undefined') {
              router.redirectAfterLogout = null
            } else {
              router.redirectAfterLogin = router.currentRoute.fullPath
              router.replace({
                name: 'user:login'
              })
            }
          }
        }
      },
      isCompletionNeeded(v) {
        if (!v) {
          console.log("COMPLETION IS NOW NOT NEEDED")
          if (router.currentRoute.meta.userDataCompletion) {
            if (router.redirectAfterCompleteData && typeof window != 'undefined') {
              router.replace(router.redirectAfterCompleteData)
            } else {
              router.replace({ name: 'userIndex' })
            }
          }
        } else {
          console.log("COMPLETION IS NOW NOT NEEDED")
          if (!router.currentRoute.meta.userDataCompletion) {
            router.redirectAfterCompleteData = router.currentRoute.fullPath
            router.replace({ name: 'user:completeData' })
          }
        }
      }
    }
  })

  router.afterEach((to, from) => {
    analytics.pageView(to.path)
    setTimeout(() => {
      router.loadingDone()
    }, 23)
  })

  router.hashScrollLocks = {}

  router.loadingDone = () => {
    const to = router.currentRoute
    if(to.hash && !router.hashScrollLocks[to.hash.slice(1)]) {
      const element = document.querySelector(to.hash)
      console.log("HASH", to.hash, "ELEMENT", element, "LOCKS:", JSON.stringify(router.hashScrollLocks))
      if(element) element.scrollIntoView()
    }
    if(typeof allLoadingTasks == 'undefined') return
    if(allLoadingTasks.length == 0 && !router.prebootCompleted && typeof preboot != 'undefined') {
      console.log("PREBOOT COMPLETE!")
      router.prebootCompleted = true
      preboot.complete()
    }
  }

  return router

}

if(typeof window != 'undefined') {
  window.history.scrollRestoration = 'manual'
  setTimeout(() => {
    if(allLoadingTasks.length == 0) router.loadingDone()
  }, 100)
}

export default createRouter

