<template>
  <div class="card main-card xml-alert-scanner">
    <div class="xml-alert-scanner-info" v-if="showUrl">
      <div class="xml-alert-scanner-url">
        <a class="dataSet-url" :href="scanner.url">{{ scanner.url }}</a>
      </div>
      <div class="xml-alert-scanner-state">{{ i18n.scannerState[scanner.state] }}</div>
    </div>
    <div class="xml-alert-scanner-info">
      <div v-if="scanner.nextScan">
        <b>{{ i18n.nextScan }}</b>
        <time :datetime="scanner.nextScan">{{ scanner.nextScan | date }} {{ scanner.nextScan | hour }}</time>
      </div>
      <div v-if="!showUrl" class="xml-alert-scanner-state">
        {{ i18n.scannerState[scanner.state] }}
      </div>
    </div>
    <observe name="lastScans"
             :what="['xmlAlert', 'scansByScanner', { scanner: scanner.to || scanner.id, reverse: true, limit: 2 }]"
             v-slot="{ value: scans }">
      <ScanCard v-for="scan in scans.filter(s => s.state != 'done').slice(0, 1)"
                :scan="scan" :scanner="scanner" :key="scan.id"></ScanCard>
      <ScanCard v-for="scan in scans.filter(s => s.state == 'done').slice(0, 1)"
                :scan="scan" :scanner="scanner" :key="scan.id"></ScanCard>
    </observe>
    <div class="buttons scanner-buttons">
      <div class="severity-select">
        <label>{{ i18n.smsSeverity }}</label>
        <SingleSelectInput :options="severityLevels" :value="scanner.notifications.sms"
                           :text="level => i18n.severity[level]"
                           @input="value => setNotificationLevel('sms', value)">
        </SingleSelectInput>
      </div>

      <div class="severity-select">
        <label>{{ i18n.emailSeverity }}</label>
        <SingleSelectInput :options="severityLevels" :value="scanner.notifications.email"
                           :text="level => i18n.severity[level]"
                           @input="value => setNotificationLevel('email', value)">
        </SingleSelectInput>
      </div>

      <div class="interval-select">
        <label>{{ i18n.scanInterval }}</label>
        <SingleSelectInput :options="scanIntervals" :value="scanner.interval"
                           :text="interval => i18n.scanIntervals[interval]"
                           @input="value => setScanInterval(value)">
        </SingleSelectInput>
      </div>

      <!--<button type="button" class="button" disabled>
        <img src="/static/icons/history.svg"><span>History</span>
      </button>-->
      <button type="button" class="button" @click="deleteScanner" v-if="deleteScannerButton !== false">
        <img src="/static/icons/delete_forever.svg"><span>{{ deleteScannerButton || i18n.deleteScanner }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import ScanCard from "./ScanCard.vue"
import SingleSelectInput from "common/components/inputs/SingleSelectInput.vue"
import api from "api"

export default {
  name: "ScannerCard",
  props: {
    scanner: {
      type: Object,
      required: true
    },
    showUrl: {
      type: Boolean,
      default: false
    },
    deleteScannerButton: {
      type: String
    }
  },
  components: { ScanCard, SingleSelectInput },
  inject: ['workingZone'],
  computed: {
    i18n() {
      return i18n().xmlAlert
    },
    severityLevels() {
      return ['never', 'fatal', 'error', 'warning', 'notice']
    },
    scanIntervals() {
      return api.actions.xmlAlert.setInterval.definition.properties.interval.options
    },
  },
  methods: {
    setNotificationLevel(type, severity) {
      const scanner = this.scanner.to || this.scanner.id
      this.workingZone.addPromise('set scanner '+scanner+' notification level '+type+' to '+ severity,
          api.command(['xmlAlert', 'setNotificationLevel'], {
            scanner,
            notificationType: type,
            notificationLevel: severity
          }))
    },
    setScanInterval(interval) {
      const scanner = this.scanner.to || this.scanner.id
      this.workingZone.addPromise('set scanner '+scanner+' interval to '+interval,
          api.command(['xmlAlert', 'setInterval'], {
            scanner,
            interval
          }))
    },
    deleteScanner() {
      const scanner = this.scanner.to || this.scanner.id
      this.workingZone.addPromise('delete scanner '+scanner,
          api.command(['xmlAlert', 'deleteScanner'], { scanner }))
    }
  }
}
</script>

<style scoped>

</style>