<template>
  <page>
    <div class="card half-width">
      <h2>{{ i18n.title }}</h2>
      <command-form service="users" action="completeUserData" :initialValues="me">
        <template v-slot:done>
          <p>{{ i18n.dataCompleted }}</p>
        </template>
        <template v-slot:default>
          <text-field name="name" :label="i18n.name" :placeholder="i18n.namePlaceholder"
                      inputClass="form-control-lg"></text-field>
          <boolean-field name="agreement1">
            <template v-slot:label>
              {{ i18n.agreement1 }} <a :href="i18n.policy">Privacy Policy</a>
            </template>
          </boolean-field>
          <boolean-field name="agreement2">
            <template v-slot:label>
              {{ i18n.agreement2 }}
            </template>
          </boolean-field>
          <phone-field-with-confirm name="phone" :label="i18n.phone" key="phoneField"
                                    confirmName="phoneConfirm" :confirmTexts="i18n.confirmSms"
                                    :comment="i18n.phoneComment">
          </phone-field-with-confirm>
          <form-field-bind name="phone" v-slot="{ value: phone }">
            <div class="buttons" v-if="phone">
              <button type="submit" class="button" role="button">{{ i18n.save }}</button>
            </div>
          </form-field-bind>
        </template>
      </command-form>
    </div>
  </page>
</template>

<script>
  import i18n from "i18n"
  import api from "api"
  import PhoneFieldWithConfirm from "common/components/fields/PhoneFieldWithConfirm"

  export default {
    name: "CompleteData",
    components: { PhoneFieldWithConfirm },
    computed: {
      i18n() { return i18n().user.complete },
      me() { return api.session.user }
    }
  }
</script>

<style scoped>

</style>
