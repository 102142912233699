import { render, staticRenderFns } from "./UserTagsEditor.vue?vue&type=template&id=da9bbad4&scoped=true&"
import script from "./UserTagsEditor.vue?vue&type=script&lang=js&"
export * from "./UserTagsEditor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da9bbad4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/source/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('da9bbad4')) {
      api.createRecord('da9bbad4', component.options)
    } else {
      api.reload('da9bbad4', component.options)
    }
    module.hot.accept("./UserTagsEditor.vue?vue&type=template&id=da9bbad4&scoped=true&", function () {
      api.rerender('da9bbad4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/tags/UserTagsEditor.vue"
export default component.exports