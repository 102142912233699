var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form-field-bind", {
    attrs: { name: _vm.name },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(ref) {
            var value = ref.value
            var error = ref.error
            return [
              _c(
                "b-form-group",
                { attrs: { label: _vm.label || _vm.name } },
                [
                  _c(
                    "ul",
                    _vm._l(value, function(val, id) {
                      return _c(
                        "li",
                        { key: val },
                        [
                          _vm._t(_vm.name + "." + id, function() {
                            return [
                              _c(_vm.editorComponent(), {
                                tag: "component",
                                attrs: {
                                  name: _vm.name + "." + id,
                                  label: "" + id
                                }
                              })
                            ]
                          }),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-danger",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.remove(id)
                                }
                              }
                            },
                            [_vm._v("×")]
                          )
                        ],
                        2
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  error
                    ? _c("small", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n      " +
                            _vm._s(
                              (_vm.errorText && _vm.errorText[error]) || error
                            ) +
                            "\n    "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("b-button", { on: { click: _vm.add } }, [
                    _vm._v("Add Element")
                  ])
                ],
                1
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }