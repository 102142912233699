<template>
  <div class="video-display" ref="videoDisplay">
    <pre style="display: none">{{ JSON.stringify(videoSizes, null, "  ") }}</pre>
    <!--<pre style="display: none">{{ JSON.stringify(allVisibleVideos, null, "  ") }}</pre>
    <pre style="display: none">{{ JSON.stringify(allVideos, null, "  ") }}</pre>-->
    <VideoDisplayVideo v-for="video in allVideos" :style="videoStyles[video.id]"
                       :key="video.id" :video="video" :volume="volume"
                       @videoResize="ev => handleVideoResize(video.id, ev)"
                       @click="ev => handleClick(video, ev)">
    </VideoDisplayVideo>
  </div>
</template>

<script>

import VideoDisplayVideo from "./VideoDisplayVideo.vue"

export default {
  name: "VideoDisplay",
  components: { VideoDisplayVideo },
  props: {
    mainVideos: {
      type: Array,
      default: () => [] // testData.mainVideos
    },
    topVideos: {
      type: Array,
      default: () => [] // testData.otherVideos
    },
    otherVideos: {
      type: Array,
      default: () => [] // testData.otherVideos
    },
    myVideos: {
      type: Array,
      default: () => [] // testData.myVideos
    },
    topBarHeight: {
      type: Number,
      default: 80
    },
    bottomBarHeight: {
      type: Number,
      default: 80
    },
    volume: {
      type: Number,
      defaultValue: 1
    }
  },
  data() {
    return {
      areaSize: {
        width: 0,
        height: 0
      },
      videoSizes: {},
      allVideos: []
    }
  },
  computed: {
    allVisibleVideos() {
      return [].concat(this.mainVideos, this.topVideos, this.otherVideos, this.myVideos)
    },
    videoStyles() {
      if(typeof window == 'undefined') return {}
      const areaSize = this.areaSize
      const mainVideos = this.mainVideos
      const topVideos = this.topVideos
      const otherVideos = this.otherVideos
      const myVideos = this.myVideos

      if(!areaSize.width || !areaSize.height) return {}

      let styles = {}
      const bottomBarVisible = this.otherVideos.length > 0  || this.myVideos.length > 0
      const bottomBarHeight = bottomBarVisible ? this.bottomBarHeight : 0

      let right = 0
      for(const myVideo of myVideos) {
        const size = this.videoSizes[myVideo.id]
        if(!size || !size.width || !size.height) {
          styles[myVideo.id] = { display: 'none' }
          continue
        }
        const { width, height } = size
        const ratio = width/height
        const newWidth = bottomBarHeight * ratio
        console.log('NW', newWidth, ratio)
        styles[myVideo.id] = {
          width: newWidth + 'px',
          height: bottomBarHeight + 'px',
          bottom: '0',
          right: right + 'px'
        }
        right += newWidth
      }

      const topBarVisible = this.topVideos.length > 0
      const topBarHeight = topBarVisible ? this.topBarHeight : 0

      this.allocateSpace(0, areaSize.height - bottomBarHeight,
          areaSize.width - right, bottomBarHeight,
          otherVideos, styles)

      this.allocateSpace(0, 0,
          areaSize.width, topBarHeight,
          topVideos, styles)

      this.allocateSpace(0, topBarHeight,
          areaSize.width, areaSize.height - bottomBarHeight - topBarHeight,
          mainVideos, styles)

      return styles
    }
  },
  watch: {
    allVisibleVideos(videos) {
      this.updateVideos()
    }
  },
  methods: {
    updateVideos() {
      for(const video of this.allVisibleVideos) {
        const index = this.allVideos.findIndex(v => v.id == video.id)
        if(index == -1) {
          console.info("ADD REMOTE VIDEO", video)
          this.allVideos.push(video)
        } else {
          console.info("UPDATE REMOTE VIDEO", video)
          this.allVideos.splice(index, 1, video)
        }
      }
      for(const video of this.allVideos) {
        if(!this.allVisibleVideos.find(v => v.id == video.id)) {
          console.info("REMOVE REMOTE VIDEO?", video)
          this.$nextTick(() => {
            if(!this.allVisibleVideos.find(v => v.id == video.id)) {
              const index = this.allVideos.findIndex(v => v.id == video.id)
              if(index != -1) {
                console.info("REMOVE REMOTE VIDEO!", video)
                this.allVideos.splice(index, 1)
              }
            }
          })
        }
      }
    },
    handleClick(video, event) {
      this.$emit('videoClick', { ...event, video })
    },
    allocateSpace(left, top, width, height, videos, styles) {
      console.log("ALLOCATE SPACE FOR VIDEOS", videos)
      if(!width || !height) return
      let visibleVideos = []
      let allRatioSum = 0
      for(let video of videos) {
        const size = this.videoSizes[video.id]
        if(!size || !size.width || !size.height) {
          styles[video.id] = { display: 'none' }
          continue
        }
        const { width, height } = size
        allRatioSum += width / height
        visibleVideos.push({ id: video.id, width, height })
      }
      /// check all options, find best fill
      console.log("SEARCH FOR BEST FILL FOR AREA", width, height, "FOR VIDEOS", visibleVideos)
      if(visibleVideos.length == 0) return
      const aspectRatio = width / height
      const availableArea = width * height
      let bestFill = { area: 0 }
      for(let rows = 1; rows <= videos.length; rows++) {
        const breakingPoint = (allRatioSum / rows)
        let ratioSum = 0
        let maxRatioSum = 0
        let row = 0
        for(const video of visibleVideos) { // find maximal aspect ratio sum (maximal row width)
          const canBreak = (row < rows - 1)
          const ratio = video.width / video.height
          if(ratioSum + ratio * 0.5 > breakingPoint && canBreak) { // break!
            //console.log("BREAK ROW BEFORE SUM!")
            row += 1
            if(ratioSum > maxRatioSum) maxRatioSum = ratioSum
            ratioSum = 0
          }
          ratioSum += ratio
          //console.log("RATIO SUM", ratioSum, "ROW", row)
          if(ratioSum > breakingPoint && canBreak) { // break!
            //console.log("BREAK ROW AFTER SUM!")
            row += 1
            if(ratioSum > maxRatioSum) maxRatioSum = ratioSum
            ratioSum = 0
          }
        }
        if(ratioSum > maxRatioSum) maxRatioSum = ratioSum
        // compute area and fill:
        const ratio = maxRatioSum / rows
        let filledWidth, filledHeight
        if(ratio > aspectRatio) { // empty bottom and top
          filledWidth = width
          filledHeight = filledWidth / ratio
        } else { // empty left and right
          filledHeight = height
          filledWidth = filledHeight * ratio
        }
        const filledArea = filledWidth * filledHeight
        if(filledArea > bestFill.area) {
          bestFill = { rows, area: filledArea, filledWidth, filledHeight }
        } else {
          console.log("ROWS", rows, "RATIO", ratio, "FILL", filledArea / availableArea)
          console.log("VIDEOS RATIO SUM", maxRatioSum, "ASPECT RATIO", aspectRatio)
        }
      }
      //console.log("BEST FILL", bestFill)

      if(!bestFill.rows) throw new Error("Couldn't find best fill!?!")

      /// we determined rows count, time to place videos
      let ratioSum = 0
      let row = 0
      let rowVideos = []
      let leftSum = 0
      const { rows, filledWidth, filledHeight } = bestFill
      const leftMargin = (width - filledWidth) / 2
      const topMargin = (height - filledHeight) / 2
      const rowHeight = filledHeight / rows
      const breakingPoint = (allRatioSum / rows)

      //console.log("rowHeight", rowHeight)

      const placeVideosInRow = (rowLeftMargin, rowVideos) => {
        for(const video of rowVideos) {
          const { left, width } = video
          //console.log("PLACE VIDEO", video)
          styles[video.id] = {
            width: width + 'px',
            height: rowHeight + 'px',
            left: (left + leftMargin + rowLeftMargin) + 'px',
            top: (top + topMargin + rowHeight * row) + 'px'
          }
          //console.log("VIDEO STYLE", video.id,  styles[video.id])
        }
      }


      for(const video of visibleVideos) {
        const canBreak = (row < rows - 1)
        const ratio = video.width / video.height
        if(ratioSum + ratio * 0.5 > breakingPoint && canBreak) { // break!
          //console.log("BREAK ROW BEFORE SUM!")
          const rowLeftMargin = (filledWidth - leftSum) / 2
          //console.log("ROW LEFT MARGIN", rowLeftMargin)
          placeVideosInRow(rowLeftMargin, rowVideos)
          row += 1
          leftSum = 0
          ratioSum = 0
          rowVideos = []
        }
        ratioSum += ratio
        const width = rowHeight * ratio
        rowVideos.push({ id: video.id, left: leftSum, width })
        leftSum += width
        //console.log("RATIO SUM", ratioSum, "ROW", row)
        if(ratioSum > breakingPoint && canBreak) { // break!
          //console.log("BREAK ROW AFTER SUM!")
          const rowLeftMargin = (filledWidth - leftSum) / 2
          //console.log("ROW LEFT MARGIN", rowLeftMargin)
          placeVideosInRow(rowLeftMargin, rowVideos)
          row += 1
          leftSum = 0
          ratioSum = 0
          rowVideos = []
        }
        if(ratioSum >= (allRatioSum / rows) * (row + 1) && row < rows - 1) { // break!
        }
      }
      if(rowVideos.length > 0) {
        const rowLeftMargin = (filledWidth - leftSum) / 2
        placeVideosInRow(rowLeftMargin, rowVideos)
      }
    },
    handleVideoResize(id, { width, height }) {
      this.$set(this.videoSizes, id, { width , height })
    }
  },
  mounted() {
    this.areaElement = this.$refs.videoDisplay || this.$el
    //console.log("AREA ELEMENT", this.areaElement)
    this.areaSize = {
      width: this.areaElement.clientWidth,
      height: this.areaElement.clientHeight
    }
    this.resizeListener = () => {
      try {
        if(this.areaSize.width != this.areaElement.clientWidth
            || this.areaSize.height != this.areaElement.clientHeight) {
          this.areaSize = {
            width: this.areaElement.clientWidth,
            height: this.areaElement.clientHeight
          }
        }
      } catch(e) { // ignore error
        console.error("RESIZE LISTENER ERROR", e)
      }
    }
    if(typeof ResizeObserver != 'undefined') {
      this.resizeObserver = new ResizeObserver(this.resizeListener)
      this.resizeObserver.observe(this.areaElement)
    } else {
      window.addEventListener('resize', this.resizeListener)
      this.resizeInterval = setInterval(this.resizeListener, 200)
    }

    this.updateVideos()
  },
  beforeDestroy() {
    if(typeof ResizeObserver != 'undefined') {
      this.resizeObserver.unobserve(this.areaElement)
    } else {
      window.removeEventListener('resize', this.resizeListener)
      clearInterval(this.resizeInterval)
    }
  }
}

const testData = {
  mainVideos: [
    {
      id: '1',
      type: 'image',
      src: '/static/video-test/1280x960.png',
    },
    {
      id: '2',
      type: 'image',
      src: '/static/video-test/2048x1080.png',
    },
    {
      id: '3',
      type: 'image',
      src: '/static/video-test/1280x720.png',
    },
    {
      id: '3b',
      type: 'image',
      src: '/static/video-test/1280x720.png',
    }
  ],
  otherVideos: [
    {
      id: '4',
      type: 'image',
      src: '/static/video-test/1280x960.png',
    },
    {
      id: '5',
      type: 'image',
      src: '/static/video-test/1280x720.png',
    },
    {
      id: '6',
      type: 'image',
      src: '/static/video-test/2048x1080.png',
    }
  ],
  myVideos: [
    {
      id: 'me',
      type: 'image',
      src: '/static/video-test/2048x1080.png',
    }
  ],
}

</script>

<style scoped>

</style>