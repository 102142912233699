<template>
  <loading :what="value" :name="tagsType + ' tags list value'">
    <div v-if="value !== undefined" class="tags-list-input">
      <span v-if="value.length == 0">{{ i18n.empty }}</span>
      <span v-for="tagId in value">
        <observe :what="['tags', 'TagOne', { tag: tagId }]" v-slot="{ value: tag }" class="tag-pill">
          <span class="tag-name" @click="searchForTag(tag.name)">{{ tag.name }}</span>
          <img class="remove-tag" src="/static/icons/clear.svg" alt="remove" />
        </observe>
      </span>
      <input type="text" class="input new-tag-input" :placeholder="i18n.typeIn" v-model="tagInput" ref="input">
      <!-- <div class="input-group-append">
        <button type="submit" class="btn btn-secondary"
                disabled="searchQuery.trim().length < 2"
                @click="addNew(tagInput); tagInput = ''">Add</button>
      </div> -->
    </div>
  </loading>
</template>

<script>
  import i18n from "i18n"
  import api from "@/api"
  import TagsPicker from "../tags/TagsPicker.vue";
  import overlayModel from "common/components/utils/overlayModel.js"

  export default {
    name: "TagsListInput",
    props: {
      value: {
      },
      tagsType: {
        type: String,
      },
      i18n: {
        type: Object,
        required: true
      }
    },
    inject: ['workingZone'],
    data() {
      return {
        tagInput: '',
        searchQuery: '',
        queryId: 0,
        lastResultId: 0,
        queryResults: null
      }
    },
    computed: {
      overlayVisible() {
        if(this.searchQuery.trim().length > 2 && this.queryResults) return true
        /*if(this.propositions && this.propositions.length > 0) return true
        if(this.searchQuery.trim().length > 3 && this.queryResults && this.queryResults.length==0) return true*/
        return false
      }
    },
    watch: {
      tagInput(v) {
        if (this.searchTimeout) clearTimeout(this.searchTimeout)
        this.timeout = setTimeout(() => this.searchQuery = v, 300)
      },
      searchQuery(query) {
        if (query.trim().length < 3) {
          this.queryResults = null
          return
        }
        const resultId = ++this.queryId
        api.get(['tags', 'findTags', { tagsType: this.tagsType, query }]).then((results) => {
          if (resultId < this.lastResultId) return; /// Too late
          this.lastResultId = resultId
          this.queryResults = results
          console.log("RESULTS", results)
        }).catch((err) => {
          if (resultId < this.lastResultId) return; /// Too late
          this.lastResultId = resultId
          this.queryResults = null
        })
        if (this.overlayVisible) this.showOverlay()
      },
      propositions() {
        if (this.overlayVisible) this.showOverlay()
      },
      overlayVisible(v) {
        if (v) {
          this.showOverlay()
        } else {
          this.hideOverlay()
        }
      },
    },
    methods: {
      addNew(name) {
        this.workingZone.addPromise("addUserDefinedTag",
            api.command(["tags", "proposeTag"], { tagType: this.tagsType, name, language: i18n.config.language })
                .then(tag => this.select(tag))
        )
      },
      select(id) {
        this.$emit("selected", id)
        const newValue = this.value.concat([id])
        this.$emit("input", newValue)
      },
      deselect(id) {
        this.$emit("deselected", id)
        let newValue = this.value.slice()
        const index = this.value.indexOf(id)
        if(index != -1) newValue.splice(index, 1)
        this.$emit("input", newValue)
      },
      isSelected(id) {
        if(!this.selectedTags) return false
        return this.selectedTags.find(g => g == id)
      },
      showOverlay() {
        if(this.overlayStackId) {
          overlayModel.close(this.overlayStackId)
        }
        this.overlayStackId = overlayModel.show({
          component: TagsPicker,
          props: {
            anchor: overlayModel.elementAnchor(this.$refs.input),
            propositions: this.queryResults,
            current: this.value,
            searchQuery: this.searchQuery,
            i18n: this.i18n,
            tagsType: this.tagsType
          },
          on: {
            selected: (id) => {
              this.select(id)
              this.tagInput = ""
            },
            add: (name) => {
              this.addNew(name)
              this.tagInput = ""
            }
          }
        })
      },
      hideOverlay() {
        if(!this.overlayStackId) return;
        overlayModel.close(this.overlayStackId)
        this.overlayStackId = null
        this.lastCloseTime = Date.now()
      },
      searchForTag(name){
        if(name !== this.$route.params.query){
          this.$router.replace({name: "searchPage", params: {query: name}})
        }
      }
    },
  }
</script>

<style scoped>
  .clickable {
    cursor: pointer
  }
</style>
