var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "country",
      staticClass: "country-input select-input",
      class: {
        "is-invalid": _vm.invalid || (_vm.required && _vm.empty),
        "is-dirty": !_vm.empty
      },
      on: { click: _vm.showCountryMenu }
    },
    [
      _c("span", { class: "select-input-value " + _vm.inputClass }, [
        !_vm.empty
          ? _c("span", {
              staticClass: "countryFlag cf-32",
              class: "cf-" + _vm.liveValue.toLowerCase()
            })
          : _vm._e(),
        _vm._v(" "),
        _c("span", { staticClass: "country-label" }, [_vm._v(_vm._s(_vm.name))])
      ]),
      _vm._v(" "),
      _vm._m(0)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "select-input-more" }, [
      _c("img", { attrs: { src: "/static/icons/arrow_drop_down.svg" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }