var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("page", [
    _c(
      "div",
      { staticClass: "card half-width main-card" },
      [
        _c("h2", [_vm._v(_vm._s(_vm.i18n.title))]),
        _vm._v(" "),
        _c(
          "command-form",
          {
            key: "createRoom",
            attrs: {
              service: "room",
              action: "createRoom",
              parameters: {
                publicUserAccessRole: "speaker",
                publicGuestAccessRole: "speaker"
              }
            },
            on: { done: _vm.handleRoomCreated }
          },
          [
            _c("text-field", { attrs: { name: "name", label: _vm.i18n.name } }),
            _vm._v(" "),
            _c("div", { staticClass: "buttons" }, [
              _c(
                "button",
                { staticClass: "button", attrs: { type: "submit" } },
                [_vm._v(_vm._s(_vm.i18n.createButton))]
              )
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }