<template>
  <loading-zone class="chat">
    <Messages :toType="chatType" :toId="chatId"></Messages>
    <MessageInput :message-parameters="{ toType: chatType, toId: chatId }"></MessageInput>
  </loading-zone>
</template>

<script>
  import Messages from "./Messages.vue"
  import MessageInput from "./MessageInput.vue"

  export default {
    name: "Chat",
    components: { Messages, MessageInput },
    props: {
      chatType: {
        type: String,
        required: true
      },
      chatId: {
        type: String,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>