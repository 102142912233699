<template>
  <form-field-bind :name="name" v-slot="{ value, setValue, error }">
    <FieldTemplate :errorText="errorText" :error="error" :label="label" :name="name">
      <div v-if="value" @click="edit">
        <uploaded-picture-img :picture="value" class="picture-field-picture" :reactive="true"></uploaded-picture-img>
      </div>
      <div class="picture-field-buttons buttons">
        <button v-if="value" type="button" class="button" @click="edit">
          <img src="/static/icons/edit.svg" />
          <span>{{ i18n.pictEdit }}</span>
        </button>
        <button v-if="value" type="button" class="button" @click="remove">
          <img src="/static/icons/delete_forever.svg" />
          <span>{{ i18n.pictDel }}</span>
        </button>
      </div>
      <div v-if="!value">
        <FileInput accept="image/*" @input="handleFile" class="button">{{ i18n.pictUp }}</FileInput>
      </div>
    </FieldTemplate>
  </form-field-bind>
</template>

<script>
  import i18n from "i18n"
  import api from "@/api"
  import FieldTemplate from "./FieldTemplate.vue"
  import FileInput from "../inputs/FileInput.vue"
  import overlayModel from "common/components/utils/overlayModel.js"
  import PictureEditor from "../inputs/PictureEditor.vue"

  export default {
    name: "PictureField",
    components: { FieldTemplate, FileInput },
    inject: ['form'],
    props: {
      name: {
        type: String,
        required: true
      },
      label: {
        type: String
      },
      errorText: {
        type: Object
      },
      width : {
        type: Number
      },
      height: {
        type: Number
      },
      pictureEditorType: {
        type: String,
        default: 'circle'
      }
    },
    data() {
      return {
        value: null,
      }
    },
    reactive: {
      picture() { return this.value && [ 'pictures', 'PictureOne', { picture: this.value } ] }
    },
    watch: {
    },
     computed: {
      i18n() { return i18n().pictureField }
    },
    methods: {
      handleFile(file) {
        this.showMenu(file)
      },
      showMenu(uploadedFile) {
        this.overlayStackId = overlayModel.show({
          component: PictureEditor,
          props: {
            uploadedFile,
            picture: this.value,
            purpose: `${this.form.service}-${this.form.action}-${this.name}`,
            width: this.width,
            height: this.height,
            type: this.pictureEditorType
          },
          on: {
            update: (picture) => {
              this.form.setFieldValue(this.name, picture)
              this.$emit('uploaded')
            }
          }
        })
      },
      edit() {
        this.showMenu(null)
      },
      remove() {
        this.form.setFieldValue(this.name, null)
        this.$emit('removed')
      }
    },
    created() {
      this.valueObserver = (v) => {
        this.value = v
        //console.log("V", v)
      }
      this.form.observe(this.name, this.valueObserver)
    },
    beforeDestroy() {
      this.form.unobserve(this.name, this.valueObserver)
    }
  }

</script>
