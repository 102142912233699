const env = require("../../env.js")
const moment = require("moment")
module.exports = {
  access: {
  },
  campaigns: {
    title: "Google Ads Campaigns",
    refresh: "Refresh",
    expand: "Expand",
    collapse: "Collapse",
    periodAdjective: {
      'unspecified': 'unspecified period',
      'unknown': 'unknown period',
      'daily': '',
      'custom': 'custom period'
    },
    status: {
      'unspecified': 'Unspecified',
      'unknown': 'Unknown',
      'enabled': 'Enabled',
      'paused': 'Paused',
      'removed': 'Removed'
    },
    servingStatus: {
      'unspecified': 'Unspecified',
      'unknown': 'Unknown',
      'serving': 'Serving',
      'none': 'None',
      'ended': 'Ended',
      'pending': 'Pending',
      'suspended': 'Suspended'
    },
    insufficientFundsThreshold: "Insufficient funds threshold",
    checkInterval: "Check interval",
    checkIntervals: {
      'never': 'Never',
      'daily': 'Daily',
      'weekly': 'Weekly'
    },
    insufficientFunds: "Insufficient funds",
    settingsState: {
      'initial': 'Initial',
      'saving': 'Saving',
      'saved': 'Saved',
    },
    connectAdsApi: "Connect to Ads API",
  },
  notifications: {
    checkError: "Ads account check found errors",
    checkDone: "Ads account check ok",
  },
  emailNotifications: {
    checkResultsEmail: ({ user, email, account, userOwnedAccount, results }) => ({
      from: 'Vole Apps <hello@apps.vole.pl>',
      to: `${user.display} <${email}>`,
      subject: `Ads account "${account.accountName}" (${account.accountId}) / "${account.name}" (${account.id}) campaigns issues detected!`,
      text: require('./checkResultsEmail.text.ejs')({ user, email, account, userOwnedAccount, results, env }),
      html: require('./checkResultsEmail.html.ejs')({ user, email, account, userOwnedAccount, results, env })
    })
  }
}
