var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "card" },
    [
      _c(
        "command-form",
        {
          attrs: { name: "deleteUser", service: "users", action: "deleteMe" },
          on: { submit: _vm.handleSubmit, done: _vm.handleDone }
        },
        [
          _c("p", [
            _vm._v(
              "Usunięcie konta kasuje dane użytkownika i nie będzie możliwe jego przywrócenie."
            )
          ]),
          _vm._v(" "),
          _c("boolean-field", {
            attrs: {
              name: "confirm",
              label: "Zaznacz tylko jeśli na pewno chcesz usunąć konto"
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "buttons" }, [
            _c(
              "button",
              {
                staticClass: "button",
                attrs: { type: "submit", role: "button" }
              },
              [_c("span", [_vm._v("Usuń Konto")])]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }