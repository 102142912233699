var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isWaiting
    ? _c("div", { staticClass: "social-login-buttons" }, [
        _vm.googleAvailable
          ? _c(
              "button",
              {
                staticClass: "google-login-button button",
                on: { click: _vm.google }
              },
              [
                _c("img", { attrs: { src: "/static/google.svg" } }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.i18n.googleButton))])
              ]
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }