<template>
  <div class="form-field">
    <div v-for="category in topCategories">
      <observe :what="['categories', 'subcategories', { category: category.id }]"
               v-slot="{ value: subCategories }">
        <div :class="'topCategory clickable '+categoryClass(category, subCategories)"
             @click="() => toggleAllSubCategories(category, subCategories)">
          <div class="top-category-header">
            <div class="radioCircle" ></div>
          </div>
          <h3>{{ categoryTitle(category) }}</h3>
          <p>{{ categoryDescription(category) }}</p>
          <div class="tailor-link">
            <a class="text-light-purple" href="#"
               @click="(ev) => { ev.preventDefault(); ev.stopImmediatePropagation(); tailor(category) }">
              {{ i18n.tailor }}
            </a>
          </div>
        </div>
      </observe>
    </div>
  </div>
</template>

<script>
  import i18n from "i18n"
  import overlayModel from "common/components/utils/overlayModel.js"
  import CategoriesSubModal from "./CategoriesSubModal.vue";

  export default {
    name: "CategoriesField",
    inject: ['form'],
    props: {
      name: {
        type: String,
        required: true
      },
      root: {
        type: String,
        default: 'root'
      }
    },
    data() {
      return {
        value: [],
        active: [],
        detailsModal: null

      }
    },
    reactive: {
      topCategories() {
        return ['categories', 'subcategories', { category: this.root }]
      }
    },
    computed: {
      lang() { return i18n.config.language },
      i18n() { return i18n().categories.field },
    },
    methods: {
      categoryClass(category, subCategories) {
        if(!this.value) return "topCategory-noneSelected"
        const subCategoriesSelected = this.value.filter(c => c[0] == category.id)
        if(subCategoriesSelected.length == 0) return "topCategory-noneSelected"
        if(subCategoriesSelected.length < subCategories.length) return "topCategory-someSelected"
        return "topCategory-allSelected"
      },
      toggleAllSubCategories(category, subCategories) {
        let newValue = this.value ? this.value.filter(c => c[0] != category.id) : []
        const subCategoriesSelected = this.value.filter(c => c[0] == category.id)
        if(subCategoriesSelected.length == 0) {
          newValue = newValue.concat(subCategories.map(sc => [category.id, sc.id]))
        }
        this.$emit('toggled', newValue, 'kurwa')

        this.form.setFieldValue(this.name, newValue)
      },
      categoryTitle(category) {
        const src = category.translations[this.lang] || category
        return src.name || category.name
      },
      categoryDescription(category) {
        const src = category.translations[this.lang] || category
        return src.description || category.description
      },
      tailor(category) {
        overlayModel.show({
          component: CategoriesSubModal,
          props: {
            parent: category,
            value: this.value.filter(c => c[0] == category.id)
          },
          on: {
            selected: (subCategory) => {
              console.log(" SUB SELECTED", [category.id, subCategory] )
              let newValue = this.value
                  ? this.value.filter(c => !(c[0] == category.id && c[1] == subCategory))
                  : []
              newValue.push([category.id, subCategory])
              this.form.setFieldValue(this.name, newValue)
              this.$emit('selected', {category: category.id, subCategory: subCategory})
            },
            deselected: (subCategory) => {
              console.log(" SUB DESELECTED", [category.id, subCategory] )
              let newValue = this.value
                  ? this.value.filter(c => !(c[0] == category.id && c[1] == subCategory))
                  : []
              this.form.setFieldValue(this.name, newValue)
              this.$emit('deselected', {category: category.id, subCategory: subCategory})
            }
          }
        })
      }
    },
    created() {
      this.valueObserver = (v) => {
        this.value = v
      }
      this.form.observe(this.name, this.valueObserver)
    },
    beforeDestroy() {
      this.form.unobserve(this.name, this.valueObserver)
    }
  }
</script>

<style scoped lang="scss">


</style>
