<template>
  <section class="card">
    <command-form name="deleteUser" service="users" action="deleteMe"
                  @submit="handleSubmit"
                  @done="handleDone">
      <p>Usunięcie konta kasuje dane użytkownika i nie będzie możliwe jego przywrócenie.</p>
      <boolean-field name="confirm" label="Zaznacz tylko jeśli na pewno chcesz usunąć konto"></boolean-field>
      <div class="buttons">
        <button type="submit" class="button" role="button">
          <span>Usuń Konto</span>
        </button>
      </div>
    </command-form>
  </section>
</template>

<script>
  const feedbackRoute = { name: 'user:removeAccountFeedback' }

  export default {
    name: "DeleteUser",
    methods: {
      handleSubmit() {
        this.$router.redirectAfterLogout = feedbackRoute
      },
      handleDone() {
        this.$router.replace(feedbackRoute)
      }
    }
  }
</script>

<style scoped>

</style>
