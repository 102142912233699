<template>
  <Notification :notification="notification" ref="notification">
    <div class="notification-avatar" @click="goTo">
      <img v-if="notification.summary.error" src="/static/icons/error.svg" class="avatar-addon"/>
      <img v-else-if="notification.summary.warning" src="/static/icons/warning.svg" class="avatar-addon"/>
      <img v-else src="/static/icons/check.svg" class="avatar-addon"/>
    </div>
    <div class="notification-content" @click="goTo">
      <div class="notification-title">{{ i18n.notifications.finished.title }}</div>
      <div class="notification-summary">
        <span v-if="notification.summary.fatal" class="fatal">
          {{ notification.summary.fatal }}
          {{ notification.summary.fatal > 1 ? i18n.severity.fatals : i18n.severity.fatal }}
        </span>
        <span v-if="notification.summary.error" class="error">
          {{ notification.summary.error }}
          {{ notification.summary.error > 1 ? i18n.severity.errors : i18n.severity.error }}
        </span>
        <span v-if="notification.summary.warning" class="warning">
          {{ notification.summary.warning }}
          {{ notification.summary.warning > 1 ? i18n.severity.warnings : i18n.severity.warning }}
        </span>
        <span v-if="notification.summary.notice" class="notice">
          {{ notification.summary.notice }}
          {{ notification.summary.notice > 1 ? i18n.severity.notices : i18n.severity.notice }}
        </span>
      </div>
      <div class="notification-description">
        {{ notification.url || 'unknown' }}
      </div>
    </div>
  </Notification>
</template>

<script>
import i18n from 'i18n'
import Notification from '@/modules/notifications/Notification.vue'

export default {
  name: "ScanFinished",
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  components: { Notification },
  computed: {
    i18n() {
      return i18n().xmlAlert
    }
  },
  methods: {
    async goTo(ev) {
      console.log(this.$refs)
      this.$refs.notification.mark('read')
      ev.preventDefault()
      const { scanner, scan } = this.notification
      this.$router.push({ name: "xml:alert:scan", params: { scanner, scan } })
    }
  }
}
</script>

<style scoped>

</style>
