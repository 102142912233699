<template>
  <div class="search-results full-width">
    <scroll-loader :what="resultsDaoPath"
                   v-if="exclusions" :key="exclusions" noDebugLog>
      <template v-slot:default="{ row: result }">
        <ExclusionsResult :data="result" :key="result.id" :viewAsXml="viewAsXml" :rawXml="rawXml">
        </ExclusionsResult>
      </template>
      <template v-slot:loadingBottom="{ connectionProblem }">
        <div class="search-bottom-loading-bar">
          <div class="card loading-card">
            <div class="cube-spinner">
              <div class="cube1"></div>
              <div class="cube2"></div>
            </div>
            <span class="loading-text" v-if="connectionProblem" v-html="i18n.connectionProblem"></span>
            <span class="loading-text" v-else v-html="i18n.loadingBottom"></span>
          </div>
        </div>
      </template>
    </scroll-loader>
  </div>
</template>

<script>
  import i18n from 'i18n'

  import ExclusionsResult from "./ExclusionsResult.vue"
  import ScrollLoader from "common/components/ScrollLoader.vue"

  export default {
    name: "ExclusionsResults",
    components: { ExclusionsResult, ScrollLoader },
    props: {
      exclusions: {
        type: String,
        default: null
      },
      viewAsXml: {
        type: Boolean,
        default: false
      },
      rawXml: {
        type: Boolean,
        default: false
      }
    },
    reactive: {
      exclusionsEntity() { return this.exclusions && ['xmlCsvExclusions', "exclusionsCheck", { exclusionsCheck: this.exclusions } ] },
      dataSetEntity() { return this.exclusionsEntity && ['xmlSearch', "dataSet", { dataSet: this.exclusionsEntity.dataSet } ] },
    },
    computed: {
      i18n() {
        return i18n().xmlSearch
      }
    },
    methods: {
      resultsDaoPath({gt, limit, reverse}) {
        return ['xmlCsvExclusions', 'exclusionCheckResults',
          { check: this.exclusions, from: gt || '', limit: reverse ? 0 : limit }]
      }
    }
  }
</script>

<style scoped>

</style>