var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form-field-bind", {
    attrs: { name: _vm.name },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var value = ref.value
          var setValue = ref.setValue
          var error = ref.error
          return [
            _c(
              "FieldTemplate",
              {
                attrs: {
                  errorText: _vm.errorText,
                  error: error,
                  label: _vm.label,
                  name: _vm.name
                }
              },
              [
                value
                  ? _c(
                      "div",
                      [
                        _c("uploaded-picture-img", {
                          staticClass: "picture-field-picture",
                          attrs: { picture: value, reactive: "", original: "" }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "picture-field-buttons buttons" }, [
                  value
                    ? _c(
                        "button",
                        {
                          staticClass: "button",
                          attrs: { type: "button" },
                          on: { click: _vm.remove }
                        },
                        [
                          _c("img", {
                            attrs: { src: "/static/icons/delete_forever.svg" }
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.i18n.pictDel))])
                        ]
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                !value
                  ? _c(
                      "div",
                      [
                        _c(
                          "FileInput",
                          {
                            staticClass: "button",
                            attrs: { accept: "image/*" },
                            on: { input: _vm.handleFile }
                          },
                          [_vm._v(_vm._s(_vm.i18n.pictUp))]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }