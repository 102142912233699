/// This is dirty hack used to trick execution order

import Vue from 'vue'

import * as filters from 'common/plugins/filters'

import purify from 'purify'

Object.keys(filters).forEach(key => {
  console.log("REGISTER FILTER", key)
  Vue.filter(key, filters[key])
})

import VueRecaptcha from 'vue-recaptcha'
Vue.component('vue-recaptcha', VueRecaptcha)


if(typeof window != "undefined") {
  const t = document.createElement('script')
  t.async = true
  t.defer = true
  t.src = "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
  const s = document.body.getElementsByTagName('script')[0]
  s.parentNode.insertBefore(t,s)
}

Vue.prototype.$sanitize = (dirty, opts) => purify.sanitize(dirty, opts)
Vue.prototype.$autoLinks = (text) => {
  text = text.replace(/((https?:)\/\/[a-z0-9&#%=.\/?_,+-]+)/gi,
      '<a href="$1" target="_blank">$1</a>')
  return text
}

import "common/components/registerGlobal.js"
import "./segments/registerGlobal.js"

console.log("VUE INITIALIZED")
