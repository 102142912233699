import SearchPage from "./SearchPage.vue"

export default function(prefix) {
  return [
    {
      path: prefix,
      name: 'xml:search',
      component: SearchPage,
      props: true
    },
    {
      path: prefix + 'Url/:searchUrl',
      name: 'xml:searchUrl',
      component: SearchPage,
      props: true,
      meta: { saveForLogin: true }
    },
    {
      path: prefix + 'Ds/:dataSet',
      name: 'xml:searchDataSet',
      component: SearchPage,
      props: true,
      meta: { saveForLogin: true }
    },
    {
      path: prefix + '/:search',
      name: 'xml:searchResults',
      component: SearchPage,
      props: true,
      meta: { saveForLogin: true }
    },
    {
      path: prefix + '/view/:search.xml',
      name: 'xml:searchResultsViewXml'
    },
    {
      path: prefix + '/:search.xml',
      name: 'xml:searchResultsFreshXml'
    },
    {
      path: prefix + '/download/:search.xml',
      name: 'xml:searchResultsDownloadXml'
    },
    {
      path: prefix + '/generator/:generator.xml',
      name: 'xml:generatorXml'
    },
  ]
}
