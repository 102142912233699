<template>
  <section class="card half-width">
    <loading :what="user" name="user data">
      <command-form v-if="user" name="user" service="users" action="updatePhone">
        <template v-slot:done>
          <h4>{{ i18n.title }}</h4>
          <p>{{ i18n.done.text }}</p>
        </template>
        <template v-slot:default>
          <h4>{{ i18n.title }}</h4>
          <p>{{ i18n.currentPhone }} <b>{{ user && user.userData.phone }}</b></p>
          <div>
            <phone-field-with-confirm name="phone" :label="i18n.phone" key="phoneField"
                                      confirmName="phoneConfirm" :confirmTexts="i18n.confirmSms">
            </phone-field-with-confirm>

            <form-field-bind name="phone" v-slot="{ value: phone }" class="form-group">
              <div class="buttons" v-if="phone">
                <button type="submit" class="button" role="button"><span>{{ i18n.save }}</span></button>
              </div>
            </form-field-bind>
          </div>
        </template>
      </command-form>
    </loading>
  </section>
</template>

<script>
  import api from "api"
  import i18n from "i18n"
  import PhoneInput from "common/components/inputs/PhoneInput.vue"
  import CountryInput from "common/components/inputs/CountryInput.vue"
  import countries from "@/../../data/countries"
  import PhoneFieldWithConfirm from "common/components/fields/PhoneFieldWithConfirm.vue"

  export default {
    name: 'UpdatePhone',
    components: { PhoneInput, CountryInput, PhoneFieldWithConfirm },
    data () {
      return {
      }
    },
    reactive: {
      user() { return ['users', 'UserOne', { user: api.session.session.user } ] }
    },
    computed: {
      i18n() { return i18n().user.changePhone }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
