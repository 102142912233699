var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.deleting
    ? _c("form-field-bind", {
        staticClass: "logic-block",
        attrs: { name: _vm.name },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function(ref) {
                var value = ref.value
                return _vm._l(value, function(item, index) {
                  return _c(
                    "div",
                    [
                      value.length > 1 || item.type != "include"
                        ? _c(
                            "div",
                            { staticClass: "fields-row logic-block-separator" },
                            [
                              _c("single-select-field", {
                                staticClass: "operator-field",
                                attrs: {
                                  name: _vm.name + "." + index + ".type",
                                  label: "",
                                  options: ["include", "exclude"],
                                  disabled: _vm.disabled,
                                  text: function(op) {
                                    return _vm.typeText(op)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "logic-block-separator-line"
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("AndBlock", {
                        key: _vm.name + "." + index + ".logic",
                        attrs: {
                          name: _vm.name + "." + index + ".logic",
                          onlyOne: _vm.onlyOne && value.length <= 1,
                          dataSet: _vm.dataSet,
                          disabled: _vm.disabled
                        },
                        on: {
                          more: function() {
                            return _vm.handleMore(index)
                          },
                          delete: function() {
                            return _vm.handleDelete(index)
                          }
                        }
                      })
                    ],
                    1
                  )
                })
              }
            }
          ],
          null,
          false,
          1689328724
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }