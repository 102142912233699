<template>
  <div :class="['message', message.sendState,
      my ? 'my-message' : 'other-user-message',
      { next: isPrevConnected, prev: isNextConnected }
    ]">
    <time v-if="isTimeVisible" :datatime="message.timestamp">{{ message.timestamp | hourOrDayShort(now) }}</time>
    <div class="message-row">
      <div class="message-content" v-html="$autoLinks($sanitize(message.text, { ALLOWED_TAGS: ['p'] }))"></div>
      <div v-if="my" class="message-readers">
        <observe v-for="readPosition in readPositions" :key="readPosition.id" v-if="readPosition.user"
                 name="userData" :what="['users', 'publicUserData', { user: readPosition.user } ]"
                 v-slot="{ value: userData }" class="message-reader">
          <uploaded-picture-img v-if="userData && userData.picture"
                                width="16" height="16" :picture="userData.picture" :reactive="true">
          </uploaded-picture-img>
          <img v-else src="/static/empty-user-photo.svg" width="20" height="20">
        </observe>
      </div>
    </div>
    <div class="message-failed-buttons buttons" v-if="message.sendState == 'failed'">
      <button class="button" type="button" @click="retry">Retry</button>
      <button class="button" type="button" @click="cancel">Cancel</button>
    </div>
  </div>
</template>

<script>
  import api from "api"
  import currentTime from "common/components/utils/currentTime.js"

  const maxConnectedMessagesTime = 36e5

  export default {
    name: "PrivateMessage",
    props: {
      message: {
        type: Object,
        required: true
      },
      prev: {
        type: Object
      },
      next: {
        type: Object
      },
      readPositions: {
        type: Array,
        default: () => []
      }
    },
    reactive: {
    },
    computed: {
      my() {
        return (this.message.user && this.message.user == api.session.session.user)
            || (this.message.session && api.session.publicSessionInfo
                && this.message.session == api.session.publicSessionInfo.id)
      },
      isNextConnected() {
        if(!this.next) return false
        return this.isConnected(this.message, this.next)
      },
      isPrevConnected() {
        if(!this.prev) return false
        return this.isConnected(this.prev, this.message)
      },
      isTimeVisible() {
        if(!this.prev) return true
        return this.isDistantInTime(this.prev, this.message)
      },
      now() {
        return new Date(currentTime.now)
      }
    },
    methods: {
      retry() {
        api.request(['clientMessages','retry'], this.message.id)
      },
      cancel() {
        api.request(['clientMessages','cancel'], this.message.id)
      },
      isDistantInTime(prev, next) {
        const prevDate = new Date(prev.timestamp)
        const nextDate = new Date(next.timestamp)
        const prevTime = prevDate.getTime()
        const nextTime = nextDate.getTime()
        return (nextTime - prevTime > maxConnectedMessagesTime)
      },
      isConnected(prev, next) {
        if(this.isDistantInTime(prev, next)) return false
        return (prev.user && (prev.user == next.user))
            || (prev.session && (prev.session == next.session))
      }
    }
  }
</script>

<style scoped>

</style>