var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page",
    [
      _c("loading", { attrs: { what: _vm.keyState, name: "key state" } }, [
        _vm.keyState
          ? _c("div", { staticClass: "card half-width main-card" }, [
              _vm.state == "used" ||
              _vm.state == "expired" ||
              _vm.state == "done" ||
              _vm.state == "notFound"
                ? _c("div", [
                    _c("h4", [_vm._v(_vm._s(_vm.i18n[_vm.state].title))]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.i18n[_vm.state].text))]),
                    _vm._v(" "),
                    _vm.state == "done"
                      ? _c(
                          "button",
                          {
                            staticClass: "button",
                            on: { click: _vm.redirectToLoginPage }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.i18n[_vm.state].exitButton) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.state == "ready"
                ? _c(
                    "div",
                    [
                      _c("h4", {
                        domProps: { innerHTML: _vm._s(_vm.i18n.title) }
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(_vm.i18n.message))
                      ]),
                      _vm._v(" "),
                      _c(
                        "command-form",
                        {
                          staticClass: "resetPassword-confirmPassForm",
                          attrs: {
                            service: "emailPassword",
                            parameters: { key: _vm.emailKey },
                            action: "finishPasswordReset"
                          },
                          on: { done: _vm.redirectToLoginPage }
                        },
                        [
                          _c("double-password-field", {
                            attrs: {
                              name: "newPasswordHash",
                              label: _vm.i18n.password,
                              "retype-label": _vm.i18n.confirmPassword,
                              "enable-toggle-password": true
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "buttons" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "button",
                                  attrs: { type: "submit" }
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.i18n.setNewPassword))
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                { attrs: { to: { name: "user:login" } } },
                                [_vm._v(_vm._s(_vm.i18n.backToSignIn))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }