var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tooltipModal modalHolder" },
    [
      _c(
        "loading-zone",
        [
          _c(
            "loading",
            {
              attrs: {
                what: _vm.parent && _vm.subCategories,
                name: "parentData"
              }
            },
            [
              _vm.parent && _vm.subCategories
                ? _c(
                    "div",
                    { staticClass: "tooltipModal-dialog categories-submodal" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "modal-header text-center tooltipModal-header"
                        },
                        [
                          _c("h5", { staticClass: "modal-title bold-font" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  (_vm.parent.translations[_vm.lang] &&
                                    _vm.parent.translations[_vm.lang].name) ||
                                    _vm.parent.name
                                ) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            { staticClass: "close", on: { click: _vm.close } },
                            [_vm._v("×")]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "tooltipModal-body modal-body justify-content-center"
                        },
                        [
                          _c("p", [_vm._v(_vm._s(_vm.i18n.description))]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "subcategories-container" },
                            [
                              _c(
                                "div",
                                { staticClass: "subcategories-image" },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "/static/category_customization.svg"
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "subcategories-selection" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "subcategories-selection-title"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.i18n.subcategoriesIn) +
                                          ' "' +
                                          _vm._s(
                                            (_vm.parent.translations[
                                              _vm.lang
                                            ] &&
                                              _vm.parent.translations[_vm.lang]
                                                .name) ||
                                              _vm.parent.name
                                          ) +
                                          '" ' +
                                          _vm._s(_vm.i18n.category)
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(_vm.subCategories, function(
                                    subCategory
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        staticClass:
                                          "custom-control custom-checkbox mt-2"
                                      },
                                      [
                                        _c("input", {
                                          staticClass:
                                            "custom-control-input mt-2",
                                          attrs: {
                                            type: "checkbox",
                                            id: "cat_" + subCategory.id
                                          },
                                          domProps: {
                                            checked: _vm.value.find(function(
                                              c
                                            ) {
                                              return c[1] == subCategory.id
                                            })
                                          },
                                          on: {
                                            input: function(ev) {
                                              return _vm.handleChecked(
                                                subCategory.id,
                                                ev.target.checked
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            staticClass: "custom-control-label",
                                            attrs: {
                                              for: "cat_" + subCategory.id
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  (subCategory.translations[
                                                    _vm.lang
                                                  ] &&
                                                    subCategory.translations[
                                                      _vm.lang
                                                    ].name) ||
                                                    subCategory.name
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  })
                                ],
                                2
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "modal-footer pb-0" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            on: {
                              click: function($event) {
                                return _vm.close()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.i18n.saveChanges))]
                        )
                      ])
                    ]
                  )
                : _vm._e()
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }