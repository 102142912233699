var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "form-field " +
        (_vm.groupClass || "") +
        (_vm.isRequired ? " form-field-required" : "")
    },
    [
      _vm.label !== ""
        ? _c("label", { attrs: { for: _vm.inputId } }, [
            _vm._v(_vm._s(_vm.label || _vm.name))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._t("above"),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _c("small", { staticClass: "field-error" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              (_vm.errorText && _vm.errorText[_vm.error]) ||
                _vm.i18n[_vm.error] ||
                _vm.error
            ) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _vm._t("below")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }