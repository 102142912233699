<template>
  <footer>
    <div class="footer-copyright text-center py-5">
      © 2020 Copyright:
      <a href="https://www.vole.pl/">VoleApps.pl</a>
    </div>
  </footer>
</template>

<script>
  export default {
    name: 'Footer'
  }
</script>

<style scoped>
</style>
