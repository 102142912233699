<template>
  <div class="first-message-modal-holder">
    <div class="card first-message-modal" ef="box"
         :style="maxDimensions">
      <div class="card-header">
        <div class="first-message-user-picture">
          <uploaded-picture-img v-if="userData && userData.picture"
                                width="45" height="45" :picture="userData.picture" :reactive="true">
          </uploaded-picture-img>
          <img v-else-if="userData === null" src="/static/deleted-user-photo.svg" width="45" height="45">
          <img v-else src="/static/empty-user-photo.svg" width="45" height="45">
          <div v-if="(userData && userData.online) || (otherSession && otherSession.online)" class="onlineDot"></div>
        </div>
        <h5 class="title" v-if="userData">{{ userData.display }}</h5>
        <h5 class="title" v-else-if="userData === null">
          {{ i18n.userDeleted }}
        </h5>
        <h5 class="title" v-else>
          {{ (sessionData && sessionData.name) || i18n.anonymous }}
        </h5>
        <span class="close-icon" data-effect="fadeOut" @click="$emit('close')">
          <img src="/static/icons/clear.svg" />
        </span>
      </div>
      <div class="first-message" v-if="!sent">
        <MessageInput action="postPrivateMessage" :csp="false"
                      :messageParameters="{ user: userData.id, session: otherSession }"
                      :placeholder="i18n.messagePlaceholder" :sendButton="i18n.submitButton"
                      onlyOne @sent="handleMessageSent"></MessageInput>
      </div>
      <div class="first-message-result" v-if="sent">
        <p>{{ i18n.sent }}</p>
        <p v-if="readHistory && readHistory.last">{{ i18n.answered }}</p>
        <p v-else>{{ i18n.new }}</p>
        <a v-if="conversation" target="flipchart_messenger" :href="$router.resolve(conversationLink).href"
           class="button message-button">
          <img src="/static/icons/chat.svg">
          <span>{{ i18n.enterChat }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import { windowDimensions } from "common/components/utils/overlayLayer.js"
  import i18n from "i18n"
  import MessageInput from "@/modules/chat/MessageInput.vue"

  export default {
    name: "FirstMessageModal",
    components: { MessageInput },
    props: {
      userData: {
        type: Object
      },
      otherSession: {
        type: Object
      }
    },
    data() {
      return {
        sent: false
      }
    },
    reactive: {
      conversation() {
        return ['messages', 'privateConversationByOtherParticipant', {
          user: this.userData && this.userData.id, session: this.otherSession && this.otherSession.id }]
      },
      readHistory() {
        return this.conversation && ['readHistory', 'readHistory', { toType: 'priv', toId: this.conversation.id }]
      }
    },
    computed: {
      i18n() {
        return i18n().messenger.firstMessage
      },
      maxDimensions() {
        return {
          'max-width': windowDimensions.width - 20 + 'px',
          'max-height': windowDimensions.height - 20 + 'px'
        }
      },
      conversationLink() {
        return this.conversation && { name: 'messenger:conversation', params: {
          conversationType: 'priv',
          conversation: this.conversation.id
        } }
      }
    },
    methods: {
      handleMessageSent() {
        this.sent = true
      }
    }
  }
</script>

<style scoped>

</style>