<template>
  <page :multiRow="true">
    <loading :what="user" name="user profile data">
      <div class="callout-wrapper callout-register callout-register-mentor">
        <div class="callout">
          <h2>Usuwam swoje konto</h2>
        </div>
      </div>

      <div class="content-wrapper main-card ">
        <div class="card">
        <p>Twoje dane osobowe są przechowywane przez rok od dnia ostatniego logowania. Po tym czasie zostają
          automatycznie wykasowane z bazy danych</p>
        <p>O ile nie otrzymywałeś od nas faktury w każdej chwili możesz żądać usunięcia Twoich danych z bazy danych
          portalu</p>
        <p>Jeśli otrzymywałeś faktury Twoje dane przechowywane będą przez 5 lat kalendarzowych od roku następującego
          po wystawieniu ostatniej faktury. Jeśli się wyrejestrujesz, wykasujemy Twój profil z wyjątkiem danych
          niezbędnych do obsługi faktur</p>
        <p>Cofnięcie zgody na przetwarzania Twoich danych w celach marketingowych przez administratora portalu
          jest równoznaczne z wyrejestrowaniem z portalu i usunięciem Twojego profilu</p>
        <p>Zawsze możesz cofnąć udzieloną zgodę na otrzymywanie materiałów marketingowych od stron trzecich poprzez
          odznaczenie okna w zakładce „Moje dane”. Nie spowoduje to wyrejestrowania z portalu</p>
        </div>
        <DeleteUser></DeleteUser>
      </div>
    </loading>
  </page>
</template>

<script>
  import i18n from "i18n"
  import api from "@/api"
  import Page from "@/segments/Page.vue"
  import DeleteUser from "./user/DeleteUser.vue"

  export default {
    name: "RemoveAccount",
    inject: ['loadingZone', 'workingZone'],
    components: { Page, DeleteUser },
    props: {
      userType: {
        type: String,
        required: false,
        validator: (value) => ['mentor', 'disciple'].indexOf(value) !== -1
      },
    },
    data() {
      return {}
    },
    computed: {
      lang() {
        return i18n.config.language
      },
      i18n() {
        return i18n().user.register
      }
    },
    reactive: {
      user() {
        return ['users', 'UserOne', { user: api.session.session.user }]
      }
    },
    methods: {}
  }
</script>

<style scoped>

</style>
