module.exports = {
  url: "XML URL",
  phrase: "Search phrase",
  operator: 'Operator',
  fields: "Fields",
  checkButton: 'Check Exclusions',
  uploadedFileLabel: 'Exclusions CSV/TSV:',
  uploadedFileButton: 'Upload CSV/TSV',
  entries: "entries",
  results: "results",
  ago: "ago",
  viewAsXml: "View as xml",
  rawXml: "Raw xml",
  useDescription: 'Search in description',
  useStrict: 'Use strict',
  loadingBottom: "Loading...",
  connectionProblem: "Connection problem :/",
  generator: "XML generator",
  generatorLastUse: "Last use",
  generationDuration: "took",
  groupResults: "Group results",
  wordCopied: 'copied to clipboard'
}
