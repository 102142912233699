<template>
  <form-field-bind class="and-block" :name="name" v-slot="{ value }" v-if="!deleting">
    <div v-for="(item, index) in value">
      <div v-if="index>0" class="and-separator">AND</div>
      <OrBlock :name="name+'.'+index" :key="name+'.'+index" :onlyOne="onlyOne && value.length <= 1"
               :dataSet="dataSet" :disabled="disabled"
               :last="index == value.length - 1"
               @and="() => handleAnd(index)" @delete="() => handleDelete(index)"
               @more="$emit('more')">
      </OrBlock>
    </div>
  </form-field-bind>
</template>

<script>
  import OrBlock from "./OrBlock.vue"
  import emptyQuery from "./emptyQuery.js"

  export default {
    name: "AndBlock",
    components: { OrBlock },
    inject: ['form'],
    props: {
      dataSet: {
        type: Object
      },
      disabled: {
        type: Boolean,
        default: false
      },
      name: {
        type: String,
        default: "query"
      },
      onlyOne: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        deleting: false
      }
    },
    methods: {
      handleAnd(index) {
        const value = this.form.getFieldValue(this.name)
        const inserted = [emptyQuery]
        const updated = [ ...value.slice(0, index+1), inserted, ...value.slice(index+1) ]
        this.form.setFieldValue(this.name, updated)
      },
      handleDelete(index) {
        const value = this.form.getFieldValue(this.name)
        if(value.length == 1) return this.$emit('delete')
        this.deleting = true
        this.$nextTick(() => {
          const value = this.form.getFieldValue(this.name)
          const updated = [ ...value.slice(0, index), ...value.slice(index+1) ]
          this.form.setFieldValue(this.name, updated)
          this.deleting = false
        })
      }
    }
  }
</script>

<style scoped>

</style>