<template>
  <div class="card main-card xml-alert-scan">
    <div v-if="scanner && !scan.to" class="xml-alert-scan-url">
      <a :href="scanner.url" target="_blank">{{ scanner.url }}</a>
      <h3 v-if="dataSetEntity && dataSetEntity.state == 'deleted'" class="form-error">{{ i18n.dataSetDeleted }}</h3>
    </div>
    <time :datetime="scan.timestamp" class="xml-alert-scan-time">
      {{ i18n.scanStarted }}
      <span class="hours">{{ (now - (new Date(scan.timestamp)).getTime())/1000 | duration }}</span>
      {{ i18n.ago }}
    </time>
    <div class="search-progress progress-bar" v-if="scan.state != 'done' && scan.state!='downloading'">
      <div class="progress-bar-fill"
           :style="{ width: Math.floor(100*(processedCount / allCount))+'%' }">
      </div>
      <div class="progress-text">
        <span>{{ processedCount }}</span>&nbsp;/&nbsp;<span>{{ allCount }}</span>
      </div>
    </div>
    <div class="xml-alert-scan-state">{{ i18n.scanStage[scan.stage] }}</div>
    <div v-if="scan.stage == 'download' && dataSetEntity && dataSetEntity.progress"
         class="search-progress progress-bar">
      <div class="progress-bar-fill" v-if="dataSetEntity && dataSetEntity.progress"
           :style="{ width: Math.floor(
               100 * (dataSetEntity.progress.transferred  / (dataSetEntity.progress.length)))+'%'
           }">
      </div>
      <div class="progress-text">
        <span>{{ dataSetEntity.progress.transferred | bytes }}</span>
        <span v-if="dataSetEntity.progress.length">&nbsp;/ {{ dataSetEntity.progress.length | bytes }}</span>
      </div>
    </div>
    <div v-if="(scan.stage == 'downloading' || scan.state=='done') && dataSetEntity && dataSetEntity.state == 'error'"
         class="download-error">
      {{ i18n.downloadError[dataSetEntity.error] || dataSetEntity.error }}
    </div>
    <div v-if="scan.state != 'done' && scan.state!='downloading' && currentStage"
         class="search-progress progress-bar">
      <div class="progress-bar-fill"
           :style="{ width: Math.floor(100*(currentStage.processed / currentStage.count))+'%' }">
      </div>
      <div class="progress-text">
        <span>{{ currentStage.processed }}</span>&nbsp;/&nbsp;<span>{{ currentStage.count }}</span>
      </div>
    </div>
    <div class="xml-alert-scan-results-counters">
      <div v-for="result in sortedResultsCounts" :class="['xml-alert-scan-results-counter', result.severity]">
        <span class="count">{{ result.count }}</span>
        <span class="severity">{{ i18n.severity[$options.filters.plural(result.severity, result.count)] }}</span>
      </div>
    </div>
    <router-link v-if="scan.to" :to="{ name: 'xml:alert:scan', params: { scan: scan.to, scanner: scan.scanner } }"
                 tag="a" class="button xml-alert-scan-view-results">
      <img src="/static/icons/preview.svg"><span>{{ i18n.viewResults }}</span>
    </router-link>
  </div>
</template>

<script>
  import i18n from 'i18n'
  import currentTime from 'common/components/utils/currentTime.js'

  const severityOrder = ['fatal', 'error', 'warning', 'notice']

  export default {
    name: "ScanCard",
    props: {
      scan: {
        type: Object,
        required: true
      },
      scanner: {
        type: Object
      }
    },
    reactive: {
      resultsCounts() {
        return ['xmlAlert', 'resultsCount', { scan: this.scan.to || this.scan.id }]
      },
      dataSetEntity() {
        return ['xmlSearch', "dataSet", { dataSet: this.scan.dataSet } ]
      },
      previousDataSetEntity() {
        return ['xmlSearch', "dataSet", { dataSet: this.scan.previousDataSet } ]
      },
      dataSetOnline() {
        return ['online', 'object', "xmlSearch_DataSet", this.scan.dataSet ]
      },
    },
    computed: {
      i18n() {
        return i18n().xmlAlert
      },
      now() {
        return currentTime.now
      },
      sortedResultsCounts() {
        return this.resultsCounts && this.resultsCounts.slice().sort((a, b) =>
            severityOrder.indexOf(a.severity) - severityOrder.indexOf(b.severity) )
      },
      processedCount() {
        return this.scan.stages.reduce((acc, stage) => acc + stage.processed, 0)
      },
      allCount() {
        return this.scan.stages.reduce((acc, stage) => acc + stage.count, 0)
      },
      currentStage() {
        return this.scan.stages.find(item => item.name == this.scan.stage)
      }
    }
  }
</script>

<style scoped>

</style>