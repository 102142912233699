<template>
  <div class="conversations-menu">
    <overlay-anchor :anchor="anchor" :directions="directions" @positioned="updatePosition">
      <loading-zone class="conversations-menu-card" :style="{ ...maxDimensions }">
        <div class="notifications-buttons">
          <button class="button" type="button" @click="markAllAsRead">{{ i18n.markAllAsRead }}</button>
          <button class="button" type="button" @click="removeAll">{{ i18n.removeAll }}</button>
        </div>
        <NotificationsList></NotificationsList>
      </loading-zone>
    </overlay-anchor>
  </div>
</template>

<script>
  import i18n from "i18n"
  import { OverlayAnchor, windowDimensions } from "common/components/utils/overlayLayer"
  import NotificationsList from "./NotificationsList.vue"
  import LoadingZone from "common/components/LoadingZone.vue"

  export default {
    name: 'NotificationsMenu',
    props: {
      anchor: {}
    },
    components: { NotificationsList, OverlayAnchor, LoadingZone },
    data () {
      return {
        directions: [{ x: -1, y: 1, ax: 1, ay: 1}],
        position: { x: 0, y: 0 },
        search: ""
      }
    },
    computed: {
      i18n() {
        return i18n().notifications
      },
      windowDimensions() { return windowDimensions },
      maxDimensions() {
        if(this.position.hidden) return { 'max-width': 0, 'max-height': 0 }
        let maxWidth = windowDimensions.width - Math.max(20, this.position.x)
        let maxHeight = windowDimensions.height - this.position.y - 20
        if(maxWidth > 400) maxWidth = 400
        if(maxHeight > 600) maxHeight = 600
        return {
          'width': this.anchor.element.clientWidth + 'px',
          'max-width': maxWidth + 'px',
          'max-height': maxHeight + 'px'
        }
      }
    },
    methods: {
      updatePosition(position) {
        this.position = position
      },
      async markAllAsRead() {
        console.log("MARK ALL AS READ!")
        return await api.command(["notifications", "markAllAsRead"], {})
      },
      async removeAll() {
        console.log("REMOVE ALL!!")
        return await api.command(["notifications", "removeAll"], {})
      }
    },
    mounted() {
    }
  }
</script>

<style>
  .select-picker-card {
    overflow-y: auto;
  }
</style>