module.exports = {
  aboutUs: 'About us',
  login: "Sign In",
  register: "Sign Up",

  userSettings: "User settings",
  yourPage: "Your Profile",
  logout: "Log out",
  search: "Search"
}
