var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("page", [
    _vm.user
      ? _c(
          "div",
          { staticClass: "row" },
          [
            _c("UpdateUserData"),
            _vm._v(" "),
            _c("UpdatePassword"),
            _vm._v(" "),
            _c("UpdateEmail"),
            _vm._v(" "),
            _c("UpdatePhone")
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.user
      ? _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "buttons remove-account-button" },
            [
              _c(
                "router-link",
                {
                  staticClass: "button",
                  attrs: {
                    to: { name: "user:removeAccount" },
                    tag: "button",
                    role: "button"
                  }
                },
                [_c("span", [_vm._v("Usuń Konto")])]
              )
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }