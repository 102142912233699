var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.state == "ready"
      ? _c("div", [_vm._t("default", null, { value: _vm.what })], 2)
      : _vm._e(),
    _vm._v(" "),
    _vm.state == "error"
      ? _c(
          "div",
          [
            _vm._t("error", function() {
              return [
                _c(
                  "div",
                  {
                    staticClass: "alert alert-danger",
                    attrs: { role: "alert" }
                  },
                  [_vm._v("error")]
                )
              ]
            })
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.state == "loading" ? _c("div", [_vm._t("loading")], 2) : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }