<template>
  <div class="confirm-holder">
    <div class="card text-center">
      <div class="card-body">
        <div class="card-title">{{ title }}</div>
        <p class="card-text">{{ text }}</p>
        <div class="buttons">
          <button v-for="button in buttons"
                  class="button" type="button"
                  @click="() => { $emit(button.event); $emit('close'); }">{{ button.text }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "MultiButtonDialog",
    props: {
      title: {
        type: String,
        required: true
      },
      text: {
        type: String,
        required: true
      },
      buttons: {
        type: Array,
        required: true
      }
    },
    methods: {
      yesClick() {
        this.$emit("yes")
        this.$emit("close")
      },
      noClick() {
        this.$emit("no")
        this.$emit("close")
      }
    }
  }
</script>

<style scoped>
  .confirm-holder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.4);
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>