var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form-field-bind", {
    staticClass: "file-upload-field",
    attrs: {
      name: _vm.name,
      valueFilter: function(n) {
        return n && n.trim()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var value = ref.value
          var setValue = ref.setValue
          var error = ref.error
          return [
            _c(
              "FieldTemplate",
              {
                attrs: {
                  errorText: _vm.errorText,
                  error: error,
                  label: _vm.label,
                  name: _vm.name,
                  groupClass: _vm.groupClass,
                  inputId: _vm.uid
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "above",
                      fn: function() {
                        return [_vm._t("above")]
                      },
                      proxy: true
                    },
                    {
                      key: "below",
                      fn: function() {
                        return [_vm._t("below")]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              },
              [
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "buttons" },
                  [
                    _vm.file
                      ? _c("div", { staticClass: "file-name" }, [
                          _vm._v(_vm._s(_vm.file.name))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.serverUpload
                      ? _c("div", { staticClass: "upload-info" }, [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.serverUpload.progress.percentage) +
                              "%\n        " +
                              _vm._s(
                                _vm._f("bytes")(
                                  _vm.serverUpload.progress.transferred
                                )
                              ) +
                              " / " +
                              _vm._s(
                                _vm._f("bytes")(
                                  _vm.serverUpload.progress.length
                                )
                              ) +
                              "\n      "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.file
                      ? _c(
                          "button",
                          {
                            staticClass: "button",
                            attrs: { type: "button" },
                            on: { click: _vm.reset }
                          },
                          [_vm._v("×")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "FileInput",
                      {
                        class: _vm.inputClass || "button",
                        attrs: { accept: _vm.accept },
                        on: { input: _vm.handleFile }
                      },
                      [_vm._v("\n        " + _vm._s(_vm.button) + "\n      ")]
                    )
                  ],
                  1
                )
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }