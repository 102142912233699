var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "messenger-view" },
    [
      _c("NavBar"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "messenger-columns" },
        [
          _c("ConversationsList", {
            attrs: { selectedConversation: _vm.conversation }
          }),
          _vm._v(" "),
          _vm.conversationType == "priv"
            ? _c("PrivateConversation", {
                attrs: { conversation: _vm.conversation }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }