var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "first-message-modal-holder" }, [
    _c(
      "div",
      {
        staticClass: "card first-message-modal",
        style: _vm.maxDimensions,
        attrs: { ef: "box" }
      },
      [
        _c("div", { staticClass: "card-header" }, [
          _c(
            "div",
            { staticClass: "first-message-user-picture" },
            [
              _vm.userData && _vm.userData.picture
                ? _c("uploaded-picture-img", {
                    attrs: {
                      width: "45",
                      height: "45",
                      picture: _vm.userData.picture,
                      reactive: true
                    }
                  })
                : _vm.userData === null
                ? _c("img", {
                    attrs: {
                      src: "/static/deleted-user-photo.svg",
                      width: "45",
                      height: "45"
                    }
                  })
                : _c("img", {
                    attrs: {
                      src: "/static/empty-user-photo.svg",
                      width: "45",
                      height: "45"
                    }
                  }),
              _vm._v(" "),
              (_vm.userData && _vm.userData.online) ||
              (_vm.otherSession && _vm.otherSession.online)
                ? _c("div", { staticClass: "onlineDot" })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.userData
            ? _c("h5", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.userData.display))
              ])
            : _vm.userData === null
            ? _c("h5", { staticClass: "title" }, [
                _vm._v("\n        " + _vm._s(_vm.i18n.userDeleted) + "\n      ")
              ])
            : _c("h5", { staticClass: "title" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      (_vm.sessionData && _vm.sessionData.name) ||
                        _vm.i18n.anonymous
                    ) +
                    "\n      "
                )
              ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "close-icon",
              attrs: { "data-effect": "fadeOut" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_c("img", { attrs: { src: "/static/icons/clear.svg" } })]
          )
        ]),
        _vm._v(" "),
        !_vm.sent
          ? _c(
              "div",
              { staticClass: "first-message" },
              [
                _c("MessageInput", {
                  attrs: {
                    action: "postPrivateMessage",
                    csp: false,
                    messageParameters: {
                      user: _vm.userData.id,
                      session: _vm.otherSession
                    },
                    placeholder: _vm.i18n.messagePlaceholder,
                    sendButton: _vm.i18n.submitButton,
                    onlyOne: ""
                  },
                  on: { sent: _vm.handleMessageSent }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.sent
          ? _c("div", { staticClass: "first-message-result" }, [
              _c("p", [_vm._v(_vm._s(_vm.i18n.sent))]),
              _vm._v(" "),
              _vm.readHistory && _vm.readHistory.last
                ? _c("p", [_vm._v(_vm._s(_vm.i18n.answered))])
                : _c("p", [_vm._v(_vm._s(_vm.i18n.new))]),
              _vm._v(" "),
              _vm.conversation
                ? _c(
                    "a",
                    {
                      staticClass: "button message-button",
                      attrs: {
                        target: "flipchart_messenger",
                        href: _vm.$router.resolve(_vm.conversationLink).href
                      }
                    },
                    [
                      _c("img", { attrs: { src: "/static/icons/chat.svg" } }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.i18n.enterChat))])
                    ]
                  )
                : _vm._e()
            ])
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }