var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "video-display-video",
      on: {
        click: function(ev) {
          return _vm.$emit("click", ev)
        }
      }
    },
    [
      _vm.video.type == "video" && _vm.video.srcObject
        ? _c(
            "video",
            _vm._b(
              {
                ref: _vm.video,
                attrs: { autoplay: "", playsinline: "" },
                domProps: {
                  srcObject: _vm.video.srcObject,
                  volume: _vm.volume
                },
                on: { resize: _vm.handleVideoResize }
              },
              "video",
              _vm.video.props,
              false
            )
          )
        : _vm.video.type == "video" && _vm.video.src
        ? _c(
            "video",
            _vm._b(
              {
                ref: "video",
                attrs: { autoplay: "", playsinline: "", src: _vm.video },
                domProps: { volume: _vm.volume },
                on: { resize: _vm.handleVideoResize }
              },
              "video",
              _vm.video.props,
              false
            )
          )
        : _vm.video.type == "image"
        ? _c(
            "img",
            _vm._b(
              {
                ref: "image",
                attrs: { src: _vm.video.src },
                on: { load: _vm.handleImageLoad }
              },
              "img",
              _vm.video.props,
              false
            )
          )
        : _vm.video.type == "audio" &&
          _vm.publicUserData &&
          _vm.publicUserData.picture
        ? _c("uploaded-picture-img", {
            attrs: {
              width: "400",
              height: "400",
              picture: _vm.publicUserData.picture
            },
            on: { load: _vm.handleImageLoad }
          })
        : _vm.video.type == "audio"
        ? _c("img", {
            attrs: {
              src: "/static/empty-user-photo.svg",
              width: "400",
              height: "400"
            },
            on: { load: _vm.handleImageLoad }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.video.type == "audio" &&
      _vm.video.srcObject &&
      (!_vm.video.props || !_vm.video.props.muted)
        ? _c(
            "audio",
            _vm._b(
              {
                attrs: { autoplay: "", playsinline: "" },
                domProps: { srcObject: _vm.video.srcObject, volume: _vm.volume }
              },
              "audio",
              _vm.video.props,
              false
            )
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.video.type == "audio" &&
      _vm.video.src &&
      (!_vm.video.props || !_vm.video.props.muted)
        ? _c("audio", {
            attrs: { autoplay: "", playsinline: "", src: _vm.video.src },
            domProps: { volume: _vm.volume }
          })
        : ((_vm.video.type == "video" &&
            _vm.peerState && _vm.peerState.videoState == "muted") ||
            _vm.video.muted) &&
          _vm.publicUserData && _vm.publicUserData.picture
        ? _c("uploaded-picture", {
            staticClass: "muted-user-picture",
            attrs: {
              width: "400",
              height: "400",
              picture: this.publicUserData.picture
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.video.srcObject
        ? _c("VolumeIndicator", { attrs: { stream: _vm.video.srcObject } })
        : _vm._e(),
      _vm._v(" "),
      _vm.peerState
        ? _c("div", { staticClass: "peer-state-icons" }, [
            false
              ? _c("img", {
                  attrs: { src: "/static/icons/no_internet_red.svg" }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.peerState.videoState == "muted"
              ? _c("img", {
                  attrs: { src: "/static/icons/videocam_off_red.svg" }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.peerState.audioState == "muted"
              ? _c("img", { attrs: { src: "/static/icons/mic_off_red.svg" } })
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }