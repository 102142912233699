import i18n from "i18n"

const googleMapsApiKey = 'AIzaSyDN6pzelr0PIOvwwLYW3vFMSZ3rwq8vQjA'

let googleMapsPromise

function loadGoogleMaps() {
  if(googleMapsPromise) return googleMapsPromise
  let resolved = false
  googleMapsPromise = new Promise((resolve, reject) => {
    if (typeof window != 'undefined') {
      window.initGoogleMaps = function () {
        if(resolved) return
        resolved = true
        resolve(google.maps)
      }

      ;(function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) return
        js = d.createElement(s)
        js.id = id
        js.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}`
            +`&libraries=places&callback=initGoogleMaps&language=${i18n.language}`
        fjs.parentNode.insertBefore(js, fjs)
      }(document, 'script', 'google-maps-sdk'))
      setTimeout(() => {
        if(resolved) return
        resolved = true
        reject('timeout')
      }, 10000)
    } else {
      reject('unavailable')
    }
  })
  return googleMapsPromise
}

export default { loadGoogleMaps }
export { loadGoogleMaps }