var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "conversations-menu" },
    [
      _c(
        "overlay-anchor",
        {
          attrs: { anchor: _vm.anchor, directions: _vm.directions },
          on: { positioned: _vm.updatePosition }
        },
        [
          _c(
            "loading-zone",
            {
              staticClass: "conversations-menu-card",
              style: Object.assign({}, _vm.maxDimensions)
            },
            [
              _c("div", { staticClass: "notifications-buttons" }, [
                _c(
                  "button",
                  {
                    staticClass: "button",
                    attrs: { type: "button" },
                    on: { click: _vm.markAllAsRead }
                  },
                  [_vm._v(_vm._s(_vm.i18n.markAllAsRead))]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button",
                    attrs: { type: "button" },
                    on: { click: _vm.removeAll }
                  },
                  [_vm._v(_vm._s(_vm.i18n.removeAll))]
                )
              ]),
              _vm._v(" "),
              _c("NotificationsList")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }