<template>
  <div class="social-login-buttons" v-if="!isWaiting">
    <!-- <button v-if="facebookAvailable" @click="facebook"
            class="facebook-login-button button">
      <img src="/static/facebook.svg"/>
      <span>{{ i18n.facebookButton }}</span>
    </button> -->
    <button v-if="googleAvailable" @click="google"
            class="google-login-button button">
      <img src="/static/google.svg"/>
      <span>{{ i18n.googleButton }}</span>
    </button>
  </div>
</template>

<script>
  import i18n from "i18n"
  import { loadFB } from "common/thirdParty/facebookApi.js"
  import { loadGoogleAuth2 } from "common/thirdParty/googleApi.js"
  export default {
    name: "SocialAuth",
    inject: ['loadingZone', 'workingZone'],
    props: {
      userData: {
        type: Object
      },
      noLoading: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        facebookAvailable: true,
        googleAvailable: true,
        isWaiting: false
      }
    },
    computed: {
      i18n() {
        return i18n().user.login
      }
    },
    created() {
      if(typeof window != 'undefined') {
        this.fbPromise = loadFB()
        this.fbPromise.then(fb => this.fb = fb)
        this.googPromise = loadGoogleAuth2()
        this.googPromise.then(goog => this.goog = goog)
      }
    },
    methods: {
      google() {
        const promise = (async () => {
          this.$emit('started')
          this.isWaiting = true
          const googleRedirectUri = document.location.protocol + '//' + document.location.host
              + this.$router.resolve({ name: 'user:google-sign-in-return' }).href
          const auth2 = await this.googPromise
          let canceled = false
          let response = await (auth2.signIn({
            scope: 'profile email'
          }).catch(error => {
            if(error.error == 'popup_blocked_by_browser') {
              localStorage.gUserData = JSON.stringify(this.userData)
              return auth2.signIn({
                scope: 'profile email',
                ux_mode: 'redirect',
                redirect_uri: googleRedirectUri
              })
            }
            if(error.error == 'popup_closed_by_user') {
              canceled = true
              return
            }
            throw error
          }))
          if(canceled) {
            this.isWaiting = false
            this.$emit('canceled')
            return 'canceled'
          }
          const apiPromise =  api.command(['googleLogin', 'registerOrLogin'], {
            accessToken: response.getAuthResponse().id_token,
            userData: this.userData
          })
          this.$emit('working')
          if(this.noLoading) this.workingZone.addPromise('Google login', apiPromise)
          await apiPromise
          this.isWaiting = false
          this.$emit('done')
          return 'ok'
        })()
        if(!this.noLoading) this.workingZone.addPromise('Google login', promise)
      },
      facebook() {
        const promise = (async () => {
          this.$emit('started')
          this.isWaiting = true
          const fbPromise = this.fbPromise.then(async fb => {
            if(fb.wasLoggedIn) {
              await new Promise((resolve, reject) => {
                fb.logout( res => {
                  console.log("FB LOGOUT RES", res)
                  resolve()
                })
              })
            }
            return fb
          })
          this.workingZone.addPromise('Facebook init', fbPromise)
          const fb = await fbPromise
          let response = await new Promise((resolve, reject) =>
              fb.login((st) => st.error ? reject(st.error) : resolve(st)), { scope: 'email' })
          fb.wasLoggedIn = true
          if(!response.authResponse) {
            this.isWaiting = false
            this.$emit('canceled')
            return 'canceled'
          } else {
            this.$emit('working')
            const apiPromise = api.command(['facebookLogin', 'registerOrLogin'], {
              accessToken: response.authResponse.accessToken,
              userData: this.userData
            })
            if(this.noLoading) this.workingZone.addPromise('Facebook login', apiPromise)
            await apiPromise
            this.isWaiting = false
            this.$emit('done')
            return 'ok'
          }
        })()
        if(!this.noLoading) this.workingZone.addPromise('Facebook login', promise)
      },
    }
  }
</script>

<style scoped>

</style>
