<template>
  <div>
    <div v-for="(category, index) in path">
      <observe v-if="category"
               :key="category"
               :name="`category ${category}`"
               :what="['categories', 'CategoryOne', { category }]"
               v-slot="{ value: categoryData }">
        <router-link :to="{ name: 'categories:category', params: { category } }">
          <span v-if="index > 0">≫</span>
          {{ categoryData.translations[lang] && categoryData.translations[lang].name || categoryData.name }}
        </router-link>
      </observe>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CategoryView",
    props: {
      viewDefinition: {
        type: Object,
        required: true
      },
      data: {
        required: true
      },
      type: {
        type: String,
        default: 'view'
      }
    },
    computed: {
      path() {
        if(Array.isArray(this.data)) return this.data
        return [this.data]
      },
      lang() {
        return 'en'
      }
    }
  }
</script>

<style scoped>

</style>