module.exports = {
  you: "You:",
  lastSeen: "last online:",
  onlineNow: "online now",
  ago: 'ago',
  userDeleted: 'Account Deleted',
  anonymous: 'Anonymous',
  mobileSendButton: 'Send',
  firstMessage: {
    title: "Write message",
    messagePlaceholder: "Write message...",
    submitButton: "Send message",
    sent: "Message sent.",
    answered: "Check answer.",
    new: "Wait for answer.",
    enterChat: "Open chat"
  }
}