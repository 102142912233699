<template>
  <div class="object-view">
    <div v-if="data">
      <div v-for="field of fields" class="property">
        <div class="property-name">{{ field }}</div>
        <ObjectValue class="property-value" :data="data[field]"
                     :viewDefinition="viewDefinition.properties[field]" :type="type">
        </ObjectValue>
      </div>
    </div>
    <div v-else>
      {{ JSON.stringify(data) }}
    </div>
  </div>
</template>

<script>
  import ObjectValue from "./ObjectValue.vue"

  export default {
    name: "ObjectView",
    components: { ObjectValue },
    props: {
      viewDefinition: {
        type: Object,
        required: true
      },
      data: {
        required: true
      },
      type: {
        type: String,
        default: 'view'
      }
    },
    computed: {
      viewConfig() {
        return this.viewDefinition[this.type] || {}
      },
      fields() {
        const conf = this.viewConfig
        if(conf.fields)
          return this.viewConfig.fields
        const defn = this.viewDefinition
        if(conf.all)
          return Object.keys(defn.properties)
        return Object.keys(defn.properties).filter(key => defn.properties[key][this.type])
      }
    }
  }
</script>

<style scoped>

</style>