<template>
  <div id="app">
    <loading-zone>
      <working-zone>
        <router-view></router-view>
        <overlay-component :overlayModel=overlayModel></overlay-component>
      </working-zone>
    </loading-zone>
  </div>
</template>

<script>
  import api from "api"
  import '../scss/style.scss'

  import { OverlayComponent } from 'common/components/utils/overlayLayer.js'
  import overlayModel from 'common/components/utils/overlayModel.js'

  export default {
    name: 'App',
    components: { OverlayComponent },
    metaInfo: {
      title: 'Vole Apps',
      titleTemplate: '%s | Vole Apps'
    },
    data() {
      return {}
    },
    methods: {
    },
    computed: {
      overlayModel() {
        return overlayModel
      },
      session() {
        return api.session /// FOR DEBUGING ONLY
      },
      metadata() {
        return api.metadata
      },
      i18nConfig() {
        return i18n.config
      }
    },
    watch: {
      apiVersion(version) {
        console.error(`API VERSION CHANGED TO ${version} DO RELOAD!`)
      }
    }
  }
</script>

<style lang="scss">
</style>
