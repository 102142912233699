var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form-field-bind", {
    staticClass: "fields-row",
    attrs: { tag: "div", notField: "", name: _vm.name },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var value = ref.value
          return [
            _c("multi-select-field", {
              staticClass: "fields-field",
              attrs: {
                name: _vm.name + ".fields",
                label: _vm.i18n.fields,
                options: _vm.searchFields,
                "all-value": ["all"],
                disabled: _vm.disabled,
                text: function(field) {
                  return _vm.fieldText(field)
                },
                pickerText: function(field) {
                  return _vm.fieldPickerText(field)
                },
                inputClass: "select-input-long"
              }
            }),
            _vm._v(" "),
            _c("single-select-field", {
              staticClass: "operator-field",
              attrs: {
                name: _vm.name + ".operator",
                label: _vm.i18n.operator,
                options: _vm.operators,
                disabled: _vm.disabled,
                text: function(op) {
                  return _vm.operatorText(op)
                },
                more: function(op) {
                  return op.more
                },
                "internal-value": function(op) {
                  return op.name
                }
              }
            }),
            _vm._v(" "),
            _c("text-field", {
              staticClass: "phrase-field",
              attrs: {
                name: _vm.name + ".phrase",
                label: _vm.i18n.phrase,
                disabled:
                  _vm.disabled ||
                  _vm.parameterlessOperators.includes(value.operator)
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "buttons" }, [
              !_vm.onlyOne
                ? _c(
                    "button",
                    {
                      staticClass: "button",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("delete")
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: { src: "/static/icons/delete_forever.svg" }
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button",
                  attrs: { type: "button", disabled: _vm.disabled },
                  on: {
                    click: function($event) {
                      return _vm.$emit("or")
                    }
                  }
                },
                [_vm._v("OR")]
              ),
              _vm._v(" "),
              _vm.last
                ? _c(
                    "button",
                    {
                      staticClass: "button",
                      attrs: { type: "button", disabled: _vm.disabled },
                      on: {
                        click: function($event) {
                          return _vm.$emit("and")
                        }
                      }
                    },
                    [_vm._v("AND")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.lastAnd
                ? _c(
                    "button",
                    {
                      staticClass: "button",
                      attrs: { type: "button", disabled: _vm.disabled },
                      on: {
                        click: function($event) {
                          return _vm.$emit("more")
                        }
                      }
                    },
                    [_vm._v("+")]
                  )
                : _vm._e()
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }