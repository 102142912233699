<template>
  <div class="search-result-data">
    <div v-if="typeof data == 'string'" v-html="encodedText(data)"
         :class="{ 'data-too-long': tooLong, 'data-expanded': expanded }">
    </div>
    <div v-else-if="Array.isArray(data)">
      <ol>
        <li v-for="item in data">
          <SearchResultData :data="item"></SearchResultData>
        </li>
      </ol>
    </div>
    <div v-else-if="typeof data == 'object'">
      <div class="property" v-for="key in Object.keys(data)">
        <div class="property-name">{{ key }}</div>
        <div class="property-value">
          <SearchResultData :data="data[key]"></SearchResultData>
        </div>
      </div>
    </div>
    <button v-if="tooLong && !expanded" class="button data-more-button" @click="more">More</button>
    <button v-if="tooLong && expanded" class="button data-less-button" @click="less">Less</button>
  </div>
</template>

<script>
  export default {
    name: "SearchResultData",
    props: {
      data: {
        required: true
      }
    },
    data() {
      return {
        expanded: false
      }
    },
    computed: {
      tooLong() {
        if(typeof this.data == 'string') {
          if(this.data.length > 300 || this.data.split('\n').length > 1) return true
        }
        return false
      }
    },
    methods: {
      encodedText(text) {
        const encodedStr = text.replace(/[\u00A0-\u9999<>\&]/gim, function(i) {
          return '&#'+i.charCodeAt(0)+';'
        })
        const multiline = encodedStr.replace(/\n/gi, /*'↵*/'<br>')
        const withLinks = multiline.replace(
            /(?![^<]*>|[^<>]*<\/)((https?:)\/\/[a-z0-9&#%=.\/?_,+-]+)/gi, '<a href="$1" target="_blank">$1</a>')
        return withLinks
      },
      more() {
        this.expanded = true
      },
      less() {
        this.expanded = false
      }
    }
  }
</script>

<style scoped lang="scss">
  .property {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
  }
  .property-name {
    min-width: 120px;
    background: #e1e1e1;
    padding: 2px 4px;
    margin: 1px;
    margin-right: -28px;
    text-align: right;
  }
  .property-value {
    min-width: 70%;
    padding: 2px 4px;
    margin: 1px;
    margin-left: 30px;
  }
  .property:nth-child(2n) {
    background: #f7f7f7;
    .property-name {
      background: #e1e1e1;
    }
  }

  .data-too-long:not(.data-expanded) {
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.2em;
    white-space: nowrap;
    width: 1000px;
    max-width: calc(100vw - 300px);
  }

  .data-more-button {
    position: absolute;
    top: 0;
    right: 0;
    max-height: 100%;
    padding: 2px 8px;
    background: white;
  }
  .data-less-button {
    padding: 2px 8px;
    background: white;
  }
</style>