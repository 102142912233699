var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card main-card xml-alert-scanner" },
    [
      _vm.showUrl
        ? _c("div", { staticClass: "xml-alert-scanner-info" }, [
            _c("div", { staticClass: "xml-alert-scanner-url" }, [
              _c(
                "a",
                {
                  staticClass: "dataSet-url",
                  attrs: { href: _vm.scanner.url }
                },
                [_vm._v(_vm._s(_vm.scanner.url))]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "xml-alert-scanner-state" }, [
              _vm._v(_vm._s(_vm.i18n.scannerState[_vm.scanner.state]))
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "xml-alert-scanner-info" }, [
        _vm.scanner.nextScan
          ? _c("div", [
              _c("b", [_vm._v(_vm._s(_vm.i18n.nextScan))]),
              _vm._v(" "),
              _c("time", { attrs: { datetime: _vm.scanner.nextScan } }, [
                _vm._v(
                  _vm._s(_vm._f("date")(_vm.scanner.nextScan)) +
                    " " +
                    _vm._s(_vm._f("hour")(_vm.scanner.nextScan))
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.showUrl
          ? _c("div", { staticClass: "xml-alert-scanner-state" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.i18n.scannerState[_vm.scanner.state]) +
                  "\n    "
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("observe", {
        attrs: {
          name: "lastScans",
          what: [
            "xmlAlert",
            "scansByScanner",
            {
              scanner: _vm.scanner.to || _vm.scanner.id,
              reverse: true,
              limit: 2
            }
          ]
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var scans = ref.value
              return [
                _vm._l(
                  scans
                    .filter(function(s) {
                      return s.state != "done"
                    })
                    .slice(0, 1),
                  function(scan) {
                    return _c("ScanCard", {
                      key: scan.id,
                      attrs: { scan: scan, scanner: _vm.scanner }
                    })
                  }
                ),
                _vm._v(" "),
                _vm._l(
                  scans
                    .filter(function(s) {
                      return s.state == "done"
                    })
                    .slice(0, 1),
                  function(scan) {
                    return _c("ScanCard", {
                      key: scan.id,
                      attrs: { scan: scan, scanner: _vm.scanner }
                    })
                  }
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("div", { staticClass: "buttons scanner-buttons" }, [
        _c(
          "div",
          { staticClass: "severity-select" },
          [
            _c("label", [_vm._v(_vm._s(_vm.i18n.smsSeverity))]),
            _vm._v(" "),
            _c("SingleSelectInput", {
              attrs: {
                options: _vm.severityLevels,
                value: _vm.scanner.notifications.sms,
                text: function(level) {
                  return _vm.i18n.severity[level]
                }
              },
              on: {
                input: function(value) {
                  return _vm.setNotificationLevel("sms", value)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "severity-select" },
          [
            _c("label", [_vm._v(_vm._s(_vm.i18n.emailSeverity))]),
            _vm._v(" "),
            _c("SingleSelectInput", {
              attrs: {
                options: _vm.severityLevels,
                value: _vm.scanner.notifications.email,
                text: function(level) {
                  return _vm.i18n.severity[level]
                }
              },
              on: {
                input: function(value) {
                  return _vm.setNotificationLevel("email", value)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "interval-select" },
          [
            _c("label", [_vm._v(_vm._s(_vm.i18n.scanInterval))]),
            _vm._v(" "),
            _c("SingleSelectInput", {
              attrs: {
                options: _vm.scanIntervals,
                value: _vm.scanner.interval,
                text: function(interval) {
                  return _vm.i18n.scanIntervals[interval]
                }
              },
              on: {
                input: function(value) {
                  return _vm.setScanInterval(value)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.deleteScannerButton !== false
          ? _c(
              "button",
              {
                staticClass: "button",
                attrs: { type: "button" },
                on: { click: _vm.deleteScanner }
              },
              [
                _c("img", {
                  attrs: { src: "/static/icons/delete_forever.svg" }
                }),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.deleteScannerButton || _vm.i18n.deleteScanner)
                  )
                ])
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }