<template>
  <form-field-bind :name="name" v-slot="{ value, setValue, error }"
                   :valueFilter="n => n && n.trim()">
    <FieldTemplate :errorText="errorText" :error="error" :label="label" :name="name" :groupClass="groupClass">
      <div class="show-password-wrapper">
        <input :type="showPassword ? 'text' : 'password'" :class="'form-control '+inputClass" :id="''+_uid"
               :value="value" @input="e => setValue(e.target.value)" ref="field"
               class="{ 'is-invalid': !!error }" :placeholder="placeholder"/>
        <div class="show-password" v-if="enableTogglePassword && showPassword === false"
             @click="showPassword = true">
          <img src="/static/icons/visibility.svg" class="show-password-icon" />
          <span class="show-password-text">{{ i18n.showPassword }}</span>
        </div>
        <div class="show-password" v-if="enableTogglePassword && showPassword === true"
             @click="showPassword = false">
          <img src="/static/icons/visibility_off.svg" class="show-password-icon" />
          <span class="show-password-text">{{ i18n.hidePassword }}</span>
        </div>
      </div>
    </FieldTemplate>
  </form-field-bind>
</template>

<script>
  import FieldTemplate from "./FieldTemplate.vue"
  import i18n from "#/i18n";

  export default {
    name: "PasswordField",
    components: { FieldTemplate },
    props: {
      name: {
        type: String,
        required: true
      },
      label: {
        type: String
      },
      placeholder: {
        type: String
      },
      errorText: {
        type: Object
      },
      inputClass: {
        type: String,
        default: ""
      },
      groupClass: {
        type: String,
        default: ""
      },
      enableTogglePassword: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        showPassword: false
      }
    },
    computed: {
      i18n() {
        return i18n().user
      },
    },
  }

</script>

<style scoped>

</style>