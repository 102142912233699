var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FieldTemplate",
    {
      attrs: {
        errorText: _vm.errorText,
        error: _vm.error,
        label: _vm.label,
        name: _vm.name,
        groupClass: _vm.groupClass
      }
    },
    [
      _c(
        "div",
        {
          ref: "select",
          class: ["select-input", _vm.inputClass, { disabled: _vm.disabled }],
          on: { click: _vm.showSelectMenu }
        },
        [
          _c("span", { staticClass: "select-input-value" }, [
            _vm.liveValue
              ? _c("div", [
                  _vm._v("\n        " + _vm._s(_vm.summary) + "\n      ")
                ])
              : _c("div", { staticClass: "select-input-placeholder" }, [
                  _vm._v("\n        " + _vm._s(_vm.placeholder) + "\n      ")
                ])
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "select-input-more" }, [
            _c("img", { attrs: { src: "/static/icons/arrow_drop_down.svg" } })
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }