import Vue from "vue"
import { imageToCanvas, loadImageUpload, cancelOrientation, hasAlpha } from "../utils/imageUtils.js"
import imageResizer from "../utils/imageResizer.js"

const maxUploadSize = 10 * 1024 * 1024
const maxUploadWidth = 2048
const maxUploadHeight = 2048

const maxProcessableSize = 50 * 1024 * 1025
const maxProcessableWidth = 10000
const maxProcessableHeight = 10000
const maxProcessablePixels = 6000*6000

async function preProcessFile(file) {
  if (file.size > maxProcessableSize) {
    throw new Error("tooBig")
    return
  }
  const img = await loadImageUpload(file)

  if (img.width > maxProcessableWidth
      || img.height > maxProcessableHeight
      || (img.width * img.height) > maxProcessablePixels) {
    throw new Error("tooBig")
    return
  }

  const processingNeeded =
      img.width > maxUploadWidth
      || img.height > maxUploadHeight
      || img.orientation

  if(!processingNeeded) return {
    blob: file,
    size: { width: img.width, height: img.height }
  }

  let canvas = imageToCanvas(img.image)

  if(img.width > maxUploadWidth || img.height > maxUploadHeight ) { /// RESIZING NEEDED
    const maxRatio = maxUploadWidth / maxUploadHeight
    const inputRatio = img.width / img.height

    let targetWidth, targetHeight
    if(inputRatio > maxRatio) { /// scale to max width
      targetWidth = maxUploadWidth
      targetHeight = Math.round(maxUploadWidth / inputRatio)
    } else { /// scale to max height
      targetWidth = Math.round(maxUploadHeight * inputRatio)
      targetHeight = maxUploadHeight
    }

    console.log(`RESIZING ${img.width}x${img.height} => ${targetWidth}x${targetHeight}`)

    let destCanvas = document.createElement('canvas')
    if(img.orientation  > 4) { // Swap dimmensions
      destCanvas.width = targetHeight
      destCanvas.height = targetWidth
    } else {
      destCanvas.width = targetWidth
      destCanvas.height = targetHeight
    }
    await imageResizer.resize(canvas, destCanvas, {
      unsharpAmount: 80,
      unsharpRadius: 0.6,
      unsharpThreshold: 2,
      alpha: hasAlpha(canvas)
    })
    canvas = destCanvas
  }

  if(img.orientation) cancelOrientation(canvas, img.orientation)

  const blob = await new Promise((resolve, reject) => canvas.toBlob(resolve, file.type))
  console.log("OUTPUT CANVAS", canvas.width, canvas.height)
  console.log("OUTPUT BLOB", blob)
  blob.name = file.name

  return {
    blob,
    size: { width: canvas.width, height: canvas.height }
  }
}

class PictureUpload {
  constructor(pictureId, url, blob, size) {
    this.pictureId = pictureId
    this.size = size
    this.url = url
    this.blob = blob
    this.uploadId = 1
    this.state = 'uploading'
    this.error = null
    this.clientUpload = null
    this.serverUpload = null

    this.donePromise = new Promise((resolve, reject) => {
      this.resolveDone = { resolve, reject }
    })


    this.doUpload()
  }

  reupload(pictureId, url, blob, size) {
    this.cancelUpload()

    this.size = size
    this.url = url
    this.state = 'uploading'
    this.blob = blob

    this.doUpload()
  }

  cancelUpload() {
    if(this.clientUploadObservable) {
      this.clientUploadObservable.unobserve(this.clientUploadObserver())
      this.clientUploadObservable = null
    }
    if(this.serverUploadObservable) {
      this.serverUploadObservable.unobserve(this.clientUploadObserver())
      this.serverUploadObservable = null
    }
    this.uploadId ++
  }

  doUpload() {
    const uploadId = this.uploadId

    this.clientUploadObservable = api.uploadFile('wysiwyg', this.blob.name, this.blob)
    this.clientUploadObserver = (signal, ...args) => {
      if(uploadId != this.uploadId) return;
      console.log(`UPLOAD ${signal} ( ${args.map(x => JSON.stringify(x)).join(', ')} )`)
      if (signal != 'set') throw new Error("unknown signal")
      this.clientUpload = args[0]
      this.observeServerUpload()
      if (this.clientUpload.state == 'done') {
        if(this.serverUpload && this.serverUpload.state == 'done') this.handleUploaded()
      }
      if (this.clientUpload.state == 'failed') {
        this.error = error
      }
    }
    this.clientUploadObservable.observe(this.clientUploadObserver)
  }

  observeServerUpload() {
    const uploadId = this.uploadId

    this.serverUploadObservable = api.observable(['uploads', 'upload', {upload: this.clientUpload.id}])
    this.serverUploadObserver = (signal, ...args) => {
      if(uploadId != this.uploadId) return;
      if (signal != 'set') throw new Error("unknown signal")
      this.serverUpload = args[0]
      if(!this.serverUpload) return
      if(this.serverUpload.state == 'done') {
        if(this.clientUpload && this.clientUpload.state == 'done') this.handleUploaded()
      }
    }
    this.serverUploadObservable.observe(this.serverUploadObserver)
  }

  handleUploaded() {
    if(this.handled) return;
    this.handled = true
    this.uploadId++
    this.state = 'uploaded'
    api.command(["pictures", "uploadPicture"], {
      picture: this.pictureId,
      original: {
        width: this.size.width,
        height: this.size.height,
        uploadId: this.clientUpload.id
      }
    }).then(result => {
      this.url = `/pictures/${this.pictureId}/original`
      this.state = 'done'
      const uploads = pictureUploads.uploads
      for(let i = 0; i < uploads.length; i++) {
        if(uploads[i].pictureId == this.pictureId) {
          uploads.splice(i, 1)
          return;
        }
      }
      this.resolveDone.resolve(result)
    }).catch(error => {
      this.error = error
      this.resolveDone.reject(error)
    })
  }

}

const pictureUploads = new Vue({
  data: {
    uploads: []
  },
  methods: {
    addUpload(pictureId, url, blob, size) {
      const existing = this.uploads.find(p => p.pictureId == pictureId)
      if( existing ) {
        existing.reupload(pictureId, url, blob, size)
        return;
      }
      const upload = new PictureUpload(pictureId, url, blob, size)
      this.uploads.push(upload)
      return upload
    }
  }
})

export { pictureUploads, PictureUpload, preProcessFile }
