var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("TagsListInput", {
    attrs: { i18n: _vm.i18n, tagsType: _vm.tagsType, value: _vm.selectedTags },
    on: { selected: _vm.select, deselected: _vm.deselect }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }