<template>
  <page :footer="true">
    <loading :what="state != 'loading'" name="key state">
      <div class="card half-width main-card" v-if="keyState || state == 'notFound'">
        <div v-if="state=='used' || state=='expired' || state=='done' || state=='notFound' || state=='resent'">
          <h4>{{ i18n[state].title }}<span v-if="state === 'done'"><br /><strong>{{ i18n[state].subtitle }}</strong></span></h4>
          <p>{{ i18n[state].text }}</p>
          <router-link v-if="state=='used'" :to="{name: 'user:login'}">{{ i18n[state].button }}</router-link>
          <a href="#" v-if="state=='expired'" @click.prevent="resendConfirmationEmail">{{ i18n.resendEmail }}</a>
          <!--
          <router-link v-if="state=='expired'" :to="{name: 'register'}">{{ i18n().registerLink }}</router-link>
          -->
          <button v-if="state=='done'" v-on:click="exit" class="button">
            {{ i18n[state].exitButton }}
          </button>
        </div>
      </div>
    </loading>
  </page>
</template>

<script>
  import api from "api"
  import i18n from "i18n"

  export default {
    name: 'ConfirmRegisterEmail',
    data () {
      return {
        notMatch: false,
        error: null,
        state: 'loading'
      }
    },
    inject: ['workingZone'],
    reactive: {
      keyState() { return ['emailPassword', 'emailKey', { key: this.key }] }
    },
    reactivePreFetch(route) {
      return [
        { what: ['emailPassword', 'emailKey', { key: route.params.key }] }
      ]
    },
    computed: {
      i18n() { return i18n().emailPassword.finishRegister },
      loggedIn() {
        return api.session.loggedIn
      },
      key() {
        return this.$route.params.key
      }
    },
    watch: {
      keyState(v) {
        this.handleKeyState(v)
      },
      loggedIn(v) {
      }
    },
    mounted() {
      if(this.keyState) this.handleKeyState(this.keyState)
    },
    methods: {
      exit() {
        this.$router.replace({ name: 'userIndex' })
      },
      handleKeyState(v) {
        console.log("HANDLE KEY STATE", v, "IN STATE", this.state)
        if(this.state == 'loading') {
          if(!v) {
            this.state = 'notFound'
            return
          }
          if(v.used) {
            if(this.loggedIn && this.keyState.user == api.session.user.id) {
              this.state = 'done'
            } else {
              this.state = 'used'
            }
          } else if(v.expire < Date.now()) {
            this.state = 'expired'
          } else if((this.state == 'loading' || this.state == 'notFound') && !api.session.session.user) {
            this.state = "working"
            this.workingZone.addPromise("Creating account",
              api.command(["emailPassword", "finishRegister"], {
                key: this.key,
                _commandId: this.key + "_" + api.windowId
              }).then(result => {
                this.state = "done"
              })/*.catch(error => {
                this.error = error
                this.state = "ready"
              })*/
            )
          }
        }
      },
      resendConfirmationEmail() {
        this.workingZone.addPromise('resendEmail',
            api.command(['emailPassword', 'resendRegisterKey'], {
              email: this.keyState.email,
              lang: i18n.config.language
            }).then(result => this.state = 'resent' )
        )
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

