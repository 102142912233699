var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page",
    { attrs: { footer: true } },
    [
      _c(
        "loading",
        {
          attrs: {
            what: _vm.keyState || _vm.state == "notFound",
            name: "key state"
          }
        },
        [
          _vm.keyState || _vm.state == "notFound"
            ? _c("div", { staticClass: "card half-width main-card" }, [
                _vm.state == "used" ||
                _vm.state == "expired" ||
                _vm.state == "done" ||
                _vm.state == "notFound" ||
                _vm.state == "taken"
                  ? _c("div", [
                      _c("h4", [_vm._v(_vm._s(_vm.i18n()[_vm.state].title))]),
                      _vm._v(" "),
                      _c("div", { staticClass: "info-message" }, [
                        _c("p", [_vm._v(_vm._s(_vm.i18n()[_vm.state].text))])
                      ]),
                      _vm._v(" "),
                      _vm.state == "done"
                        ? _c(
                            "button",
                            { staticClass: "button", on: { click: _vm.exit } },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.i18n()[_vm.state].exitButton) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }