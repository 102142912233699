var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-picker" },
    [
      _c(
        "overlay-anchor",
        {
          attrs: { anchor: _vm.anchor, directions: _vm.directions },
          on: { positioned: _vm.updatePosition }
        },
        [
          _c(
            "div",
            {
              staticClass: "select-picker-card card",
              style: Object.assign({}, _vm.maxDimensions),
              attrs: { ef: "box" }
            },
            [
              _c(
                "div",
                { staticClass: "options" },
                _vm._l(_vm.options, function(option, index) {
                  return _c(
                    "div",
                    {
                      ref: "option_" + index,
                      refInFor: true,
                      staticClass: "select",
                      on: {
                        click: function($event) {
                          return _vm.selectSelected(option, index)
                        }
                      }
                    },
                    [
                      _vm.more(option)
                        ? _c("div", { staticClass: "select-with-more" }, [
                            _c("div", { staticClass: "selectName" }, [
                              _vm._v(_vm._s(_vm.text(option)))
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "select-input-more" }, [
                              _c("img", {
                                attrs: {
                                  src: "/static/icons/arrow_drop_down.svg"
                                }
                              })
                            ])
                          ])
                        : _c("div", { staticClass: "selectName" }, [
                            _vm._v(_vm._s(_vm.text(option)))
                          ])
                    ]
                  )
                }),
                0
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }