<template>
  <form-field-bind :name="name" v-slot="{ value, setValue, error }">
    <div class="checkbox-field">
      <span class="checkbox">
        <input type="checkbox" class="checkbox-input" :id="uid" :checked="value"
               @input="(ev) => setValue(ev.target.checked)" />
        <span class="checkbox-mark"></span>
      </span>
      <label class="custom-control-label" :for="uid">
        <slot name="label">{{ label }}</slot>
      </label>
    </div>
    <small class="field-error">
      {{ (errorText && errorText[error]) || i18n[error] || error }}
    </small>
  </form-field-bind>
</template>

<script>
  import FieldTemplate from "./FieldTemplate.vue"
  import i18n from 'i18n'


  export default {
    name: "BooleanField",
    components: {FieldTemplate},
    props: {
      name: {
        type: String,
        required: true
      },
      label: {
        type: String
      },
      errorText: {
        type: Object
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      i18n() { return i18n().validation },
      uid() {
        return this._uid
      }
    }
  }

</script>

<style scoped>

</style>