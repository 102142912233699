var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("page", [
    _c("h1", [_vm._v("XML ALERT!")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "full-width" },
      [
        _vm.isLoggedIn
          ? _c("observe", {
              attrs: {
                name: "myScanners",
                what: ["xmlAlert", "myScanners", {}]
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(ref) {
                      var scanners = ref.value
                      return _vm._l(scanners, function(scanner) {
                        return _c("ScannerCard", {
                          key: scanner.to,
                          attrs: { scanner: scanner, "show-url": "" }
                        })
                      })
                    }
                  }
                ],
                null,
                false,
                1265202807
              )
            })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card main-card full-width" },
      [
        _c("h3", [_vm._v("Add url:")]),
        _vm._v(" "),
        _c(
          "command-form",
          {
            ref: "form",
            attrs: {
              service: "xmlAlert",
              action: "createScanner",
              keepOnDone: true
            },
            on: { done: _vm.handleScannerAdded }
          },
          [
            _c("text-field", {
              attrs: {
                name: "url",
                label: _vm.i18n.url,
                fieldValidators: {
                  url: function(v) {
                    return _vm.validateUrl(v)
                  }
                }
              }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button",
                attrs: { type: "submit", role: "button" }
              },
              [_vm._v(_vm._s(_vm.i18n.createScannerSubmit))]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }