import ReactiveDao from "@live-change/dao"
import ReactiveSockJS from "@live-change/dao-sockjs"
import ClientDatabaseServer from "@live-change/db-web"
import ClientMessages from "./ClientMessages.js"

export default (sessionId) => {

  const memDatabase = new ClientDatabaseServer({
    backend: 'mem',
    dbPrefix: 'memDb'
  })
  memDatabase.initialize()
  window.memDb = memDatabase


  const clientMessages = new ClientMessages()

  const dao = new ReactiveDao({ sessionId }, {

    remoteUrl: document.location.protocol + '//' + document.location.host + "/api/sockjs",

    protocols: {
      'sockjs': ReactiveSockJS
    },

    connectionSettings: {
      queueRequestsWhenDisconnected: true,
      requestSendTimeout: Infinity,
      requestTimeout: Infinity,
      queueActiveRequestsOnDisconnect: true,
      autoReconnectDelay: 200,
      logLevel: 1,
      /*connectionMonitorFactory: (connection) =>
          new ReactiveDao.ConnectionMonitorPinger(connection, {
            pingInterval: 50,
            pongInterval: 200
          })*/
    },

    memDb: {
      type: "local",
      source: memDatabase.getDatabaseDao()
    },

    clientMessages: {
      type: "local",
      source: clientMessages
    },

    defaultRoute: {
      type: "remote",
      generator: ReactiveDao.ObservableList
    }
  })

  clientMessages.init(dao)

  return dao
}
