var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "loading-zone" }, [
    _c(
      "div",
      { staticClass: "content", class: { loading: _vm.loading.length } },
      [_vm._t("default")],
      2
    ),
    _vm._v(" "),
    _vm.loading.length || _vm.errors.length
      ? _c(
          "div",
          { staticClass: "loader-main" },
          [
            _vm.loading.length && !_vm.errors.length
              ? _vm._t("loading", function() {
                  return [
                    _c("div", { staticClass: "loading-overlay" }, [
                      _c("div", { staticClass: "card loading-card" }, [
                        _vm._m(0),
                        _vm._v(" "),
                        _vm.connectionProblem
                          ? _c("span", {
                              staticClass: "loading-text",
                              domProps: {
                                innerHTML: _vm._s(_vm.i18n.connectionProblem)
                              }
                            })
                          : _c("span", {
                              staticClass: "loading-text",
                              domProps: { innerHTML: _vm._s(_vm.i18n.loading) }
                            })
                      ])
                    ])
                  ]
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.errors.length
              ? _vm._t("error", function() {
                  return [
                    _c("InternalServerError", {
                      attrs: { taskType: "Loading", errors: _vm.errors }
                    })
                  ]
                })
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cube-spinner" }, [
      _c("div", { staticClass: "cube1" }),
      _vm._v(" "),
      _c("div", { staticClass: "cube2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }