var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "loading-zone",
    { staticClass: "access-members" },
    [
      _vm._l(_vm.members, function(member) {
        return _c("observe", {
          key: member.user,
          attrs: { what: ["users", "publicUserData", { user: member.user }] },
          scopedSlots: _vm._u(
            [
              {
                key: "default",
                fn: function(ref) {
                  var userData = ref.value
                  return [
                    _c(
                      "div",
                      {
                        class: [
                          "access-member",
                          { online: userData.online, offline: !userData.online }
                        ]
                      },
                      [
                        _c("uploaded-picture-img", {
                          attrs: {
                            width: "25",
                            height: "25",
                            picture: userData.picture
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "access-member-name" }, [
                          _vm._v(_vm._s(userData.display))
                        ]),
                        _vm._v(" "),
                        _c("observe", {
                          attrs: {
                            name: "session online",
                            what: [
                              "accessControl",
                              "userAccessOnline",
                              {
                                access: _vm.toType + "_" + _vm.toId,
                                user: member.user
                              }
                            ]
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var online = ref.value
                                  return [
                                    _vm._v(
                                      "\n        " +
                                        _vm._s(online.online ? "+" : "-") +
                                        "\n      "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ],
            null,
            true
          )
        })
      }),
      _vm._v(" "),
      _vm._l(_vm.sessions, function(session) {
        return _c("observe", {
          key: session.id,
          attrs: {
            name: "public session info",
            what: [
              "accessControl",
              "publicSessionInfo",
              { publicSessionInfo: session.id }
            ]
          },
          scopedSlots: _vm._u(
            [
              {
                key: "default",
                fn: function(ref) {
                  var info = ref.value
                  return [
                    _c(
                      "div",
                      {
                        class: [
                          "access-member",
                          { online: info.online, offline: !info.online }
                        ]
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "/static/empty-user-photo.svg",
                            width: "25",
                            height: "25"
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "access-member-name" }, [
                          _vm._v(_vm._s(info.name || "Anonymous"))
                        ]),
                        _vm._v(" "),
                        _c("observe", {
                          attrs: {
                            name: "session online",
                            what: [
                              "accessControl",
                              "sessionAccessOnline",
                              {
                                access: _vm.toType + "_" + _vm.toId,
                                publicSessionInfo: session.id
                              }
                            ]
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var online = ref.value
                                  return [
                                    _vm._v(
                                      "\n        " +
                                        _vm._s(online.online ? "+" : "-") +
                                        "\n      "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ],
            null,
            true
          )
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }