var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "visible-area",
    { ref: "area", on: { update: _vm.setVisibleArea } },
    [
      _c("div", {
        staticClass: "scroll-top-fill",
        style: { height: _vm.topFill + "px" }
      }),
      _vm._v(" "),
      _vm.isLoadingTop
        ? _vm._t("loadingTop", null, null, {
            connectionProblem: _vm.isLoadingTopTooLong
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.visibleState.rows, function(row, index) {
        return _c(
          "div",
          {
            key: _vm.rowKey(row),
            ref: "row",
            refInFor: true,
            staticClass: "scroll-data",
            attrs: { id: _vm.rowId(row) }
          },
          [
            _vm._t("default", null, null, {
              row: row,
              index: index,
              rows: _vm.visibleState.rows
            })
          ],
          2
        )
      }),
      _vm._v(" "),
      _vm.isLoadingBottom
        ? _vm._t("loadingBottom", null, null, {
            connectionProblem: _vm.isLoadingBottomTooLong
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        staticClass: "scroll-bottom-fill",
        style: { height: _vm.bottomFill + "px" }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }