import Vue from "vue"

import ObjectView from "./ObjectView.vue"
Vue.component('object-view', ObjectView)

import ListView from "./ListView.vue"
Vue.component('list-view', ListView)

import JsonView from "./JsonView.vue"
Vue.component('object-view', JsonView)

import TextView from "./TextView.vue"
Vue.component('text-view', TextView)

import PictureView from "./PictureView.vue"
Vue.component('category-view', PictureView)

import CategoryView from "@/modules/categories/CategoryView.vue"
Vue.component('category-view', CategoryView)

const dataViews = {
  byName: {
    "object": ObjectView,
    "json": JsonView,
    "list": ListView,
    "text": TextView,
    "picture": PictureView,
    "category": CategoryView,
  },
  byType: {
    "String": TextView,
    "Number": TextView,
    "Array": ListView,
    "Object": ObjectView,
    "pictures:Picture": PictureView,
    "categories:Category": CategoryView,
  //  "Date": DateTimeView,
  //  "Boolean": BooleanView,
  //  "pictures:Picture": PictureView,
  },
  defaultView: JsonView,
  byDefinition(fieldDefinition, viewConfig) {
    let view
    if(viewConfig) {
      if(typeof viewConfig == 'string') {
        view = dataViews.byName[viewConfig]
        if(!view) throw new Error("unknown data view " + viewConfig)
        return view
      } else if(typeof viewConfig == 'object' && viewConfig.view) {
        view = dataViews.byName[viewConfig.view]
        if(!view) throw new Error("unknown editor " + viewConfig.view)
        return view
      }
    }
    view = dataViews.byType[fieldDefinition.type]
    if(view) return view
    if(dataViews.defaultView) return dataViews.defaultView
    if(!view) throw new Error("no editor for field type "+fieldDefinition.type)
    return view
  }
}

Vue.prototype.$dataViews = dataViews