<template>
  <form-field-bind :name="name" v-slot="{ value, setValue, error }">
    <FieldTemplate :errorText="errorText" :error="error" :label="label" :name="name">
      <br>
      <span v-for="option in options" @click="option == value ? setValue(undefined) : setValue(option)">
        <slot v-if="option == value" name="selected" :option="option"></slot>
        <slot v-else name="default" :option="option"></slot>
      </span>
    </FieldTemplate>
  </form-field-bind>
</template>

<script>
  import DatePicker from 'vue2-datepicker'
  import FieldTemplate from "./FieldTemplate.vue"

  export default {
    name: "OptionsField",
    components: { FieldTemplate, DatePicker },
    props: {
      name: {
        type: String,
        required: true
      },
      label: {
        type: String
      },
      errorText: {
        type: Object
      },
      options: {
        type: Array,
        required: true
      }
    },
    methods: {
    }

  }

</script>

<style scoped>

</style>
