import ConfirmEmail from '@/modules/user/emailPassword/ConfirmEmail.vue'
import ConfirmRegisterEmail from '@/modules/user/emailPassword/ConfirmRegisterEmail.vue'

import AccountSettings from '@/modules/user/AccountSettings.vue'
import CompleteData from '@/modules/user/user/CompleteData.vue'
import RegisterPage from '@/modules/user/RegisterPage.vue'
import LoginPage from "./LoginPage"
import LogoutProcess from "./LogoutPage.vue"
import ResetPasswordPage from '@/modules/user/emailPassword/ResetPasswordPage.vue'
import ResetPasswordForm from "./emailPassword/ResetPasswordForm"

import GoogleSignInReturn from "./GoogleSignInReturn.vue"

import ProfilePage from "./ProfilePage.vue"
import RemoveAccount from "./RemoveAccount.vue"
import RemoveAccountFeedback from "./RemoveAccountFeedback"
import PubDataTest from "./PubDataTest.vue"

export default function(prefix) {
  const routes = [
    {
      path: prefix+'/confirm-sign-up/:key',
      name: 'emailPassword:confirm-register',
      component: ConfirmRegisterEmail
    },
    {
      path: prefix+'/change-email/:key',
      name: 'emailPassword:change-email',
      component: ConfirmEmail
    },
    {
      path: prefix+'/sign-up',
      name: 'user:register',
      component: RegisterPage,
      meta: { requiresLogout: true }
    },
    {
      path: prefix+'/remove-account',
      name: 'user:removeAccount',
      component: RemoveAccount,
      meta: { requiresLogin: true }
    },
    {
      path: prefix+'/remove-account-feedback',
      name: 'user:removeAccountFeedback',
      component: RemoveAccountFeedback,
      meta: { requiresLogin: false }
    },
    {
      path: prefix+'/settings',
      name: 'user:settings',
      component: AccountSettings,
      meta: { requiresLogin: true }
    },
    {
      path: prefix+'/completeData',
      name: 'user:completeData',
      component: CompleteData,
      meta: { userDataCompletion: true }
    },
    {
      name: 'user:google-sign-in-return',
      path: prefix+'/google-sign-in',
      component: GoogleSignInReturn,
      meta: { requiresLogout: true }
    },
    {
      path: prefix+'/sign-out',
      name: 'user:logout',
      component: LogoutProcess,
      meta: { ignoreUserDataCompletion: true },
      props: true
    },
    {
      path: prefix+'/sign-in',
      component: LoginPage,
      name: 'user:login',
      meta: { requiresLogout: true },
      props: true
    },
    {
      path: prefix+'/reset-password/:emailKey',
      name: 'user:resetPasswordForm',
      component: ResetPasswordForm,
      props: true,
      meta: { requiresLogout: true }
    },
    {
      path: prefix+'/start-password-reset/:step',
      name: 'user:resetPassword',
      component: ResetPasswordPage,
      props: true
    },
    {
      path: prefix+'/profile/:user',
      name: 'user:profile',
      component: ProfilePage,
      props: true
    },
    {
      path: prefix+'/pubDataTest/:user',
      name: 'user:pubDataTest',
      component: PubDataTest,
      props: true
    },
  ]

  routes.sitemap = async (route, api) => {
    const usersListPromise = api.get(["users", "UserRange", { }]).then(users => users.filter(
        user => user.userData.agreement1
    ))

    console.log("FOUND USERS", (await usersListPromise).length)
    for(const user of await usersListPromise) {
      route({ name: 'user:profile', params: { user: user.slug } }, { changefreq: 'monthly', priorty: 0.3 })
    }
  }
  return routes
}
