<template>
  <div class="search-results-groups full-width">
    <div v-for="group in exclusionsGroups" class="search-results-group">
      <div class="search-results-group-title card main-card">
        <div class="title"  @click="() => toggleGroup(group)">
          <h3>{{ group.word }}</h3>
        </div>
        <div @click="copyGroupWord(group.word)" class="right-buttons">
          <div class="copy-info" v-if="groupWordCopied == group.word">{{ i18n.wordCopied }}</div>
          <button type="button" class="button" >
            <img src="/static/icons/content_copy.svg">
          </button>
        </div>
        <div @click="() => toggleGroup(group)" class="right-buttons">
          <span>{{ group.count }}</span>
          <img v-if="isExpanded(group)" src="/static/icons/expand_less.svg">
          <img v-else src="/static/icons/expand_more.svg">
        </div>
      </div>
      <div v-if="isExpanded(group)" class="search-results full-width">
        <scroll-loader :what="resultsGroupDaoPath(group)"
                      v-if="exclusions" :key="exclusions" noDebugLog>
          <template v-slot:default="{ row: result }">
            <ExclusionsResult :data="result" :key="result.id" :viewAsXml="viewAsXml" :rawXml="rawXml">
            </ExclusionsResult>
          </template>
          <template v-slot:loadingBottom="{ connectionProblem }">
            <div class="search-bottom-loading-bar">
              <div class="card loading-card">
                <div class="cube-spinner">
                  <div class="cube1"></div>
                  <div class="cube2"></div>
                </div>
                <span class="loading-text" v-if="connectionProblem" v-html="i18n.connectionProblem"></span>
                <span class="loading-text" v-else v-html="i18n.loadingBottom"></span>
              </div>
            </div>
          </template>
        </scroll-loader>
      </div>
    </div>
  </div>
</template>

<script>
  import i18n from 'i18n'

  import ExclusionsResult from "./ExclusionsResult.vue"
  import ScrollLoader from "common/components/ScrollLoader.vue"
  import copyToClipboard from "copy-to-clipboard"

  export default {
    name: "ExclusionsGroups",
    components: { ExclusionsResult, ScrollLoader },
    props: {
      exclusions: {
        type: String,
        default: null
      },
      viewAsXml: {
        type: Boolean,
        default: false
      },
      rawXml: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        expandedGroups: [],
        groupWordCopied: false
      }
    },
    reactive: {
      exclusionsEntity() {
        return this.exclusions && ['xmlCsvExclusions', "exclusionsCheck", { exclusionsCheck: this.exclusions } ]
      },
      dataSetEntity() {
        return this.exclusionsEntity && ['xmlSearch', "dataSet", { dataSet: this.exclusionsEntity.dataSet } ]
      },
      exclusionsGroups() {
        return ['xmlCsvExclusions', 'exclusionCheckGroups', { check: this.exclusions }]
      }
    },
    computed: {
      i18n() {
        return i18n().xmlExclusions
      }
    },
    methods: {
      isExpanded(group) {
        return this.expandedGroups.indexOf(group.id) != -1
      },
      toggleGroup(group) {
        const index = this.expandedGroups.indexOf(group.id)
        if(index != -1) {
          this.expandedGroups.splice(index, 1)
        } else {
          this.expandedGroups.push(group.id)
        }
      },
      resultsGroupDaoPath(group) {
        return ({gt, limit, reverse}) => ['xmlCsvExclusions', 'exclusionCheckWord',
          { check: this.exclusions, word: group.word, from: gt || '', limit: reverse ? 0 : limit }]
      },
      copyGroupWord(word) {
        copyToClipboard(word)
        if(this.groupWordCopied) clearTimeout(this.copyTimeout)
        this.groupWordCopied = word
        this.copyTimeout = setTimeout(() => this.groupWordCopied = false, 3020)
      },
    }
  }
</script>

<style scoped>

</style>