<template>
  <form-field-bind :name="name" v-slot="{ error }">
    <FieldTemplate :errorText="errorText" :error="error" :label="label" :name="name">
      <br />
      <DatePicker lang="en" type="datetime" :format="lang === 'en' ? 'MM-DD-YYYY HH:mm' : 'DD.MM.YYYY HH:mm'"
                  :placeholder="placeholder" input-class="form-control" width="100%"
                  :value="inputValue" @input="handleInput" :minute-step="5" >

                <img slot="calendar-icon" src="/static/ICO_calendar.svg" />

      </DatePicker>
    </FieldTemplate>
  </form-field-bind>

</template>
<script>
  import DatePicker from 'vue2-datepicker'
  import FieldTemplate from "./FieldTemplate.vue"
  import moment from "moment"
  import i18n from "i18n"

  export default {
    name: "DateTimeField",
    components: { FieldTemplate, DatePicker },
    inject: ["form"],
    props: {
      name: {
        type: String,
        required: true
      },
      label: {
        type: String
      },
      errorText: {
        type: Object
      },
      pushDate: {
        type: String
      },
      placeholder: {
        type: String
      }
    },
    data() {
      return {
        inputValue: null
      }
    },
    computed: {
      lang() { return i18n.config.language }
     /* dateTime() {
        const dateParts = this.date.split('-')
        const timeParts = this.time.split(':')
        if(timeParts && timeParts.length<3) timeParts.push("00")
        //console.log("dtp", dateParts, timeParts)
        if(dateParts.length==3 && timeParts.length==3) {
          //const offset = moment().utcOffset()

          let dt = new Date(Date.UTC.apply(undefined,
              [ dateParts[0], +dateParts[1]-1, +dateParts[2]].concat(timeParts)))
          //dt = (new Date((dt.getTime())+(new Date()).getTimezoneOffset()*60000)).toISOString()
          let time = dt.getTime() + dt.getTimezoneOffset()*60000
          //console.log("TIME!!!", time, new Date(time))
          dt.setTime(time)
          return dt.toUTCString()
        }
        return null
      }*/
    },
    watch: {
    },
    methods: {
      handleInput(input) {
        console.log("input", input)
        /*console.log("TZ offset", input.getTimezoneOffset())
        console.log("Local TZ offset", (new Date()).getTimezoneOffset())*/
        //let uts = input.getTime() + input.getTimezoneOffset()*60000
        let date = new Date(input)
        console.log("UTC", date.toUTCString())
        if(!this.connected) return;
        this.form.setFieldValue(this.name, date.toUTCString())
        if(this.pushDate) {
          const dayMs = 24*60*60*1000
          let dest = this.form.getFieldValue(this.pushDate)
          if(!dest) {
            this.form.setFieldValue(this.pushDate, date.toUTCString())
          } else {
            const srcTime = date.getTime()
            const destTime = (new Date(dest)).getTime()
            const destOffset = destTime % dayMs
            const srcOffset = srcTime % dayMs
            let dd = new Date()
            if (destOffset > srcOffset && destOffset - srcOffset < 5*60*60*1000) {
              dd.setTime(srcTime + destOffset - srcOffset)
            } else {
              dd.setTime(srcTime)
            }
            this.form.setFieldValue(this.pushDate, dd.toUTCString())
          }
        }
      }
    },
    created() {
      this.valueObserver = (v) => {
        this.connected = false
        if(v && v != this.dateTime) {
          const utcDate = v
          //const offset = moment().utcOffset()
          this.inputValue = moment.utc(utcDate).toDate()//.utcOffset(offset)
        }
        this.connected = true
      }
      this.errorObserver = (e) => {
        this.connected = false
        this.error = e
        this.connected = true
      }
      this.form.observe(this.name, this.valueObserver)
      this.form.observeError(this.name, this.errorObserver)
      this.connected = true
    },
    beforeDestroy() {
      this.form.unobserve(this.name, this.valueObserver)
      this.form.unobserveError(this.name, this.errorObserver)
      this.connected = false
    }
  }

</script>

<style scoped>

</style>
