<template>
  <page>
    <div v-if="myApiAccess" class="card half-width main-card">
      <h2>Google Ads API</h2>
      <p>Google Ads API Access active</p>
      <button class="button" type="button" @click="removeApiAccess">
        Disconnect from Google Ads API
      </button>
      <p>
        <router-link :to="{ name: 'adsApi:campaigns' }">
          View Campaigns
        </router-link>
      </p>
    </div>
    <div v-else class="card half-width main-card">
      <h2>No Google Ads API Access</h2>
      <div v-if="!authUri" class="buttons">
        <button class="button" type="button" @click="authenticateApi">
          Connect to Google Ads API
        </button>
      </div>
      <div v-if="authUri">
        <p>We will redirect you to the authentication page. If that doesn't happen, click the link below:</p>
        <a class="authUri" :href="authUri">{{ authUri }}</a>
      </div>
    </div>
  </page>
</template>

<script>
import api from "api"

function getAuthorizationUri(config) {
  const {
    authorizationBase = 'https://accounts.google.com/o/oauth2/auth',
    accessType = 'offline',
    approvalPrompt = 'force',
    scope,
    responseType = 'code',
    clientId,
    redirectUri = 'urn:ietf:wg:oauth:2.0:oob'
  } = config

  const authorizationUri = authorizationBase +
      `?access_type=${accessType}&approval_prompt=${approvalPrompt}` +
      `&scope=${encodeURIComponent(scope)}&response_type=${responseType}`+
      `&client_id=${encodeURIComponent(clientId)}`+
      //`&state=${encodeURIComponent(clientId)}` +
      `&redirect_uri=${encodeURIComponent(redirectUri)}`

  return authorizationUri
}


export default {
  name: "Access",
  inject: ['workingZone'],
  reactive: {
    myApiAccess() { return ['adsApi', 'myApiAccess', { } ] }
  },
  reactivePreFetch(route) {
    return [
      {
        what: ['adsApi', 'myApiAccess', { } ]
      }
    ]
  },
  data() {
    return {
      authUri: null
    }
  },
  methods: {
    authenticateApi() {
      const clientId = ENV.ADS_CLIENT_ID
      console.log("CLIENT ID", clientId)
      const path = this.$router.resolve({ name: 'adsApi:accessReturn' }).href
      console.log("PATH", path)
      const authUri = getAuthorizationUri({
        clientId,
        scope: 'https://www.googleapis.com/auth/adwords',
        redirectUri: document.location.protocol + '//' + document.location.host + path
      })
      console.log("AUTH URI", authUri)
      this.authUri = authUri

      setTimeout(() => window.location = this.authUri, 1000)
    },
    removeApiAccess() {
      this.workingZone.addPromise('removeApiAccess', (async () => {
        const result = await api.command(['adsApi', 'removeAccess'], { })
        console.log( result )
      })())
    }
  }
}
</script>

<style scoped>
  .authUri {
    word-break: break-word;
  }
</style>
