<template>
  <loading name="conversation card" :what="allLoaded" :class="['conversation-card', 'card', { unread: isUnread, selected }]">
    <div class="conversation-card-picture">
      <uploaded-picture-img v-if="userData && userData.picture"
                            width="45" height="45" :picture="userData.picture" :reactive="true">
      </uploaded-picture-img>
      <img v-else-if="userData === null" src="/static/deleted-user-photo.svg" width="45" height="45">
      <img v-else src="/static/empty-user-photo.svg" width="45" height="45">
      <div v-if="(userData && userData.online) || (otherSession && otherSession.online)" class="onlineDot"></div>
    </div>
    <div class="conversation-info">
      <div class="conversation-card-user-name" v-if="userData">{{ userData.display }}</div>
      <div class="conversation-card-user-name" v-else-if="userData === null">
        {{ i18n.userDeleted }}
      </div>
      <div class="conversation-card-user-name" v-else>
        {{ (sessionData && sessionData.name) || i18n.anonymous }}
      </div>
      <div v-if="(lastMessageTimestamp||'') > (writeMessageTimestamp||'')" class="conversation-card-message">
        <div v-if="lastMessage" class="message-text">
          <span v-html="$sanitize(lastMessage.text, {ALLOWED_TAGS: []})"></span>
        </div>
        <time datetime="lastMessageTimestamp" class="conversation-card-timestamp">
          {{ lastMessageTimestamp | hourOrDayShort }}
        </time>
      </div>
      <div v-else class="conversation-card-message">
        <div class="message-you">{{ i18n.you }}</div>
        <div v-if="writeMessage" class="message-text">
          <span v-html="$sanitize(writeMessage.text, {ALLOWED_TAGS: []})"></span>
        </div>
        <time datetime="lastMessageTimestamp" class="conversation-card-timestamp">
          {{ writeMessageTimestamp | hourOrDayShort }}
        </time>
      </div>
    </div>

  </loading>
</template>

<script>
  import api from "api"
  import i18n from "i18n"

  export default {
    name: "PrivateConversationCard",
    props: {
      history: {
        type: Object,
        required: true
      },
      selected: {
        type: Boolean,
        default: false
      }
    },
    reactive: {
      conversationData() { return ['messages', 'privateConversation', { privateConversation: this.history.toId } ]},
      userData() { return this.otherUser && ['users', "publicUserData", { user: this.otherUser } ] },
      sessionData() { return this.otherSession
          && ['accessControl', "publicSessionInfo", { publicSessionInfo: this.otherSession } ] },
      lastMessage() {
        return this.history.last && ['messages', 'message', { message: this.history.last }]
      },
      writeMessage() {
        return this.history.write && ['messages', 'message', { message: this.history.write }]
      }
    },
    computed: {
      i18n() {
        return i18n().messenger
      },
      allLoaded() {
        if(!this.conversationData) return false
        if(this.userData == null) return true
        if(this.sessionData == null) return true
        if(!(this.userData || this.sessionData)) return false
        return true
      },
      otherUser() {
        const convData = this.conversationData
        if(!convData) return
        if(!api.session.session) return
        const me = api.session.session.user
        if(!me) return convData.user1 || convData.user2
        if(convData.user1 == me) return convData.user2
        if(convData.user2 == me) return convData.user1
      },
      otherSession() {
        const convData = this.conversationData
        if(!convData) return
        if(!api.session.publicSessionInfo) return
        const me = api.session.publicSessionInfo.id
        if(!me) return convData.session1 || convData.session2
        if(convData.session1 == me) return convData.session2
        if(convData.session2 == me) return convData.session1
      },
      lastMessageTimestamp() {
        return this.history.last && this.history.last.split('_').pop()
      },
      writeMessageTimestamp() {
        return this.history.write && this.history.write.split('_').pop()
      },
      readMessageTimestamp() {
        return this.history.read && this.history.read.split('_').pop()
      },
      visibleTimestamp() {
        return (this.lastMessageTimestamp > this.writeMessageTimestamp)
          ? this.lastMessageTimestamp
          :this.writeMessageTimestamp
      },
      isUnread() {
        return !this.history.read || this.lastMessageTimestamp > this.readMessageTimestamp
      }
    }
  }
</script>

<style scoped>

</style>