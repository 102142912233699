
let prefix = "$barrier_"

const VueBarrier = {
  install(Vue, options) {
    Vue.mixin({
      beforeCreate() {
        if(!this.$options.barrier) return; // Avoid distributed fat
        const optionData = this.$options.data
        if (!this.$options.computed) this.$options.computed = {}
        if (!this.$options.watch) this.$options.watch = {}
        for(let key in this.$options.barrier) {
          let fun = this.$options.barrier[key]
          this.$options.computed[prefix + key] = fun
          this.$options.watch[prefix + key] = function vueBarrier(value) {
            if(this[key]) {
              this[key+"Blocked"] = true
              return
            }
            this[key] = JSON.parse(JSON.stringify(value))
          }
        }
        this.$options.data = function vueBarrierInjectedDataFn () {
          const data = (
              (typeof optionData === 'function')
                  ? optionData.call(this)
                  : optionData
          ) || {}
          for (let key in this.$options.barrier) {
            data[key] = null
            data[key+"Blocked"] = false
          }
          return data
        }
      }
    })
  }
}


export default VueBarrier
