var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "overlay-anchor",
        {
          attrs: { anchor: _vm.anchor, directions: _vm.directions },
          on: { positioned: _vm.updatePosition }
        },
        [
          _c(
            "div",
            {
              staticClass: "navbar-dropdown dropdown-menu user-menu",
              on: {
                "!click": function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [
              !_vm.loggedIn
                ? _c(
                    "router-link",
                    {
                      staticClass: "dropdown-item",
                      attrs: { to: { name: "user:register" }, role: "button" }
                    },
                    [_vm._v(_vm._s(_vm.i18n.register))]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.loggedIn
                ? _c(
                    "router-link",
                    {
                      staticClass: "dropdown-item",
                      attrs: { to: { name: "user:login" }, role: "button" }
                    },
                    [_vm._v(_vm._s(_vm.i18n.login))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.loggedIn
                ? _c("hr", { staticClass: "dropdown-divider" })
                : _vm._e(),
              _vm._v(" "),
              _vm.loggedIn
                ? _c(
                    "router-link",
                    {
                      staticClass: "dropdown-item",
                      attrs: { to: { name: "user:settings" }, tag: "a" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.close.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.i18n.userSettings) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.loggedIn
                ? _c(
                    "router-link",
                    {
                      staticClass: "dropdown-item",
                      attrs: {
                        to: {
                          name: "user:profile",
                          params: { user: this.user.slug }
                        },
                        tag: "a"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.close.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.i18n.yourPage) + "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.loggedIn
                ? _c("hr", { staticClass: "dropdown-divider" })
                : _vm._e(),
              _vm._v(" "),
              _vm.loggedIn
                ? _c(
                    "router-link",
                    {
                      staticClass: "dropdown-item",
                      attrs: { to: { name: "user:logout" }, tag: "a" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.close.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.i18n.logout) + "\n      "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }