<template>
  <span class="phone-input">
    <div class="prefix select-input" @click="showPrefixMenu" ref="prefix"
         :class="{'is-invalid': prefixInvalid, disabled }" >
      <span :class="'select-input-value '+inputClass">
        <div v-if="prefix.length > 0 ">
          {{ prefix  }}
        </div>
        <div v-else class="select-input-placeholder">
          +??
        </div>
      </span>
      <span class="select-input-more"><img src="/static/icons/arrow_drop_down.svg" /></span>
    </div>

    <div class="number form-field">
      <input class="input" type="text" id="number" v-model="number"
             required ref="number" pattern="[0-9 #+-]{3,}"
             :disabled="disabled">
    </div>
  </span>
</template>

<script>
  import api from "api"
  import countries from "@/../../data/countries"
  import overlayModel from "../utils/overlayModel.js"
  import PhonePrefixPicker from "./PhonePrefixPicker.vue"

  export default {
    name: 'PhoneInput',
    props: {
      value: {
      },
      required: {
        type: Boolean,
        default: false
      },
      countryNames: {
        type: Object
      },
      invalid: {
        type: Boolean
      },
      inputClass: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data () {
      let sep = this.value.indexOf(' ')
      let empty = sep == -1
      let prefix = empty ? "" : this.value.slice(0, sep)
      let number = empty ? "" : this.value.slice(sep+1)
      let country = countries.filter(c=>c.dial_code==prefix)[0]
      return {
        prefix, number,
        isoCode: country ? country.isoCode : null,
        overlayStackId: null,
        lastCloseTime: 0
      }
    },
    reactive: {
      geoIpIsoCode: ["geo", "country"]
    },
    computed: {
      liveValue() {
        return (this. prefix + " " + this.number).trim()
      },
      prefixInvalid() {
        return this.invalid && this.prefix.trim().length==0
      }
    },
    watch: {
      geoIpIsoCode(iso) {
        if(iso == 'unknown') {
          let langCountries = navigator.languages.map(l=>l.split('-')).reduce((a,b)=>a.concat(b))
              .map(l => countries.find(c=>c.code==l.toUpperCase())).filter(c=>!!c)
          if(langCountries.length > 0) {
            iso = langCountries[0].code
          } else {
            iso = null
          }
        }
        if(!this.isoCode) {
          this.isoCode = iso
          let country = countries.find(country=> country.code == iso)
          this.prefix = country && country.dial_code
        }
      },
      liveValue(value) {
        this.$emit('input', value)
      },
      invalid(v) {
        if(!v) return
        if(!this.number.match(/^[0-9 #+-]{3,}$/)) {
          this.$refs.number.parentElement.setAttribute('class',
            this.$refs.number.parentElement.getAttribute('class')+' is-invalid')
        }
      },
      disabled(d) {
        if(d && this.overlayStackId !== null) {
          overlayModel.close(this.overlayStackId)
          this.overlayStackId = null
          this.lastCloseTime = Date.now()
        }
      }
    },
    updated () {
    },
    created() {
    },
    mounted() {
    },
    methods: {
      showPrefixMenu() {
        if(this.overlayStackId === null &&  Date.now() - this.lastCloseTime > 230) {
          if(this.disabled) return
          this.overlayStackId = overlayModel.show({
            component: PhonePrefixPicker,
            props: {
              anchor: overlayModel.elementAnchor(this.$refs.prefix),
              countryNames: this.countryNames
            },
            on: {
              selected: (code) => this.prefix = code,
              close: () => {
                this.overlayStackId = null
                this.lastCloseTime = Date.now()
              }
            }
          })
        } else {
          overlayModel.close(this.overlayStackId)
          this.overlayStackId = null
          this.lastCloseTime = Date.now()
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .phone-input {
    display: flex;
    position: relative;
    flex-wrap: nowrap;
    align-content: center;
  }

  .prefix {
    width: 60px;
    position: relative;
    cursor: default;
    vertical-align: baseline;
  }

  .number {
    width: calc(100% - 74px);
    margin-left: 10px;
  }



</style>
