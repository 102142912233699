<template>
  <page>
    <loading :what="keyState" name="key state">
      <div class="card half-width main-card" v-if="keyState">
        <div v-if="state=='used' || state=='expired' || state=='done' || state=='notFound'">
          <h4>{{ i18n[state].title }}</h4>
          <p>{{ i18n[state].text }}</p>
          <button v-if="state=='done'" v-on:click="redirectToLoginPage" class="button">
            {{ i18n[state].exitButton }}
          </button>
        </div>
        <div v-if="state=='ready'">
          <h4 v-html="i18n.title"></h4>
          <p class="text-center">{{i18n.message}}</p>
          <command-form service="emailPassword" :parameters="{ key: emailKey }"
                        action="finishPasswordReset" @done="redirectToLoginPage" class="resetPassword-confirmPassForm">
            <double-password-field name="newPasswordHash" :label="i18n.password"
                                   :retype-label="i18n.confirmPassword" :enable-toggle-password="true" />
            <div class="buttons">
              <button type="submit" class="button"><span>{{ i18n.setNewPassword }}</span></button>
              <router-link :to="{ name: 'user:login' }">{{ i18n.backToSignIn }}</router-link>
            </div>
          </command-form>
        </div>
      </div>
    </loading>
  </page>
</template>

<script>
  import i18n from "i18n"
  export default {
    name: "ResetPasswordForm",
    computed: {
      i18n() { return i18n().user.resetPasswordForm }
    },
    props: {
      emailKey: {
        required: true,
        type: String
      }
    },
    reactive: {
      keyState() { return ['emailPassword', 'emailKey', { key: this.$route.params.emailKey }] }
    },
    reactivePreFetch(route) {
      return [
        { what: ['emailPassword', 'emailKey', { key: route.params.emailKey }] }
      ]
    },
    data() {
      return {
        state: 'loading'
      }
    },
    watch: {
      keyState(v) {
        this.handleKeyState(v)
      }
    },
    mounted() {
      if(this.keyState) this.handleKeyState(this.keyState)
    },
    methods: {
      redirectToLoginPage() {
        this.$router.push({ name: 'user:login', params: { passwordResetSuccess: true } })
      },
      handleKeyState(v) {
        if(this.state == 'loading') {
          if(!v) {
            this.state = 'notFound'
            return;
          }
          if(v.used) {
            this.state = 'used'
          } else if(v.expire < Date.now()) {
            this.state = 'expired'
          } else {
            this.state = "ready"
          }
        }
      }
    }
  }
</script>

<style scoped>

</style>
