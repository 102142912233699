<template>
  <div class="notifications-list">
    <div class="notifications-view">
      <scroll-loader :what="range => notificationsDaoPath(range)" class="notifications" key="notifications"
                     :bucketSize="3" readMode="id" hardClose noDebugLog
                     v-slot:default="{ row: notification, rows }">
        <loading-zone>
          <component :is="notificationComponent(notification)" :notification="notification"></component>
        </loading-zone>
      </scroll-loader>
    </div>
  </div>
  
   <!--     <loading-zone>
          <loading :what="notifications" name="notifications">
            <div v-if="notifications && notifications.length === 0">
              <div class="notification"><p>{{ i18n.none }}</p></div>
            </div>
            <div class="notifications-wrapper" :style="{ 'max-height': notificationsContainerHeight - 70 +'px'}">
              <div v-for="notification in notificationsSorted"
                   :class="{ 'border-bottom': true,
                   'notification-read': notification.readState === 'read',
                   'notification-new': notification.readState === 'new' }">
                <working-zone>

                  <pre>{{ JSON.stringify(notification, null, "  ") }}</pre>
                </working-zone>
              </div>
            </div>
          </loading>
        </loading-zone>-->
</template>

<script>
  import i18n from "i18n"
  import ScrollLoader from "common/components/ScrollLoader.vue"
  import LoadingZone from "common/components/LoadingZone.vue"
  import notificationsComponents from "./notificationsComponents.js"

  export default {
    name: "NotificationsList",
    components: { ScrollLoader, LoadingZone },
    computed: {
      i18n() {
        return i18n().notifications
      }
    },
    methods: {
      notificationsDaoPath(range) {
        let reversed = {
          gt: range.lt,
          gte: range.lte,
          lt: range.gt == '' ? '\xFF\xFF\xFF\xFF' : range.gt,
          lte: range.gte,
          limit: range.limit,
          reverse: !range.reverse
        }
        return ['notifications', 'myNotifications', reversed]
      },
      async markAllAsRead() {
        return await api.command(["notifications", "markAllAsRead"], {})
      },
      notificationComponent(notification) {
        const found = notificationsComponents[notification.notificationType]
        if(!found) return notificationsComponents.unknown
        return found
      }
    }
  }
</script>

<style scoped>
</style>
