var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form-field-bind", {
    attrs: {
      name: _vm.name,
      valueFilter: function(n) {
        return n && n.trim()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var value = ref.value
          var setValue = ref.setValue
          var error = ref.error
          var setError = ref.setError
          return [
            _c(
              "FieldTemplate",
              {
                attrs: {
                  errorText: _vm.errorText,
                  error: error,
                  label: _vm.label,
                  name: _vm.name,
                  groupClass: _vm.groupClass
                }
              },
              [
                _c("div", { staticClass: "show-password-wrapper" }, [
                  _c("input", {
                    ref: "field",
                    staticClass: "{ 'is-invalid': !!error }",
                    class: "form-control " + _vm.inputClass,
                    attrs: {
                      type: _vm.showPassword ? "text" : "password",
                      id: "" + _vm._uid,
                      placeholder: _vm.placeholder
                    },
                    domProps: { value: value },
                    on: {
                      input: function(e) {
                        return setValue(e.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.enableTogglePassword && _vm.showPassword === false
                    ? _c(
                        "div",
                        {
                          staticClass: "show-password",
                          on: {
                            click: function($event) {
                              _vm.showPassword = true
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "show-password-icon",
                            attrs: { src: "/static/icons/visibility.svg" }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "show-password-text" }, [
                            _vm._v(_vm._s(_vm.i18n.showPassword))
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.enableTogglePassword && _vm.showPassword === true
                    ? _c(
                        "div",
                        {
                          staticClass: "show-password",
                          on: {
                            click: function($event) {
                              _vm.showPassword = false
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "show-password-icon",
                            attrs: { src: "/static/icons/visibility_off.svg" }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "show-password-text" }, [
                            _vm._v(_vm._s(_vm.i18n.hidePassword))
                          ])
                        ]
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _vm.passwordRules
                  ? _c("div", { staticClass: "password-rules" }, [
                      _c("span", [_vm._v(_vm._s(_vm.passwordRules))])
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "FieldTemplate",
              {
                attrs: {
                  label: _vm.retypeLabel,
                  name: _vm.name,
                  groupClass: _vm.groupClass
                }
              },
              [
                (_vm.showPassword ? "text" : "password") === "checkbox"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.second,
                          expression: "second"
                        }
                      ],
                      ref: "field2",
                      staticClass: "{ 'is-invalid': !!error }",
                      class: "form-control " + _vm.inputClass,
                      attrs: {
                        id: "second" + _vm._uid,
                        placeholder: _vm.retypePlaceholder,
                        type: "checkbox"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.second)
                          ? _vm._i(_vm.second, null) > -1
                          : _vm.second
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.second,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.second = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.second = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.second = $$c
                          }
                        }
                      }
                    })
                  : (_vm.showPassword ? "text" : "password") === "radio"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.second,
                          expression: "second"
                        }
                      ],
                      ref: "field2",
                      staticClass: "{ 'is-invalid': !!error }",
                      class: "form-control " + _vm.inputClass,
                      attrs: {
                        id: "second" + _vm._uid,
                        placeholder: _vm.retypePlaceholder,
                        type: "radio"
                      },
                      domProps: { checked: _vm._q(_vm.second, null) },
                      on: {
                        change: function($event) {
                          _vm.second = null
                        }
                      }
                    })
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.second,
                          expression: "second"
                        }
                      ],
                      ref: "field2",
                      staticClass: "{ 'is-invalid': !!error }",
                      class: "form-control " + _vm.inputClass,
                      attrs: {
                        id: "second" + _vm._uid,
                        placeholder: _vm.retypePlaceholder,
                        type: _vm.showPassword ? "text" : "password"
                      },
                      domProps: { value: _vm.second },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.second = $event.target.value
                        }
                      }
                    })
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }