<template>
  <div class="search-result card main-card">
    <ul>
      <li v-for="found in data.found">
        <i>{{ found.word }}</i>
        in
        <b>{{ (typeof found.field) == 'string' ? found.field : found.field.join('\u00A0→\u00A0') }}</b>
        <div @click="copyFoundWord(found)" class="copy-buttons">
          <button type="button" class="button" >
            <img src="/static/icons/content_copy.svg">
          </button>
          <div class="copy-info" v-if="foundWordCopied && foundWordCopied == found">
            {{ i18n.wordCopied }}
          </div>
        </div>
      </li>
    </ul>
    <vue-code-highlight v-if="viewAsXml" language="xml" class="xml-code-view" :key="rawXml ? 'raw' : 'xml'">
      <pre>{{ rawXml ? cleanedXml : formattedXml }}</pre>
    </vue-code-highlight>
    <SearchResultData v-else :data="preparedData" ></SearchResultData>
  </div>
</template>

<script>
  import SearchResultData from '../xmlSearch/SearchResultData.vue'
  import parseXml from '../xmlSearch/parseXml.js'
  import xmlFormatter from 'xml-formatter'
  import { component as VueCodeHighlight } from 'vue-code-highlight'
  import 'prism-es6/components/prism-markup'
  import 'vue-code-highlight/themes/prism-coy.css'
  import copyToClipboard from "copy-to-clipboard"

  export default {
    name: "ExclusionsResult",
    components: { SearchResultData, VueCodeHighlight },
    props: {
      data: {
        type: Object,
        required: true
      },
      viewAsXml: {
        type: Boolean,
        default: false
      },
      rawXml: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        foundWordCopied: null
      }
    },
    computed: {
      i18n() {
        return i18n().xmlExclusions
      },
      preparedData() {
        //console.log('DATA', JSON.stringify(this.data))
        let items = parseXml(this.data.xml)
        //console.log('ITEMS', items)
        return items[0]
      },
      cleanedXml() {
        const xml = this.data.xml
        const indentMatch = xml.match(/[\r\n]([ \t]*)<\/item>/i)
        const indent = indentMatch && indentMatch[1] || ''
        //console.log("INDENT", indentMatch, indent)
        return indent + xml
      },
      formattedXml() {
        return xmlFormatter(this.data.xml, {
          indentation: ' ',
          collapseContent: true,
          whiteSpaceAtEndOfSelfclosingTag: true
        }) || ''
      }
    },
    methods: {
      copyFoundWord(found) {
        copyToClipboard(found.word)
        if(this.foundWordCopied) clearTimeout(this.copyTimeout)
        this.foundWordCopied = found
        this.copyTimeout = setTimeout(() => this.foundWordCopied = false, 3020)
      },
    }
  }
</script>

<style scoped lang="scss">

</style>