import ExclusionsPage from "./ExclusionsPage.vue"

export default function(prefix) {
  return [
    {
      path: prefix,
      name: 'xml:exclusions',
      component: ExclusionsPage,
      props: true
    },
    {
      path: prefix + 'Url/:exclusionsUrl',
      name: 'xml:exclusionsUrl',
      component: ExclusionsPage,
      props: true,
      meta: { saveForLogin: true }
    },
    {
      path: prefix + 'Ds/:dataSet',
      name: 'xml:exclusionsDataSet',
      component: ExclusionsPage,
      props: true,
      meta: { saveForLogin: true }
    },
    {
      path: prefix + 'File/:listId/:fileName',
      name: 'xml:exclusionsCsv'
    },
    {
      path: prefix + '/:exclusions',
      name: 'xml:exclusionsResults',
      component: ExclusionsPage,
      props: true,
      meta: { saveForLogin: true }
    },
    // {
    //   path: prefix + '/view/:exclusions.xml',
    //   name: 'xml:ExclusionsResultsViewXml'
    // },
    // {
    //   path: prefix + '/:exclusions.xml',
    //   name: 'xml:ExclusionsResultsFreshXml'
    // },
    // {
    //   path: prefix + '/download/:exclusions.xml',
    //   name: 'xml:ExclusionsResultsDownloadXml'
    // },
    // {
    //   path: prefix + '/generator/:generator.xml',
    //   name: 'xml:generatorXml'
    // },
  ]
}
