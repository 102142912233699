var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "loading",
    { attrs: { what: _vm.userData, name: "user profile data" } },
    [
      _c("page", { attrs: { multiRow: true } }, [
        _vm.userData
          ? _c("div", { staticClass: "page-row" }, [
              _c("aside", [
                _c(
                  "div",
                  { staticClass: "card" },
                  [
                    _c(
                      "div",
                      { staticClass: "editable-picture-holder" },
                      [
                        _c("uploaded-picture-img", {
                          attrs: {
                            width: "200",
                            height: "200",
                            picture: _vm.userData.picture
                          }
                        }),
                        _vm._v(" "),
                        _vm.isMe
                          ? _c(
                              "button",
                              {
                                staticClass: "button",
                                attrs: { type: "file", id: "" },
                                on: {
                                  click: function(ev) {
                                    return ev.preventDefault()
                                  }
                                }
                              },
                              [
                                _c("img", {
                                  attrs: { src: "/static/icons/edit.svg" }
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.i18n.updatePicture))
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isMe && _vm.userData.picture
                          ? _c("div", {
                              staticClass: "editable-picture-click-catcher",
                              on: {
                                click: function(ev) {
                                  ev.preventDefault()
                                  _vm.showPictureEditor()
                                }
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isMe && !_vm.userData.picture
                          ? _c("input", {
                              staticClass: "editable-picture-click-catcher",
                              attrs: { type: "file" },
                              on: { change: _vm.handleFileUpload }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.userData.display))]),
                    _vm._v(" "),
                    !_vm.isMe && _vm.isLoggedIn && _vm.chatStarted
                      ? _c(
                          "router-link",
                          {
                            staticClass: "button message-button",
                            attrs: {
                              target: "_blank",
                              tag: "a",
                              to: _vm.conversationLink
                            }
                          },
                          [
                            _c("img", {
                              attrs: { src: "/static/icons/chat.svg" }
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.i18n.continueChat))])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isMe && _vm.isLoggedIn && !_vm.chatStarted
                      ? _c(
                          "button",
                          {
                            staticClass: "button message-button",
                            on: { click: _vm.showMessageDialog }
                          },
                          [
                            _c("img", {
                              attrs: { src: "/static/icons/chat.svg" }
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.i18n.message))])
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("main", [
                _c("div", { staticClass: "card full-width" }, [
                  !_vm.editingAbout
                    ? _c("div", [
                        _c("h2", [_vm._v(_vm._s(_vm.i18n.about))]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "user-about-text",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$sanitize(_vm.userData.about, {
                                ALLOWED_TAGS: ["p"]
                              })
                            )
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "buttons" }, [
                          _vm.isMe
                            ? _c(
                                "button",
                                {
                                  staticClass: "button",
                                  attrs: { type: "button", role: "button" },
                                  on: { click: _vm.editAbout }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.i18n.edit) +
                                      "\n              "
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.editingAbout
                    ? _c(
                        "div",
                        [
                          _c(
                            "command-form",
                            {
                              ref: "aboutForm",
                              attrs: {
                                service: "users",
                                action: "updateUserAbout",
                                initialValues: { about: _vm.userData.about }
                              },
                              on: {
                                done: function() {
                                  return (_vm.editingAbout = false)
                                }
                              }
                            },
                            [
                              _c("h2", [_vm._v(_vm._s(_vm.i18n.aboutYou))]),
                              _vm._v(" "),
                              _c("multi-line-text-field", {
                                staticClass: "user-about-text",
                                attrs: {
                                  name: "about",
                                  placeholder: "...",
                                  label: ""
                                }
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "buttons" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "button",
                                    attrs: { type: "submit", role: "button" }
                                  },
                                  [_vm._v(_vm._s(_vm.i18n.save))]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ])
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }