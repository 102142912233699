let components = {}

import ScanStarted from '@/views/xmlAlert/notifications/ScanStarted.vue'
components.scanStarted = ScanStarted
import ScanFinished from '@/views/xmlAlert/notifications/ScanFinished.vue'
components.scanFinished = ScanFinished

import AdsAccountCheckResult from '@/modules/adsapi/notifications/AdsAccountCheckResult.vue'
components.adsAccountCheckResults = AdsAccountCheckResult

import UnknownNotification from "./UnknownNotification.vue"
components.unknown = UnknownNotification

export default components
