<template>
  <form-field-bind :name="name" v-slot="{ error }">
    <FieldTemplate :errorText="errorText" :error="error" :label="label" :name="name">
      <EditorContent :editor="editor" :data-placeholder="placeholder"
                     :class="['input', 'multi-line-text-field', { empty }]">
      </EditorContent>
      <template v-slot:below>
        <span class="field-tip" v-if="charsLeft >= 0">{{` ${validation_i18n.characters} ${charsLeft}`}}</span>
      </template>
    </FieldTemplate>
  </form-field-bind>
</template>

<script>
  import { Editor, EditorContent } from 'tiptap'
  import Limit from "common/components/tiptap/Limit.js"
  import FieldTemplate from "./FieldTemplate.vue"
  import OnEnter from 'common/components/tiptap/OnEnter.js'
  import i18n from "i18n"

  export default {
    name: "MultiLineTextField",
    inject: ['form'],
    props: {
      name: {
        type: String
      },
      label: {
        type: String
      },
      placeholder: {
        type: String
      },
      errorText: {
        type: Object
      },
      rows: {
        type: Number
      },
      submitOnEnter : {
        type: Boolean,
        default: false
      }
    },
    components: { EditorContent, FieldTemplate },
    computed: {
      i18n() {
        return i18n().comments
      },
      validation_i18n() { return i18n().validation },
      maxTextLength() {
        const validation = this.form.getFieldDefinition(this.name).validation
        return validation && validation.find(v => v.name && v.name === 'maxTextLength')
      },
      charsLeft() {
        const len = this.form.getFieldValue(this.name).replace(/<[^>]*>/g,'').length
        if(this.maxTextLength) return this.maxTextLength.length - len
        return -1
      }
    },
    data() {
      return {
        editor: null,
        content: null,
        empty: false
      }
    },
    mounted() {
      this.editor = new Editor({
        content: this.content,
        autoFocus: true,
        extensions: [
          /*new Placeholder({
            emptyNodeClass: 'is-empty',
            emptyNodeText: this.placeholder,
            showOnlyWhenEditable: true
          }),*/
          ...(this.submitOnEnter ? [
            new OnEnter(() => {
              this.form.submit()
              this.editor.setContent('')
            })
          ] : []),
          ...(this.maxTextLength ? [
            new Limit({ limit: this.maxTextLength.length })
          ] : [])
        ],
        onUpdate: (update) => {
          //console.log("UPD", update.state.doc.textContent.length)
          const html = update.getHTML()
          this.empty = html.replace(/<[^>]*>/g,'').trim().length == 0
          this.emited = true
          this.form.setFieldValue(this.name, html)
        },
      })
      this.valueObserver = (v) => {
        //console.log("VALUE UPDATE", v, '!=', this.content, "ET", this.emited)
        this.content = v
        if(this.emited) {
          this.emited = false
          return;
        }
        this.editor.setContent(v)
      }
      this.form.observe(this.name, this.valueObserver)
    },
    watch: {
      content(v) {
        this.empty = v.replace(/<[^>]*>/g,'').trim().length == 0
      }
    },
    created() {
    },
    beforeDestroy() {
      this.form.unobserve(this.name, this.valueObserver)
      this.editor.destroy()
    },
  }
</script>

<style scoped>

</style>
