var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search-result-data" }, [
    typeof _vm.data == "string"
      ? _c("div", {
          class: {
            "data-too-long": _vm.tooLong,
            "data-expanded": _vm.expanded
          },
          domProps: { innerHTML: _vm._s(_vm.encodedText(_vm.data)) }
        })
      : Array.isArray(_vm.data)
      ? _c("div", [
          _c(
            "ol",
            _vm._l(_vm.data, function(item) {
              return _c(
                "li",
                [_c("SearchResultData", { attrs: { data: item } })],
                1
              )
            }),
            0
          )
        ])
      : typeof _vm.data == "object"
      ? _c(
          "div",
          _vm._l(Object.keys(_vm.data), function(key) {
            return _c("div", { staticClass: "property" }, [
              _c("div", { staticClass: "property-name" }, [
                _vm._v(_vm._s(key))
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "property-value" },
                [_c("SearchResultData", { attrs: { data: _vm.data[key] } })],
                1
              )
            ])
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.tooLong && !_vm.expanded
      ? _c(
          "button",
          { staticClass: "button data-more-button", on: { click: _vm.more } },
          [_vm._v("More")]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.tooLong && _vm.expanded
      ? _c(
          "button",
          { staticClass: "button data-less-button", on: { click: _vm.less } },
          [_vm._v("Less")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }