<template>
  <FieldTemplate :errorText="errorText" :error="error" :label="label" :name="name">
    <div class="editor">
      <editor-block-menu :editor="editor" :addMargins="false" v-slot="{ commands, isActive, block, markAttrs }">
        <div class="editor__block" :class="{ hidden: !block.isActive }"
             :style="{ left: `${block.left-10}px`, top: `${block.top-5}px`, width: `${block.width+20}px`}">
          <div class="editor__block-border" :class="{ hidden: block.isEmpty }"
               :style="{ width: `${block.width+20}px`, height: `${block.height+10}px` }">
          </div>
          <div class="editor__block-menus" :class="{ hidden: block.isEmpty }">
            <div class="editor__block-menu">
              <button class="editor__block-button" type="button"
                      :class="{ 'is-active': isActive.bold() }"
                      @click="commands.bold">
                <img src="/static/editor/bold.svg" />
              </button>
              <button class="editor__block-button" type="button"
                      :class="{ 'is-active': isActive.italic() }"
                      @click="commands.italic">
                <img src="/static/editor/italic.svg" />
              </button>
              <button class="editor__block-button" type="button"
                      :class="{ 'is-active': isActive.strike() }"
                      @click="commands.strike">
                <img src="/static/editor/strike.svg" />
              </button>
              <button class="editor__block-button" type="button"
                      :class="{ 'is-active': isActive.underline() }"
                      @click="commands.underline">
                <img src="/static/editor/underline.svg" />
              </button>
              <button class="editor__block-button" type="button"
                      @click="commands.nbsp">
                <img src="/static/editor/space_bar.svg" />
              </button>
              <button class="editor__block-button" type="button"
                      :class="{ 'is-active': isActive.paragraph() }"
                      @click="commands.paragraph">
                <img src="/static/editor/paragraph.svg">
              </button>
              <button class="editor__block-button" type="button"
                      :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                      :disabled="isActive.bullet_list() || isActive.ordered_list()"
                      @click="commands.heading({ level: 1 })">
                H1
              </button>
              <button class="editor__block-button" type="button"
                      :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                      :disabled="isActive.bullet_list() || isActive.ordered_list()"
                      @click="commands.heading({ level: 2 })">
                H2
              </button>
              <button class="editor__block-button" type="button"
                      :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                      :disabled="isActive.bullet_list() || isActive.ordered_list()"
                      @click="commands.heading({ level: 3 })">
                H3
              </button>
              <button class="editor__block-button" type="button"
                      :class="{ 'is-active': isActive.bullet_list() }"
                      @click="commands.bullet_list">
                <img src="/static/editor/ul.svg">
              </button>
              <button class="editor__block-button" type="button"
                      :class="{ 'is-active': isActive.ordered_list() }"
                      @click="commands.ordered_list">
                <img src="/static/editor/ol.svg">
              </button>
              <button class="editor__block-button" type="button"
                      :class="{ 'is-active': isActive.blockquote() }"
                      @click="commands.blockquote">
                <img src="/static/editor/quote.svg">
              </button>
              <button class="editor__block-button" type="button"
                      :class="{ 'is-active': isActive.link() }"
                      @click="isActive.link()
                      ? commands.link({ remove: true }) :
                      commands.link({ href: '/', rel: 'noopener noreferrer nofollow', target: '_blank' })">
                <img src="/static/editor/link.svg" />
              </button>
            </div>
            <div  v-if="isActive.link()" v-for="mark in [editor.activeMarkAttrs.link]" class="editor__block-menu fields-row">
<!--              {{ JSON.stringify(editor.activeMarkAttrs) }}-->
              <div class="editor__block-menu-field editor__block-menu-field-url form-field">
                <label>Href:</label>
                <input class="editor__block-input input" placeholder="url"
                       :value="mark.href"
                       @input="ev => { commands.link({ ...mark, href: ev.target.value }); ev.target.focus() }">
              </div>
              <div class="editor__block-menu-field editor__block-menu-field-target  form-field">
                <label>Target:</label>
                <input class="editor__block-input input" placeholder="target"
                       :value="mark.target"
                       @input="ev => { commands.link({ ...mark, target: ev.target.value }); ev.target.focus() }">
              </div>
              <div class="editor__block-menu-field form-field">
                <label>Rel:</label>
                <input class="editor__block-input input" placeholder="rel"
                       :value="mark.rel"
                       @input="ev => { commands.link({ ...mark, rel: ev.target.value }); ev.target.focus() }">
              </div>
            </div>
          </div>
          <div class="editor__block-init" :class="{ hidden: !block.isEmpty }">
            <button class="editor__block-button" type="button"
                    :class="{ 'is-active': isActive.paragraph() }"
                    @click="commands.paragraph">
              <img src="/static/editor/paragraph.svg">
            </button>
            <button class="editor__block-button" type="button"
                    :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                    :disabled="isActive.bullet_list() || isActive.ordered_list()"
                    @click="commands.heading({ level: 1 })">
              H1
            </button>
            <button class="editor__block-button" type="button"
                    :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                    :disabled="isActive.bullet_list() || isActive.ordered_list()"
                    @click="commands.heading({ level: 2 })">
              H2
            </button>
            <button class="editor__block-button" type="button"
                    :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                    :disabled="isActive.bullet_list() || isActive.ordered_list()"
                    @click="commands.heading({ level: 3 })">
              H3
            </button>
            <button class="editor__block-button" type="button"
                    :class="{ 'is-active': isActive.bullet_list() }"
                    @click="commands.bullet_list">
              <img src="/static/editor/ul.svg">
            </button>
            <button class="editor__block-button" type="button"
                    :class="{ 'is-active': isActive.ordered_list() }"
                    @click="commands.ordered_list">
              <img src="/static/editor/ol.svg">
            </button>
            <button class="editor__block-button" type="button"
                    :class="{ 'is-active': isActive.blockquote() }"
                    @click="commands.blockquote">
              <img src="/static/editor/quote.svg">
            </button>
            <button class="editor__block-button" type="button"
                    @click="commands.horizontal_rule">
              <img src="/static/editor/hr.svg">
            </button>
            <span class="file-input">
              <button class="editor__block-button" type="button">
                <img src="/static/editor/image.svg">
              </button>
              <input class="hidden-file" type="file" accept="image/*"
                     v-on:change="ev => startPictureUpload(ev, commands.createPictures)">
            </span>
          </div>
        </div>
      </editor-block-menu>
      <EditorContent :editor="editor"
                     :class="['content-editor__content', 'wysiwyg-field', { empty }, inputClass]"
                     :data-placeholder="placeholder">
      </EditorContent>
    </div>
  </FieldTemplate>
</template>

<script>
  import FieldTemplate from "common/components/fields/FieldTemplate.vue"
  import EditorBlockMenu from "common/components/tiptap/EditorBlockMenu.js"
  import Picture from "common/components/tiptap/PicturePlugin.js"
  import Link2 from "common/components/tiptap/Link2.js"
  import Nbsp from "common/components/tiptap/Nbsp.js"

  import { Editor, EditorContent } from 'tiptap'

  import {
    Blockquote,
    CodeBlock,
    HardBreak,
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
    Link,
    Strike,
    Underline,
    History,
    Placeholder
  } from 'tiptap-extensions'

  export default {
    name: "WysiwygField",
    props: {
      name: {
        type: String,
        required: true
      },
      label: {
        type: String
      },
      placeholder: {
        type: String,
        default: "Write here..."
      },
      errorText: {
        type: Object
      },
      inputClass: {
        type: String,
        default: null
      }
    },
    inject: ['form', 'workingZone'],
    components: { FieldTemplate, EditorContent, EditorBlockMenu },
    data() {
      return {
        error: null,
        editor: null,
        content: null,
        empty: true
      }
    },
    mounted() {
      this.picturePlugin = new Picture({
        deviceOptions: ['mobile', 'desktop']
      })
      this.picturePlugin.workingZone = this.workingZone
      this.editor = new Editor({
        /* Disable markdown rules
        disableInputRules: ['bold'],
        disablePasteRules: ['bold'],*/
        content: this.content,
        autoFocus: true,
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link2(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Nbsp(),
         /* new Placeholder({
            emptyNodeClass: 'is-empty',
            emptyNodeText: this.placeholder,
            showOnlyWhenEditable: true
          }),*/
          this.picturePlugin
        ],
        onUpdate: ({ getJSON, getHTML }) => {
          let html = getHTML()
          html = html.replace(/&nbsp;/g, ' ')
          html = html.replace(/<span data-type="nbsp">.?<\/span>/g, '&nbsp;')
          this.empty = this.isEmpty(html)
          this.emited = true
          this.form.setFieldValue(this.name, html)
        },
      })
    },
    watch: {
      content(content) {
        if(this.emited) return;
        if(this.editor) {
          content = content.replace(/\u00A0/g, '<span data-type="nbsp">\u00A0</span>')
          content = content.replace(/&nbsp;/g, '<span data-type="nbsp">\u00A0</span>')
          this.editor.setContent(content)
        }
      }
    },
    methods: {
      async startPictureUpload(e, command) {
        let files = e.target.files || e.dataTransfer.files
        if (!files.length) return
        let picturesPromises = []
        for(let file of files) {
          picturesPromises.push( this.picturePlugin.createPicture(file))
        }
        const picturesPromise = Promise.all(picturesPromises)
        this.workingZone.addPromise('addPictures', picturesPromise)
        const pictures = await picturesPromise
        command(pictures)
      },
      isEmpty(html) {
        if(!html) return true
        if( html.replace(/<[^>]*>/g,'').trim().length > 0 ) return false
        if( html.match(/<img /) ) return false
        return true
      }
    },
    created() {
      this.valueObserver = (v) => {
        this.content = v
        this.empty = this.isEmpty(v)
      }
      this.errorObserver = (e) => {
        this.error = e
      }
      this.form.observe(this.name, this.valueObserver)
      this.form.observeError(this.name, this.errorObserver)

      this.validator = () => {
        if( this.picturePlugin.isUploading(this.editor.view.docView) ) return "waitingForUpload"
      }
      this.form.addValidator(this.name, this.validator)
    },
    beforeDestroy() {
      this.form.unobserve(this.name, this.valueObserver)
      this.form.unobserveError(this.name, this.errorObserver)

      this.form.removeValidator(this.name, this.validator)
    }
  }
</script>