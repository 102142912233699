var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "card half-width" },
    [
      _c(
        "loading",
        { attrs: { what: _vm.user, name: "user data" } },
        [
          _vm.user
            ? _c("command-form", {
                attrs: {
                  name: "user",
                  service: "users",
                  action: "updateUserData",
                  initialValues: _vm.user.userData
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "done",
                      fn: function() {
                        return [
                          _c("h4", [_vm._v(_vm._s(_vm.i18n.title))]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(_vm.i18n.done.text))])
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "default",
                      fn: function() {
                        return [
                          _c("h4", [_vm._v(_vm._s(_vm.i18n.title))]),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("text-field", {
                                attrs: {
                                  name: "name",
                                  label: _vm.i18n.firstName,
                                  placeholder: _vm.i18n.firstNamePlaceholder
                                }
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "buttons" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "button",
                                    attrs: { type: "submit", role: "button" }
                                  },
                                  [_vm._v(_vm._s(_vm.i18n.save))]
                                )
                              ])
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2905660743
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }