var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form-field-bind", {
    attrs: { name: _vm.name },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var value = ref.value
          var setValue = ref.setValue
          var error = ref.error
          return [
            _c(
              "FieldTemplate",
              {
                attrs: {
                  errorText: _vm.errorText,
                  error: error,
                  label: _vm.label,
                  name: _vm.name,
                  groupClass: _vm.groupClass
                }
              },
              [
                _c("CountryInput", {
                  attrs: {
                    inputClass: _vm.inputClass,
                    countryNames: _vm.countryNames,
                    value: value,
                    required: _vm.required,
                    invalid: !!error
                  },
                  on: { input: setValue }
                })
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }