<template>
  <form-field-bind class="logic-block" :name="name" v-slot="{ value }" v-if="!deleting">
    <div v-for="(item, index) in value">
      <div v-if="value.length > 1 || item.type != 'include'" class="fields-row logic-block-separator">
        <single-select-field class="operator-field" :name="name+'.'+index+'.type'" :label="''"
                             :options="['include', 'exclude']"
                             :disabled="disabled"
                             :text="op => typeText(op)">
        </single-select-field>
        <div class="logic-block-separator-line"></div>
      </div>
      <AndBlock :name="name+'.'+index+'.logic'" :key="name+'.'+index+'.logic'" :onlyOne="onlyOne && value.length <= 1"
               :dataSet="dataSet" :disabled="disabled"
               @more="() => handleMore(index)" @delete="() => handleDelete(index)">
      </AndBlock>
    </div>
  </form-field-bind>
</template>

<script>
  import i18n from "i18n"
  import AndBlock from "./AndBlock.vue"
  import emptyQuery from "./emptyQuery.js"

  export default {
    name: "LogicBlock",
    components: { AndBlock },
    inject: ['form'],
    props: {
      dataSet: {
        type: Object
      },
      disabled: {
        type: Boolean,
        default: false
      },
      name: {
        type: String,
        default: "query"
      },
      onlyOne: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        deleting: false
      }
    },
    computed: {
      i18n() {
        return i18n().xmlSearch
      }
    },
    methods: {
      handleMore(index) {
        const value = this.form.getFieldValue(this.name)
        const inserted = { type: "include", logic: [[emptyQuery]] }
        const updated = [ ...value.slice(0, index+1), inserted, ...value.slice(index+1) ]
        this.form.setFieldValue(this.name, updated)
      },
      handleDelete(index) {
        const value = this.form.getFieldValue(this.name)
        if(value.length == 1) return this.$emit('delete')
        this.deleting = true
        this.$nextTick(() => {
          const value = this.form.getFieldValue(this.name)
          const updated = [ ...value.slice(0, index), ...value.slice(index+1) ]
          this.form.setFieldValue(this.name, updated)
          this.deleting = false
        })
      },
      typeText(t) {
        return this.i18n.logicBlockType[t]
      }
    }
  }
</script>

<style scoped>

</style>