var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("page", [
    _c("div", { staticClass: "card half-width main-card" }, [
      _vm.step === "email" && !_vm.error
        ? _c(
            "div",
            { staticClass: "resetPassword-body resetPassword-email" },
            [
              _c("h4", {
                domProps: { innerHTML: _vm._s(_vm.i18n.email.title) }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "text-center" }, [
                _vm._v(_vm._s(_vm.i18n.email.message))
              ]),
              _vm._v(" "),
              _c(
                "command-form",
                {
                  key: "emailPassword",
                  attrs: {
                    service: "emailPassword",
                    action: "startPasswordReset",
                    parameters: { lang: _vm.lang },
                    ignoreError: ["notFound", "registrationNotConfirmed"]
                  },
                  on: { error: _vm.handleError, done: _vm.confirmSendingEmail }
                },
                [
                  _c("text-field", {
                    staticClass: "resetPassword-email-form",
                    attrs: {
                      name: "email",
                      label: _vm.i18n.email.emailLabel,
                      groupClass: "register-field",
                      "error-text": _vm.i18n.errors
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttons" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button",
                        attrs: { type: "submit", role: "button" }
                      },
                      [_vm._v(_vm._s(_vm.i18n.email.resetPassword))]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            return _vm.$router.go(-1)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.i18n.email.goBack))]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.error
        ? _c("div", { staticClass: "resetPassword-body resetPassword-error" }, [
            _c("p", { staticClass: "form-error" }, [
              _vm._v(_vm._s(_vm.i18n.errors[_vm.error]))
            ]),
            _vm._v(" "),
            _vm.error == "registrationNotConfirmed"
              ? _c("div", { staticClass: "custom-form-error text-danger" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.resendConfirmationEmail.apply(
                            null,
                            arguments
                          )
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.i18n.resendButton))]
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.step === "sent"
        ? _c("div", { staticClass: "resetPassword-body resetPassword-sent" }, [
            _c("div", {
              staticClass: "text-center",
              domProps: { innerHTML: _vm._s(_vm.i18n.sent.title) }
            }),
            _vm._v(" "),
            _c("p", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.i18n.sent.message))
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }