<template>
  <div class="tags-picker">
    <overlay-anchor :anchor="anchor" class="card card-inverse" :directions="directions"
      v-slot="{ position, anchor }">
      <div class="select-box mb-3" ef="box"
           :style="{
             'min-width': (anchor.w) +'px',
             'max-width': (windowDimensions.width - position.x - 20)+'px',
             'max-height': (windowDimensions.height - position.y - 20)+'px'
           }">
        <div class="sidebar--tags-picker">
          <div class="select" v-for="select in filteredPropositions" @click="selectSelected(select.id)">
            <h5 class="selectName m-2 ">{{ select.name }}</h5>
            <p class="selectDescription">{{ select.description }}</p>
          </div>
          <div class="select m-2" v-if="canAddNew">
            <p class="addDescription" @click="addNew">
              {{ i18n.addNew }} "{{ searchQuery.trim() }}"
            </p>
          </div>
          <div class="select m-2" v-if="filteredPropositions.length == 0 && !canAddNew">
            <p class="alreadyAdded">
              {{ i18n.alreadyExists(propositions[0].name) }}
            </p>
          </div>
        </div>
      </div>
    </overlay-anchor>
  </div>
</template>

<script>
  import i18n from "i18n"
  import Vue from "vue"
  import { OverlayAnchor, windowDimensions } from "common/components/utils/overlayLayer"

  export default {
    name: 'TagsPicker',
    props: {
      anchor: {
        required: true
      },
      propositions: {
        type: Array,
        required: true
      },
      current: {
        type: Array
      },
      i18n: {
        type: Object,
        required: true
      },
      searchQuery: {}
    },
    components: { OverlayAnchor },
    data () {
      return {
        directions: [{ x:1, y:1, ax: -1, ay: 1}, { x:1, y:-1, ax: -1, ay: -1 },
                     { x:-1, y:1, ax: 1, ay: 1}, { x:-1, y:-1, ax: 1, ay: -1 }],
        search: ""
      }
    },
    computed: {
      windowDimensions() { return windowDimensions },
      filteredOptions() {
        let query = this.search.toLowerCase().trim()
        if(query.length == 0) return this.options
        return this.options.filter(option => {
          let name = option.name || option
          if(name.toLowerCase().indexOf(query) != -1) return true
          return false
        })
      },
      filteredPropositions() {
        const results = this.propositions
        if(!results) return null
        return results.filter(
            tag => this.current.indexOf(tag.id) == -1
        )
      },
      canAddNew() {
        if(!this.propositions) return true
        for(let tag of this.propositions) {
          console.log("CMP", tag.name.trim().toLowerCase(), this.searchQuery.trim().toLowerCase())
          if(tag.name.trim().toLowerCase() == this.searchQuery.trim().toLowerCase()) return false
        }
        return true
      }
    },
    methods: {
      focusSearch() {
        if(this.$refs.search) {
          //this.$refs.search.parentNode.classList.add('is-dirty');
          this.$refs.search.focus()
          this.$refs.search.select()
        }
        if(document.activeElement != this.$refs.search) {
          setTimeout(() => this.focusSearch(), 50)
        }
      },
      selectSelected(select) {
        this.$emit("close")
        this.$emit("selected", select)
      },
      addNew() {
        this.$emit("close")
        this.$emit('add', this.searchQuery)
      }
    },
    mounted() {
      setTimeout(()=>this.focusSearch(), 50)
    }
  }

</script>

<style scoped>
  .select-box {
    display: block;
  }
  .select:hover {
    background: rgba(0,0,0, 0.05);
  }
  .selectName {
    margin-left: 1em;
  }
  .alreadyAdded {
    color: darkred;
    font-weight: 600;
  }
  .overlayAnchor {
    display: block !important;
  }
</style>
