<template>
  <page>
    <div class="row" v-if="user">

      <UpdateUserData></UpdateUserData>

      <UpdatePassword></UpdatePassword>

      <UpdateEmail></UpdateEmail>

      <UpdatePhone></UpdatePhone>

    </div>
    <div class="row" v-if="user">
      <div class="buttons remove-account-button">
        <router-link :to="{ name: 'user:removeAccount' }" tag="button" class="button" role="button">
          <span>Usuń Konto</span>
        </router-link>
      </div>
    </div>
  </page>
</template>

<script>
  import api from "api"

  import UpdatePassword from "./emailPassword/UpdatePassword.vue"
  import UpdateEmail from "./emailPassword/UpdateEmail.vue"

  import UpdatePhone from "./user/UpdatePhone.vue"
  import UpdateUserData from "./user/UpdateUserData.vue"
  import DeleteUser from "./user/DeleteUser.vue"

  import i18n from "i18n"

  export default {
    name: 'account-settings',
    metaInfo: {
      title: i18n().accountSettings.title
    },
    components: {
      UpdatePassword, UpdateEmail, UpdatePhone, UpdateUserData, DeleteUser
    },
    data () {
      return {
      }
    },
    methods: {
      i18n() {return i18n().accountSettings}
    },
    reactive: {
      user() { return ['users', 'UserOne', { user: api.session.session.user } ] }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @media screen and (max-width: 1024px) {
    .mdl-layout__header {
      display: block;
    }
  }


  .cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1000px;
    margin:auto;
  }

</style>
