<template>
  <page>
    <div class="card main-card">
      <h4>{{i18n.loggedOut.title}}</h4>
      <p class="">{{i18n.loggedOut.message}}</p>
      <router-link :to="{ name: 'user:login' }" tag="button" class="button">
        {{i18n.loggedOut.logInAgain}}
      </router-link>
    </div>
  </page>
</template>

<script>
  import i18n from "i18n"
  import api from "api"

  export default {
    name: "LogoutPage",
    computed: {
      i18n() { return i18n().user.logoutProcess }
    },
    inject: ['workingZone'],
    mounted () {
      if(!api.session.session.user) {
        return
      }
      this.workingZone.addPromise('logging out',
          api.command(['session', 'logout'], {})
              .catch(error => {
                if(error != 'loggedOut') throw error
              })
      )
    }
  }
</script>

<style scoped>

</style>