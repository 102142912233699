import Vue from "vue"

const dropZoneState = new Vue({
  data: {
    visible: false,
    dropReceiver: null
  },
  watch: {
    visible(v) {
      console.log("DROP ZONE VISIBLE", v)
    }
  }
})

const GlobalDropZone = {
  name: "GlobalDropZone",
  data() {
    return {}
  },
  computed: {
    visible() {
      return dropZoneState.visible
    }
  },
  methods: {
    dragLeave() {
      dropZoneState.visible = false
    },
    allowDrop(e) {
      e.dataTransfer.dropEffect = 'copy'
      e.preventDefault()
    },
    handleDrop(ev) {
      ev.preventDefault()
      dropZoneState.visible = false
      console.log("DROPPED", ev)
      let files = []
      if (ev.dataTransfer.items) {
        for (let i = 0; i < ev.dataTransfer.items.length; i++) {
          if (ev.dataTransfer.items[i].kind === 'file') {
            let file = ev.dataTransfer.items[i].getAsFile();
            files.push(file)
          }
        }
      } else {
        for (var i = 0; i < ev.dataTransfer.files.length; i++) {
          files.push(ev.dataTransfer.files[i])
        }
      }
      for(let file of files) {
        console.log('... file[' + i + '].name = ' + file.name)
      }
      if(dropZoneState.dropReceiver) dropZoneState.dropReceiver(files)
    }
  },
  render(createElement, context) {
    return createElement('div', {
      props: { "class": "dropZone"+( this.visible ? " visible" : "") },
      on: {
        dragleave: () => this.dragLeave(),
        dragenter: (e) => this.allowDrop(e),
        dragover: (e) => this.allowDrop(e),
        drop: (e) => this.handleDrop(e)
      }
    }, [])
  },
  /*template: '<div class="dropZone" :class="{ visible: visible }" ' +
    '@dragleave="dragLeave" @dragenter="allowDrop" @dragover="allowDrop" @drop="handleDrop"></div>'*/
}

export { GlobalDropZone, dropZoneState }
