var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search-generator-card card full-width" }, [
    _c("div", { staticClass: "search-generator-info buttons" }, [
      _c("div", { staticClass: "generator-title" }, [
        _vm._v(_vm._s(_vm.i18n.generator))
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-field copy-area" }, [
        _c("input", {
          staticClass: "input",
          attrs: { readonly: "" },
          domProps: { value: _vm.generatorXmlUrl }
        })
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button",
          attrs: { type: "button" },
          on: { click: _vm.copyGeneratorXmlUrl }
        },
        [_c("img", { attrs: { src: "/static/icons/content_copy.svg" } })]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button",
          attrs: { type: "button" },
          on: { click: _vm.openSearch }
        },
        [_c("img", { attrs: { src: "/static/icons/search.svg" } })]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "button",
          attrs: { type: "button", href: _vm.generatorXmlUrl }
        },
        [_c("img", { attrs: { src: "/static/icons/preview.svg" } })]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button",
          attrs: { type: "button" },
          on: { click: _vm.deleteGenerator }
        },
        [_c("img", { attrs: { src: "/static/icons/delete_forever.svg" } })]
      )
    ]),
    _vm._v(" "),
    _vm.urlCopied ? _c("h4", [_vm._v(_vm._s(_vm.i18n.urlCopied))]) : _vm._e(),
    _vm._v(" "),
    _vm.showQuery
      ? _c(
          "div",
          {
            class: [
              "query-view",
              _vm.generator.query.length == 1
                ? "query-view-single"
                : "query-view-multi"
            ]
          },
          _vm._l(_vm.generator.query, function(logicBlock) {
            return _c(
              "div",
              {
                class: [
                  "query-view-logic-block",
                  "query-view-logic-block-" + logicBlock.type
                ]
              },
              [
                _c("div", { staticClass: "query-view-logic-block-type" }, [
                  _vm._v(_vm._s(_vm.i18n.logicBlockType[logicBlock.type]))
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "query-view-and-block" },
                  _vm._l(logicBlock.logic, function(orBlock) {
                    return _c(
                      "div",
                      { staticClass: "query-view-or-block" },
                      _vm._l(orBlock, function(filter) {
                        return _c("div", { staticClass: "query-view-filter" }, [
                          _c(
                            "div",
                            { staticClass: "query-view-fields" },
                            _vm._l(filter.fields, function(field) {
                              return _c(
                                "div",
                                { staticClass: "query-view-field" },
                                [_vm._v(_vm._s(field))]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "query-view-operator" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  filter.operator &&
                                    (_vm.i18n.operators[
                                      filter.operator.name || filter.operator
                                    ] ||
                                      filter.operator.name || filter.operator)
                                ) +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "query-view-phrase" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(filter.phrase) +
                                "\n            "
                            )
                          ])
                        ])
                      }),
                      0
                    )
                  }),
                  0
                )
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.generator.lastUse
      ? _c("div", { staticClass: "search-generator-last-use" }, [
          _c("div", { staticClass: "search-generator-last-use-label" }, [
            _vm._v(_vm._s(_vm.i18n.generatorLastUse))
          ]),
          _vm._v(" "),
          _c("time", [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm._f("durationSeconds")(
                    (_vm.currentTime -
                      new Date(_vm.generator.lastUse.timestamp).getTime()) /
                      1000
                  )
                ) +
                " " +
                _vm._s(_vm.i18n.ago) +
                "\n      -\n      " +
                _vm._s(
                  _vm._f("date")(new Date(_vm.generator.lastUse.timestamp))
                ) +
                "\n      " +
                _vm._s(
                  _vm._f("hour")(new Date(_vm.generator.lastUse.timestamp))
                ) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              "\n      " +
                _vm._s(_vm.i18n.generationDuration) +
                " " +
                _vm._s(
                  _vm._f("durationMillis")(
                    _vm.generator.lastUse.duration / 1000
                  )
                ) +
                "\n    "
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }