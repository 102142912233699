var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("page", [
    _vm.myApiAccess
      ? _c("div", { staticClass: "card half-width main-card" }, [
          _c("h2", [_vm._v("Google Ads API")]),
          _vm._v(" "),
          _c("p", [_vm._v("Google Ads API Access active")]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "button",
              attrs: { type: "button" },
              on: { click: _vm.removeApiAccess }
            },
            [_vm._v("\n      Disconnect from Google Ads API\n    ")]
          ),
          _vm._v(" "),
          _c(
            "p",
            [
              _c(
                "router-link",
                { attrs: { to: { name: "adsApi:campaigns" } } },
                [_vm._v("\n        View Campaigns\n      ")]
              )
            ],
            1
          )
        ])
      : _c("div", { staticClass: "card half-width main-card" }, [
          _c("h2", [_vm._v("No Google Ads API Access")]),
          _vm._v(" "),
          !_vm.authUri
            ? _c("div", { staticClass: "buttons" }, [
                _c(
                  "button",
                  {
                    staticClass: "button",
                    attrs: { type: "button" },
                    on: { click: _vm.authenticateApi }
                  },
                  [_vm._v("\n        Connect to Google Ads API\n      ")]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.authUri
            ? _c("div", [
                _c("p", [
                  _vm._v(
                    "We will redirect you to the authentication page. If that doesn't happen, click the link below:"
                  )
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  { staticClass: "authUri", attrs: { href: _vm.authUri } },
                  [_vm._v(_vm._s(_vm.authUri))]
                )
              ])
            : _vm._e()
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }