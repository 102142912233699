<template>
  <div class="picture" :style="{ backgroundImage: `url('${picture}')` }">
  </div>
</template>

<script>
  /**
   * TODO WiP, but something to load static svgs properly should be here
   */
  export default {
    name: "StaticPicture",
    props: {
      picture: {
        required: true
      }
    },
    watch: {
    },
    computed: {
    }
  }
</script>

<style scoped>
  .picture {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
</style>
