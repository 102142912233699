var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "volume-indicator" }, [
    _c("div", { ref: "low", staticClass: "volume-indicator-bar" }),
    _vm._v(" "),
    _c("div", { ref: "mid", staticClass: "volume-indicator-bar" }),
    _vm._v(" "),
    _c("div", { ref: "high", staticClass: "volume-indicator-bar" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }