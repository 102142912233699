<template>
  <form-field-bind :name="name" v-slot="{ value, setValue, error }"
                   :valueFilter="n => n && n.trim()" class="file-upload-field">
    <FieldTemplate :errorText="errorText" :error="error" :label="label" :name="name"
                   :groupClass="groupClass" :inputId="uid">
      <template v-slot:above><slot name="above"></slot></template>

      <div class="buttons">
        <div class="file-name" v-if="file">{{ file.name }}</div>
        <div class="upload-info" v-if="serverUpload">
          {{ serverUpload.progress.percentage }}%
          {{ serverUpload.progress.transferred | bytes }} / {{ serverUpload.progress.length | bytes }}
        </div>
        <button v-if="file" class="button" type="button" @click="reset">×</button>
        <FileInput :accept="accept" @input="handleFile" :class="inputClass || 'button'">
          {{ button }}
        </FileInput>
      </div>

      <template v-slot:below><slot name="below"></slot></template>
    </FieldTemplate>
  </form-field-bind>
</template>

<script>
  import FieldTemplate from "./FieldTemplate.vue"
  import FileInput from "../inputs/FileInput.vue"

  export default {
    name: 'FileField',
    components: { FieldTemplate, FileInput },
    props: {
      name: {
        type: String,
        required: true
      },
      accept: {
        type: String
      },
      multiple: {
        type: Boolean,
        default: false
      },
      button: {
        type: String,
        default: "Select file"
      },
      label: {
        type: String
      },
      errorText: {
        type: Object
      },
      inputClass: {
        type: String
      },
      groupClass: {
        type: String
      },
    },
    inject: ['form'],
    data () {
      return {
        file: null,
        clientUpload: null,
        liveValue: null,
        uploadPromise: null
      }
    },
    reactive: {
      serverUpload() {
        return this.clientUpload && ['uploads', 'upload', { upload: this.clientUpload.id }]
      }
    },
    computed: {
      uid() { return ''+this._uid }
    },
    methods: {
      handleFile(file) {
        this.file = file
        this.startUpload(file)
      },
      startUpload(file) {
        this.uploadPromise = new Promise((resolve, reject) => {
          if (this.uploadLocalObservable) this.uploadLocalObservable.unobserve(this.uploadObserver)
          this.uploadLocalObservable = api.uploadFile(`${this.purpose}`, this.file.name, this.file)
          this.uploadObserver = (signal, ...args) => {
            console.log(`UPLOAD ${signal} ( ${args.map(x => JSON.stringify(x)).join(', ')} )`)

            if (signal != 'set') throw new Error("unknown signal")
            this.clientUpload = args[0]
            if (this.clientUpload.state == 'done') {
              resolve(this.uploadLocalObservable.value.id)
            }
            if (this.clientUpload.state == 'failed') {
              reject("uploadFailed")
            }
          }
          this.uploadLocalObservable.observe(this.uploadObserver)
        }).then(async (uploadId) => {
          //if(!upload) throw new Error("race-condition - server not found")
          console.log("UPLOADED", uploadId, this.lastUploadId)
          if (uploadId == this.lastUploadId) return
          this.liveValue = uploadId
          this.form.setFieldValue(this.name, uploadId)
        }).catch(error => {
          console.error("UPLOAD ERROR", error)
          this.setFieldError(this.name, error)
        })
        console.log("STARTED UPLOAD")
        this.uploadPromise.then((pictureId) => {
          console.log("ORIGINAL FINISHED", pictureId, " == ", this.livePicture)
        })
      },
      reset() {
        this.file = null
        this.clientUpload = null
        this.liveValue = null
        this.form.setFieldValue(this.name, null)
      }
    },
    created() {
      this.validator = async () => {
        if(this.uploadPromise) await this.uploadPromise
      }
      this.form.addValidator(this.name, this.validator)
    },
    mounted() {
    },
    beforeDestroy() {
      this.form.removeValidator(this.name, this.validator)
    }
  }
</script>
