<template>
  <Notification :notification="notification" ref="notification">
    <div class="notification-avatar" @click="goTo">
      <img v-if="notification.results.length" src="/static/icons/error.svg" class="avatar-addon"/>
      <img v-else src="/static/icons/check.svg" class="avatar-addon"/>
    </div>
    <div class="notification-content" @click="goTo">
      <div class="notification-title">
        {{ notification.results.length ? i18n.notifications.checkError : i18n.notifications.checkDone }}
      </div>
      <div class="notification-summary">
        Account: {{ notification.account.accountName }} ({{ notification.account.accountId }})<br>
        {{ notification.account.name }}
      </div>
      <div class="notification-description">
        <div v-for="result in notification.results" :key="result.id">
          <div v-if="result.type === 'searchBudgetLostImpressionShare'">
            Campaign: {{ result.campaign.name }} ({{ result.campaign.id }}) <br>
            Ads Limited by budget: {{ (result.metrics.search_budget_lost_impression_share * 100).toFixed(2) }}%
          </div>
        </div>
      </div>
    </div>
  </Notification>
</template>

<script>
import i18n from 'i18n'
import Notification from '@/modules/notifications/Notification.vue'

export default {
  name: "AdsAccountCheckResult",
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  components: { Notification },
  computed: {
    i18n() {
      return i18n().adsApi
    }
  },
  methods: {
    async goTo(ev) {
      this.$refs.notification.mark('read')
      ev.preventDefault()
      this.$router.push({ name: "adsApi:campaigns", params: { scanner, scan } })
    }
  }
}
</script>

<style scoped>

</style>
