var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FieldTemplate",
    {
      attrs: {
        errorText: _vm.errorText,
        error: _vm.error,
        label: _vm.label,
        name: _vm.name
      }
    },
    [
      _c(
        "div",
        { staticClass: "editor" },
        [
          _c("editor-block-menu", {
            attrs: { editor: _vm.editor, addMargins: false },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var commands = ref.commands
                  var isActive = ref.isActive
                  var block = ref.block
                  var markAttrs = ref.markAttrs
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "editor__block",
                        class: { hidden: !block.isActive },
                        style: {
                          left: block.left - 10 + "px",
                          top: block.top - 5 + "px",
                          width: block.width + 20 + "px"
                        }
                      },
                      [
                        _c("div", {
                          staticClass: "editor__block-border",
                          class: { hidden: block.isEmpty },
                          style: {
                            width: block.width + 20 + "px",
                            height: block.height + 10 + "px"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "editor__block-menus",
                            class: { hidden: block.isEmpty }
                          },
                          [
                            _c("div", { staticClass: "editor__block-menu" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "editor__block-button",
                                  class: { "is-active": isActive.bold() },
                                  attrs: { type: "button" },
                                  on: { click: commands.bold }
                                },
                                [
                                  _c("img", {
                                    attrs: { src: "/static/editor/bold.svg" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "editor__block-button",
                                  class: { "is-active": isActive.italic() },
                                  attrs: { type: "button" },
                                  on: { click: commands.italic }
                                },
                                [
                                  _c("img", {
                                    attrs: { src: "/static/editor/italic.svg" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "editor__block-button",
                                  class: { "is-active": isActive.strike() },
                                  attrs: { type: "button" },
                                  on: { click: commands.strike }
                                },
                                [
                                  _c("img", {
                                    attrs: { src: "/static/editor/strike.svg" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "editor__block-button",
                                  class: { "is-active": isActive.underline() },
                                  attrs: { type: "button" },
                                  on: { click: commands.underline }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "/static/editor/underline.svg"
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "editor__block-button",
                                  attrs: { type: "button" },
                                  on: { click: commands.nbsp }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "/static/editor/space_bar.svg"
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "editor__block-button",
                                  class: { "is-active": isActive.paragraph() },
                                  attrs: { type: "button" },
                                  on: { click: commands.paragraph }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "/static/editor/paragraph.svg"
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "editor__block-button",
                                  class: {
                                    "is-active": isActive.heading({ level: 1 })
                                  },
                                  attrs: {
                                    type: "button",
                                    disabled:
                                      isActive.bullet_list() ||
                                      isActive.ordered_list()
                                  },
                                  on: {
                                    click: function($event) {
                                      return commands.heading({ level: 1 })
                                    }
                                  }
                                },
                                [_vm._v("\n                H1\n              ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "editor__block-button",
                                  class: {
                                    "is-active": isActive.heading({ level: 2 })
                                  },
                                  attrs: {
                                    type: "button",
                                    disabled:
                                      isActive.bullet_list() ||
                                      isActive.ordered_list()
                                  },
                                  on: {
                                    click: function($event) {
                                      return commands.heading({ level: 2 })
                                    }
                                  }
                                },
                                [_vm._v("\n                H2\n              ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "editor__block-button",
                                  class: {
                                    "is-active": isActive.heading({ level: 3 })
                                  },
                                  attrs: {
                                    type: "button",
                                    disabled:
                                      isActive.bullet_list() ||
                                      isActive.ordered_list()
                                  },
                                  on: {
                                    click: function($event) {
                                      return commands.heading({ level: 3 })
                                    }
                                  }
                                },
                                [_vm._v("\n                H3\n              ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "editor__block-button",
                                  class: {
                                    "is-active": isActive.bullet_list()
                                  },
                                  attrs: { type: "button" },
                                  on: { click: commands.bullet_list }
                                },
                                [
                                  _c("img", {
                                    attrs: { src: "/static/editor/ul.svg" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "editor__block-button",
                                  class: {
                                    "is-active": isActive.ordered_list()
                                  },
                                  attrs: { type: "button" },
                                  on: { click: commands.ordered_list }
                                },
                                [
                                  _c("img", {
                                    attrs: { src: "/static/editor/ol.svg" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "editor__block-button",
                                  class: { "is-active": isActive.blockquote() },
                                  attrs: { type: "button" },
                                  on: { click: commands.blockquote }
                                },
                                [
                                  _c("img", {
                                    attrs: { src: "/static/editor/quote.svg" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "editor__block-button",
                                  class: { "is-active": isActive.link() },
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      isActive.link()
                                        ? commands.link({ remove: true })
                                        : commands.link({
                                            href: "/",
                                            rel: "noopener noreferrer nofollow",
                                            target: "_blank"
                                          })
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: { src: "/static/editor/link.svg" }
                                  })
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _vm._l([_vm.editor.activeMarkAttrs.link], function(
                              mark
                            ) {
                              return isActive.link()
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "editor__block-menu fields-row"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "editor__block-menu-field editor__block-menu-field-url form-field"
                                        },
                                        [
                                          _c("label", [_vm._v("Href:")]),
                                          _vm._v(" "),
                                          _c("input", {
                                            staticClass:
                                              "editor__block-input input",
                                            attrs: { placeholder: "url" },
                                            domProps: { value: mark.href },
                                            on: {
                                              input: function(ev) {
                                                commands.link(
                                                  Object.assign({}, mark, {
                                                    href: ev.target.value
                                                  })
                                                )
                                                ev.target.focus()
                                              }
                                            }
                                          })
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "editor__block-menu-field editor__block-menu-field-target  form-field"
                                        },
                                        [
                                          _c("label", [_vm._v("Target:")]),
                                          _vm._v(" "),
                                          _c("input", {
                                            staticClass:
                                              "editor__block-input input",
                                            attrs: { placeholder: "target" },
                                            domProps: { value: mark.target },
                                            on: {
                                              input: function(ev) {
                                                commands.link(
                                                  Object.assign({}, mark, {
                                                    target: ev.target.value
                                                  })
                                                )
                                                ev.target.focus()
                                              }
                                            }
                                          })
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "editor__block-menu-field form-field"
                                        },
                                        [
                                          _c("label", [_vm._v("Rel:")]),
                                          _vm._v(" "),
                                          _c("input", {
                                            staticClass:
                                              "editor__block-input input",
                                            attrs: { placeholder: "rel" },
                                            domProps: { value: mark.rel },
                                            on: {
                                              input: function(ev) {
                                                commands.link(
                                                  Object.assign({}, mark, {
                                                    rel: ev.target.value
                                                  })
                                                )
                                                ev.target.focus()
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            })
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "editor__block-init",
                            class: { hidden: !block.isEmpty }
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "editor__block-button",
                                class: { "is-active": isActive.paragraph() },
                                attrs: { type: "button" },
                                on: { click: commands.paragraph }
                              },
                              [
                                _c("img", {
                                  attrs: { src: "/static/editor/paragraph.svg" }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "editor__block-button",
                                class: {
                                  "is-active": isActive.heading({ level: 1 })
                                },
                                attrs: {
                                  type: "button",
                                  disabled:
                                    isActive.bullet_list() ||
                                    isActive.ordered_list()
                                },
                                on: {
                                  click: function($event) {
                                    return commands.heading({ level: 1 })
                                  }
                                }
                              },
                              [_vm._v("\n              H1\n            ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "editor__block-button",
                                class: {
                                  "is-active": isActive.heading({ level: 2 })
                                },
                                attrs: {
                                  type: "button",
                                  disabled:
                                    isActive.bullet_list() ||
                                    isActive.ordered_list()
                                },
                                on: {
                                  click: function($event) {
                                    return commands.heading({ level: 2 })
                                  }
                                }
                              },
                              [_vm._v("\n              H2\n            ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "editor__block-button",
                                class: {
                                  "is-active": isActive.heading({ level: 3 })
                                },
                                attrs: {
                                  type: "button",
                                  disabled:
                                    isActive.bullet_list() ||
                                    isActive.ordered_list()
                                },
                                on: {
                                  click: function($event) {
                                    return commands.heading({ level: 3 })
                                  }
                                }
                              },
                              [_vm._v("\n              H3\n            ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "editor__block-button",
                                class: { "is-active": isActive.bullet_list() },
                                attrs: { type: "button" },
                                on: { click: commands.bullet_list }
                              },
                              [
                                _c("img", {
                                  attrs: { src: "/static/editor/ul.svg" }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "editor__block-button",
                                class: { "is-active": isActive.ordered_list() },
                                attrs: { type: "button" },
                                on: { click: commands.ordered_list }
                              },
                              [
                                _c("img", {
                                  attrs: { src: "/static/editor/ol.svg" }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "editor__block-button",
                                class: { "is-active": isActive.blockquote() },
                                attrs: { type: "button" },
                                on: { click: commands.blockquote }
                              },
                              [
                                _c("img", {
                                  attrs: { src: "/static/editor/quote.svg" }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "editor__block-button",
                                attrs: { type: "button" },
                                on: { click: commands.horizontal_rule }
                              },
                              [
                                _c("img", {
                                  attrs: { src: "/static/editor/hr.svg" }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "file-input" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "editor__block-button",
                                  attrs: { type: "button" }
                                },
                                [
                                  _c("img", {
                                    attrs: { src: "/static/editor/image.svg" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                staticClass: "hidden-file",
                                attrs: { type: "file", accept: "image/*" },
                                on: {
                                  change: function(ev) {
                                    return _vm.startPictureUpload(
                                      ev,
                                      commands.createPictures
                                    )
                                  }
                                }
                              })
                            ])
                          ]
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("EditorContent", {
            class: [
              "content-editor__content",
              "wysiwyg-field",
              { empty: _vm.empty },
              _vm.inputClass
            ],
            attrs: { editor: _vm.editor, "data-placeholder": _vm.placeholder }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }