var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "object-picker" },
    [
      _c(
        "overlay-anchor",
        {
          staticClass: "card card-inverse",
          attrs: { anchor: _vm.anchor, directions: _vm.directions },
          on: { positioned: _vm.updatePosition }
        },
        [
          _c(
            "div",
            {
              staticClass: "objects-box card card-inverse card-success mb-3",
              style: {
                "max-width":
                  _vm.windowDimensions.width - _vm.position.x - 20 + "px",
                "max-height":
                  _vm.windowDimensions.height - _vm.position.y - 20 + "px"
              },
              attrs: { ef: "box" }
            },
            [
              _c(
                "div",
                { staticClass: "objects" },
                [
                  _c(
                    "loading-zone",
                    [
                      _c(
                        "loading",
                        {
                          attrs: {
                            what: _vm.allLoaded,
                            error: _vm.loadingError,
                            name:
                              "objects list " + _vm.service + "/" + _vm.model
                          }
                        },
                        _vm._l(_vm.objects, function(object) {
                          return _c(
                            "div",
                            {
                              staticClass: "object",
                              on: {
                                click: function($event) {
                                  return _vm.objectSelected(object)
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "countryName" }, [
                                _vm._v(_vm._s(_vm.displayName(object)))
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }