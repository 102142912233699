var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "message",
        _vm.message.sendState,
        _vm.my ? "my-message" : "other-user-message",
        { next: _vm.isPrevConnected, prev: _vm.isNextConnected }
      ]
    },
    [
      _vm.isTimeVisible
        ? _c("time", { attrs: { datatime: _vm.message.timestamp } }, [
            _vm._v(
              _vm._s(_vm._f("hourOrDayShort")(_vm.message.timestamp, _vm.now))
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "message-row" }, [
        _c("div", {
          staticClass: "message-content",
          domProps: {
            innerHTML: _vm._s(
              _vm.$autoLinks(
                _vm.$sanitize(_vm.message.text, { ALLOWED_TAGS: ["p"] })
              )
            )
          }
        }),
        _vm._v(" "),
        _vm.my
          ? _c(
              "div",
              { staticClass: "message-readers" },
              _vm._l(_vm.readPositions, function(readPosition) {
                return readPosition.user
                  ? _c("observe", {
                      key: readPosition.id,
                      staticClass: "message-reader",
                      attrs: {
                        name: "userData",
                        what: [
                          "users",
                          "publicUserData",
                          { user: readPosition.user }
                        ]
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var userData = ref.value
                              return [
                                userData && userData.picture
                                  ? _c("uploaded-picture-img", {
                                      attrs: {
                                        width: "16",
                                        height: "16",
                                        picture: userData.picture,
                                        reactive: true
                                      }
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src: "/static/empty-user-photo.svg",
                                        width: "20",
                                        height: "20"
                                      }
                                    })
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  : _vm._e()
              }),
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.message.sendState == "failed"
        ? _c("div", { staticClass: "message-failed-buttons buttons" }, [
            _c(
              "button",
              {
                staticClass: "button",
                attrs: { type: "button" },
                on: { click: _vm.retry }
              },
              [_vm._v("Retry")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button",
                attrs: { type: "button" },
                on: { click: _vm.cancel }
              },
              [_vm._v("Cancel")]
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }