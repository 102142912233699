import { Plugin, PluginKey } from 'prosemirror-state'
import { toPx } from 'common/components/utils/toPx.js'
import { getMarkAttrs } from 'tiptap-utils'

class Menu {

  constructor({ options, editorView }) {
    this.options = {
      ...{
        resizeObserver: true,
        element: null,
        onUpdate: () => false,
      },
      ...options,
    }
    this.preventHide = false
    this.editorView = editorView
    this.isActive = false
    this.top = 0
    this.left = 0
    this.width = 0
    this.height = 0

    // the mousedown event is fired before blur so we can prevent it
    this.mousedownHandler = this.handleClick.bind(this)
    this.options.element.addEventListener('mousedown', this.mousedownHandler)

    this.options.editor.on('focus', ({ view }) => {
      this.update(view)
    })

    this.options.editor.on('blur', ({ event }) => {
      if (this.preventHide) {
        this.preventHide = false
        return
      }

      this.hide(event)
    })

    // sometimes we have to update the position
    // because of a loaded images for example
    if (this.options.resizeObserver && window.ResizeObserver) {
      this.resizeObserver = new ResizeObserver(() => {
        if (this.isActive) {
          this.update(this.editorView)
        }
      })
      this.resizeObserver.observe(this.editorView.dom)
    }
  }

  handleClick() {
    this.preventHide = true
  }

  update(view, lastState) {
    const { state } = view

    // Don't do anything if the document/selection didn't change
    if (lastState && lastState.doc.eq(state.doc) && lastState.selection.eq(state.selection)) {
      return
    }

    const currentDom = view.domAtPos(state.selection.anchor)



    /*const isActive = currentDom.node.innerHTML === '<br>'
        && currentDom.node.tagName === 'P'
        && currentDom.node.parentNode === view.dom*/

    let block = currentDom.node
    while(this.options.blockTags.indexOf(block.tagName) == -1) {
      block = block.parentElement
      if(block == this.options.element) {
        block = null
        break;
      }
    }

    if (!block) {
      this.hide()
      return
    }

    const parent = this.options.element.offsetParent

    if (!parent) {
      this.hide()
      return
    }

    const editorBoundings = parent.getBoundingClientRect()
    const elementBoundings = block.getBoundingClientRect()
    let top,left,width,height
    if(this.options.addMargins) {
      const computedStyle = window.getComputedStyle(block)
      const marginTop = toPx(block, computedStyle.marginTop, 'marginTop')
      const marginLeft = toPx(block, computedStyle.marginLeft, 'marginLeft')
      const marginRight = toPx(block, computedStyle.marginRight, 'marginRight')
      const marginBottom = toPx(block, computedStyle.marginBottom, 'marginBottom')

      top = elementBoundings.top - editorBoundings.top - marginTop
      left = elementBoundings.left - editorBoundings.left - marginLeft
      width = elementBoundings.width + marginLeft + marginRight
      height = elementBoundings.height + marginTop + marginBottom
    } else {
      top = elementBoundings.top - editorBoundings.top
      left = elementBoundings.left - editorBoundings.left
      width = elementBoundings.width
      height = elementBoundings.height
    }

    this.isActive = true
    this.isEmpty = block.innerHTML == '<br>'
    this.top = top
    this.left = left
    this.width = width
    this.height = height

    /*console.log("SEL", state.selection)
    console.log("SCHEMA", this.options.editor.schema)
    console.log("ATTRS", getMarkAttrs(state, this.options.editor.schema.marks.link))*/

    this.sendUpdate()
  }

  sendUpdate() {
    console.log("UPDATE", this.isActive)
    this.options.onUpdate({
      isActive: this.isActive,
      isEmpty: this.isEmpty,
      top: this.top,
      left: this.left,
      width: this.width,
      height: this.height
    })
  }

  hide(event) {
    if (event
        && event.relatedTarget
        && this.options.element.parentNode.contains(event.relatedTarget)
    ) {
      return
    }
    this.isActive = false
    this.sendUpdate()
  }

  destroy() {
    this.options.element.removeEventListener('mousedown', this.mousedownHandler)

    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.editorView.dom)
    }
  }

}

export default function (options) {
  return new Plugin({
    key: new PluginKey('block_menu'),
    view(editorView) {
      return new Menu({ editorView, options })
    },
  })
}