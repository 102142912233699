var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("page", [
    _c("div", {
      staticClass: "card full-width",
      domProps: { innerHTML: _vm._s(_vm.partial && _vm.partial.data.content) }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }