<template>
  <div>
    <overlay-anchor :anchor="anchor" :directions="directions" @positioned="updatePosition">
      <div class="navbar-dropdown dropdown-menu user-menu" @click.capture="$emit('close')">

        <router-link v-if="!loggedIn" :to="{name:'user:register'}"
                     class="dropdown-item" role="button">{{i18n.register}}</router-link>
        <router-link v-if="!loggedIn" :to="{name:'user:login'}"
                     class="dropdown-item" role="button">{{i18n.login}}</router-link>

        <hr class="dropdown-divider" v-if="loggedIn" ></hr>

        <router-link v-if="loggedIn" :to="{name:'user:settings'}" @click.native="close"
                     class="dropdown-item" tag="a" >
          {{ i18n.userSettings }}
        </router-link>
        <router-link v-if="loggedIn" :to="{name:'user:profile', params: { user: this.user.slug }}" @click.native="close"
                     class="dropdown-item" tag="a" >
          {{ i18n.yourPage }}
        </router-link>
        <hr class="dropdown-divider" v-if="loggedIn" ></hr>
        <router-link v-if="loggedIn" :to="{name:'user:logout'}" @click.native="close"
                     class="dropdown-item" tag="a">
          {{ i18n.logout }}
        </router-link>
      </div>
    </overlay-anchor>
  </div>
</template>

<script>
  import api from "@/api"
  import i18n from "i18n"
  import { OverlayAnchor, windowDimensions } from "common/components/utils/overlayLayer"

  export default {
    name: "MobileMenu",
    props: ['anchor'],
    components: { OverlayAnchor },
    data () {
      return {
        directions: [ { x:-1, y:1, ax: 1, ay: 1}, { x:1, y:1, ax: -1, ay: 1}],
        position: {x:0, y:0},
        search: ""
      }
    },
    computed: {
      i18n() { return i18n().navBar },
      lang() { return i18n.config.language },
      user() { return api.session.user },
      windowDimensions() { return windowDimensions },
      loggedIn() {
        return !!api.session.session.user
      }
    },
    methods: {
      updatePosition(position) {
        this.position = position
      },
      close() {
        this.$emit('close')
      },
      setLanguage(lang) {
        console.log("SET LANG", lang)
        i18n.config.language = lang
        document.cookie = `language=${lang}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
      }
    }
  }
</script>

<style scoped>
  .mobileMenuBox {
    position: relative;
    display: block;
    box-sizing: border-box;
    overflow-y: auto;
  }
</style>
