var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "exclusionsList-info-file" }, [
    _vm.exclusionsList
      ? _c(
          "a",
          {
            staticClass: "exclusionsList-filename",
            attrs: { href: _vm.csvFileUrl }
          },
          [
            _vm._v(
              "\n    " + _vm._s(_vm.exclusionsList.originalFileName) + "\n  "
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.exclusionsList
      ? _c("div", { staticClass: "exclusionsList-status" }, [
          _c("div", { staticClass: "exclusionsList-size" }, [
            _vm._v(
              _vm._s(_vm.exclusionsList.wordsCount) +
                " " +
                _vm._s(_vm.i18n.entries)
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.deleteable
      ? _c(
          "button",
          {
            staticClass: "button",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$emit("delete")
              }
            }
          },
          [_c("img", { attrs: { src: "/static/icons/close.svg" } })]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }