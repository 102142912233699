<template>
  <form-field-bind :name="name" v-slot="{ value, setValue, error, setError }"
                   :valueFilter="n => n && n.trim()">
    <FieldTemplate :errorText="errorText" :error="error" :label="label" :name="name" :groupClass="groupClass">
      <div class="show-password-wrapper">
        <input :type="showPassword ? 'text' : 'password'" :class="'form-control '+inputClass" :id="''+_uid"
               :value="value" @input="e => setValue(e.target.value)" ref="field"
               class="{ 'is-invalid': !!error }" :placeholder="placeholder"/>
        <div class="show-password" v-if="enableTogglePassword && showPassword === false"
             @click="showPassword = true">
          <img src="/static/icons/visibility.svg" class="show-password-icon" />
          <span class="show-password-text">{{ i18n.showPassword }}</span>
        </div>
        <div class="show-password" v-if="enableTogglePassword && showPassword === true"
             @click="showPassword = false">
          <img src="/static/icons/visibility_off.svg" class="show-password-icon" />
          <span class="show-password-text">{{ i18n.hidePassword }}</span>
        </div>

      </div>
      <div class="password-rules" v-if="passwordRules">
        <span>{{ passwordRules }}</span>
      </div>
    </FieldTemplate>


    <FieldTemplate :label="retypeLabel" :name="name" :groupClass="groupClass">
      <input :type="showPassword ? 'text' : 'password'" :class="'form-control '+inputClass" :id="'second'+_uid" v-model="second" ref="field2"
             class="{ 'is-invalid': !!error }" :placeholder="retypePlaceholder"/>
    </FieldTemplate>
  </form-field-bind>
</template>

<script>
  import FieldTemplate from "./FieldTemplate.vue"
  import i18n from "i18n"

  export default {
    name: "DoublePasswordField",
    components: {FieldTemplate},
    inject: ['form'],
    props: {
      name: {
        type: String,
        required: true
      },
      label: {
        type: String
      },
      retypeLabel: {
        type: String
      },
      placeholder: {
        type: String
      },
      retypePlaceholder: {
        type: String
      },
      passwordRules: {
        type: String
      },
      errorText: {
        type: Object
      },
      inputClass: {
        type: String
      },
      groupClass: {
        type: String
      },
      enableTogglePassword: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        showPassword: false,
        second: ''
      }
    },
    computed: {
      i18n() {
        return i18n().user
      },
    },
    methods: {},
    created() {
      this.validator = () => {
        let value = this.form.getFieldValue(this.name)
        console.log("PASSWORDS MATCH?", this.second, value)
        if (value != this.second.trim()) return "notMatch"
      }
      this.form.addValidator(this.name, this.validator)
    },
    beforeDestroy() {
      this.form.removeValidator(this.name, this.validator)
    }
  }

</script>

<style scoped>
  .showPasswordWrapper {
    float: right;
    margin-top: -32px;
  }

  .showPassword {
    font-size: 14px;
    color: #a730b0;
    margin-right: 5px;
  }
</style>
