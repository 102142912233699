<template>
  <page>
    <div class="card half-width main-card">
      <h2>Sign In</h2>
      <SocialAuth v-if="!passwordResetSuccess" no-loading
                  @started="handleSocialAuthStarted" @canceled="handleSocialAuthCanceled"
                  @done="handleSocialAuthDone"/>
      <div v-if="passwordResetSuccess" class="alert alert-success passwordReset-success">
        {{ i18n.passwordResetSuccess }}
      </div>
      <div class="loginPage-email-section" v-if="!waitingForSocialAuth">
        <div class="dotted-line"></div>
        <p v-if="!passwordResetSuccess" class="standard-login-label">{{ i18n.orUseEmail }}</p>
        <command-form key="login" service="emailPassword" action="login" ref="form"
                      :ignoreError="['notFound', 'wrongPassword', 'registrationNotConfirmed']" @submit="handleSubmit"
                      @done="handleLoginDone" @error="handleLoginError">
          <div class="form-error" v-if="error == 'notFound' || error =='wrongPassword'">
            {{ i18n.incorrectEmail }}
          </div>
          <div class="form-error" v-if="error == 'registrationNotConfirmed'">
            {{ i18n.registrationNotConfirmed }}
            <a href="#" @click.prevent="resendConfirmationEmail">{{ i18n.resendEmail }}</a>
          </div>
          <div class="form-error" v-if="error == 'emailSent'">
            {{ i18n.emailSent }}
          </div>
          <text-field name="email" :label="i18n.email" ref="email"></text-field>
          <password-field name="passwordHash" :label="i18n.password"></password-field>
          <div class="buttons">
            <button type="submit" class="button" role="button"><span>{{ i18n.loginButton }}</span></button>
            <router-link class="login-forgot-password-link"
                         :to="{ name: 'user:resetPassword', params: { step: 'email' }}">{{i18n.forgotPassword}}
            </router-link>
          </div>
        </command-form>
      </div>
      <div v-if="waitingForSocialAuth">
        <p class="info-message">{{ i18n.waitingForSocialAuth }}</p>
      </div>
    </div>
  </page>
</template>

<script>
  import i18n from "i18n"
  import api from "@/api"
  import Page from "@/segments/Page.vue"
  import SocialAuth from "./SocialAuth.vue"

  export default {
    name: "LoginPage",
    computed: {
      i18n() {
        return i18n().user.login
      },
    },
    inject: ['loadingZone', 'workingZone'],
    components: { SocialAuth, Page },
    data() {
      return {
        showPassword: false,
        error: null,
        email: null,
        waitingForSocialAuth: false
      }
    },
    props: {
      passwordResetSuccess: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      handleLoginDone() {
        if(this.finished) return
        this.afterLoginTask = this.workingZone.started({ name: 'waiting for login' })
      },
      handleLoginError({ error }) {
        if(error == 'notFound' || error == 'wrongPassword' || error == 'registrationNotConfirmed') {
          this.error = error
          this.$refs.form.setState('ready')
          return
        }
      },
      handleSubmit({ parameters }) {
        this.email = parameters.email
        this.error = null
      },
      resendConfirmationEmail() {
        this.workingZone.addPromise('resendEmail',
            api.command(['emailPassword', 'resendRegisterKey'], { email: this.email, lang: i18n.config.language })
                .then(result => this.error = 'emailSent')
        )
      },
      handleSocialAuthStarted() {
        this.waitingForSocialAuth = true
      },
      handleSocialAuthCanceled() {
        this.waitingForSocialAuth = false
      },
      handleSocialAuthDone() {
        this.afterLoginTask = this.workingZone.started({ name: 'waiting for login' })
      }
    },
    beforeDestroy() {
      this.finished = true
      if(this.afterLoginTask) this.workingZone.finished(this.afterLoginTask)
    },
  }
</script>

<style scoped>

</style>
