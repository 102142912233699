<template>
  <div class="naviWrapper" v-if="path">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb pl-0 pb-0 bg-transparent">
        <li class="breadcrumb-item" v-for="part in (path.slice(0, -1))">
          <router-link :to="part">
            {{ i18n[part.name] ? i18n[part.name](part) : `i18n:${part.name}` }}
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page" v-for="part in (path.slice(-1))">
          {{ i18n[part.name] ? i18n[part.name](part) : `i18n:${part.name}` }}
        </li>
      </ol>
    </nav>
    <div class="breadcrumb-back-button col-12 d-block text-left pl-0 ml-0">
      <a @click="$router.go(-1)" class="btn btn-primary btn-xs" href="#" role="button">
        <static-picture picture="/static/ICO_chevron_left.svg"></static-picture>
        <span class="pr-2">{{ i18n.back }}</span>
      </a>
    </div>
  </div>
</template>

<script>
  import i18n from "i18n"
  import api from "@/api"
  import StaticPicture from "./StaticPicture";
  export default {
    name: "BreadcrumbNavi",
    props: {
      path: {
        type: Array,
        required: true
      }
    },
    components: {
      StaticPicture
    },
    computed: {
      i18n() { return i18n().navigation }
    }
  }
</script>

<style scoped>

</style>
