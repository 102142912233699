import { Node, Plugin } from 'tiptap'
import { Slice, Fragment } from 'prosemirror-model'

import api from "@/api"
import { PictureUpload, pictureUploads, preProcessFile } from "../utils/PictureUpload.js"
import { blobToDataUrl } from "common/components/utils/imageUtils.js"


class Picture extends Node {

  get defaultOptions() {
    return {
      caption: false,
      deviceOptions: false,
      inline: false
    }
  }

  get name() {
    return 'picture'
  }

  get schema() {
    let defaultDevices = null
    if(this.options.deviceOptions) {
      defaultDevices = {}
      for(const option of this.options.deviceOptions) {
        defaultDevices[option] = true
      }
    }
    return {
      inline: this.options.inline,
      attrs: {
        pictureId: {},
        caption: {
          default: null,
        },
        devices: {
          default: defaultDevices
        }
      },
      group: this.options.inline ? 'inline' : 'block',
      draggable: true,
      parseDOM: [{
          tag: `div[data-type=${this.name}]`,
          getAttrs: dom => {
            const devices = {}
            if(this.options.deviceOptions) {
              for(const option of this.options.deviceOptions) {
                if(dom.getAttribute(`data-device-${option}`)) devices[option] = true
              }
            }
            return ({
              pictureId: dom.querySelector('img').getAttribute('src').split('/').slice(-2)[0],
              caption: dom.querySelector('span') && dom.querySelector('span').innerText,
              devices
            })
          },
        },
      ],
      toDOM: node => {
        let deviceData = {}
        if(this.options.deviceOptions) {
          for(const option of this.options.deviceOptions) {
            if(node.attrs.devices && node.attrs.devices[option]) {
              deviceData[`data-device-${option}`] = true
            }
          }
        }
        return [ 'div', { 'data-type': this.name, ...deviceData },
          ['img', { src: `/pictures/${node.attrs.pictureId}/original` /*,contenteditable:'false'*/ }]
        ].concat((node.attrs.caption && this.options.caption) ? [
          ['div', { /*contenteditable:'false'*/ }, node.attrs.caption]
        ] : [])
      },
    }
  }

  commands({ type }) {
    return {
      createPictures: pictures => (
        (state, dispatch) => {
          const { selection } = state
          const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos
          const transaction = state.tr
          for(let picture of pictures) {
            const node = type.create(picture)
            transaction.insert(position, node)
          }
          dispatch(transaction)
        }
      )
    }
  }
  inputRules({ type }) {
    return []
  }
  get view() {
    const options = this.options
    return {
      name: "PictureNode",
      props: ['node', 'updateAttrs', 'view', 'getPos'],
      computed: {
        pictureId: {
          get() {
            return this.node.attrs.pictureId
          },
          set(pictureId) {
            this.updateAttrs({ pictureId })
          }
        },
        caption: {
          get() {
            return this.node.attrs.caption
          },
          set(caption) {
            this.updateAttrs({ caption })
          }
        },
        devices() {
          return this.node.attrs.devices
        },
        upload() {
          const id = this.pictureId
          return pictureUploads.uploads.find(p => p.pictureId == id)
        },
        url() {
          if(this.upload) return this.upload.url
          const id = this.pictureId
          return `/pictures/${id}/original`
        },
        options() {
          return options
        },
        uid() {
          return this._uid
        }
      },
      methods: {
        setDevice(device, checked) {
          const devices = JSON.parse(JSON.stringify(this.node.attrs.devices  || {}))
          if(checked) devices[device] = true
            else delete devices[device]
          console.log("DEVICES", devices, device, checked)
          this.updateAttrs({ devices })
        },
        remove() {
          const transaction = this.view.state.tr
          const pos = this.getPos()
          transaction.delete(pos, pos + this.node.nodeSize)
          this.view.dispatch(transaction)
        }
      },
      template: `
        <div :data-type="node.type.name">      
          <img :src="url">
          <div v-if="view.editable && options.deviceOptions" class="device-options">
            <span v-for="option in options.deviceOptions" class="deviceOption">
              <input type="checkbox" :id="uid + '_' + option"
                     :checked="devices && devices[option]"
                     @input="ev => setDevice(option, ev.target.checked)">
              <label :for="uid + '_' + option">{{ option }}</label>
            </span>
          </div>
          <input v-if="view.editable && options.caption" class="picture-caption-input" @paste.stop type="text"
                 v-model="caption" placeholder="Add caption..." v-on:keydown.enter.prevent />
          <div v-else>{{ caption }}</div>
          <div class="picture-delete-button" @click="remove">
            <img src="/static/icons/close.svg">
          </div>
        </div>
      `
    }
  }

  isUploading(at) {
    console.log("isUploading", at)
    if(at.node && at.node.type.name == 'picture') {
      if(pictureUploads.uploads.find(p=>p.pictureId == at.node.attrs.pictureId)) return true
    } else {
      for(let child of at.children) {
        if(this.isUploading(child)) return true
      }
    }
  }

  get plugins() {
    const picturePlugin = this
    return [
      new Plugin({
        props: {
          handleDOMEvents: {
            drop(view, event) {
              const hasFiles = event.dataTransfer
                  && event.dataTransfer.files
                  && event.dataTransfer.files.length

              if (!hasFiles) return

              const images = Array
                  .from(event.dataTransfer.files)
                  .filter(file => (/image/i).test(file.type))

              if (images.length === 0) return

              event.preventDefault()

              const { schema } = view.state
              const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY })

              let picturesPromises = []
              for(let file of images) {
                picturesPromises.push(picturePlugin.createPicture(file))
              }
              const picturesPromise = Promise.all(picturesPromises)
              const promise = picturesPromise.then(pictures => {
                const transaction = view.state.tr
                for (let picture of pictures) {
                  const node = schema.nodes.picture.create(picture)
                  transaction.insert(coordinates.pos, node)
                }
                view.dispatch(transaction)
              })

              if(picturePlugin.workingZone) picturePlugin.workingZone.addPromise('addDroppedPictures', promise)
            },
            paste(view, event) {
              const hasFiles =
                  event.clipboardData &&
                  event.clipboardData.files &&
                  event.clipboardData.files.length;

              if (!hasFiles) return

              const images = Array
                  .from(event.clipboardData.files)
                  .filter(file => /image/i.test(file.type));

              if (images.length === 0) return

              event.preventDefault()

              const { schema } = view.state

              let picturesPromises = []
              for(let file of images) {
                picturesPromises.push(picturePlugin.createPicture(file))
              }
              const picturesPromise = Promise.all(picturesPromises)
              const promise = picturesPromise.then(pictures => {
                const transaction = view.state.tr
                const nodes = pictures.map(picture => schema.nodes.picture.create(picture))
                transaction.replaceSelection(new Slice(Fragment.from(nodes), 0, 0))
                view.dispatch(transaction)
              })

              if(picturePlugin.workingZone) picturePlugin.workingZone.addPromise('addDroppedPictures', promise)
            },
          },
        },
      }),
    ]
  }

  async createPicture(file) {
    const name = file.name
    const pictureIdPromise = api.command(["pictures", "createEmptyPicture"], {
      name, purpose:'wysiwyg'
    })
    this.processing = true
    const filePromise = preProcessFile(file)
    filePromise.then(() => {
      this.processing = false
    })
    const [img, pictureId] = await Promise.all([filePromise, pictureIdPromise])
    const url = await blobToDataUrl(img.blob)
    pictureUploads.addUpload(pictureId, url, img.blob, img.size)
    return { pictureId }
  }
}

export default Picture
