import Debugger from './Debugger.vue'

export default function(prefix) {
  return [
    {
      path: prefix+'/debugger/:toType/:toId',
      name: 'peer-connection:debugger',
      component: Debugger,
      props: true
    },
  ]
}
