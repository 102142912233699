<template>
  <div :label="label || name" class="object">
    <div v-for="prop in properties" class="prop" :key="name+'.e.'+prop">
      <component v-if="definition.properties[prop].editor !== false"
                 :is="editorComponent(prop)" :name="name + '.' + prop" :label="prop">
      </component>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ObjectField",
    inject: ['form'],
    props: {
      name: {
        type: String,
        required: true
      },
      label: {
        type: String,
      }
    },
    data() {
      return {
        definition: null
      }
    },
    computed: {
      properties() {
        return this.definition
            && ( this.definition.properties ? Object.keys(this.definition.properties) : [])
      }
    },
    mounted() {
      this.definition = this.form.getFieldDefinition(this.name)
      console.log("CF", name, "defn", JSON.stringify(this.definition))
    },
    methods: {
      editorComponent(fieldName) {
        let fieldDefinition = this.definition.properties[fieldName]
        return this.$editors.byDefinition(fieldDefinition)
      }
    }
  }
</script>

<style scoped>
  .object {

  }
  .prop {
    padding-left: 2em;
  }
</style>