var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-result card main-card" },
    [
      _c(
        "ul",
        _vm._l(_vm.data.found, function(found) {
          return _c("li", [
            _c("i", [_vm._v(_vm._s(found.word))]),
            _vm._v("\n      in\n      "),
            _c("b", [
              _vm._v(
                _vm._s(
                  typeof found.field == "string"
                    ? found.field
                    : found.field.join("\u00A0→\u00A0")
                )
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "copy-buttons",
                on: {
                  click: function($event) {
                    return _vm.copyFoundWord(found)
                  }
                }
              },
              [
                _vm._m(0, true),
                _vm._v(" "),
                _vm.foundWordCopied && _vm.foundWordCopied == found
                  ? _c("div", { staticClass: "copy-info" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.i18n.wordCopied) +
                          "\n        "
                      )
                    ])
                  : _vm._e()
              ]
            )
          ])
        }),
        0
      ),
      _vm._v(" "),
      _vm.viewAsXml
        ? _c(
            "vue-code-highlight",
            {
              key: _vm.rawXml ? "raw" : "xml",
              staticClass: "xml-code-view",
              attrs: { language: "xml" }
            },
            [
              _c("pre", [
                _vm._v(_vm._s(_vm.rawXml ? _vm.cleanedXml : _vm.formattedXml))
              ])
            ]
          )
        : _c("SearchResultData", { attrs: { data: _vm.preparedData } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "button", attrs: { type: "button" } }, [
      _c("img", { attrs: { src: "/static/icons/content_copy.svg" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }