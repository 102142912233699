<template>
  <page>
    <h1>XML ALERT!</h1>
    <div class="full-width">
      <observe v-if="isLoggedIn" name="myScanners" :what="['xmlAlert', 'myScanners', {}]" v-slot="{ value: scanners }">
        <ScannerCard v-for="scanner in scanners" :scanner="scanner" :key="scanner.to"
                     show-url></ScannerCard>
      </observe>
    </div>
    <div class="card main-card full-width">
      <h3>Add url:</h3>
      <command-form service="xmlAlert" action="createScanner"
                    :keepOnDone="true" @done="handleScannerAdded" ref="form">

        <text-field name="url" :label="i18n.url" :fieldValidators="{ url: v => validateUrl(v) }">
        </text-field>

        <button type="submit" class="button" role="button">{{ i18n.createScannerSubmit }}</button>

      </command-form>
    </div>
  </page>
</template>

<script>
  import api from "api"
  import i18n from "i18n"
  import SingleSelectInput from "common/components/inputs/SingleSelectInput.vue"
  import ScannerCard from "./ScannerCard.vue"

  export default {
    name: "XmlAlertPage",
    components: { SingleSelectInput, ScannerCard },
    inject: ['workingZone'],
    computed: {
      i18n() {
        return i18n().xmlAlert
      },
      isLoggedIn() {
        return api.session.loggedIn
      }
    },
    methods: {
      validateUrl(value) {
        console.error("CUSTOM URL VALIDATOR!", value)
        if(value && !value.match(/^https?:\/\//)
            && value.match(/^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)) {
          this.$refs.form.setFieldValue('url', 'https://'+value)
        }
      },
      handleScannerAdded() {

      }
    }
  }
</script>

<style scoped>

</style>