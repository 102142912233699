var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user &&
    _vm.user.loginMethods.find(function(lm) {
      return lm.type == "emailPassword"
    })
    ? _c(
        "section",
        { staticClass: "card half-width" },
        [
          _c("command-form", {
            attrs: {
              service: "emailPassword",
              action: "updateAllPasswordsByUser"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "done",
                  fn: function() {
                    return [
                      _c("h4", [_vm._v(_vm._s(_vm.i18n.done.title))]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n        " + _vm._s(_vm.i18n.done.text) + "\n      "
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "default",
                  fn: function() {
                    return [
                      _c("h4", [_vm._v(_vm._s(_vm.i18n.title))]),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(_vm.i18n.instruction))]),
                      _vm._v(" "),
                      _c("password-field", {
                        attrs: {
                          name: "oldPasswordHash",
                          label: _vm.i18n.oldPassword,
                          placeholder: _vm.i18n.oldPasswordPlaceholder,
                          inputClass: "form-control-lg"
                        }
                      }),
                      _vm._v(" "),
                      _c("double-password-field", {
                        attrs: {
                          name: "newPasswordHash",
                          label: _vm.i18n.newPassword,
                          placeholder: _vm.i18n.newPasswordPlaceholder,
                          retypeLabel: _vm.i18n.retypePassword,
                          retypePlaceholder: _vm.i18n.retypePasswordPlaceholder,
                          inputClass: "form-control-lg"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "buttons" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button",
                            attrs: { type: "submit", role: "button" }
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.i18n.submit))])]
                        )
                      ])
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              4047895500
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }