<template>
  <page>
    <div class="card" v-if="item">
      <div class="checkbox-field form-field">
        <span class="checkbox">
          <input type="checkbox" class="checkbox-input" id="viewAsXml" :checked="viewAsXml"
                 @input="(ev) => viewAsXml = ev.target.checked" />
          <span class="checkbox-mark"></span>
        </span>
        <label class="custom-control-label" for="viewAsXml">
          <slot name="label">{{ i18n.viewAsXml }}</slot>
        </label>
      </div>
      <div class="checkbox-field form-field" v-if="viewAsXml">
        <span class="checkbox">
          <input type="checkbox" class="checkbox-input" id="rawXml" :checked="rawXml"
                  @input="(ev) => rawXml = ev.target.checked" />
          <span class="checkbox-mark"></span>
        </span>
        <label class="custom-control-label" for="rawXml">
          <slot name="label">{{ i18n.rawXml }}</slot>
        </label>
      </div>
    </div>
    <SearchResult v-if="item" :data="item" :viewAsXml="viewAsXml" :rawXml="rawXml"></SearchResult>
    <div class="card" v-if="!item && itemDataError == 'not_found'">
      <h3 class="form-error">{{ i18n.dataSetDeleted }}</h3>
    </div>

    <scroll-loader :what="range => ['xmlAlert', 'itemResults', { item: +this.position, scan: this.scan, ...range }]"
                   :key="scan" debugLog
                   class="full-width">
      <template v-slot:default="{ row: result }">
        <ScanResult :result="result" no-more></ScanResult>
      </template>
      <template v-slot:loadingBottom="{ connectionProblem }">
        <div class="search-bottom-loading-bar">
          <div class="card loading-card">
            <div class="cube-spinner">
              <div class="cube1"></div>
              <div class="cube2"></div>
            </div>
            <span class="loading-text" v-if="connectionProblem" v-html="i18n.connectionProblem"></span>
            <span class="loading-text" v-else v-html="i18n.loadingBottom"></span>
          </div>
        </div>
      </template>
    </scroll-loader>
  </page>
</template>

<script>
import i18n from 'i18n'

import SearchResult from "../xmlSearch/SearchResult.vue"
import ScrollLoader from "common/components/ScrollLoader.vue"
import ScanResult from "./ScanResult.vue"

export default {
  name: "ItemPage",
  components: { SearchResult, ScrollLoader, ScanResult },
  props: {
    scan: {
      type: String
    },
    scanner: {
      type: String
    },
    position: {
      type: String
    }
  },
  reactive: {
    scanData() {
      return ['xmlAlert', 'scan', { scan: this.scan }]
    },
    scannerData() {
      return ['xmlAlert', 'scanner', { scanner: this.scanner }]
    },
    itemData() {
      if(!this.scanData) return
      return ['xmlSearch', 'dataSetItems', {
        dataSet: this.scanData.dataSet,
        from: this.position,
        limit: 1
      }]
    }
  },
  data() {
    return {
      viewAsXml: false,
      rawXml: true
    }
  },
  computed: {
    i18n() {
      return i18n().xmlAlert
    },
    item() {
      return this.itemData && this.itemData[0]
    },

  }
}


</script>

<style scoped>

</style>