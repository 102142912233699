import { ReactiveDaoProxy, ReactiveCache, DaoCache, Path } from "@live-change/dao"
import Vue from "vue"
import '../vueInit.js'

import createDao from "./create.js"

import guid from "./guid.js"
import i18n from "i18n"

const windowId = (typeof window != "undefined") ? window.__WINDOW_ID__ || guid() : guid()

const api = new ReactiveDaoProxy()

import ReactiveDaoVue from '@live-change/dao-vue'
Vue.use(ReactiveDaoVue, {
  dao: api
})
import VueBarrier from 'common/plugins/vueBarrier.js'
Vue.use(VueBarrier, {})

if(typeof window != 'undefined') {
  api.cache = new ReactiveCache()
  api.cache.mode = 'load'
  const dao = createDao(window.__SESSION_ID__)
  console.log("DC", DaoCache)
  const daoCache = new DaoCache(dao)
  api.apiDao = daoCache
  api.cache.dao = api.apiDao
  api.cache.setCache(window.__DAO_CACHE__)

  api.setDao(api.cache)

} else {
  api.mode = 'save'
}

api.metadata = new Vue({
  reactive: {
    api: ['metadata', 'api'],
    version: ['version', 'version']
  },
  computed: {
    softwareVersion() {
      if(typeof window == 'undefined') return
      return window.__VERSION__
    },
    apiVersion() {
      return this.version
    },
    versionMismatch() {
      const software = this.softwareVersion
      const api = this.apiVersion
      if(!api) return
      if(!software) return
      return api != software
    },
    serviceDefinitions() {
      return this.api && this.api.services
    },
  },
  preFetch() {
    console.trace("METADATA PREFETCH!!!")
    return api.get(['metadata', 'api'])
  },
  watch: {
    serviceDefinitions(definitions, oldDefinitions) {
      api.generateServicesApi()
    }
  }
})

api.session = new Vue({
  reactive: {
    api: ['metadata', 'api'],
    session: ['session', 'currentSession'],
    publicSessionInfo: ['accessControl', 'myPublicSessionInfo'],
    user: ['users', 'me'],
    selfOnline: ['online', 'self']
  },
  /*data: {
    session : null
  },*/
  computed: {
    serviceDefinitions() {
      return this.api && this.api.services
    },
    loggedIn() {
      return this.api && !!this.api.client.user
    },
    roles() {
      return (this.api && this.api.client.roles) || []
    },
    isCompletionNeeded(){
      if(!this.session) return false
      if(!this.session.user) return false
      if(!this.user) return false
      if(!this.user.name) return true
      if(!this.user.phone) return true
      if(!this.user.agreement1) return true
      //if(!this.user.agreement2) return true
      return false
    }
  },
  watch: {
    api(val, oldVal) {
      const changed = !!(oldVal && (JSON.stringify(val) != JSON.stringify(oldVal)))
      if(typeof window != 'undefined' && changed) {
        console.log("API RESET => CLEAR CACHE", val, oldVal)
        api.apiDao.clear()
      }
    },
    session(s, oldVal) {
      if(!oldVal) this.initSession()
      const changed = !!(oldVal && (JSON.stringify(s) != JSON.stringify(oldVal)))
      if(changed) {
        api.setDao(api.apiDao)
        console.log("API SESSION CHANGE FROM", JSON.stringify(oldVal))
        console.log("API SESSION CHANGE TO", JSON.stringify(s))
        console.trace("API SESSION CHANGE")
      }
    },
    user(s, oldVal) {
      if(!oldVal) this.initUser()
    },
    loggedIn(loggedIn) {
      console.log("LOGGED IN =", loggedIn)
    }
  },
  reactivePreFetch() {
    return [
      { what: ['metadata', 'api'] },
      { what: ['session', 'currentSession'] },
      { what: ['users', 'me'] },
    ]
  },
  methods: {
    initSession() {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      const language = localStorage.language || Object.keys(i18n.languages)[0]
      if(typeof window != 'undefined' && !this.sessionLanguageAndTimezoneSet
          && (this.session.timezone != timezone || this.session.language != language)) {
        console.log("SET SESSION LANGUAGE", language, "AND TIMEZONE", timezone)
        api.request(['session', 'setLanguageAndTimezone'], { language, timezone }).then(res => {
          this.sessionLanguageAndTimezoneSet = true
        })
      }
    },
    initUser() {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      const language = localStorage.language || Object.keys(i18n.languages)[0]
      if(this.user && typeof window != 'undefined' && this.user.id != this.userLanguageAndTimezoneSet
          && (this.user.timezone != timezone || this.user.language != language)) {
        const userId = this.user.id
        console.log("SET USER", userId, "LANGUAGE", language, "AND TIMEZONE", timezone)
        api.request(['users','setLanguageAndTimezone'], { language, timezone }).then(res => {
          this.userLanguageAndTimezoneSet = userId
        })
      }
    }
  },
  created() {
    if(this.session) this.initSession()
    if(this.user) this.initUser()
  }
})
Vue.prototype.$session = api.session

let servicesApiDefinitions
api.generateServicesApi = () => {
  let definitions = api.metadata.api && api.metadata.api.services
  if(!definitions && typeof __DAO_CACHE__ != 'undefined') {
    for(const [daoPath, value] of __DAO_CACHE__) {
      if(daoPath == '["metadata","api"]') definitions = value.services
    }
  }
  console.log("SERVICES", definitions, "IN API", api.metadata.api)
  if(JSON.stringify(definitions) == JSON.stringify(servicesApiDefinitions)) return
  servicesApiDefinitions = definitions
  console.log("GENERATE SERVICES API!")
  let globalViews = {}
  let globalFetch = (...args) => new Path(...args)
  let globalActions = {}
  for(const serviceDefinition of definitions) {
    let views = { }
    globalViews[serviceDefinition.name] = views
    for(const viewName in serviceDefinition.views) {
      views[viewName] = (params) => [serviceDefinition.name, viewName, params]
      views[viewName].definition = serviceDefinition.views[viewName]
    }
    let fetch = { }
    globalFetch[serviceDefinition.name] = fetch
    for(const viewName in serviceDefinition.views) {
      fetch[viewName] = (params) => new Path([serviceDefinition.name, viewName, params])
      fetch[viewName].definition = serviceDefinition.views[viewName]
    }
    let actions = { }
    globalActions[serviceDefinition.name] = actions
    for(const actionName in serviceDefinition.actions) {
      actions[actionName] = (params) => api.command([serviceDefinition.name, actionName], params)
      actions[actionName].definition = serviceDefinition.actions[actionName]
    }
  }
  api.views = globalViews
  api.fetch = globalFetch
  api.actions = globalActions
  Vue.prototype.$views = globalViews
  Vue.prototype.$actions = globalActions
  Vue.prototype.$fetch = api.fetch
}

api.initialPreFetch = async (cache) => {
  console.log("INITIAL API PREFETCH")
  let preFetchPromises = []
  if(api.session.$options.preFetch) preFetchPromises.push(api.session.$options.preFetch())
  if(api.session.$options.reactivePreFetch) {
    const paths = api.session.$options.reactivePreFetch()
    preFetchPromises.push(api.get({ paths }).then(results => {
      console.log("PATHS RESULT", results)
      for(let { what, data } of results) {
        cache.set(what, data)
      }
    }))
  }
  if(api.metadata.$options.preFetch) preFetchPromises.push(api.metadata.$options.preFetch())
  await Promise.all(preFetchPromises)
  api.generateServicesApi()
}

api.visibility = new Vue({
  data: {
    hidden: false
  },
  created() {
    if(typeof window == 'undefined') return
    let hidden, visibilityChange
    if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
      hidden = "hidden"
      visibilityChange = "visibilitychange"
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden"
      visibilityChange = "msvisibilitychange"
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange"
    }
    this.hidden = document[hidden]
    document.addEventListener(visibilityChange, () => this.hidden = document[hidden], false)
  }
})

console.log("API SESSION", api.session.session)

api.command = function(method, args) {
  const _commandId = args._commandId || guid()
  console.trace("COMMAND "+_commandId+":"+JSON.stringify(method))
  return api.request(method, { ...args, _commandId })
}

import uploadFile from "./upload.js"
api.uploadFile = uploadFile
api.guid = guid
api.windowId = windowId

if(typeof window != 'undefined') {
  window.api = api
  window.Vue = Vue
}

export default api
