<template>
  <div class="search-generator-card card full-width">
    <div class="search-generator-info buttons">
      <div class="generator-title">{{ i18n.generator }}</div>
      <div class="form-field copy-area">
        <input class="input" :value="generatorXmlUrl" readonly>
      </div>
      <button type="button" class="button" @click="copyGeneratorXmlUrl">
        <img src="/static/icons/content_copy.svg">
      </button>
      <button @click="openSearch" type="button" class="button">
        <img src="/static/icons/search.svg">
      </button>
      <a type="button" class="button" :href="generatorXmlUrl"><img src="/static/icons/preview.svg"></a>
      <button type="button" class="button" @click="deleteGenerator">
        <img src="/static/icons/delete_forever.svg">
      </button>
    </div>
    <h4 v-if="urlCopied">{{ i18n.urlCopied }}</h4>
    <div v-if="showQuery"
         :class="['query-view', generator.query.length == 1 ? 'query-view-single' : 'query-view-multi']">
      <div v-for="logicBlock in generator.query"
           :class="[
               'query-view-logic-block',
               'query-view-logic-block-'+logicBlock.type]">
        <div class="query-view-logic-block-type">{{ i18n.logicBlockType[logicBlock.type] }}</div>
        <div class="query-view-and-block">
          <div v-for="orBlock in logicBlock.logic" class="query-view-or-block">
            <div v-for="filter in orBlock" class="query-view-filter">
              <div class="query-view-fields">
                <div class="query-view-field" v-for="field in filter.fields">{{ field }}</div>
              </div>
              <div class="query-view-operator">
                {{ filter.operator && (i18n.operators[filter.operator.name || filter.operator]
                  || (filter.operator.name || filter.operator))}}
              </div>
              <div class="query-view-phrase">
                {{ filter.phrase }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="search-generator-last-use" v-if="generator.lastUse">
      <div class="search-generator-last-use-label">{{ i18n.generatorLastUse }}</div>
      <time>
        {{ (currentTime-(new Date(generator.lastUse.timestamp)).getTime())/1000 | durationSeconds }} {{ i18n.ago }}
        -
        {{ new Date(generator.lastUse.timestamp) | date }}
        {{ new Date(generator.lastUse.timestamp) | hour }}
      </time>
      <div>
        {{ i18n.generationDuration }} {{ generator.lastUse.duration/1000 | durationMillis }}
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "i18n"
import api from "api"
import copyToClipboard from "copy-to-clipboard"
import currentTime from "common/components/utils/currentTime.js"

export default {
  name: "GeneratorCard",
  props: {
    generator: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    showQuery: {
      type: Boolean,
      default: false
    }
  },
  inject: ['workingZone'],
  data() {
    return {
      urlCopied: false
    }
  },
  computed: {
    i18n() {
      return i18n().xmlSearch
    },
    currentTime() {
      return currentTime.now
    },
    urlPrefix() {
      if(typeof window != 'undefined') {
        return document.location.protocol + '//' + document.location.host
      } else {
        return ''
      }
    },
    generatorXmlUrl() {
      if(!this.generator) return null
      const route = this.$router.resolve({ name:'xml:generatorXml', params: {
          generator: this.generator.to || this.generator.id
        } })
      return this.urlPrefix + route.href
    }
  },
  methods: {
    deleteGenerator() {
      this.workingZone.addPromise('delete generator',
          api.actions.xmlSearch.deleteGenerator({
            generator: this.generator.id
          })
      )
    },
    copyGeneratorXmlUrl() {
      copyToClipboard(this.generatorXmlUrl)
      this.urlCopied = true
      setTimeout(() => {
        this.urlCopied = false
      }, 3020)
    },
    openSearch() {
      this.workingZone.addPromise('search generator',
          api.actions.xmlSearch.search({
            url: this.generator.url,
            refresh: false,
            query: this.generator.query
          }).then(({ search, dataSet }) => {
            router.push({ name: 'xml:searchResults', params: { search } })
          })
      )
    }
  }
}
</script>

<style scoped>

</style>