var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", [
      _c("div", { staticClass: "footer-copyright text-center py-5" }, [
        _vm._v("\n    © 2020 Copyright:\n    "),
        _c("a", { attrs: { href: "https://www.vole.pl/" } }, [
          _vm._v("VoleApps.pl")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }