<template>
  <loading :what="userData" name="user profile data">
    <page :multiRow="true">
      <div class="page-row" v-if="userData">
        <aside>
          <div class="card">

            <div class="editable-picture-holder">
              <uploaded-picture-img width="200" height="200" :picture="userData.picture">
              </uploaded-picture-img>
              <button type="file" id="" @click="ev=>ev.preventDefault()" class="button" v-if="isMe">
                <img src="/static/icons/edit.svg"> <span>{{ i18n.updatePicture }}</span>
              </button>
              <div class="editable-picture-click-catcher" v-if="isMe && userData.picture"
                   @click="ev => { ev.preventDefault(); showPictureEditor() }"></div>
              <input class="editable-picture-click-catcher" type="file" v-if="isMe && !userData.picture"
                     v-on:change="handleFileUpload">
            </div>

            <p>{{ userData.display }}</p>

            <router-link v-if="!isMe && isLoggedIn && chatStarted" target="_blank" tag="a" class="button message-button"
                        :to="conversationLink">
              <img src="/static/icons/chat.svg">
              <span>{{ i18n.continueChat }}</span>
            </router-link>
            <button v-if="!isMe && isLoggedIn && !chatStarted" class="button message-button"
                         @click="showMessageDialog">
              <img src="/static/icons/chat.svg">
              <span>{{ i18n.message }}</span>
            </button>

          </div>
        </aside>
        <main>
          <div class="card full-width">

            <div v-if="!editingAbout">
              <h2>{{ i18n.about }}</h2>
              <div class="user-about-text" v-html="$sanitize(userData.about, {ALLOWED_TAGS: ['p']})"></div>
              <div class="buttons">
                <button type="button" class="button" role="button" @click="editAbout" v-if="isMe">
                  {{ i18n.edit }}
                </button>
              </div>
            </div>

            <div v-if="editingAbout">
              <command-form service="users" action="updateUserAbout" :initialValues="{ about: userData.about }"
                            ref="aboutForm" @done="() => editingAbout = false">
                <h2>{{ i18n.aboutYou }}</h2>
                <multi-line-text-field name="about" placeholder="..."
                                       class="user-about-text" label=""></multi-line-text-field>
                <div class="buttons">
                  <button type="submit" class="button" role="button">{{ i18n.save }}</button>
                </div>
              </command-form>
            </div>

        <!--    <div>
              <h2>{{ i18n.interests }}</h2>
              <UserTagsEditor v-if="isMe" tagsType="interest" :i18n="i18n.interestsListInput"></UserTagsEditor>
              <observe v-if="!isMe" :what="['tags', 'UserSelectedTags', { tagsType:'interest', user: slug.to } ]"
                       v-slot:default="{ value: tags }">
                <observe :what="['tags', 'TagOne', {tag: tag}]" v-slot="{ value: tag }"
                         class="tag-pill" v-for="tag in tags" :key="tag.name">
                    <span class="tag-name">{{ tag.translations[lang].name || tag.name }}</span>
                </observe>
              </observe>
            </div>

            <div>
              <h2>{{ i18n.skills }}</h2>
              <UserTagsEditor v-if="isMe" tagsType="skill" :i18n="i18n.skillsListInput"></UserTagsEditor>
              <observe :what="['tags', 'UserSelectedTags', { tagsType:'skill', user: slug.to } ]"
                       v-slot:default="{ value: tags }">
                <observe :what="['tags', 'TagOne', {tag: tag}]" v-slot="{ value: tag }"
                         class="tag-pill" v-for="tag in tags" :key="tag.name">
                  <span class="tag-name">{{ tag.translations[lang].name || tag.name }}</span>
                </observe>
              </observe>
            </div>-->

          </div>
        </main>
      </div>
    </page>
  </loading>
</template>

<script>
  import i18n from "i18n"
  import analytics from "analytics"
  import api from "@/api"
  import overlayModel from "common/components/utils/overlayModel.js"
  import PictureEditor from "common/components/inputs/PictureEditor.vue"
  import UserTagsEditor from "@/modules/tags/UserTagsEditor.vue"
  import FirstMessageModal from "@/modules/messenger/FirstMessageModal.vue"

  import { appId as facebookAppId } from "common/thirdParty/facebookApi.js"

  export default {
    name:"ProfilePage",
    metaInfo() {
      if(!this.userData) return
      const base = ENV.BASE_HREF
      const user = this.userData
      const picture = this.userData.picture
      const url = this.$router.resolve({ name: 'user:profile', params: { user: user.slug } }).href
      const description = user.about && (user.about
          .replace(/\s+/gi," ")
          .replace(/<\/p>/gi, " ")
          .replace(/<[^>]*>/gi, ""))
      return {
        title: this.userData && this.userData.display,
        meta: [
          { property: 'og:title', content: user.display },
          { property: 'og:description', content: description },
          { property: 'og:type', content: 'profile' },
          { property: 'og:image', content: base
                + (picture ? `/pictures/${picture}/crop/rect-630-630` : '/static/images/avatar.png') },
          { property: 'og:image:width', content: '630' },
          { property: 'og:image:height', content: '630' },
          { property: 'og:profile:first_name', content: user.firstName },
          { property: 'og:profile:last_name', content: user.lastName },
          { property: 'og:profile:username', content: user.display },
          { property: 'og:url', content: url },
          { property: 'fb:app_id', content: facebookAppId }
        ]
      }
    },
    props: {
      user: {
        type: String,
        require: true
      }
    },
    components: {
      UserTagsEditor
    },
    inject: ['workingZone'],
    reactive: {
      slug() {  return ['slugs', 'slugByPath', { group: "user", path: this.user }] },
      userData() { return this.slug && ['users', "publicUserData", { user: this.slug.to } ] },
      conversation() {
        return this.slug && ['messages', 'privateConversationByOtherParticipant', { user: this.slug.to }]
      },
      readHistory() {
        return this.conversation && ['readHistory', 'readHistory', { toType: 'priv', toId: this.conversation.id }]
      }
    /*  memberships() { return this.slug &&
          ["members", "userMembershipsByType", { user: this.slug.to, listType: "Project" }] }*/
    },
    reactivePreFetch(route) {
      return [
        {
          what: ['slugs', 'slugByPath', { group: "user", path: route.params.user }],
          more: [
            {
              schema: [['users', "publicUserData", { object: { user: { property: 'to' } } }]]
            },/* {
              schema: [['tags', 'UserSelectedTags', { object: { tagsType:'interest', user: { property: 'to' } } }]],
              more: [{
                schema: [["tags", "TagOne", { object: { tag: { identity: true } } } ]]
              }]
            }, {
              schema: [['tags', 'UserSelectedTags', { object: { tagsType:'skill', user: { property: 'to' } } }]],
              more: [{
                schema: [["tags", "TagOne", { object: { tag: { identity: true } } } ]]
              }]
            },*/
            {
              schema: [['messages', 'privateConversationByOtherParticipant', { object: { user: { property: 'to' } } }]],
              more: [{
                schema: [['readHistory', 'readHistory', { object: { toType: 'priv',
                    toId: { property: 'id' } } }]]
              }]
            }

          ]
        }
      ].concat(api.session.session.user ? [
        /*{
          what: ['tags', 'MySelectedTags', { tagsType: 'interest' }],
          more: [{
            schema: [["tags", "TagOne", { object: { tag: { identity: true } } } ]]
          }]
        }, {
          what: ['tags', 'MySelectedTags', { tagsType: 'skill' }],
          more: [{
            schema: [["tags", "TagOne", { object: { tag: { identity: true } } } ]]
          }]
        }*/
      ] : [])
    },
    data() {
      return {
        editingAbout: false
      }
    },
    watch: {
      userData(data) {
        if(this.isMe && (!data.about || data.about.trim() == 0)) this.editingAbout = true
      },
      slug(slug) {
        if(slug && slug.redirect) {
          analytics.redirect('user/'+slug.path, 'user/'+slug.redirect)
          this.$router.replace({ ...this.$router.route, params: { ...this.$router.params , user: slug.redirect }})
        }
      }
    },
    computed: {
      i18n() { return i18n().user.profile },
      isLoggedIn() {
        return api.session.session.user
      },
      isMe() {
        if(!api.session.session.user || !this.slug) return false
        return api.session.session.user == this.slug.to
      },
      projects() {
        if(!this.memberships) return null
        return this.memberships.map(m => m.list)
      },
      conversationLink() {
        if(!api.session.session.user || !this.slug) return false
        return { name: 'messenger:startPrivateConversation', params: { withType: 'user', withId: this.slug.to } }
      },
      chatStarted() {
        return !!(this.readHistory && (this.readHistory.last || this.readHistory.write))
      }
    },
    methods: {
      showPictureEditor(uploadedFile) {
        this.overlayStackId = overlayModel.show({
          component: PictureEditor,
          props: {
            uploadedFile,
            picture: this.userData.picture,
            purpose: `users-updatePicture-picture`
          },
          on: {
            update: (picture) => {
              this.workingZone.addPromise("update picture",
                api.command(["users", "updateUserPicture"], { picture: picture })
              )
            }
          }
        })
      },
      handleFileUpload(e) {
        const files = e.target.files || e.dataTransfer.files
        if (!files.length) return
        this.showPictureEditor(files[0])
      },
      editAbout() {
        this.editingAbout = true
      },
      showMessageDialog() {
        this.overlayStackId = overlayModel.show({
          component: FirstMessageModal,
          props: {
            userData: this.userData
          }
        })
      },
      goToMyMessages() {
        if(window.innerWidth > 800) {
          this.loadingZone.addPromise('goToMyMessages', api.dao.get(
              ["readHistory", "myReadHistories", { "lt": "ÿÿÿÿ", "limit": 1, "reverse": true }]))
              .then(myLastConversation => {
                if(myLastConversation && myLastConversation[0]) {
                  this.$router.push({
                    name: 'messenger:conversation', params: {
                      conversationType: myLastConversation[0].toType,
                      conversation: myLastConversation[0].toId
                    }
                  })
                }
              })
        } else {
          this.$router.push({
            name: 'messenger:conversations'
          })
        }
      }
    }

  }
</script>

<style lang="scss" scoped>

  .clickCatcher {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    opacity: 0;
  }

</style>
