<template>
  <div class="dialog-holder">
    <div class="card text-center">
      <div class="card-body">
        <h2>{{ i18n.title }}</h2>
        <p class="card-text">{{ i18n.text }}</p>
        <p><a :href="`mailto:${i18n.email}`">{{ i18n.email }}</a></p>
        <button class="btn btn-primary card-link" @click="$emit('close')">{{ i18n.ok }}</button>
      </div>
    </div>
  </div>
</template>

<script>
  import i18n from 'i18n'

  export default {
    name: "PremiumAlert",
    computed: {
      i18n() {
        return i18n().premium.alert
      },
    }
  }
</script>

<style scoped>

</style>