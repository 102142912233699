<template>
  <command-form :service="csp ? 'clientMessages' : 'messages'" serviceDefinitionSource="messages" :action="action"
                class="message-input-form"
                :parameters="{ ...messageParameters, messageType: 'text' }"
                :resetOnDone="!onlyOne"
                @done="$emit('sent')">
    <multi-line-text-field name="text" label="" :submit-on-enter="!(isMobile || onlyOne)" :placeholder="placeholder">
    </multi-line-text-field>
    <div class="buttons">
      <button v-if="sendButton" type="submit" class="button">{{ sendButton }}</button>
    </div>
    <button v-if="isMobile && mobileSendButton" type="submit"
            class="button mobile-send-button">{{ mobileSendButton }}</button>
  </command-form>
</template>

<script>
  export default {
    name: "MessageInput",
    props: {
      messageParameters: {
        type: Object,
        required: true
      },
      action: {
        type: String,
        default: 'postMessage'
      },
      onlyOne: {
        type: Boolean,
        default: false
      },
      csp: {
        type: Boolean,
        default: true
      },
      placeholder: {
        type: String,
        default: null
      },
      sendButton: {
        type: String
      },
      mobileSendButton: {
        type: String
      }
    },
    computed: {
      isMobile() {
        if(typeof window == 'undefined') return false
        if('ontouchstart' in document.documentElement) return true
        return false
      }
    }
  }
</script>

<style scoped>

</style>