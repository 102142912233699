module.exports = {
  title: 'Image Editor',
  upload: 'Upload picture',
  save: 'Save',

  errors: {
    uploadFailed: "File upload error.",
    tooBig: "This file is to big. Max file size is 20MiB",
    imageError: "Picture read error."
  }

}
