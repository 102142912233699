<template>
  <div class="form-field">
    <FieldTemplate v-for="(element, index) in value" :key="index"
                   :error="(!index && error) || null"
                   :errorText="errorText"
                   :label="levelLabels && levelLabels[index] || (''+(1+index))" :name="name"
                   class="subcategory" :style="{ minWidth: `${100/maxDepth}%` }">
      <observe :what="['categories', 'subcategories', { category: index ? value[index-1] : root }]"
               v-slot="{ value: categories }" :noLoadingZone="true">
        <SingleSelectInput :value="element"
                           :options="[
                               ...((deleteOption && element) ? [{}] : []),
                               ...sortCategories(categories).map(c=>c.id).filter(filter)
                           ]"
                           :placeholder="Array.isArray(placeholder) ? placeholder[index] : placeholder"
                           :text="c=>categoryText(c, categories)" @input="v=>handleSelected(index,v)">
        </SingleSelectInput>
      </observe>
    </FieldTemplate>
  </div>
</template>

<script>
  import SingleSelectInput from "common/components/inputs/SingleSelectInput.vue"
  import FieldTemplate from "common/components/fields/FieldTemplate.vue"
  import i18n from "i18n"

  export default {
    name: "CategoryField",
    inject: ['form'],
    props: {
      name: {
        type: String,
        required: true
      },
      root: {
        type: String,
        default: 'root'
      },
      levelLabels: {
        type: Array
      },
      minDepth: {
        type: Number,
        default: 0
      },
      maxDepth: {
        type: Number,
        default: Infinity
      },
      errorText: {

      },
      placeholder: {
        default: null
      },
      filter: {
        type: Function,
        default: () => true
      },
      deleteOption: {
        type: String,
        default: null
      }
    },
    components: { SingleSelectInput, FieldTemplate },
    data() {
      return {
        value: null,
        error: null
      }
    },
    computed: {
      lang() { return i18n.config.language },
      canAddMore() {
        return !this.value || this.value.length < this.maxDepth
      }
    },
    methods: {
      setValue(v) {
        if(!v || !v.length) {
          v = []
        }
        if(v.length == this.maxDepth) {
          this.value = v.slice()
        } else {
          this.value = v.slice().concat([null])
        }
      },
      categoryText(id, categories) {
        if(typeof id == 'object') return this.deleteOption
        const category = categories.find(c=>c.id==id)
        if(!category) return '\t \t '
        const translation = category.translations[this.lang]
        if(translation && translation.name) return translation.name
        return category.name
      },
      handleSelected(index, cat) {
        if(typeof cat == 'object') cat = null
        if(this.value[index] == cat) return;
        if(cat) {
          if(index < this.maxDepth-1) {
            this.value.splice(index, Infinity, cat, null)
          } else {
            this.value.splice(index, Infinity, cat)
          }
        } else {
          this.value.splice(index, Infinity, null)
        }
        this.ignoreUpdate = true
        this.form.setFieldValue(this.name, this.value)
        this.ignoreUpdate = false
      },
      sortCategories(inputCategories) {
        let categories = JSON.parse(JSON.stringify(inputCategories))
        categories.sort((a, b) => ((a || 0).order < (b || 0).order) ? -1 : (((a || 0).order > (b || 0).order) ? 1 : 0))
        return categories
      }
    },
    created() {
      this.valueObserver = (v) => {
        if(this.ignoreUpdate) return
        this.setValue(v)
      }
      this.errorObserver = (e) => {
        this.error = e
      }
      this.form.observe(this.name, this.valueObserver)
      this.form.observeError(this.name, this.errorObserver)
    },
    beforeDestroy() {
      this.form.unobserve(this.name, this.valueObserver)
      this.form.unobserveError(this.name, this.errorObserver)
    }
  }
</script>

<style scoped>

  .subcategory + .subcategory {
    padding-right: 16px;
  }
  .category-field {
    margin-right: -16px;
  }
</style>