var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "picture-editor-holder" }, [
    _c(
      "div",
      {
        staticClass: "card picture-editor-card",
        style: {
          maxWidth: Math.max(_vm.width, 350) + 50 + "px",
          maxHeight: _vm.height + 240 + "px"
        },
        attrs: { ef: "box" }
      },
      [
        _c("div", { staticClass: "card-header" }, [
          _c("h5", {
            staticClass: "title",
            domProps: { innerHTML: _vm._s(_vm.i18n.title) }
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "close-icon",
              attrs: { "data-effect": "fadeOut" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_c("img", { attrs: { src: "/static/icons/clear.svg" } })]
          )
        ]),
        _vm._v(" "),
        !_vm.error
          ? _c(
              "div",
              {
                ref: "editor",
                staticClass: "picture-editor-view",
                on: {
                  mousedown: _vm.handleEditorMouseDown,
                  mouseup: _vm.handleEditorMouseUp,
                  mouseout: _vm.handleEditorMouseOut,
                  mousemove: _vm.handleEditorMouseMove,
                  touchstart: _vm.handleEditorTouchStart,
                  touchend: _vm.handleEditorTouchEnd,
                  touchcancel: _vm.handleEditorTouchCancel,
                  touchmove: _vm.handleEditorTouchMove,
                  wheel: _vm.handleEditorWheel
                }
              },
              [
                _c("div", { staticClass: "picture-editor-view-inside" }, [
                  _c("div", { staticClass: "v-center-spacer" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "positioner-wrapper" }, [
                    _c("img", {
                      staticClass: "positioner-image",
                      attrs: {
                        src: _vm.positionerSvgUrl,
                        width: _vm.width,
                        height: ":height"
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "positioner" }, [
                      _c(
                        "div",
                        {
                          staticClass: "content-holder",
                          style: {
                            paddingTop:
                              ((_vm.height / _vm.width) * 100).toFixed(2) + "%"
                          }
                        },
                        [
                          _c(
                            "div",
                            { ref: "content", staticClass: "content" },
                            [
                              _vm.sourceImage
                                ? _c("img", {
                                    staticClass: "picture-editor-img",
                                    style: _vm.imageTransform,
                                    attrs: { src: _vm.sourceImage.url }
                                  })
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _vm.sourceImage
                  ? _c("canvas", {
                      ref: "overlayCanvas",
                      staticClass: "picture-editor-overlay"
                    })
                  : _vm._e()
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "picture-editor-card-buttons" }, [
          _vm.position
            ? _c("div", { staticClass: "picture-scale-slider-holder" }, [
                _c("input", {
                  staticClass: "picture-scale-slider",
                  attrs: {
                    type: "range",
                    min: Math.log2(_vm.minScale),
                    max: Math.log2(_vm.maxScale),
                    step: "0.001"
                  },
                  domProps: { value: Math.log2(_vm.position.scale) },
                  on: {
                    input: function(e) {
                      return _vm.updatePosition(
                        this$1.position.x,
                        this$1.position.y,
                        Math.pow(2, e.target.value)
                      )
                    }
                  }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.error
            ? _c(
                "div",
                { staticClass: "picture-editor-buttons buttons" },
                [
                  _c(
                    "FileInput",
                    {
                      staticClass: "button",
                      attrs: { accept: "image/*" },
                      on: { input: _vm.handleFile }
                    },
                    [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.i18n.upload) }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button picture-save-button",
                      attrs: { type: "button" },
                      on: { click: _vm.save }
                    },
                    [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.i18n.save) }
                      })
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.working
          ? _c("div", { staticClass: "window-overlay" }, [_vm._m(0)])
          : _vm._e(),
        _vm._v(" "),
        _vm.error
          ? _c("div", { staticClass: "window-overlay" }, [
              _c("div", { staticClass: "error", attrs: { role: "alert" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.i18n.errors[_vm.error] || _vm.error) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.downloading || _vm.processing
          ? _c("div", { staticClass: "window-overlay" }, [_vm._m(1)])
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card loading-card" }, [
      _c("div", { staticClass: "cube-spinner" }, [
        _c("div", { staticClass: "cube1" }),
        _vm._v(" "),
        _c("div", { staticClass: "cube2" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card loading-card" }, [
      _c("div", { staticClass: "cube-spinner" }, [
        _c("div", { staticClass: "cube1" }),
        _vm._v(" "),
        _c("div", { staticClass: "cube2" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }