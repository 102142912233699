var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "file-input" },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c("input", {
        ref: "file",
        staticClass: "hidden-file",
        attrs: {
          name: _vm.name,
          type: "file",
          multiple: _vm.multiple,
          accept: _vm.accept
        },
        on: { change: _vm.handleInputChange }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }