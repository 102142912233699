var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "buttons file-upload-field" }, [
    _vm.file
      ? _c("div", { staticClass: "file-name" }, [_vm._v(_vm._s(_vm.file.name))])
      : _vm._e(),
    _vm._v(" "),
    _vm.serverUpload
      ? _c("div", { staticClass: "upload-info" }, [
          _vm._v(
            "\n    " +
              _vm._s(_vm.serverUpload.progress.percentage) +
              "%\n    " +
              _vm._s(_vm._f("bytes")(_vm.serverUpload.progress.transferred)) +
              " / " +
              _vm._s(_vm._f("bytes")(_vm.serverUpload.progress.length)) +
              "\n  "
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "button",
        attrs: { type: "button" },
        on: {
          click: function($event) {
            return _vm.$emit("delete")
          }
        }
      },
      [_c("img", { attrs: { src: "/static/icons/close.svg" } })]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }