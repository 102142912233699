<<template>
  <page>
    <div class="card full-width" v-html="partial && partial.data.content">
    </div>
  </page>
</template>
<script>

  export default {
    name: "IndexPage",
    reactive: {
      partial: ['partials', 'partial', { partial: 'index' } ]
    },
    reactivePreFetch() {
      return [
        { what: ['partials', 'partial', { partial:'index' } ] }
      ]
    }
  }

</script>
<style lang="scss">

</style>
