var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form-field-bind", {
    attrs: { name: _vm.name },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(ref) {
            var value = ref.value
            var setValue = ref.setValue
            var error = ref.error
            return [
              _c("div", { staticClass: "checkbox-field" }, [
                _c("span", { staticClass: "checkbox" }, [
                  _c("input", {
                    staticClass: "checkbox-input",
                    attrs: { type: "checkbox", id: _vm.uid },
                    domProps: { checked: value },
                    on: {
                      input: function(ev) {
                        return setValue(ev.target.checked)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkbox-mark" })
                ]),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: _vm.uid }
                  },
                  [
                    _vm._t("label", function() {
                      return [_vm._v(_vm._s(_vm.label))]
                    })
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c("small", { staticClass: "field-error" }, [
                _vm._v(
                  "\n    " +
                    _vm._s(
                      (_vm.errorText && _vm.errorText[error]) ||
                        _vm.i18n[error] ||
                        error
                    ) +
                    "\n  "
                )
              ])
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }