import UrlsPage from "./UrlsPage.vue"
import UrlPage from "./UrlPage.vue"

export default function(prefix) {
  return [
    {
      path: prefix,
      name: 'urls',
      component: UrlsPage,
      props: true,
      meta: { requiresLogin: true }
    },
    {
      path: prefix+'/:url',
      name: 'urls:url',
      component: UrlPage,
      props: true,
      meta: { requiresLogin: true }
    },
/*    {
      path: prefix + '/:scanner',
      name: 'xml:alert:scanner',
      component: ScannerPage,
      props: true,
      meta: { saveForLogin: true }
    }, */

  ]
}
