var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "message",
        _vm.message.sendState,
        _vm.my ? "my-message" : "other-user-message",
        { next: _vm.isPrevConnected, prev: _vm.isNextConnected }
      ]
    },
    [
      _vm.isTimeVisible
        ? _c("time", { attrs: { datatime: _vm.message.timestamp } }, [
            _vm._v(
              _vm._s(_vm._f("hourOrDayShort")(_vm.message.timestamp, _vm.now))
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "message-row" },
        [
          !_vm.isPrevConnected && _vm.message.user
            ? _c("observe", {
                staticClass: "message-author",
                attrs: {
                  name: "userData",
                  what: ["users", "publicUserData", { user: _vm.message.user }]
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var userData = ref.value
                        return [
                          userData && userData.picture
                            ? _c("uploaded-picture-img", {
                                staticClass: "message-author-picture",
                                attrs: {
                                  title: userData.display,
                                  width: "35",
                                  height: "35",
                                  picture: userData.picture,
                                  reactive: true
                                }
                              })
                            : _c("img", {
                                staticClass: "message-author-picture",
                                attrs: {
                                  src: "/static/empty-user-photo.svg",
                                  width: "35",
                                  height: "35",
                                  title: userData.display
                                }
                              })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1654076326
                )
              })
            : !_vm.isPrevConnected
            ? _c("observe", {
                staticClass: "message-author",
                attrs: {
                  name: "publicSessionData",
                  what: [
                    "accessControl",
                    "publicSessionInfo",
                    { publicSessionInfo: _vm.message.session }
                  ]
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var sessionData = ref.value
                      return [
                        _c("img", {
                          staticClass: "message-author-picture",
                          attrs: {
                            src: "/static/empty-user-photo.svg",
                            width: "35",
                            height: "35",
                            title:
                              (sessionData && sessionData.name) ||
                              _vm.i18n.anonymous
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", {
            staticClass: "message-content",
            domProps: {
              innerHTML: _vm._s(
                _vm.$autoLinks(
                  _vm.$sanitize(_vm.message.text, { ALLOWED_TAGS: ["p"] })
                )
              )
            }
          }),
          _vm._v(" "),
          _vm.my
            ? _c(
                "div",
                { staticClass: "message-readers" },
                _vm._l(_vm.readPositions, function(readPosition) {
                  return _c(
                    "div",
                    { key: readPosition.id, staticClass: "message-reader" },
                    [
                      readPosition.user
                        ? _c("observe", {
                            attrs: {
                              name: "userData",
                              what: [
                                "users",
                                "publicUserData",
                                { user: readPosition.user }
                              ]
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var userData = ref.value
                                    return [
                                      userData && userData.picture
                                        ? _c("uploaded-picture-img", {
                                            attrs: {
                                              width: "16",
                                              height: "16",
                                              picture: userData.picture,
                                              reactive: true
                                            }
                                          })
                                        : _c("img", {
                                            attrs: {
                                              src:
                                                "/static/empty-user-photo.svg",
                                              width: "20",
                                              height: "20",
                                              title:
                                                (userData &&
                                                  userData.display) ||
                                                "Anonim"
                                            }
                                          })
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        : _c("observe", {
                            attrs: {
                              name: "sessionData",
                              what: [
                                "accessControl",
                                "publicSessionInfo",
                                { publicSessionInfo: _vm.session.id }
                              ]
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var info = ref.value
                                    return [
                                      _c("img", {
                                        attrs: {
                                          src: "/static/empty-user-photo.svg",
                                          width: "20",
                                          height: "20",
                                          title: (info && info.name) || "Anonim"
                                        }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.message.sendState == "failed"
        ? _c("div", { staticClass: "message-failed-buttons buttons" }, [
            _c(
              "button",
              {
                staticClass: "button",
                attrs: { type: "button" },
                on: { click: _vm.retry }
              },
              [_vm._v("Retry")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button",
                attrs: { type: "button" },
                on: { click: _vm.cancel }
              },
              [_vm._v("Cancel")]
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }