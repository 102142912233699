var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "object-value" },
    [
      _vm.data !== undefined
        ? _c(_vm.viewComponent(_vm.viewDefinition, _vm.viewConfig), {
            tag: "component",
            attrs: {
              data: _vm.data,
              viewDefinition: _vm.viewDefinition,
              type: _vm.type
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }