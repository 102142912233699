<template>
  <page>
    <div class="card half-width main-card">
      <div class="resetPassword-body resetPassword-email" v-if="step === 'email' && !error">
        <h4 v-html="i18n.email.title"></h4>
        <p class="text-center">{{i18n.email.message}}</p>
        <command-form key="emailPassword" service="emailPassword"
                      action="startPasswordReset" :parameters="{ lang }"
                      :ignoreError="['notFound', 'registrationNotConfirmed']"
                      @error="handleError"
                      @done="confirmSendingEmail">
          <text-field name="email" :label="i18n.email.emailLabel" class="resetPassword-email-form"
                      groupClass="register-field" :error-text="i18n.errors">
          </text-field>
          <div class="buttons">
            <button type="submit" class="button" role="button">{{i18n.email.resetPassword}}</button>
            <a @click="$router.go(-1)" href="#">{{i18n.email.goBack}}</a>
          </div>
        </command-form>
      </div>
      <div class="resetPassword-body resetPassword-error" v-if="error">
        <p class="form-error">{{ i18n.errors[error] }}</p>
        <div class="custom-form-error text-danger" v-if="error=='registrationNotConfirmed'">
          <a href="#" @click.prevent="resendConfirmationEmail">{{ i18n.resendButton }}</a>
        </div>
      </div>
      <div class="resetPassword-body resetPassword-sent" v-if="step === 'sent'">
        <div v-html="i18n.sent.title" class="text-center"></div>
        <p class="text-center">{{i18n.sent.message}}</p>
      </div>
    </div>
  </page>
</template>

<script>
  import i18n from "i18n"
  export default {
    name: "ResetPasswordPage",
    props: {
      step: {
        type: String,
        default: 'email'
      }
    },
    inject: ['loadingZone', 'workingZone'],
    data() {
      return {
        error: null,
        email: null
      }
    },
    computed: {
      lang() { return i18n.config.language },
      i18n() { return i18n().user.resetPassword }
    },
    methods: {
      confirmSendingEmail() {
        this.$router.push({
          name: this.$router.currentRoute.name,
          params: {
            step: 'sent'
          }
        })
      },
      handleError({ error, parameters }) {
        if(error == 'notFound' || error == 'registrationNotConfirmed') {
          this.email = parameters.email
          this.error = error
          return
        }
      },
      resendConfirmationEmail() {
        this.workingZone.addPromise('resendEmail',
            api.command(['emailPassword', 'resendRegisterKey'], { email: this.email, lang: i18n.config.language })
                .then(result => this.error = 'emailSent' )
        )
      }
    }
  }
</script>

<style scoped>

</style>