var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user &&
    _vm.user.loginMethods.find(function(lm) {
      return lm.type == "emailPassword"
    })
    ? _c(
        "section",
        { staticClass: "card half-width" },
        [
          _c("command-form", {
            attrs: {
              service: "emailPassword",
              action: "startEmailChange",
              parameters: { lang: _vm.lang }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "done",
                  fn: function() {
                    return [
                      _c("h4", [_vm._v(_vm._s(_vm.i18n.done.title))]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n        " + _vm._s(_vm.i18n.done.text) + "\n      "
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "default",
                  fn: function() {
                    return [
                      _c("h4", [_vm._v(_vm._s(_vm.i18n.title))]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(_vm._s(_vm.i18n.currentEmail) + " "),
                        _c("b", [
                          _vm._v(_vm._s(_vm.user && _vm.user.userData.email))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(_vm.i18n.instruction))]),
                      _vm._v(" "),
                      _c("text-field", {
                        attrs: {
                          name: "newEmail",
                          label: _vm.i18n.newEmail,
                          errorText: _vm.i18n.errors,
                          placeholder: _vm.i18n.newEmailPlaceholder
                        }
                      }),
                      _vm._v(" "),
                      _c("password-field", {
                        attrs: {
                          name: "passwordHash",
                          label: _vm.i18n.currentPassword,
                          errorText: _vm.i18n.errors,
                          placeholder: _vm.i18n.currentPasswordPlaceholder
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "buttons" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button",
                            attrs: { type: "submit", role: "button" }
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.i18n.submit))])]
                        )
                      ])
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2598957219
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }