<template>
  <div class="picture-editor-holder">
    <div class="card picture-editor-card" ef="box"
         :style="{ maxWidth: `${Math.max(width, 350)+50}px`, maxHeight:`${height+240}px` }">
      <div class="card-header">
        <h5 class="title" v-html="i18n.title"></h5>
        <span class="close-icon" data-effect="fadeOut" @click="$emit('close')">
          <img src="/static/icons/clear.svg" />
        </span>
      </div>
      <div class="picture-editor-view" v-if="!error" ref="editor"
           @mousedown="handleEditorMouseDown"
           @mouseup="handleEditorMouseUp"
           @mouseout="handleEditorMouseOut"
           @mousemove="handleEditorMouseMove"
           @touchstart="handleEditorTouchStart"
           @touchend="handleEditorTouchEnd"
           @touchcancel="handleEditorTouchCancel"
           @touchmove="handleEditorTouchMove"
           @wheel="handleEditorWheel">
        <div class="picture-editor-view-inside">
          <div class="v-center-spacer"></div>
          <span class="positioner-wrapper">
            <img class="positioner-image" :src="positionerSvgUrl" :width="width" height=":height">
            <div class="positioner">
              <div class="content-holder" :style="{ paddingTop: (height/width*100).toFixed(2)+'%' }">
                <div class="content" ref="content">
                  <img v-if="sourceImage" :src="sourceImage.url" class="picture-editor-img"
                    :style="imageTransform">
                </div>
              </div>
            </div>
          </span>
        </div>
        <canvas class="picture-editor-overlay" v-if="sourceImage" ref="overlayCanvas"></canvas>
      </div>
      <div class="picture-editor-card-buttons">
        <div class="picture-scale-slider-holder" v-if="position">
          <input type="range" :min="Math.log2(minScale)" :max="Math.log2(maxScale)" step="0.001"
                 class="picture-scale-slider"
                 :value="Math.log2(position.scale)"
                 @input="e=>updatePosition(this.position.x, this.position.y, Math.pow(2,e.target.value))">
        </div>
        <div class="picture-editor-buttons buttons" v-if="!error">
          <FileInput accept="image/*" @input="handleFile" class="button"><span v-html="i18n.upload"></span></FileInput>
          <button type="button" class="button picture-save-button" @click="save">
            <span v-html="i18n.save"></span>
          </button>
        </div>
      </div>
      <div v-if="working" class="window-overlay">
        <div class="card loading-card">
          <div class="cube-spinner">
            <div class="cube1"></div>
            <div class="cube2"></div>
          </div>
        </div>
      </div>
      <div v-if="error" class="window-overlay">
        <div class="error" role="alert">
          {{ i18n.errors[error] || error }}
        </div>
        <button type="button" class="button" @click="$emit('close')">Close</button>
      </div>
      <div class="window-overlay" v-if="downloading || processing">
        <div class="card loading-card">
          <div class="cube-spinner">
            <div class="cube1"></div>
            <div class="cube2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import i18n from "i18n"
  import api from "@/api"
  import FileInput from "./FileInput.vue"
  import { imageToCanvas, loadImageUpload, getDataURIData, hasAlpha } from "../utils/imageUtils.js"
  import imageResizer from "../utils/imageResizer.js"
  import { getElementPositionInWindow, getElementPositionInDocument, getElementPositionInElement } from "../utils/dom"

  const maxUploadSize = 10 * 1024 * 1024
  const maxUploadWidth = 2048
  const maxUploadHeight = 2048

  const maxProcessableSize = 50 * 1024 * 1025
  const maxProcessableWidth = 10000
  const maxProcessableHeight = 10000
  const maxProcessablePixels = 6000*6000

  async function sleep(ms) {
    return new Promise((resolve, reject) => setTimeout(resolve, ms))
  }

  export default {
    name: "PictureEditor",
    components: {FileInput},
    props: {
      picture: {
        type: String,
        default: null
      },
      uploadedFile: {
        type: (typeof window != "undefined") ? Blob : undefined,
        default: null
      },
      purpose: {
        type: String,
        default: "unknown"
      },
      width: {
        type: Number,
        default: 256
      },
      height: {
        type: Number,
        default: 256
      },
      type: {
        type: String,
        default: 'rect' // or 'circle'
      },
      fill: {
        type: Boolean,
        default: true
      }
    },
    reactive: {
      pictureData() {
        return this.picture && ['pictures', 'PictureOne', { picture: this.livePicture }]
      },
      serverUpload() {
        return this.clientUpload && ['uploads', 'upload', { upload: this.clientUpload.id }]
      },
      croppedServerUpload() {
        return this.croppedClientUpload && ['uploads', 'upload', { upload: this.croppedClientUpload.id }]
      },
    },
    data() {
      return {
        clientUpload: null,
        uploadedImage: null,
        uploadedInfo: null,
        format: 'png',
        lastUploadId: null,

        croppedClientUpload: null,

        livePicture: this.picture,
        crop: null,
        working: false,

        downloading: false,
        processing: false,

        error: null,

        sourceImage: null,

        position: null,

        dragStart: null
      }
    },
    computed: {
      i18n() {
        return i18n().pictureEditor
      },
      isUploaded() {
        const upload = this.serverUpload
        if (!upload) return false
        if (upload.state != 'done') return false
        if (!this.uploadedInfo) return false
        return true
      },
      isCroppedUploaded() {
        const upload = this.croppedServerUpload
        if (!upload) return false
        if (upload.state != 'done') return false
        return true
      },
      positionerSvgUrl() {
        return `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'`+
            ` width='${this.width}' height='${this.height}'` +
            ` class='svg'%3E%3Crect width='100%25' height='100%25' fill='red'/%3E%3C/svg%3E`
      },
      imageTransform() {
        const ratio = (this.width/this.height) / (this.sourceImage.width/this.sourceImage.height)
        //console.log(JSON.stringify(this.position))
        const x = - this.position.x * 50
        const y = - this.position.y * 50
        const s = this.position.scale
        //console.log("TR", x, y, s, "R", ratio)
        return {
          transform: `translate(-50%, -50%) scale(${s}) translate(${x}%, ${y}%)`,
         // 'transform-origin': `${xo}% ${yo}%`
        }
      },
      minScale() {
        if(!this.sourceImage) return 0.01
        const imageRatio = this.sourceImage.width/this.sourceImage.height
        const requiredRatio = this.width/this.height
        if(this.fill) {
          return (imageRatio > requiredRatio)
              ? (imageRatio / requiredRatio)
              : 1
        } else {
          return (imageRatio > requiredRatio)
              ? 1
              : imageRatio / requiredRatio
        }
      },
      maxScale() {
        return 5
      },
    },
    watch: {
      isCroppedUploaded(uploaded) {
        if (!uploaded) return
        if(!this.croppedClientUpload) throw new Error("race-condition - croppedClientUpload not found")
        console.log("SAVE CROPPED IMAGE", this.livePicture)
        api.command(["pictures", "cropPicture"], {
          picture: this.livePicture,
          crop: this.crop,
          uploadId: this.croppedClientUpload.id
        }).then(
          newPictureId => {
            console.log(this.livePicture, "CROP", JSON.stringify(this.crop), "RESULT:", newPictureId )
            setTimeout(() => {
              this.$emit("update", newPictureId)
              this.$emit("close")
            }, 300)
          }
        ).catch(error => this.error = error)
      },
      pictureData(data) {
        if (!this.picture) return
        this.format = data.original.extension
        if (this.format.toLowerCase() == 'jpg') this.format = "jpeg"
        if(this.pictureData && this.pictureData.crop && this.sourceImage) {
          this.setExistingCrop(this.pictureData.crop)
        }
      }
    },
    methods: {
      save() {
        this.working = true
        console.log("SAVE!")
        this.currentUploadPromise.then(() => {
          console.log("SAVING!")
          return new Promise(async (resolve, reject) => {
            const sourceImage = this.sourceImage
            const position = this.position

            const ratio = (this.width/this.height) / (this.sourceImage.width/this.sourceImage.height)

            const xMin = Math.round(((position.x - 1/position.scale) / 2 + 0.5) * sourceImage.width)
            const xMax = Math.round(((position.x + 1/position.scale) / 2 + 0.5) * sourceImage.width)
            console.log("X", xMin, xMax)
            const yMin = Math.round(((position.y - 1/position.scale/ratio) / 2 + 0.5) * sourceImage.height)
            const yMax = Math.round(((position.y + 1/position.scale/ratio) / 2 + 0.5) * sourceImage.height)
            console.log("Y", yMin, yMax)
            this.crop = {
              x: xMin,
              y: yMin,
              width: xMax - xMin,
              height: yMax - yMin
            }

            console.log("POS", JSON.stringify(this.position), "RATIO", ratio)
            console.log("CROP", JSON.stringify(this.crop))
            console.log("SRC", this.sourceImage.width, this.sourceImage.height)

            const srcXMin = Math.max(0, xMin)
            const srcYMin = Math.max(0, yMin)
            const srcXMax = Math.min(sourceImage.width, xMax)
            const srcYMax = Math.max(sourceImage.height, yMax)

            console.log("CUT", srcXMin, srcYMin, srcXMax, srcYMax)

            const canvas = imageToCanvas(sourceImage.image)
            const context = canvas.getContext('2d')
            let imageData = context.getImageData(srcXMin, srcYMin, srcXMax - srcXMin, srcYMax - srcYMin)
            console.log("IMG DATA", imageData.width, imageData.height, imageData)
            canvas.width = this.crop.width
            canvas.height = this.crop.height
            context.clearRect(0, 0, canvas.width, canvas.height)
            context.putImageData(imageData, srcXMin - xMin, srcYMin - yMin)
            console.log("RESULT", canvas)

            const blob = canvas.toBlob
                ? await new Promise((resolve, reject) => canvas.toBlob(resolve, `image/${this.format}`))
                : new Blob([getDataURIData(canvas.toDataURL(`image/${this.format}`)).buffer], { type: `image/${this.format}` })
            console.log("OUTPUT BLOB", blob)

            if (this.uploadLocalObservable) this.uploadLocalObservable.unobserve(this.uploadObserver)
            this.uploadLocalObservable = api.uploadFile(`${this.purpose}-original`, "crop." + this.format, blob)
            this.uploadObserver = (signal, ...args) => {
              console.log(`UPLOAD CROPPED ${signal} ( ${args.map(x => JSON.stringify(x)).join(', ')} )`)
              if (signal != 'set') throw new Error("unknown signal")
              this.croppedClientUpload = args[0]
              if (this.croppedClientUpload.state == 'done') {
                resolve('uploadDone')
              }
              if (this.croppedClientUpload.state == 'failed') {
                reject("uploadFailed")
              }
            }
            this.uploadLocalObservable.observe(this.uploadObserver)
          })
          //this.$emit("update", this.livePicture)
        }).catch(error => {
          this.error = error
        })
      },
      update(val) {
        this.crop = val
      },
      setExistingCrop(crop) {
        console.trace("S EX CR")
        console.log("SC IMG", this.sourceImage.width, this.sourceImage.height)
        console.log("EX CR", crop.x, crop.y, crop.width, crop.height)
        const minX = (crop.x / this.sourceImage.width) * 2 - 1
        const minY = (crop.y / this.sourceImage.height) * 2 - 1
        const sizeX = (crop.width / this.sourceImage.width) * 2
        const sizeY = (crop.height / this.sourceImage.height) * 2

        console.log('CROP SET', minX, minY, sizeX, sizeY)

        this.updatePosition(minX + sizeX / 2, minY + sizeY / 2, 2/ sizeX )

      },
      setInitialPosition() {
        if(this.pictureData && this.pictureData.crop && this.sourceImage && this.livePicture) {
          this.setExistingCrop(this.pictureData.crop)
          return
        }
        const image = this.sourceImage.image
        console.log("SET INITIAL POS", JSON.stringify(this.position))
        const imageRatio = image.width/image.height
        const requiredRatio = this.width/this.height
        const scale = (imageRatio > requiredRatio)
            ? (imageRatio / requiredRatio)
            : 1
        this.position = {
          x: 0,
          y: 0,
          scale
        }
        console.log("CROP SET ON IP", this.pictureData)
      },
      bindUploaded(blob, fileName) {
        if (typeof window == 'undefined') return
        this.format = this.uploadedImage.type.split("/")[1]
        const urlCreator = window.URL || window.webkitURL
        const fr = new FileReader()
        fr.onload = () => {
          const imageUrl = fr.result
          let image = new Image
          image.onload = () => {
            this.uploadedInfo = {
              width: image.width,
              height: image.height
            }
            const canvas = imageToCanvas(image)
            this.sourceImage = {
              url: canvas.toDataURL('image/'+this.format),
              width: image.width,
              height: image.height,
              image
            }
            this.setInitialPosition()
          }
          image.onerror = () => {
            this.error = "imageError"
          }
          image.src = imageUrl
        }
        fr.readAsDataURL(blob)
      },
      downloadAndBind(uploaded) {
        console.log("DOWNLOAD AND BIND")
        if(!uploaded) this.downloading = true
        const imageUrl =
            `${document.location.protocol}//${document.location.host}/pictures/${this.livePicture}/original`
        console.log("PICTURE URL", imageUrl)
        let image = new Image
        image.onload = () => {
          this.downloading = false
          const canvas = imageToCanvas(image)
          this.sourceImage = {
            url: canvas.toDataURL('image/'+this.format),
            width: image.width,
            height: image.height,
            image
          }
          this.setInitialPosition()
        }
        image.onerror = () => {
          this.error = "imageError"
        }
        image.src = imageUrl
      },
      handleFile(file) {
        this.livePicture = null
        this.clientUpload = null
        this.croppedClientUpload = null
        this.uploadedInfo = null

        this.uploadedImage = file
        console.log("FILE", file.name, "SIZE", file.size)
        this.preProcessFile(file).then((blob) => {
          this.bindUploaded(blob)
          this.uploadOriginalImage(blob, file.name)
        })
      },
      uploadOriginalImage(blob, fileName) {
        this.currentUploadPromise = new Promise((resolve, reject) => {
          if (this.uploadLocalObservable) this.uploadLocalObservable.unobserve(this.uploadObserver)
          this.uploadLocalObservable = api.uploadFile(`${this.purpose}-original`, fileName, blob)
          this.uploadObserver = (signal, ...args) => {
            console.log(`UPLOAD ${signal} ( ${args.map(x => JSON.stringify(x)).join(', ')} )`)
            if (signal != 'set') throw new Error("unknown signal")
            this.clientUpload = args[0]
            if (this.clientUpload.state == 'done') {
              resolve(this.uploadLocalObservable.value.id)
            }
            if (this.clientUpload.state == 'failed') {
              reject("uploadFailed")
            }
          }
          this.uploadLocalObservable.observe(this.uploadObserver)
        }).then(async (uploadId) => {
          //if(!upload) throw new Error("race-condition - server not found")
          console.log("UPLOADED", uploadId, this.lastUploadId)
          if (uploadId == this.lastUploadId) return
          this.lastUploadId = uploadId
          const name = this.uploadedImage.name
          const purpose = this.purpose
          return api.command(["pictures", "createPicture"], {
            name, purpose,
            original: { ...this.uploadedInfo, uploadId }
          }).then(created => {
            this.livePicture = created
            this.state = 'crop'
            console.log("CREATED IMAGE", created)
            setTimeout(() => {
              this.downloadAndBind(true)
            }, 50)
            return created
          }).catch(error => {
            this.error = "saveUploadedImageFailed"
            console.error("ORIGINAL UPLOAD ERROR", this.error)
          })
        }).catch(error => {
          this.error = error
        })
        console.log("STARTED ORIGINAL UPLOAD")
        this.currentUploadPromise.then((pictureId) => {
          console.log("ORIGINAL UPLOAD FINISHED", pictureId, " == ", this.livePicture)
        })
      },
      async preProcessFile(file) {
        if (file.size > maxProcessableSize) {
          this.error = "tooBig"
          return
        }

        this.processing = true
        await sleep(10)
        const img = await loadImageUpload(file)
        this.processing = false

        if (img.width > maxProcessableWidth
            || img.height > maxProcessableHeight
            || (img.width * img.height) > maxProcessablePixels) {
          this.error = "tooBig"
          return
        }

        const processingNeeded =
            img.width > maxUploadWidth
            || img.height > maxUploadHeight
            || img.orientation

        if(!processingNeeded) return Promise.resolve(file)

        this.processing = true
        await sleep(10)

        let canvas = imageToCanvas(img.image)
        //resizeCanvas(canvas, targetWidth, targetHeight)

        if(img.width > maxUploadWidth || img.height > maxUploadHeight ) { /// RESIZING NEEDED

          const maxRatio = maxUploadWidth / maxUploadHeight
          const inputRatio = img.width / img.height

          let targetWidth, targetHeight
          if(inputRatio > maxRatio) { /// scale to max width
            targetWidth = maxUploadWidth
            targetHeight = Math.round(maxUploadWidth / inputRatio)
          } else { /// scale to max height
            targetWidth = Math.round(maxUploadHeight * inputRatio)
            targetHeight = maxUploadHeight
          }

          console.log("IMAGE ORIENTATION", img.orientation)
          console.log(`RESIZING ${img.width}x${img.height} => ${targetWidth}x${targetHeight}`)

          let destCanvas = document.createElement('canvas')
          if(img.orientation  > 4) { // Swap dimmensions
            destCanvas.width = targetHeight
            destCanvas.height = targetWidth
          } else {
            destCanvas.width = targetWidth
            destCanvas.height = targetHeight
          }

          await imageResizer.resize(canvas, destCanvas, {
            unsharpAmount: 80,
            unsharpRadius: 0.6,
            unsharpThreshold: 2,
            alpha: hasAlpha(canvas)
          })
          canvas = destCanvas
        }

        //if(img.orientation) cancelOrientation(canvas, img.orientation)

        const blob = canvas.toBlob
            ? await new Promise((resolve, reject) => canvas.toBlob(resolve, file.type))
            : new Blob([getDataURIData(canvas.toDataURL(file.type)).buffer], { type: file.type })
        console.log("OUTPUT BLOB", blob)

        this.processing = false

        return blob
      },
      updatePosition(x, y, scale) {
        console.log("UPDATE POSITION", x, y, scale)
        if(scale > this.maxScale) scale = this.maxScale
        if(scale < this.minScale) scale = this.minScale
        const ratio = (this.width/this.height) / (this.sourceImage.width/this.sourceImage.height)
        let xMin, xMax, yMin, yMax
        if(this.fill) {
          xMin = -1 + (1 / scale)
          xMax = 1 - (1 / scale)
          yMin = -1 + (1 / scale / ratio)
          yMax = 1 - (1 / scale / ratio)
        } else {
          xMin = - 1
          xMax = 1
          yMin = - 1
          yMax = 1
        }
        if(x < xMin) x = xMin
        if(x > xMax) x = xMax
        if(y < yMin) y = yMin
        if(y > yMax) y = yMax
        // TODO: limits
        this.position = { x, y, scale }
        console.log(JSON.stringify(this.position))
      },
      preProcessTouch(ev, id) {
        const contentPosition = getElementPositionInWindow(this.$refs.content)
        const contentSize = { x: this.$refs.content.clientWidth, y: this.$refs.content.clientHeight }
        const destX = (ev.clientX - contentPosition.x ) / contentSize.x * 2 - 1
        const destY = (ev.clientY - contentPosition.y ) / contentSize.y * 2 - 1
        const ratio = (this.width/this.height) / (this.sourceImage.width/this.sourceImage.height)
        const srcX = destX / this.position.scale
        const srcY = destY / this.position.scale / ratio
        //console.log(destX, destY, "R", ratio, "S", srcX, srcY)
        return {
          id,
          x: srcX,
          y: srcY,
          dx: destX,
          dy: destY
        }
      },
      updateTouches(newTouches) {
        //console.log("UPDATE TOUCHES", JSON.stringify(newTouches))
        if(!this.sourceImage) return;
        const ratio = (this.width/this.height) / (this.sourceImage.width/this.sourceImage.height)
        const newCenter = newTouches.reduce(
            (a, b) => ({
              x: a.x + b.x / newTouches.length,
              y: a.y + b.y / newTouches.length,
              dx: a.dx + b.dx / newTouches.length,
              dy: a.dy + b.dy / newTouches.length
            }), { x: 0, y: 0, dx:0, dy:0 })
        const newSize = newTouches.length > 1
            ? newTouches.map(t => {
                let x = t.dx - newCenter.dx, y = (t.dy - newCenter.dy)*ratio
                return Math.sqrt(x*x+y*y)
              }).reduce((a,b) => a + b / newTouches.length, 0)
            : 1
        if(newTouches.length == (this.dragStart && this.dragStart.touchCount || 0)) {
          if(!newTouches.length) return
          //console.log("newSize", newSize, "size", this.dragStart.size, "scale", this.dragStart.size * newSize)
          this.updatePosition(
              this.dragStart.x - newCenter.x,
              this.dragStart.y - newCenter.y,
              this.dragStart.size * newSize
          )
        } else {
          if(newTouches.length) {
            this.dragStart = {
              x: this.position.x + newCenter.x,
              y: this.position.y + newCenter.y,
              size: this.position.scale / newSize,
              touchCount: newTouches.length
            }
          } else {
            this.dragStart = null
          }
        }
      },
      handleEditorMouseDown(ev) {
        ev.preventDefault()
        ev.stopPropagation()
        if(this.$refs.editor && this.$refs.content)
          this.updateTouches([ this.preProcessTouch(ev, 'mouse') ])
      },
      handleEditorMouseUp(ev) {
        ev.preventDefault()
        ev.stopPropagation()
        this.updateTouches([ ])
      },
      handleEditorMouseOut(ev) {
        ev.preventDefault()
        ev.stopPropagation()
        this.updateTouches([ ])
      },
      handleEditorMouseMove(ev) {
        ev.preventDefault()
        ev.stopPropagation()
        if(this.$refs.editor && this.$refs.content && this.dragStart)
          this.updateTouches([ this.preProcessTouch(ev, 'mouse') ])
      },
      handleEditorTouchStart(ev) {
        ev.preventDefault()
        ev.stopPropagation()
        if(this.$refs.editor && this.$refs.content)
          this.updateTouches(Array.prototype.slice.call(ev.targetTouches)
              .map(t => this.preProcessTouch(t, t.identifier)))
      },
      handleEditorTouchEnd(ev) {
        ev.preventDefault()
        ev.stopPropagation()
        if(this.$refs.editor && this.$refs.content)
          this.updateTouches(Array.prototype.slice.call(ev.targetTouches)
              .map(t => this.preProcessTouch(t, t.identifier)))
      },
      handleEditorTouchCancel(ev) {
        ev.preventDefault()
        ev.stopPropagation()
        if(this.$refs.editor && this.$refs.content)
          this.updateTouches(Array.prototype.slice.call(ev.targetTouches)
              .map(t => this.preProcessTouch(t, t.identifier)))
      },
      handleEditorTouchMove(ev) {
        ev.preventDefault()
        ev.stopPropagation()
        if(this.$refs.editor && this.$refs.content)
          this.updateTouches(Array.prototype.slice.call(ev.targetTouches)
              .map(t => this.preProcessTouch(t, t.identifier)))
      },
      handleEditorWheel(ev) {
        ev.preventDefault()
        ev.stopPropagation()
        const rate = 0.2
        if(ev.deltaY > 0) {
          this.updatePosition(this.position.x, this.position.y, this.position.scale / (1 + rate))
        } else if(ev.deltaY < 0) {
          this.updatePosition(this.position.x, this.position.y, this.position.scale * (1 + rate))
        }
      }
    },
    created() {
      this.currentUploadPromise = Promise.resolve(null)
    },
    mounted() {
      if (this.uploadedFile) {
        const file = this.uploadedFile
        this.uploadedImage = this.uploadedFile
        this.clientUpload = null
        console.log("FILE", file.name, "SIZE", file.size)

        this.preProcessFile(file).then((blob) => {
          this.bindUploaded(blob, file.name)
          this.uploadOriginalImage(blob, file.name)
        })
      } else if (this.picture) {
        this.livePicture = this.picture
        this.downloadAndBind(false)
      }
      this.canvasUpdateCallback = () => {
        const editor = this.$refs.editor
        const content = this.$refs.content
        const canvas = this.$refs.overlayCanvas
        const pixelRatio = window.devicePixelRatio || 1
        let repaint = false
        if(content && editor && canvas) { // Repaint check
          if(this.repaintOverlay) repaint = true
          if((canvas.width != Math.floor(canvas.clientWidth * pixelRatio))
              || (canvas.height != Math.floor(canvas.clientHeight * pixelRatio))) {
            canvas.width = Math.floor(canvas.clientWidth * pixelRatio)
            canvas.height = Math.floor(canvas.clientHeight * pixelRatio)
            repaint = true
          }
        }
        if(repaint) {
          const position = getElementPositionInElement(content, editor)
          const size = { x: content.clientWidth, y: content.clientHeight }
          const context = canvas.getContext('2d')
          context.clearRect(0, 0, canvas.width, canvas.height)
          context.fillStyle = 'rgba(0, 0, 0, 0.5)'
          context.fillRect(0, 0, canvas.width, canvas.height)
          context.strokeStyle = "white"
          context.lineWidth = 1.5 * pixelRatio;
          if(this.type == 'circle') {
            context.save()
            context.globalCompositeOperation = 'destination-out'
            context.fillStyle = '#000'
            context.beginPath()
            context.ellipse(
                (position.x + size.x/2)*pixelRatio,
                (position.y + size.y/2)*pixelRatio,
                (size.x/2)*pixelRatio,
                (size.y/2)*pixelRatio,
                0, 0, 2*Math.PI
            )
            context.fill()
            context.restore()
            context.beginPath()
            context.ellipse(
                (position.x + size.x/2)*pixelRatio,
                (position.y + size.y/2)*pixelRatio,
                (size.x/2)*pixelRatio,
                (size.y/2)*pixelRatio,
                0, 0, 2*Math.PI
            )
            context.stroke()
          } else {
            context.clearRect(position.x * pixelRatio, position.y * pixelRatio,
                size.x * pixelRatio, size.y * pixelRatio)
            context.strokeRect(position.x * pixelRatio, position.y * pixelRatio,
                size.x * pixelRatio, size.y * pixelRatio)
          }
          this.repaintOverlay = false
        }
        if(this.canvasUpdateCallback) setTimeout(this.canvasUpdateCallback, 50)
      }
      this.repaintOverlay = true
      this.canvasUpdateCallback()
    },
    beforeDestroy() {
      this.canvasUpdateCallback = null
    }
  }
</script>

<style lang="scss" scoped>


</style>
