<template>
  <div class="form-field">

    <command-form service="smsConfirm" action="sendSmsConfirmationCode"
                  :initialValues="{ phone }" :parameters="{ lang }"
                  @done="handleSent" :keepOnDone="true"
                  key="phone" formTag="div"
                  class="form-group">

      <form-field-bind name="phone" v-slot="{ value, setValue, error }">
        <FieldTemplate :errorText="errorText" :error="error" :label="label" name="phone">
          <PhoneInput class="phoneNumber" :disabled="sent" :countryNames="countryNames"
                      :value="value" @input="setValue" :invalid="!!error">
          </PhoneInput>
          <small v-if="comment">{{ comment }}</small>
        </FieldTemplate>
      </form-field-bind>

      <div class="buttons">
        <submit-button v-if="!sent">
          <span>{{ confirmTexts.confirmButton }}</span>
        </submit-button>
      </div>

    </command-form>

    <div v-if="sent" class="form-group">
      <text-field :name="confirmName" :label="confirmTexts.phoneConfirm"></text-field>
    </div>

    <div v-if="sent && waitTime > 0" class="form-info">
      <p>{{ confirmTexts.resendWaitPart1 }} {{ waitTimeFormatted }} {{ confirmTexts.resendWaitPart2 }}</p>
    </div>

    <div v-if="sent && waitTime < 0" class="form-info">
      <p>
        <a href="#" @click.prevent="resendCode">{{ confirmTexts.resendButton }}</a>
        |
        <a href="#" @click.prevent="editPhone">{{ confirmTexts.editPhone }}</a>
      </p>
    </div>
  </div>
</template>

<script>
  import FieldTemplate from "./FieldTemplate.vue"
  import PhoneInput from "common/components/inputs/PhoneInput.vue"
  import i18n from "i18n"
  import currentTime from "common/components/utils/currentTime.js"

  export default {
    name: "PhoneFieldWithConfirm",
    inject: ['form', 'workingZone'],
    components: { FieldTemplate, PhoneInput },
    props: {
      name: {
        type: String,
        required: true
      },
      label: {
        type: String
      },
      comment: {
        type: String
      },
      confirmName: {
        type: String,
        required: true
      },
      confirmTexts: {
        type: Object,
        default: {}
      },
      errorText: {
        type: Object
      },
      countryNames: {
        type: Object
      }
    },
    data() {
      return {
        phone: null,
        sent: false,
        sentTime: 0
      }
    },
    computed: {
      lang() { return i18n.config.language },
      currentTime() {
        return currentTime.now
      },
      waitTime() {
        return this.sentTime + 60*1000 - this.currentTime
      },
      waitTimeFormatted() {
        const seconds = (this.waitTime/1000)|0
        return `${seconds}s`
      }
    },
    created() {
      this.phoneValueObserver = (v) => {
        this.phone = v
        console.log("PHONE", v)
      }
      this.form.observe(this.name, this.phoneValueObserver)
    },
    beforeDestroy() {
      this.form.unobserve(this.name, this.phoneValueObserver)
    },
    methods: {
      handleSent({ result, parameters }) {
        this.form.setFieldValue(this.name, parameters.phone)
        this.sent = true
        this.sentTime = Date.now()
      },
      resendCode() {
        this.workingZone.addPromise('resendSms',
            api.command(['smsConfirm', 'sendSmsConfirmationCode'],
                { phone: this.phone, lang: this.lang })
                .then(result => this.sentTime = Date.now() )
        )
      },
      editPhone() {
        this.form.setFieldValue(this.name, "")
        this.sent = false
      }
    }
  }

</script>

<style scoped>

</style>