var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    { tag: "component" },
    [
      _vm.state == "ready"
        ? _vm._t("default", null, null, { value: _vm.value })
        : _vm._e(),
      _vm._v(" "),
      _vm.state == "error"
        ? _vm._t("error", function() {
            return [
              _c(
                "div",
                { staticClass: "alert alert-danger", attrs: { role: "alert" } },
                [_vm._v("error")]
              )
            ]
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.state == "loading" ? _vm._t("loading") : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }