var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-results-groups full-width" },
    _vm._l(_vm.exclusionsGroups, function(group) {
      return _c("div", { staticClass: "search-results-group" }, [
        _c(
          "div",
          { staticClass: "search-results-group-title card main-card" },
          [
            _c(
              "div",
              {
                staticClass: "title",
                on: {
                  click: function() {
                    return _vm.toggleGroup(group)
                  }
                }
              },
              [_c("h3", [_vm._v(_vm._s(group.word))])]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "right-buttons",
                on: {
                  click: function($event) {
                    return _vm.copyGroupWord(group.word)
                  }
                }
              },
              [
                _vm.groupWordCopied == group.word
                  ? _c("div", { staticClass: "copy-info" }, [
                      _vm._v(_vm._s(_vm.i18n.wordCopied))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._m(0, true)
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "right-buttons",
                on: {
                  click: function() {
                    return _vm.toggleGroup(group)
                  }
                }
              },
              [
                _c("span", [_vm._v(_vm._s(group.count))]),
                _vm._v(" "),
                _vm.isExpanded(group)
                  ? _c("img", {
                      attrs: { src: "/static/icons/expand_less.svg" }
                    })
                  : _c("img", {
                      attrs: { src: "/static/icons/expand_more.svg" }
                    })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _vm.isExpanded(group)
          ? _c(
              "div",
              { staticClass: "search-results full-width" },
              [
                _vm.exclusions
                  ? _c("scroll-loader", {
                      key: _vm.exclusions,
                      attrs: {
                        what: _vm.resultsGroupDaoPath(group),
                        noDebugLog: ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var result = ref.row
                              return [
                                _c("ExclusionsResult", {
                                  key: result.id,
                                  attrs: {
                                    data: result,
                                    viewAsXml: _vm.viewAsXml,
                                    rawXml: _vm.rawXml
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "loadingBottom",
                            fn: function(ref) {
                              var connectionProblem = ref.connectionProblem
                              return [
                                _c(
                                  "div",
                                  { staticClass: "search-bottom-loading-bar" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "card loading-card" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "cube-spinner" },
                                          [
                                            _c("div", { staticClass: "cube1" }),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "cube2" })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        connectionProblem
                                          ? _c("span", {
                                              staticClass: "loading-text",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.i18n.connectionProblem
                                                )
                                              }
                                            })
                                          : _c("span", {
                                              staticClass: "loading-text",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.i18n.loadingBottom
                                                )
                                              }
                                            })
                                      ]
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "button", attrs: { type: "button" } }, [
      _c("img", { attrs: { src: "/static/icons/content_copy.svg" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }