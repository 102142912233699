<template>
  <form-field-bind :tag="'div'" notField class="fields-row" :name="name" v-slot="{ value }">
    <multi-select-field class="fields-field" :name="name+'.fields'" :label="i18n.fields"
                        :options="searchFields" :all-value="['all']" :disabled="disabled"
                        :text="field => fieldText(field)"
                        :pickerText="field => fieldPickerText(field)"
                        inputClass="select-input-long">
    </multi-select-field>
    <single-select-field class="operator-field" :name="name+'.operator'" :label="i18n.operator"
                         :options="operators" :disabled="disabled"
                         :text="op => operatorText(op)"
                         :more="op => op.more"
                         :internal-value="op => op.name" >
    </single-select-field>
    <text-field class="phrase-field" :name="name+'.phrase'" :label="i18n.phrase"
                :disabled="disabled || parameterlessOperators.includes(value.operator)">
    </text-field>

    <div class="buttons">
      <button class="button" type="button" v-if="!onlyOne" @click="$emit('delete')">
        <img src="/static/icons/delete_forever.svg">
      </button>
      <button class="button" type="button" @click="$emit('or')" :disabled="disabled">OR</button>
      <button class="button" type="button" v-if="last" @click="$emit('and')" :disabled="disabled">AND</button>
      <button class="button" type="button" v-if="lastAnd" @click="$emit('more')" :disabled="disabled">+</button>
    </div>
  </form-field-bind>
</template>

<script>
  import searchFields from "./searchFields.js"
  import MultiSelectField from "common/components/fields/MultiSelectField.vue"
  import i18n from "i18n"
  import SingleSelectInput from "common/components/inputs/SingleSelectInput.vue"

  export default {
    name: "SingleFilter",
    components: { SingleSelectInput, MultiSelectField },
    inject: ['form'],
    props: {
      dataSet: {
        type: Object
      },
      disabled: {
        type: Boolean,
        default: false
      },
      name: {
        type: String,
        default: "query"
      },
      last: {
        type: Boolean,
        default: false
      },
      lastAnd: {
        type: Boolean,
        default: false
      },
      onlyOne: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      operators() {
        return this.form.getFieldDefinition(this.name + '.operator').menu
      },
      numericOperators() {
        return this.form.getFieldDefinition(this.name + '.operator').numericOptions
      },
      parameterlessOperators() {
        return this.form.getFieldDefinition(this.name + '.operator').parameterlessOptions
      },
      searchFields() {
        if(this.dataSet && this.dataSet.fields) {
          let sortedFields = this.dataSet.fields.slice()
          /// SHUFFLE TEST:
          /*sortedFields = sortedFields.map(value => ({ value, sort: Math.random() }))
              .sort((a, b) => a.sort - b.sort)
              .map(({ value }) => value)*/
          //console.log("INITIAL", sortedFields)
          function doSort() {
            let changed = false
            for(let i = 0; i < sortedFields.length ; i++) {
              const field = sortedFields[i]
              if(Array.isArray(field) && field.length > 1) {
                //console.log("SORT", i, field)
                const fieldParent = field.slice(0, -1)
                const isGood = (i > 0 && JSON.stringify(sortedFields[i - 1].slice(0, fieldParent.length)) == JSON.stringify(fieldParent))
                //console.log("IS GOOD", isGood)
                if(!isGood) {
                  const index = sortedFields.findIndex(f => JSON.stringify(f) == JSON.stringify(fieldParent))
                  //console.log("MOVE FROM", i, "TO", index)
                  changed = true
                  if(index >= 0) {
                    if(index + 1 > i) {
                      sortedFields.splice(index + 1, 0, field)
                      sortedFields.splice(i, 1)
                    } else if(index + 1 < i) {
                      sortedFields.splice(i, 1)
                      sortedFields.splice(index + 1, 0, field)
                    } else {
                      throw new Error("Field is already in place")
                    }
                  }
                  // console.log("AFTER CHANGE", sortedFields)
                }
              }
            }
            return changed
          }
          while(doSort()) {}
          return sortedFields
        }
        return searchFields
      },
      i18n() {
        return i18n().xmlSearch
      }
    },
    methods: {
      operatorText(op) {
        if(typeof op == 'string') return this.i18n.operators[op] || op
        return this.i18n.operators[op.name] || op.name
      },
      fieldPickerText(field) {
        console.log("FD", field)
        if(typeof field == 'string') return field
        if(field.length == 1) return field[0]
        let text = ''
        for(let i = 0; i < field.length - 1; i++) {
          text += '\u00A0\u00A0'
        }
        text += '⮡\u00A0'
        text += field[field.length - 1]
        return text
      },
      fieldText(field) {
        if(typeof field == 'string') return field
        return field.join('\u00A0→\u00A0')
      }
    },
    created() {
      this.valueValidator = async () => {
        const operator = this.form.getFieldValue(this.name + '.operator')
        const value = this.form.getFieldValue(this.name + '.phrase')
        console.log("VALIDATE", operator, value)
        if(this.numericOperators.includes(operator)) {
          if(value === '' || !Number.isInteger(+value)) {
            return 'notNumber'
          }
        }
      }
      this.form.addValidator(this.name + '.phrase', this.valueValidator)
    },
    beforeDestroy() {
      this.form.removeValidator(this.name + '.phrase', this.valueValidator)
    }
  }
</script>


<style scoped>


</style>