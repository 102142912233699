var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-results full-width" },
    [
      _vm.searchEntity && _vm.searchEntity.state == "error"
        ? _c("div", { staticClass: "card search-result-error" }, [
            _c("h2", [_vm._v("Error!")]),
            _vm._v(" "),
            _c("pre", [_vm._v(_vm._s(_vm.searchEntity.error))])
          ])
        : _vm.search
        ? _c("scroll-loader", {
            key: _vm.search,
            attrs: { what: _vm.resultsDaoPath, noDebugLog: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var result = ref.row
                  return [
                    _c("SearchResult", {
                      key: result.id,
                      attrs: {
                        data: result,
                        viewAsXml: _vm.viewAsXml,
                        rawXml: _vm.rawXml
                      }
                    })
                  ]
                }
              },
              {
                key: "loadingBottom",
                fn: function(ref) {
                  var connectionProblem = ref.connectionProblem
                  return [
                    _c("div", { staticClass: "search-bottom-loading-bar" }, [
                      _c("div", { staticClass: "card loading-card" }, [
                        _c("div", { staticClass: "cube-spinner" }, [
                          _c("div", { staticClass: "cube1" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "cube2" })
                        ]),
                        _vm._v(" "),
                        connectionProblem
                          ? _c("span", {
                              staticClass: "loading-text",
                              domProps: {
                                innerHTML: _vm._s(_vm.i18n.connectionProblem)
                              }
                            })
                          : _c("span", {
                              staticClass: "loading-text",
                              domProps: {
                                innerHTML: _vm._s(_vm.i18n.loadingBottom)
                              }
                            })
                      ])
                    ])
                  ]
                }
              }
            ])
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }