var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-picker" },
    [
      _c(
        "overlay-anchor",
        {
          attrs: { anchor: _vm.anchor, directions: _vm.directions },
          on: { positioned: _vm.updatePosition }
        },
        [
          _c(
            "div",
            {
              staticClass: "select-picker-card",
              style: Object.assign({}, _vm.maxDimensions),
              attrs: { ef: "box" }
            },
            [
              _c(
                "div",
                { staticClass: "options" },
                [
                  _c("div", { staticClass: "select" }, [
                    _c("span", { staticClass: "checkbox" }, [
                      _c("input", {
                        staticClass: "checkbox-input",
                        attrs: { type: "checkbox" },
                        domProps: { checked: _vm.isAllSelected },
                        on: {
                          input: function(ev) {
                            return _vm.setAll(ev.target.checked)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "checkbox-mark" })
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "selectName",
                        on: {
                          click: function($event) {
                            _vm.setValue(_vm.options.slice())
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.allText) +
                            "\n          "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.options, function(option) {
                    return _c("div", { staticClass: "select" }, [
                      _c("span", { staticClass: "checkbox" }, [
                        _c("input", {
                          staticClass: "checkbox-input",
                          attrs: { type: "checkbox" },
                          domProps: { checked: _vm.isSelected(option) },
                          on: {
                            input: function(ev) {
                              return _vm.setSelected(option, ev.target.checked)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkbox-mark" })
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "selectName",
                          on: {
                            click: function($event) {
                              return _vm.setValue([option])
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.text(option)) +
                              "\n          "
                          )
                        ]
                      )
                    ])
                  })
                ],
                2
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }