var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-holder" }, [
    _c("div", { staticClass: "card text-center" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("h2", [_vm._v(_vm._s(_vm.i18n.title))]),
        _vm._v(" "),
        _c("p", { staticClass: "card-text" }, [_vm._v(_vm._s(_vm.i18n.text))]),
        _vm._v(" "),
        _c("p", [
          _c("a", { attrs: { href: "mailto:" + _vm.i18n.email } }, [
            _vm._v(_vm._s(_vm.i18n.email))
          ])
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary card-link",
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          },
          [_vm._v(_vm._s(_vm.i18n.ok))]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }