<template>
  <div class="country-input select-input" ref="country" @click="showCountryMenu"
       :class="{
         'is-invalid': invalid || (required && empty) ,
         'is-dirty' : !empty
       }">
    <span :class="'select-input-value '+inputClass">
      <span v-if="!empty" class="countryFlag cf-32" :class="'cf-'+(liveValue.toLowerCase())"></span>
      <span class="country-label">{{ name }}</span>
    </span>
    <span class="select-input-more"><img src="/static/icons/arrow_drop_down.svg" /></span>
  </div>
</template>

<script>
  import api from "api"
  import countries from "@/../../data/countries"
  import overlayModel from "../utils/overlayModel.js"
  import CountryPicker from "./CountryPicker.vue"

  export default {
    name: 'CountryInput',
    props: {
      value: {
      },
      required: {
        type: Boolean,
        required: true
      },
      invalid: {
        type: Boolean
      },
      inputClass: {
        type: String
      },
      countryNames: {
        type: Object
      }
    },
    data () {
      return {
        liveValue: this.value,
        overlayStackId: null,
        lastCloseTime: 0
      }
    },
    reactive: {
      geoIpIsoCode: ["geo", "country"]
    },
    computed: {
      empty() {
        if(!this.liveValue) return true
        if(this.liveValue.trim().length == 0) return true
        return false
      },
      name() {
        if(this.empty) return ""
        if(this.countryNames && this.countryNames[this.liveValue.toLowerCase()])
          return this.countryNames[this.liveValue.toLowerCase()]
        let country = countries.filter(c => c.code == this.liveValue)[0]
        if(!country) return "Unknown"
        return country.name
      }
    },
    watch: {
      geoIpIsoCode(iso) {
        if(iso == 'unknown') {
          let langCountries = navigator.languages.map(l=>l.split('-')).reduce((a,b)=>a.concat(b))
              .map(l => countries.find(c=>c.code==l.toUpperCase())).filter(c=>!!c)
          if(langCountries.length > 0) {
            iso = langCountries[0].code
          } else {
            iso = null
          }
        }
        if(!this.liveValue) {
          this.liveValue = iso
          let country = countries.find(country=> country.code == iso)
          this.liveValue = country && country.code
        }
      },
      liveValue(value) {
        this.$emit('input', value)
      }
    },
    updated () {
    },
    created() {
    },
    mounted() {
    },
    methods: {
      showCountryMenu() {
        if(this.overlayStackId === null &&  Date.now() - this.lastCloseTime > 230) {
          this.overlayStackId = overlayModel.show({
            component: CountryPicker,
            props: {
              anchor: overlayModel.elementAnchor(this.$refs.country),
              countryNames: this.countryNames
            },
            on: {
              selected: (code) => {
                console.log("SELECTED COUNTRY", code)
                this.liveValue = code
              },
              close: () => {
                this.overlayStackId = null
                this.lastCloseTime = Date.now()
              }
            }
          })
        } else {
          overlayModel.close(this.overlayStackId)
          this.overlayStackId = null
          this.lastCloseTime = Date.now()
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .countryFlag {
    align-self: center;
    width: 35px;
  }
  .overlayAnchor {
    display: block !important;
  }

  .country-input .more {
    position: absolute;
    right: 23px;
    top: 8px;
  }

  .value {
    display: flex;
  }

  .country-label {
    align-self: center;
    margin-left: 5px;
  }


  .title {
    margin-bottom: 10px;
  }
</style>
