<template>
  <div class="textWrapper">
    <div class="">
      <h2 class="">Google Sign-In</h2>
    </div>
    <div class="loading">
      <p>Signing in...</p>
    </div>
  </div>
</template>

<script>

  export default {
    name: "GoogleSignInReturn",
    inject: [ 'workingZone'],
    mounted() {
      const id_token = decodeURIComponent(
          document.location.hash.slice(1).split('&').map(p=>p.split('=')).find(a=>a[0]=='id_token')[1]
      )
      this.workingZone.addPromise('Google login', (async ()=> {
        await api.command(['googleLogin', 'registerOrLogin'], {
          accessToken: id_token,
          userData: localStorage.gUserData && JSON.parse(localStorage.gUserData)
        })
        delete localStorage.gUserData
        this.afterLoginTask = this.workingZone.started({ name: 'waiting for login' })
        return 'ok'
      })())
    },
    beforeDestroy() {
      this.finished = true
      if(this.afterLoginTask) this.workingZone.finished(this.afterLoginTask)
    }
  }

</script>

<style scoped>

</style>