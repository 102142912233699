<<template>
  <page>
    <div class="card half-width main-card">
      <h2>{{ i18n.title }}</h2>
      <command-form key="createRoom" service="room" action="createRoom"
                    @done="handleRoomCreated"
                    :parameters="{ publicUserAccessRole: 'speaker', publicGuestAccessRole: 'speaker' }">
        <text-field name="name" :label="i18n.name"></text-field>
        <div class="buttons">
          <button type="submit" class="button">{{ i18n.createButton }}</button>
        </div>
      </command-form>
    </div>
  </page>
</template>
<script>

  import i18n from "../../../../i18n"

  export default {
    name: "CreateRoom",
    computed: {
      lang() { return i18n.config.language },
      i18n() { return i18n().videoChat.createRoom },
    },
    methods: {
      handleRoomCreated({ result: { room, slug }, parameters }) {
        this.$router.push({
          name: 'video-chat:room', params: { room: slug }
        })
      }
    }

  }

</script>
<style lang="scss">

</style>
