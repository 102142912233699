import { getElementPositionInDocument } from "common/components/utils/dom.js"

const autoScrollHash = {
  mounted() {
    if(typeof window == 'undefined') return
    let thresholds = []
    this.autoScrollHash_onResize = () => {
      let nodes = Array.prototype.slice.call(document.querySelectorAll('.auto-scroll-hash'))
      thresholds = nodes.map(node => ({
        top: getElementPositionInDocument(node).y,
        hash: node.getAttribute('id')
      }))
    }
    let scrollTimeout = () => {
      const y = window.scrollY
      let hash = ''
      for(let threshold of thresholds) {
        if(threshold.top <= y+5) hash = '#'+threshold.hash
        else break
      }
      if(hash != this.$router.currentRoute.hash) {
        autoScrollHash.preventScroll = true
        this.$router.replace({ ...this.$router.currentRoute, hash: hash }).catch(err => {}).then(() => {
          autoScrollHash.preventScroll = false
        })
      }
    }
    let scrollTimeoutId
    this.autoScrollHash_onScroll = () => {
      if(scrollTimeoutId) clearTimeout(scrollTimeoutId)
      scrollTimeoutId = setTimeout(scrollTimeout, 100)
    }
    window.addEventListener('scroll', this.autoScrollHash_onScroll)
    window.addEventListener('resize', this.autoScrollHash_onResize)
    this.autoScrollHash_onResize()
  },
  beforeDestroy() {
    if(typeof window == 'undefined') return
    window.removeEventListener('scroll', this.autoScrollHash_onScroll)
    window.addEventListener('resize', this.autoScrollHash_onResize)
  },
  preventScroll: false
}

export default autoScrollHash
