<template>
  <pre>{{ JSON.stringify(userData, null, "  ") }}</pre>
</template>

<script>
  export default {
    name: "PubDataTest",
    props: {
      user: {
        type: String
      }
    },
    reactive: {
      userData() { return ['users', 'publicUserData', { user: this.user}] }
    }
  }
</script>

<style scoped>

</style>