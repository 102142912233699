var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("img", {
    attrs: {
      src: _vm.url,
      width: _vm.width,
      height: _vm.height,
      title: _vm.title
    },
    on: {
      load: function(ev) {
        return _vm.$emit("load", ev)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }