<template>
  <div class="permissions-modal-holder">
    <div class="card permissions-modal" ef="box"
         :style="maxDimensions">
      <div class="card-header">
        <h3>{{ title }}</h3>
        <span class="close-icon" v-if="closeable" data-effect="fadeOut" @click="$emit('close')">
          <img src="/static/icons/clear.svg" />
        </span>
      </div>
      <div v-if="introduction" class="permissions-introduction" v-html="introduction">
      </div>
      <div class="permissions-state">
        <div class="permission-state card" v-for="permissionState in permissionsState">
          <div class="permission-state-icon">
            <img :src="permissionIcons[permissionState.name][+(permissionState.state!='denied')]">
          </div>
          <div class="permission-description">
            <p class="permission-name">{{ i18n.permissions[permissionState.name] }}</p>
            <p>{{ i18n.state }} <span class="permission-state-name">{{ i18n.states[permissionState.state] }}</span></p>
          </div>
        </div>
      </div>
      <div class="buttons permissions-buttons">
        <button v-for="button in buttons" class="button" type="button"
                :disabled="button.hasOwnProperty('needPermissions')
                ? (button.needPermissions && !isOk) || (!button.needPermissions && isOk)
                : false"
                @click="() => { $emit(button.event); $emit('close') }">
          <span>{{ button.name }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { windowDimensions } from "common/components/utils/overlayLayer.js"
import i18n from "i18n"
import MessageInput from "@/modules/chat/MessageInput.vue"

const permissionIcons = {
  camera: ['/static/icons/videocam_off.svg', '/static/icons/videocam.svg'],
  microphone: ['/static/icons/mic_off.svg', '/static/icons/mic.svg']
}

export default {
  name: "PermissionsModal",
  components: { MessageInput },
  props: {
    title: {
      type: String
    },
    closeable: {
      type: Boolean,
      defaultValue: true
    },
    introduction: {
      type: String
    },
    buttons: {
      type: Array
    },
    requiredPermissions: {
      type: Array
    },
    autoClose: {
      type: String
    }
  },
  data() {
    return {
      permissionsState: [],
      finished: false
    }
  },
  reactive: {
  },
  computed: {
    i18n() {
      return i18n().permissions
    },
    maxDimensions() {
      return {
        'max-width': windowDimensions.width - 20 + 'px',
        'max-height': windowDimensions.height - 20 + 'px'
      }
    },
    permissionIcons() {
      return permissionIcons
    },
    isOk() {
      for(const permission of this.permissionsState) {
        if(permission.state == 'denied') return false
      }
      return true
    }
  },
  watch: {
    isOk(ok) {
      if(ok && this.autoClose) {
        this.$emit(this.autoClose)
        this.$emit('close')
      }
    }
  },
  methods: {
    async updatePermissions() {
      if(this.finished) return
      let permissionsState = []
      for(const requiredPermission of this.requiredPermissions) {
        const permissionState = await navigator.permissions.query(requiredPermission)
        const state = permissionState ? permissionState.state : "unknown"
        permissionsState.push({ ...requiredPermission, state })
      }
      this.permissionsState = permissionsState
      setTimeout(() => {
        this.updatePermissions()
      }, 400)
    }
  },
  mounted() {
    this.updatePermissions()
  },
  beforeDestroy() {
    this.finished = true
  }
}
</script>

<style scoped>

</style>