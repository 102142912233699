const confirmSms = {
  confirmButton: "Send sms with confirmation code",
  phoneConfirm: "We have sent an SMS code to confirm the phone number, enter the code",
  resendWaitPart1: "if the text message has not arrived, we can send another one in ",
  resendWaitPart2: " .",
  resendButton: "Send it again",
  editPhone: "Correct the phone number"
}

module.exports = {

  changeUserData: {
    title: "Fill the form with basic personal data",
    firstName: 'Name',
    firstNamePlaceholder: "Your name",
    lastName: 'Surname',
    lastNamePlaceholder: "Your surname",
    save: "Save changes",

    phoneNumber: 'Phone number',
    companyName: 'Company name',
    addressLine1: 'Address line 1',
    addressLine2: 'Address line 2',
    postalCode: 'Postal code',
    taxNumber: 'Tax number',
    country: 'Country',
    city: 'City',

    sex: "Sex",
    sexPlaceholder: "",
    birth: "Birth date",
    birthPlaceholder: "",

    sexLabels: {
      man: "Man",
      woman: "Woman"
    },

    submit: "Save",
    errors: {

    },
    done: {
      title: "Update data",
      text: "Data successfully saved."
    }
  },

  changePhone: {
    title: "Change phone number",
    currentPhone: 'Current phone number: ',
    phone: 'New phone number',
    save: "Save",
    confirmSms,
    done: {
      title: "Change phone number",
      text: "New phone number successfully saved."
    }
  },

  logout: {
    title: "Logout",
    working: {
      title: "Logout in progress",
      text: "Please wait..."
    },
    done: {
      title: "Logged out",
      text: "You have been successfully Logged out."
    },
    login: "Log in again"
  },

  menu: {
    userSettings: "User settings",
    yourPage: "Your Profile",
    logout: "Log out"
  },

  register: {
    title: "Let's register!",
    firstName: "First name",
    lastName: "Last name",
    name: "First name and last name",
    phone: "Phone number",
    phoneComment: "",
    confirmSms,
    country: "Country",
    city: "City",
    email: "Email address",
    password: "Password",
    retypePassword: "Confirm password",
    signUpButton: "Sign up",
    facebookButton: "Become a member with Facebook",
    googleButton: "Become a member with Google",
    loginButton: "Do you have an account? Log in now!",
    resendButton: "Resend confirmation email.",
    linkSent: "We have sent a special secret link to your email address. Click on it to finish the registration.",
    linkResent: "We have one more secret link to your email address. Click on it to finish the registration.",
    passwordRules: "At least 8 characters long and include at least one lowercase letter, one uppercase letter," +
        " and a number.",
    agreement1: 'I agree to the processing of my personal data for the purposes described in the',
    agreement2: 'I agree to receive business information about',
    agreement22: 'services by e-mail (newsletter)',
    policy: "../../static/PrivacyPolicy.pdf",

    orUseEmail: 'Or use your e-mail address',

    agreementRequired: "Agreement is required.",
    becomeAMember: "Become a member",
    saveAndContinue: "Save and continue",
    emailResent: 'The message with activation link has been sent again to your e-mail address',
    resendActivation: {
      header: 'Resending the activation link',
      content: 'Before sending the activation message again, please check that the previous message did not appear ' +
          'in the SPAM folder.',
      button: 'Resend activation message'
    },

    waitingForSocialAuth: 'A separate window with registration options has been displayed. If you want to go back ' +
        'to normal registration, you must close it.',
  },

  complete: {
    title: "Complete your profile data.",
    firstName: "First name",
    lastName: "Last name",
    name: "Your name",
    namePlaceholder: "",
    country: "Country",
    city: "City",
    email: "Email address",
    save: "Save",

    phone: "Phone number",
    phoneComment: "",
    confirmSms,

    sex: "Sex",
    sexPlaceholder: "",
    birth: "Birth date",
    birthPlaceholder: "",

    sexLabels: {
      man: "Man",
      woman: "Woman"
    },

    agreement1: 'I agree to the processing of my personal data for the purposes described in the',
    agreement2: 'I agree to receive business information about our services by e-mail (newsletter)',
    policy: "../../static/PrivacyPolicy.pdf",

    dataCompleted: "Data saved, please wait...",

    agreementRequired: "Agreement is required."
  },

  citySidebar: {
    title: "Search for projects in your city",
    chooseCountry: "Choose country",
    chooseCity: "Choose city"
  },

  profile: {
    about: "About me",
    aboutYou: "About you",
    edit: "Edit",
    save: "Save changes",
    interests: "Interests",
    skills: "Skills",
    ongoingProjects: "Ongoing projects",
    ongoingProjectsCount: "Count of ongoing projects:",
    updatePicture: "Update picture",
    interestsListInput: {
      add: "Add a new interest",
      typeIn: "Type in a new interest",
    },
    skillsListInput: {
      add: "Add a new skill",
      typeIn: "Type in a new skill",
    },
    message: "Message",
    continueChat: "Continue chat"
  },

  login: {
    title: 'Sign in',
    facebookButton: 'Sign in with Facebook',
    googleButton: 'Sign in with Google',
    orUseEmail: 'Or use your e-mail address',
    email: 'Email',
    password: 'Password',
    loginButton: 'Sign in',
    forgotPassword: 'Forgot your password?',
    incorrectEmail: 'E-mail or password is incorrect',
    registrationNotConfirmed: 'E-mail is registered but not confirmed.',
    resendEmail: "Click here to resend confirmation email",
    emailSent: "Confirmation email was sent.",
    passwordResetSuccess: 'Your password was successfully updated.',

    waitingForSocialAuth: 'A separate window with registration options has been displayed. If you want to go back ' +
        'to normal registration, you must close it.',
  },

  logoutProcess: {
    logoutInProcess: 'Log out in process<br> <span class="bold-font">Please wait...</span>',
    loggedOut: {
      title: 'Logged out',
      message: 'You have  been successfully logged out.',
      logInAgain: 'Log in again'
    }
  },

  resetPassword: {
    email: {
      title: 'Forgot your password?',
      message: 'We’ll help you reset it and get back on track.',
      emailLabel: 'E-mail',
      resetPassword: 'Reset password',
      goBack: 'Back'
    },
    resendButton: "Resend confirmation email.",
    errors: {
      notFound: "E-mail not found in our database.",
      registrationNotConfirmed: "E-mail is registered but not confirmed.",
      emailSent: "We have one more secret link to your email address. Click on it to finish the registration.",
      google: "This account was registered with google, please use google account to sign in."
    },
    sent: {
      title: 'Your request has been submitted',
      message: `If you have an account with this adress, we'll email you a reset link. Please wait up to 5 minutes for a message from us.`
    }
  },

  resetPasswordForm: {
    title: 'Please set your new password',
    message: 'A new password must be different from the current one.',
    password: 'New Password',
    confirmPassword: 'Confirm new password',
    passwordRules: "At least 8 characters long and include at least one lowercase letter, one uppercase letter, and a number.",
    setNewPassword: 'Set new password',
    backToSignIn: 'Back to sign in',
    done: {
      title: "Reset password",
      text: "Password successfully changed.",
      loginButton: 'Sign In'
    },
    loading : {
      title: "Reset password",
      text: "Loading. please wait..."
    },
    used : {
      title: "Reset password",
      text: "Your link has been used before."
    },
    expired : {
      title: "Reset password",
      text: "Your link expired."
    },
    notFound : {
      title: "Not found",
      text: "We could not find your link in our database. Please copy and paste address from email that your received."
    }
  },

  showPassword: "Show",
  hidePassword: "Hide",
}
