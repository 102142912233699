const env = require('../../env.js')
const moment = require('moment')

module.exports = {

  privateMessagesEmail: ({ user, email, otherUser, otherSession, messages, toId, purify }) => {
    const otherName = otherUser ? otherUser.display : (otherSession.name || 'Anonymous')
    return ({
      from: 'Vole Apps <hello@apps.vole.pl>',
      to: `${user.display} <${email}>`,
      subject: `${otherName} sent you a message!`,
      text: require('./privateMessages.text.ejs')({ moment, user, otherUser, otherName, env, messages, toId, purify }),
      html: require('./privateMessages.html.ejs')({ moment, user, otherUser, otherName, env, messages, toId, purify })
    })
  },

  notificationsEmail: ({ user, email, notifications, purify }) => {
    return ({
      from: 'Vole Apps <hello@apps.vole.pl>',
      to: `${user.display} <${email}>`,
      subject: `You got new ${notifications.length + (notifications.length > 1 ? ' notifications' : ' notification')}!`,
      text: require('./notifications.text.ejs')({ user, env, moment, notifications, purify }),
      html: require('./notifications.html.ejs')({ user, env, moment, notifications, purify })
    })
  }

}
