var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "videoDisplay", staticClass: "video-display" },
    [
      _c("pre", { staticStyle: { display: "none" } }, [
        _vm._v(_vm._s(JSON.stringify(_vm.videoSizes, null, "  ")))
      ]),
      _vm._v(" "),
      _vm._l(_vm.allVideos, function(video) {
        return _c("VideoDisplayVideo", {
          key: video.id,
          style: _vm.videoStyles[video.id],
          attrs: { video: video, volume: _vm.volume },
          on: {
            videoResize: function(ev) {
              return _vm.handleVideoResize(video.id, ev)
            },
            click: function(ev) {
              return _vm.handleClick(video, ev)
            }
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }