var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "object-view" }, [
    _vm.data
      ? _c(
          "div",
          _vm._l(_vm.fields, function(field) {
            return _c(
              "div",
              { staticClass: "property" },
              [
                _c("div", { staticClass: "property-name" }, [
                  _vm._v(_vm._s(field))
                ]),
                _vm._v(" "),
                _c("ObjectValue", {
                  staticClass: "property-value",
                  attrs: {
                    data: _vm.data[field],
                    viewDefinition: _vm.viewDefinition.properties[field],
                    type: _vm.type
                  }
                })
              ],
              1
            )
          }),
          0
        )
      : _c("div", [
          _vm._v("\n    " + _vm._s(JSON.stringify(_vm.data)) + "\n  ")
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }