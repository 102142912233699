var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "peer-connection-debugger" }, [
    _c("header", { staticClass: "navbar" }, [
      _c("div", { ref: "content", staticClass: "navbar-content" }, [
        _c(
          "span",
          { staticClass: "navbar-item navbar-brand" },
          [
            _c(
              "router-link",
              {
                staticClass: "navbar-brand-link",
                attrs: { to: { name: "index" }, tag: "a" }
              },
              [_vm._v("\n          Peer Connection Debugger\n        ")]
            )
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "peer-connection-debugger-content" },
      [
        _c("h1", [_vm._v("Peer Connection Debugger")]),
        _vm._v(" "),
        _vm.peer
          ? _c("div", [
              _c("h2", [_vm._v("Peer connection")]),
              _vm._v(" "),
              _c("pre", [
                _vm._v(_vm._s(JSON.stringify(_vm.peer.summary, null, "  ")))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "buttons" }, [
                _c(
                  "button",
                  {
                    staticClass: "button",
                    attrs: { type: "button", role: "button" },
                    on: {
                      click: function() {
                        return _vm.peer.setOnline(true)
                      }
                    }
                  },
                  [_vm._v("Set Online")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button",
                    attrs: { type: "button", role: "button" },
                    on: {
                      click: function() {
                        return _vm.peer.setOnline(false)
                      }
                    }
                  },
                  [_vm._v("Set Offline")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button",
                    attrs: { type: "button", role: "button" },
                    on: { click: _vm.sendTestMessage }
                  },
                  [_vm._v("Test Message")]
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.remoteStreams, function(remoteStream) {
          return _c("div", [
            _c("h2", [
              _vm._v(
                "Remote stream " +
                  _vm._s(remoteStream.stream.id) +
                  " from " +
                  _vm._s(remoteStream.from)
              )
            ]),
            _vm._v(" "),
            _c("video", {
              attrs: { autoplay: "", playsinline: "" },
              domProps: { srcObject: remoteStream.stream }
            })
          ])
        }),
        _vm._v(" "),
        _c("div", [
          _c("h2", [_vm._v("Local data channels")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.newDataChannelLabel,
                expression: "newDataChannelLabel"
              }
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.newDataChannelLabel },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.newDataChannelLabel = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("button", { on: { click: _vm.createDataChannel } }, [
            _vm._v("createDataChannel")
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("h2", [_vm._v("User media")]),
            _vm._v(" "),
            _vm.videoInputDevices && _vm.videoInputDevices.length > 0
              ? _c("single-select-input", {
                  attrs: {
                    options: _vm.videoInputDevices,
                    text: function(option) {
                      return option
                        ? option.label || "unknown"
                        : "Browser default"
                    },
                    placeholder: "Select video device..."
                  },
                  model: {
                    value: _vm.selectedVideoInput,
                    callback: function($$v) {
                      _vm.selectedVideoInput = $$v
                    },
                    expression: "selectedVideoInput"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.audioInputDevices && _vm.audioInputDevices.length > 0
              ? _c("single-select-input", {
                  attrs: {
                    options: _vm.audioInputDevices,
                    text: function(option) {
                      return option
                        ? option.label || "unknown"
                        : "Browser default"
                    },
                    placeholder: "Select audio device..."
                  },
                  model: {
                    value: _vm.selectedAudioInput,
                    callback: function($$v) {
                      _vm.selectedAudioInput = $$v
                    },
                    expression: "selectedAudioInput"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.userMedia
              ? _c("div", { staticClass: "buttons" }, [
                  _c(
                    "button",
                    { staticClass: "button", on: { click: _vm.getUserMedia } },
                    [_vm._v("getUserMedia")]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.userMedia
              ? _c("div", { staticClass: "buttons" }, [
                  _c(
                    "button",
                    { staticClass: "button", on: { click: _vm.dropUserMedia } },
                    [_vm._v("drop UserMedia")]
                  ),
                  _vm._v(" "),
                  _vm.userMediaMuted
                    ? _c(
                        "button",
                        {
                          staticClass: "button",
                          attrs: { type: "button" },
                          on: {
                            click: function() {
                              return (_vm.userMediaMuted = false)
                            }
                          }
                        },
                        [_vm._v("\n          Unmute user media\n        ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.userMediaMuted
                    ? _c(
                        "button",
                        {
                          staticClass: "button",
                          attrs: { type: "button" },
                          on: {
                            click: function() {
                              return (_vm.userMediaMuted = true)
                            }
                          }
                        },
                        [_vm._v("\n          Mute user media\n        ")]
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.userMedia
              ? _c("video", {
                  attrs: { autoplay: "", playsinline: "" },
                  domProps: {
                    muted: _vm.userMediaMuted,
                    srcObject: _vm.userMedia
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", [
          _c("h2", [_vm._v("Display media")]),
          _vm._v(" "),
          !_vm.displayMedia
            ? _c("div", { staticClass: "buttons" }, [
                _c(
                  "button",
                  { staticClass: "button", on: { click: _vm.getDisplayMedia } },
                  [_vm._v("getDisplayMedia")]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.displayMedia
            ? _c("div", { staticClass: "buttons" }, [
                _c(
                  "button",
                  {
                    staticClass: "button",
                    on: { click: _vm.dropDisplayMedia }
                  },
                  [_vm._v("drop DisplayMedia")]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.displayMedia
            ? _c("video", {
                attrs: { autoplay: "", playsinline: "", muted: "" },
                domProps: { muted: true, srcObject: _vm.displayMedia }
              })
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm._l(_vm.peer ? _vm.peer.localTracks : [], function(track, index) {
          return _c("div", [
            _vm._v(
              "\n      Track #" +
                _vm._s(index) +
                " " +
                _vm._s(track.track.kind) +
                " (" +
                _vm._s(track.track.label) +
                ") enabled: " +
                _vm._s(track.enabled) +
                "\n      id: " +
                _vm._s(track.track.id) +
                "\n      "
            ),
            _c("div", { staticClass: "buttons" }, [
              !track.enabled
                ? _c(
                    "button",
                    {
                      staticClass: "button",
                      attrs: { type: "button" },
                      on: {
                        click: function() {
                          return _vm.peer.setTrackEnabled(track, true)
                        }
                      }
                    },
                    [_vm._v("\n          Enable Track\n        ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              track.enabled
                ? _c(
                    "button",
                    {
                      staticClass: "button",
                      attrs: { type: "button" },
                      on: {
                        click: function() {
                          return _vm.peer.setTrackEnabled(track, false)
                        }
                      }
                    },
                    [_vm._v("\n          Disable Track\n        ")]
                  )
                : _vm._e()
            ])
          ])
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }