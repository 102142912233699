var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "loading",
    {
      staticClass: "conversation-view",
      attrs: { name: "conversation view", what: _vm.allLoaded }
    },
    [
      _vm.allLoaded
        ? _c(
            "div",
            { staticClass: "conversation-view-content" },
            [
              _vm.userData === null
                ? _c("div", { staticClass: "conversation-header" }, [
                    _c("div", { staticClass: "conversation-header-picture" }, [
                      _c("img", {
                        attrs: {
                          src: "/static/deleted-user-photo.svg",
                          width: "45",
                          height: "45"
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "conversation-info" }, [
                      _c(
                        "div",
                        { staticClass: "conversation-header-user-name" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.i18n.userDeleted) +
                              "\n        "
                          )
                        ]
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.userData
                ? _c("div", { staticClass: "conversation-header" }, [
                    _c(
                      "div",
                      { staticClass: "conversation-header-picture" },
                      [
                        _vm.userData && _vm.userData.picture
                          ? _c("uploaded-picture-img", {
                              attrs: {
                                width: "45",
                                height: "45",
                                picture: _vm.userData.picture,
                                reactive: true
                              }
                            })
                          : _c("img", {
                              attrs: {
                                src: "/static/empty-user-photo.svg",
                                width: "45",
                                height: "45"
                              }
                            }),
                        _vm._v(" "),
                        (_vm.userData && _vm.userData.online) ||
                        (_vm.otherSession && _vm.otherSession.online)
                          ? _c("div", { staticClass: "onlineDot" })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "conversation-info" }, [
                      _vm.userData
                        ? _c(
                            "div",
                            { staticClass: "conversation-header-user-name" },
                            [_vm._v(_vm._s(_vm.userData.display))]
                          )
                        : _c(
                            "div",
                            { staticClass: "conversation-header-user-name" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    (_vm.sessionData && _vm.sessionData.name) ||
                                      _vm.i18n.anonymous
                                  ) +
                                  "\n        "
                              )
                            ]
                          ),
                      _vm._v(" "),
                      _vm.userData.online
                        ? _c(
                            "div",
                            { staticClass: "conversation-header-last-online" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.i18n.onlineNow) +
                                  "\n        "
                              )
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "conversation-header-last-online" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.i18n.lastSeen) +
                                  " " +
                                  _vm._s(
                                    _vm._f("timeAgo")(_vm.userData.lastOnline)
                                  ) +
                                  " " +
                                  _vm._s(_vm.i18n.ago) +
                                  "\n        "
                              )
                            ]
                          )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("PrivateMessages", {
                key: "priv_messages_" + _vm.conversation,
                attrs: { toType: "priv", toId: _vm.conversation }
              }),
              _vm._v(" "),
              _c("MessageInput", {
                key: "priv_input_" + _vm.conversation,
                attrs: {
                  "message-parameters": {
                    toType: "priv",
                    toId: _vm.conversation
                  },
                  mobileSendButton: _vm.i18n.mobileSendButton
                }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }