<template>
  <div class="form-group xml-search-query">
    <LogicBlock name="query" :dataSet="dataSet" :disabled="disabled"></LogicBlock>
  </div>
</template>

<script>
  import i18n from "i18n"
  import MultiSelectField from "common/components/fields/MultiSelectField.vue"
  import LogicBlock from "./query/LogicBlock.vue"
  import searchFields from "./query/searchFields.js"

  export default {
    name: "QueryFields",
    inject: ['form'],
    components: {
      LogicBlock
    },
    props: {
      dataSet: {
        type: Object
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      i18n() {
        return i18n().xmlSearch
      }
    }
  }
</script>

<style scoped>

</style>