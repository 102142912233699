import guid from "./api/guid";

const analyticsConstants = (typeof window != 'undefined') && {
  language: navigator.language || navigator.userLanguage || navigator.browserLanguage || navigator.systemLanguage,
  webdriver: !!navigator.webdriver,
  userAgent: navigator.userAgent,
  colorDepth: window.screen.colorDepth,
  deviceMemory: navigator.deviceMemory,
  pixelRatio: window.devicePixelRatio,
  resolution: window.screen.width + 'x' + window.screen.height,
  availableResolution: window.screen.availHeight +'x' + window.screen.availWidth,
  timezoneOffset: new Date().getTimezoneOffset(),
  host: document.location.host,
  sessionId: window.__SESSION_ID__,
  windowId: window.__WINDOW_ID__ || guid()
}

export default analyticsConstants