var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "loading",
    { attrs: { what: _vm.value, name: _vm.tagsType + " tags list value" } },
    [
      _vm.value !== undefined
        ? _c(
            "div",
            { staticClass: "tags-list-input" },
            [
              _vm.value.length == 0
                ? _c("span", [_vm._v(_vm._s(_vm.i18n.empty))])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.value, function(tagId) {
                return _c(
                  "span",
                  [
                    _c("observe", {
                      staticClass: "tag-pill",
                      attrs: { what: ["tags", "TagOne", { tag: tagId }] },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var tag = ref.value
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "tag-name",
                                    on: {
                                      click: function($event) {
                                        return _vm.searchForTag(tag.name)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(tag.name))]
                                ),
                                _vm._v(" "),
                                _c("img", {
                                  staticClass: "remove-tag",
                                  attrs: {
                                    src: "/static/icons/clear.svg",
                                    alt: "remove"
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.tagInput,
                    expression: "tagInput"
                  }
                ],
                ref: "input",
                staticClass: "input new-tag-input",
                attrs: { type: "text", placeholder: _vm.i18n.typeIn },
                domProps: { value: _vm.tagInput },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.tagInput = $event.target.value
                  }
                }
              })
            ],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }