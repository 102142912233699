var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    {
      tag: "component",
      class: _vm.notField ? [] : [{ formFieldError: !!_vm.error }, "form-field"]
    },
    [
      _vm._t("default", null, null, {
        value: _vm.value,
        error: _vm.error,
        setValue: _vm.setValue,
        setError: _vm.setError
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }