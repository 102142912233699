<template>
  <page>
    <div class="card half-width main-card">
      <h2>Connecting to API</h2>
      <p>Please wait...</p>
    </div>
  </page>
</template>

<script>
import api from "api"

export default {
  name: "Return",
  inject: ['workingZone'],
  computed: {
    query() {
      return this.$route.query
    }
  },
  mounted() {
    const path = this.$router.resolve({ name: 'adsApi:accessReturn' }).href
    const code = this.query.code
    console.log("ACCESS CODE", code)
    console.log("ACCESS REDIRECT URI", )
    this.workingZone.addPromise('useAccessCode ' + code, (async () => {
      const result = await api.command(['adsApi', 'useAccessCode'], {
        code,
        redirectUri: document.location.protocol + '//' + document.location.host + path
      })
      console.log( result )
      this.$router.push({ name: 'adsApi:campaigns' })
    })())


  }
}
</script>
