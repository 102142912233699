<template>
  <div>
    <h4>Unknown notification</h4>
    <pre>{{ JSON.stringify(notification, null, "  ") }}</pre>
  </div>
</template>

<script>
export default {
  name: "UnknownNotification",
  props: {
    notification: {
    }
  }
}
</script>

<style scoped>

</style>