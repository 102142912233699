<template>
  <form-field-bind :name="name" v-slot="{ value, setValue, error }">
    <FieldTemplate :errorText="errorText" :error="error" :label="label" :name="name">
      <br />
      <DatePicker lang="en" type="time" placeholder="" format="HH:mm" input-class="form-control" width="100%"
                  :value="value" @input="setValue" :minute-step="5"></DatePicker>
    </FieldTemplate>
  </form-field-bind>
</template>

<script>
  import DatePicker from 'vue2-datepicker'
  import FieldTemplate from "./FieldTemplate.vue"

  export default {
    name: "TimeField",
    components: { FieldTemplate, DatePicker },
    props: {
      name: {
        type: String,
        required: true
      },
      label: {
        type: String
      },
      errorText: {
        type: Object
      }
    },
    methods: {
    }

  }

</script>

<style scoped>

</style>