var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ol",
    _vm._l(_vm.data, function(element) {
      return _c(
        "li",
        [
          _c("ObjectValue", {
            staticClass: "property-value",
            attrs: {
              data: element,
              viewDefinition: _vm.viewDefinition.of,
              type: _vm.type
            }
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }