import ConversationPage from "./ConversationPage.vue"
import ConversationsPage from "./ConversationsPage.vue"
import StartConversation from "./StartConversation.vue"

export default function(prefix) {
  return [
    {
      path: prefix + '/c/:conversationType/:conversation',
      name: 'messenger:conversation',
      component: ConversationPage,
      meta: { requiresLogin: true },
      props: true
    },
    {
      path: prefix + '/conversations',
      name: 'messenger:conversations',
      component: ConversationsPage,
      meta: { requiresLogin: true },
      props: true
    },
    {
      path: prefix + '/priv/:withType/:withId',
      name: 'messenger:startPrivateConversation',
      component: StartConversation,
      meta: { requiresLogin: true },
      props: true
    }
  ]
}
