var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "phone-input" }, [
    _c(
      "div",
      {
        ref: "prefix",
        staticClass: "prefix select-input",
        class: { "is-invalid": _vm.prefixInvalid, disabled: _vm.disabled },
        on: { click: _vm.showPrefixMenu }
      },
      [
        _c("span", { class: "select-input-value " + _vm.inputClass }, [
          _vm.prefix.length > 0
            ? _c("div", [
                _vm._v("\n        " + _vm._s(_vm.prefix) + "\n      ")
              ])
            : _c("div", { staticClass: "select-input-placeholder" }, [
                _vm._v("\n        +??\n      ")
              ])
        ]),
        _vm._v(" "),
        _vm._m(0)
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "number form-field" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.number,
            expression: "number"
          }
        ],
        ref: "number",
        staticClass: "input",
        attrs: {
          type: "text",
          id: "number",
          required: "",
          pattern: "[0-9 #+-]{3,}",
          disabled: _vm.disabled
        },
        domProps: { value: _vm.number },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.number = $event.target.value
          }
        }
      })
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "select-input-more" }, [
      _c("img", { attrs: { src: "/static/icons/arrow_drop_down.svg" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }