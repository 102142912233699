var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form-field-bind", {
    staticClass: "multi-file-upload-field",
    attrs: { name: _vm.name },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var value = ref.value
          var setValue = ref.setValue
          var error = ref.error
          return [
            _c(
              "FieldTemplate",
              {
                attrs: {
                  errorText: _vm.errorText,
                  error: error,
                  label: _vm.label,
                  name: _vm.name,
                  groupClass: _vm.groupClass,
                  inputId: _vm.uid
                }
              },
              [
                _vm._l(_vm.uploads, function(upload, fileIndex) {
                  return _c("FileUploadField", {
                    key: upload.id,
                    attrs: {
                      file: upload.file,
                      name: _vm.name + "." + fileIndex
                    },
                    on: {
                      delete: function($event) {
                        return _vm.handleFileDeleted(fileIndex)
                      }
                    }
                  })
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "buttons" },
                  [
                    _c(
                      "FileInput",
                      {
                        class: _vm.inputClass || "button",
                        attrs: {
                          multiple: "",
                          name: _vm.name,
                          accept: _vm.accept
                        },
                        on: { input: _vm.handleFiles }
                      },
                      [_vm._v("\n        " + _vm._s(_vm.button) + "\n      ")]
                    )
                  ],
                  1
                )
              ],
              2
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }