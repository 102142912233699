<template>
  <form-field-bind :name="name" v-slot="{ value, setValue, error }" :key="country">
    <FieldTemplate :errorText="errorText" :error="error" :label="label" :name="name"
                   :groupClass="groupClass">
      <PlaceInput :inputClass="inputClass" :types="['geocode']" :country="country" :value="value" @input="v=>setValue(v)">
      </PlaceInput>
    </FieldTemplate>
  </form-field-bind>
</template>

<script>
  import FieldTemplate from "./FieldTemplate.vue"
  //import SingleSelectWithSearch from "common/components/inputs/SingleSelectWithSearch.vue"
  import PlaceInput from "common/components/inputs/PlaceInput.vue"


  export default {
    name: "PlaceField",
    components: { FieldTemplate, PlaceInput },
    props: {
      name: {
        type: String,
        required: true
      },
      label: {
        type: String
      },
      errorText: {
        type: Object
      },
      required: {
        type: Boolean,
        default: false
      },
      inputClass: {
        type: String
      },
      groupClass: {
        type: String
      },
      country: {
        type: String
      }
    },
    data() {
      return {
        country: null
      }
    }
  }
</script>

<style scoped>

</style>