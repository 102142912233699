var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-field" },
    _vm._l(_vm.topCategories, function(category) {
      return _c(
        "div",
        [
          _c("observe", {
            attrs: {
              what: ["categories", "subcategories", { category: category.id }]
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var subCategories = ref.value
                    return [
                      _c(
                        "div",
                        {
                          class:
                            "topCategory clickable " +
                            _vm.categoryClass(category, subCategories),
                          on: {
                            click: function() {
                              return _vm.toggleAllSubCategories(
                                category,
                                subCategories
                              )
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "top-category-header" }, [
                            _c("div", { staticClass: "radioCircle" })
                          ]),
                          _vm._v(" "),
                          _c("h3", [
                            _vm._v(_vm._s(_vm.categoryTitle(category)))
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(_vm._s(_vm.categoryDescription(category)))
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "tailor-link" }, [
                            _c(
                              "a",
                              {
                                staticClass: "text-light-purple",
                                attrs: { href: "#" },
                                on: {
                                  click: function(ev) {
                                    ev.preventDefault()
                                    ev.stopImmediatePropagation()
                                    _vm.tailor(category)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.i18n.tailor) +
                                    "\n          "
                                )
                              ]
                            )
                          ])
                        ]
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }