import Vue from "vue"


import LoadingZone from 'common/components/LoadingZone.vue'
Vue.component('loading-zone', LoadingZone)
import Loading from 'common/components/Loading.vue'
Vue.component('loading', Loading)

import Observe from 'common/components/Observe.vue'
Vue.component('observe', Observe)

import WorkingZone from 'common/components/WorkingZone.vue'
Vue.component('working-zone', WorkingZone)
import CommandForm from 'common/components/CommandForm.vue'
Vue.component('command-form', CommandForm)
import FormFieldBind from "common/components/FormFieldBind.vue";
Vue.component('form-field-bind', FormFieldBind)
import FormBind from "common/components/FormBind.vue";
Vue.component('form-bind', FormBind)
import SubmitButton from "common/components/SubmitButton.vue";
Vue.component('submit-button', SubmitButton)

import Picture from 'common/components/UploadedPicture.vue'
Vue.component('uploaded-picture', Picture)
import PictureImg from 'common/components/UploadedPictureImg.vue'
Vue.component('uploaded-picture-img', PictureImg)

import BreadcrumbNavi from 'common/components/BreadcrumbNavi.vue'
Vue.component('breadcrumb-navi', BreadcrumbNavi)

import "./fields/registerGlobal.js"
import "./views/registerGlobal.js"
