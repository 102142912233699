const env = require('../../env.js')
const moment = require('moment')
require('moment-timezone')

module.exports = {
  url: "URL",
  addUrlTitle: 'Add URL:',
  createCheckerSubmit: "Add URL",
  ago: "ago",
  checkerState: {
    starting: "Starting...",
    broken: "Broken",
    ok: "Ok",
    waiting: "Waiting"
  },
  urlBroken: "Error",
  connectionProblem: "Connection problem :/",

  lastCheckTime: "checked",
  nextCheckTime: "next check in",
  now: "now",

  deleteScanner: "Disable scanner",
  deleteChecker: "Delete URL",
  addScanner: 'Add scanner',
  search: "Open search",
  checkExclusions: "Check exclusions",

  newDataSet: 'Latest xml:',
  oldDataSet: 'Older xml:',
  downloadedIn: "downloaded in",

  urlDeleted: "URL is deleted",
  returnToList: "Return to Urls list",

  checkStatus: {
    ok: 'OK',
    error: 'ERROR'
  },

  notifications: {
    broken: {
      title: "URL broken"
    },
    restored: {
      title: "URL restored",
      text: "Working again"
    }
  },
  smsSeverity: "Send sms on",
  emailSeverity: "Send email on",

  checkInterval: "Check interval",
  checkIntervals: {
    'auto': 'Automatic',
    'never': 'Disable checking',
    '1s': 'every second',
    '2s': 'every 2 seconds',
    '5s': 'every 5 seconds',
    '10s': 'every 10 seconds',
    '30s': 'twice per minute',
    '1m': 'once per minute',
    '2m': 'every 2 minutes',
    '5m': 'every 5 minutes',
    '10m': 'every 10 minutes'
  },

  alertDelay: {
    'never': 'never',
    '1x': '1st error',
    '2x': '2nd error',
    '3x': '3rd error',
    '5x': 'after 5th error',
    '10x': '10th error',
    '30x': '30th error',
    '1s': '1 second broken',
    '2s': '2 seconds broken',
    '5s': '5 seconds broken',
    '10s': '10 seconds broken',
    '30s': '30 seconds broken',
    '1m': '1 minute broken',
    '2m': '2 minutes broken',
    '5m': '5 minutes broken',
    '30m': '30 minutes broken',
    '1h': '1 hour broken'
  },

  emailNotifications: {
    alertEmail: ({ user, notification, purify }) => ({
      text: require('./smsAlertEmail.text.ejs')({ user, env, moment, notification }),
      html: require('./smsAlertEmail.html.ejs')({ user, env, moment, notification })
    })
  },
  smsNotifications: {
    alertSms: ({ user, email, url, notification }) =>
        require('./smsAlertSms.text.ejs')({ user, env, moment, notification })
  }

}