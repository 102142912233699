<template>
  <section class="card half-width" v-if="user && user.loginMethods.find(lm=>lm.type=='emailPassword')">
    <command-form service="emailPassword" action="updateAllPasswordsByUser">
      <template v-slot:done>
        <h4>{{ i18n.done.title }}</h4>
        <p>
          {{ i18n.done.text }}
        </p>
      </template>
      <template v-slot:default>
        <h4>{{ i18n.title }}</h4>
        <p>{{ i18n.instruction }}</p>
        <password-field name="oldPasswordHash" :label="i18n.oldPassword" :placeholder="i18n.oldPasswordPlaceholder"
                        inputClass="form-control-lg"></password-field>
        <double-password-field name="newPasswordHash"
                               :label="i18n.newPassword" :placeholder="i18n.newPasswordPlaceholder"
                               :retypeLabel="i18n.retypePassword" :retypePlaceholder="i18n.retypePasswordPlaceholder"
                               inputClass="form-control-lg">
        </double-password-field>
        <div class="buttons">
          <button type="submit" class="button" role="button"><span>{{ i18n.submit }}</span></button>
        </div>
      </template>
    </command-form>
  </section>
</template>

<script>
  import api from "api"
  import i18n from "i18n"

  export default {
    name: 'UpdatePassword',
    data () {
      return {
      }
    },
    computed: {
      i18n() { return i18n().emailPassword.changePassword }
    },
    reactive: {
      user() { return ['users', 'UserOne', { user: api.session.session.user } ] }
    },
    methods: {
    }
  }
</script>

<style scoped>
</style>
