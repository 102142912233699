<template>
  <page>
    <h1>XML ALERT!</h1>
    <ScanCard v-if="scanData" :scan="scanData" :scanner="scannerData" class="main-card card full-width"></ScanCard>
    <scroll-loader :what="range => ['xmlAlert', 'results', { scan: this.scan, ...range }]"
                   :key="scan" noDebugLog
                   class="full-width">
      <template v-slot:default="{ row: result }">
        <ScanResult :result="result"></ScanResult>
      </template>
      <template v-slot:loadingBottom="{ connectionProblem }">
        <div class="search-bottom-loading-bar">
          <div class="card loading-card">
            <div class="cube-spinner">
              <div class="cube1"></div>
              <div class="cube2"></div>
            </div>
            <span class="loading-text" v-if="connectionProblem" v-html="i18n.connectionProblem"></span>
            <span class="loading-text" v-else v-html="i18n.loadingBottom"></span>
          </div>
        </div>
      </template>
    </scroll-loader>
  </page>
</template>

<script>
  import i18n from 'i18n'

  import ScanCard from "./ScanCard.vue"
  import ScanResult from "./ScanResult.vue"
  import ScrollLoader from "common/components/ScrollLoader.vue"

  export default {
    name: "ScanPage",
    props: {
      scan: {
        type: String
      },
      scanner: {
        type: String
      }
    },
    components: { ScanCard, ScrollLoader, ScanResult },
    reactive: {
      scanData() {
        return ['xmlAlert', 'scan', { scan: this.scan }]
      },
      scannerData() {
        return ['xmlAlert', 'scanner', { scanner: this.scanner }]
      },
      /*results() {
        return ['xmlAlert', 'results', { scan: this.scan }]
      }*/
    },
    computed: {
      i18n() {
        return i18n().xmlAlert
      }
    },
    methods: {
    }
  }
</script>

<style scoped>

</style>