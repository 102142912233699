<template>
  <form-field-bind :name="name" v-slot="{ value, setValue, error }" :key="country" v-if="country">
    <FieldTemplate :errorText="errorText" :error="error" :label="label" :name="name"
                   :groupClass="groupClass">
      <PlaceInput :inputClass="inputClass" :types="['geocode']" :country="country" :value="value" @input="v=>setValue(v)">
      </PlaceInput>
      <!--<loading :what="cities" :name="'group cities'">
        <SingleSelectWithSearch :inputClass="inputClass" :options="cities" :value="value" @input="setValue">
        </SingleSelectWithSearch>
      </loading>-->
    </FieldTemplate>
  </form-field-bind>
</template>

<script>
  import FieldTemplate from "./FieldTemplate.vue"
  //import SingleSelectWithSearch from "common/components/inputs/SingleSelectWithSearch.vue"
  import PlaceInput from "common/components/inputs/PlaceInput.vue"


  export default {
    name: "CountryPlaceField",
    components: { FieldTemplate, PlaceInput /*SingleSelectWithSearch*/ },
    inject: ['form'],
    props: {
      name: {
        type: String,
        required: true
      },
      label: {
        type: String
      },
      errorText: {
        type: Object
      },
      required: {
        type: Boolean,
        default: false
      },
      inputClass: {
        type: String
      },
      groupClass: {
        type: String
      }
    },
    /*reactive: {
      cities() {
        if(!this.country) return null
        return ['geo', 'citiesByCountry', this.country]
      }
    }, */
    data() {
      return {
        country: null
      }
    },
    created() {
      this.prefix = this.name.slice(0, this.name.lastIndexOf(".")+1)
      this.countryValueObserver = (v) => {
        if(this.country != v) {
          if(this.country) this.form.setFieldValue(this.name, null)
          this.country = v
          console.log("COUNTRY", v)
        }
      }
      this.form.observe(this.prefix + "country", this.countryValueObserver)
    },
    beforeDestroy() {
      this.form.unobserve(this.prefix + "country", this.countryValueObserver)
    }
  }
</script>

<style scoped>

</style>