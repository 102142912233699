<template>
  <div class="conversations-list">
    <div class="conversations-view">
      <scroll-loader :what="range => conversationsDaoPath(range)" class="conversations" key="conversations"
                     :bucketSize="3" readMode="id" hardClose noDebugLog
                     v-slot:default="{ row: history, rows }">
        <router-link tag="div" :to="{ name: 'messenger:conversation', params: {
          conversationType: history.toType, conversation: history.toId
        } }" class="conversation-link">
          <loading-zone>
            <PrivateConversationCard v-if="history.toType == 'priv'"
                                     :selected="selectedConversation == history.toId"
                                     :history="history">
            </PrivateConversationCard>
<!--    TODO:        <RoomConversationCard v-if="history.toType == 'room'"
                                     :selected="selectedConversation == history.toId"
                                     :history="history">
            </RoomConversationCard>-->
          </loading-zone>
        </router-link>
      </scroll-loader>
    </div>
  </div>
</template>

<script>
  import ScrollLoader from "common/components/ScrollLoader.vue"
  import PrivateConversationCard from "./PrivateConversationCard.vue"
  import LoadingZone from "common/components/LoadingZone.vue"

  export default {
    name: "ConversationsList",
    components: { ScrollLoader, PrivateConversationCard, LoadingZone },
    props: {
      selectedConversation: {
        default: null
      }
    },
    methods: {
      conversationsDaoPath(range) { /// TODO parametrize list
        let reversed = {
          toType: 'priv',
          gt: range.lt,
          gte: range.lte,
          lt: range.gt == '' ? '\xFF\xFF\xFF\xFF' : range.gt,
          lte: range.gte,
          limit: range.limit,
          reverse: !range.reverse
        }
        ///return ['readHistory', 'myReadHistories', reversed] todo
        return ['readHistory', 'myReadHistoriesByType', reversed]
      }
    }
  }
</script>

<style scoped>

</style>