import { render, staticRenderFns } from "./IndexPage.vue?vue&type=template&id=a01c3592&"
import script from "./IndexPage.vue?vue&type=script&lang=js&"
export * from "./IndexPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/source/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a01c3592')) {
      api.createRecord('a01c3592', component.options)
    } else {
      api.reload('a01c3592', component.options)
    }
    module.hot.accept("./IndexPage.vue?vue&type=template&id=a01c3592&", function () {
      api.rerender('a01c3592', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/IndexPage.vue"
export default component.exports