<template>
  <page>
    <loading name="search and dataSet" :what="allLoaded || notFound"></loading>
    <PageNotFound v-if="notFound" />
    <div v-if="allLoaded && isDeleted" class="card main-card half-width">
      <h2>{{ i18n.deleted.title }}</h2>
      <p>{{ i18n.deleted.text }}</p>
      <loading name="dataSetsHistory" :what="dataSetsHistory"></loading>
      <div class="buttons" if="dataSetsHistory">
        <SingleSelectInput v-if="dataSetsHistory" :value="null"
                           :options="dataSetsHistory.filter(h=>h.state=='done')"
                           :text="dataSet => historyLabel(dataSet)"
                           :placeholder="i18n.deleted.selectHistory"
                           class="form-field history-select"
                           @input="(dataSet) => redoSearch(dataSet.to)"></SingleSelectInput>
        <span>&nbsp; or &nbsp;</span>
        <button class="button" @click="() => redoSearch()">{{ i18n.deleted.fresh }}</button>
      </div>
    </div>
    <div v-if="allLoaded && !isDeleted" class="card main-card full-width" @click.capture="handleDisabledClick">
      <command-form service="xmlSearch" action="search" :initialValues="formInitialValues"
                    :parameters="{ dataSet: dataSetParameter }"
                    :keepOnDone="true" @done="handleSearchStarted"
                    :fieldValidators="{ url: v => validateUrl(v) }" ref="form">

        <form-field-bind v-if="editingUrl || !dataSetEntity || dataSetEntity.state == 'deleted'"
             name="upload" v-slot="{ value: upload }">
          <div class="form-group buttons">
            <text-field name="url" v-if="!upload" :label="i18n.url" :disabled="!canEdit"
                        class="search-url-field" />
            <file-field name="upload" :label="i18n.upload" :disable="!canEdit" accept=".xml,application/xml"
                        :class="{ 'search-url-field': !!upload }" />
          </div>
        </form-field-bind>
        <div v-else class="form-group dataSet">
          <div class="loading-animation"
               v-if="dataSetEntity.state == 'downloading' || dataSetEntity.state == 'connecting'">
            <div class="cube-spinner">
              <div class="cube1"></div>
              <div class="cube2"></div>
            </div>
          </div>
          <div class="dataSet-buttons buttons">
            <submit-button v-if="!['downloading', 'connecting', 'uploaded'].includes(dataSetEntity.state)"
                class="button" :parameters="{ refresh: true }" :disabled="!canEdit">
              <img src="/static/icons/refresh.svg">
            </submit-button>
            <button type="button" class="button" @click="editUrl" :disabled="!canEdit">
              <img src="/static/icons/edit.svg">
            </button>
            <button type="button" v-if="dataSetsHistory && dataSetsHistory.length > 0"
                    class="button" @click="toggleHistory" :disabled="!canEdit">
              <img src="/static/icons/history.svg">
            </button>
            <SingleSelectInput v-if="historyVisible && dataSetsHistory" :value="dataSetEntity"
                               :options="dataSetsHistory.filter(h=>h.state=='done')"
                               :text="dataSet => historyLabel(dataSet)"
                               class="form-field history-select"
                               @input="goToHistory"></SingleSelectInput>
            <button type="button" v-if="historyVisible"
                    class="button" @click="toggleHistory">
              <img src="/static/icons/clear.svg">
            </button>
          </div>
          <div class="dataSet-info" v-if="!historyVisible">
            <a class="dataSet-url" :href="dataSetEntity.url">{{ dataSetEntity.url }}</a>
            <div class="dataSet-status">
              <div class="dataSet-state">{{ dataSetEntity.state }}</div>
              <div class="dataSet-timestamp">
                {{ (currentTime-(new Date(dataSetEntity.timestamp)).getTime())/1000 | durationShort }}
                -
                {{ new Date(dataSetEntity.timestamp) | date }}
                {{ new Date(dataSetEntity.timestamp) | hour }}
              </div>
              <div class="dataSet-size">{{ dataSetEntity.entries }} {{ i18n.entries }}</div>
              <div class="dataSet-progress" v-if="dataSetEntity.progress">
                <span>{{ dataSetEntity.progress.transferred | bytes }}</span>
                <span v-if="dataSetEntity.progress.length && dataSetEntity.progress.length > dataSetEntity.progress">
                  / {{ dataSetEntity.progress.length | bytes }}
                </span>
              </div>
            </div>
          </div>
          <div class="dataSet-buttons buttons" v-if="!historyVisible">
            <router-link v-if="checker"
                         :to="{ name: 'urls:url', params: { url: encodeURIComponent(dataSetEntity.url)} }"
                         tag="button" type="button" class="button">
              <img src="/static/icons/link.svg">
            </router-link>
          </div>
        </div>

        <QueryFields :data-set="dataSetEntity" :disabled="!canEdit"></QueryFields>

        <div class="buttons">
          <button type="submit" class="button" role="button" :disabled="!canEdit">{{ i18n.searchButton }}</button>
          <div class="checkbox-field form-field">
            <span class="checkbox">
              <input type="checkbox" class="checkbox-input" id="viewAsXml" :checked="viewAsXml"
                     @input="(ev) => setViewAsXml(ev.target.checked)" />
              <span class="checkbox-mark"></span>
            </span>
            <label class="custom-control-label" for="viewAsXml">
              <slot name="label">{{ i18n.viewAsXml }}</slot>
            </label>
          </div>
          <div class="checkbox-field form-field" v-if="viewAsXml">
            <span class="checkbox">
              <input type="checkbox" class="checkbox-input" id="rawXml" :checked="rawXml"
                     @input="(ev) => setRawXml(ev.target.checked)" />
              <span class="checkbox-mark"></span>
            </span>
            <label class="custom-control-label" for="rawXml">
              <slot name="label">{{ i18n.rawXml }}</slot>
            </label>
          </div>
        </div>
      </command-form>
    </div>
    <div v-if="search && allLoaded && !isDeleted" class="search-info card full-width">
      <div class="search-progress progress-bar" v-if="searchEntity.state != 'done'">
        <div class="progress-bar-fill" v-if="dataSetEntity.progress && dataSetEntity.progress.length"
             :style="{ width: Math.floor(
                 100*(searchEntity.processed/dataSetEntity.entries)
                 *(+dataSetEntity.progress.transferred / +dataSetEntity.progress.length)
                 )+'%' }">
        </div>
        <div class="progress-bar-fill" v-else
             :style="{ width: Math.floor(100*(searchEntity.processed/dataSetEntity.entries))+'%' }">
        </div>
        <div class="progress-text">
          <span>{{ searchEntity.processed }}</span>&nbsp;/&nbsp;<span>{{ dataSetEntity.entries }}</span>
        </div>
      </div>
      <div class="search-status form-group" v-if="searchEntity.state == 'done'">
        <div class="search-state">{{ searchEntity.state }}</div>
        <div class="search-results-count">{{ searchEntity.results }} {{ i18n.results }}</div>
        <div class="search-timestamp">
          {{ (currentTime-(new Date(searchEntity.timestamp)).getTime())/1000 | duration }}
          {{ i18n.ago }}
        </div>
        <div class="search-share-buttons">
          <button v-if="!generator && dataSetEntity && dataSetEntity.url" type="button" class="button"
                  @click="createGenerator">
            <img src="/static/icons/cloud_upload.svg">
          </button>
          <button type="button" class="button" @click="toggleShare">
            <img src="/static/icons/share.svg">
          </button>
        </div>
      </div>
      <div class="search-share buttons" v-if="shareVisible">
        <div class="form-field copy-area">
          <input class="input" :value="freshXmlUrl" readonly>
        </div>
        <button type="button" class="button" @click="copyXmlUrl"><img src="/static/icons/content_copy.svg"></button>
        <!--<a type="button" class="button" :href="xmlUrl"><img src="/static/icons/preview.svg"></a>-->
        <a type="button" class="button" :href="downloadXmlUrl"><img src="/static/icons/save_alt.svg"></a>
      </div>
      <h4 v-if="urlCopied">{{ i18n.urlCopied }}</h4>
      <div class="search-view-options">

      </div>
    </div>
    <GeneratorCard v-if="search && generator && allLoaded && !isDeleted" :generator="generator">
    </GeneratorCard>
    <SearchResults v-if="search && allLoaded && !isDeleted" :search="search" :viewAsXml="viewAsXml" :rawXml="rawXml">
    </SearchResults>
  </page>
</template>

<script>
  import i18n from "i18n"
  import QueryFields from "./QueryFields.vue"
  import SearchResults from "./SearchResults.vue"
  import currentTime from "common/components/utils/currentTime.js"
  import emptyQuery from "./query/emptyQuery.js"
  import api from 'api'
  import copyToClipboard from 'copy-to-clipboard'
  import overlayModel from "common/components/utils/overlayModel.js"

  import SingleSelectInput from "common/components/inputs/SingleSelectInput.vue"
  import FileField from "common/components/fields/FileField.vue"

  import PremiumAlert from "common/components/PremiumAlert.vue"
  import GeneratorCard from "./GeneratorCard.vue"

  import PageNotFound from '../errors/PageNotFound'

  export default {
    name: "XmlSearchPage",
    components: { SingleSelectInput, FileField, QueryFields, SearchResults, GeneratorCard, PageNotFound },
    props: {
      search: {
        type: String,
        default: null
      },
      searchUrl: {
        type: String,
        default: ''
      },
      dataSet: {
        type: String,
        default: null
      }
    },
    inject: ['loadingZone', 'workingZone'],
    data() {
      return {
        editingUrl: false,
        shareVisible: false,
        historyVisible: false,
        viewAsXml: false,
        rawXml: false,
        historyDataSet: null,
        urlCopied: false
      }
    },
    reactive: {
      searchEntity() { return this.search && ['xmlSearch', "search", { search: this.search } ] },
      dataSetEntity() { return (this.searchEntity || this.dataSet)
          && ['xmlSearch', "dataSet", { dataSet: this.searchEntity ? this.searchEntity.dataSet : this.dataSet } ] },
      searchOnline() { return this.search && ['online', 'object', "xmlSearch_Search", this.search ] },
      dataSetOnline() { return (this.searchEntity || this.dataSet)
          && ['online', 'object', "xmlSearch_DataSet", this.searchEntity ? this.searchEntity.dataSet : this.dataSet ] },
      dataSetsHistory() { return this.searchEntity
          && ['xmlSearch', 'dataSetsByUrl', { url: this.searchEntity.url }]},
      generator() { return this.searchEntity && this.searchEntity.url && this.searchEntity.query
          && ['xmlSearch', 'myGeneratorByUrlQuery', { url: this.searchEntity.url, query: this.searchEntity.query }]},
      checker() {
        return this.dataSetEntity && ['urlChecker', 'myCheckerByUrl', { url: this.dataSetEntity.url }]
      }
    },
    reactivePreFetch(route) {
      return [
        {
          what: ['xmlSearch', 'search', { search: route.params.search }],
          more: [
            {
              schema: [['xmlSearch', 'dataSet', { object: { dataSet: { property: 'dataSet' } } }]]
            }
          ]
        }
      ]
    },
    computed: {
      i18n() {
        return i18n().xmlSearch
      },
      allLoaded() {
        if(this.dataSet && !this.dataSetEntity) return false
        return this.search ? (this.searchEntity && this.dataSetEntity) : true
      },
      notFound() {
        if((this.dataSet || this.search) && this.dataSetEntity === null) return true
        if(this.search && this.searchEntity === null) return true
        return false
      },
      canEdit() {
        if(api.session.roles.indexOf('member') != -1) return true
        return false
      },
      currentTime() {
        return currentTime.now
      },
      isDeleted() {
        if(this.dataSetEntity && this.searchEntity) {
          if(this.dataSetEntity.state == 'deleted' || this.searchEntity.state == 'deleted') {
            return true
          }
        }
        return false
      },
      formInitialValues() {
        let searchQuery = this.searchEntity && this.searchEntity.query
        if(searchQuery && searchQuery.length && Array.isArray(searchQuery[0])) { // convert from old format
          searchQuery = [
            {
              type: "include",
              logic: searchQuery
            }
          ]
        }
        return {
          url: (this.dataSetEntity && this.dataSetEntity.url) || decodeURIComponent(this.searchUrl),
          query: (searchQuery) || [
            {
              type: "include",
              logic: [
                [
                  emptyQuery
                ]
              ]
            }
          ]
        }
      },
      urlPrefix() {
        if(typeof window != 'undefined') {
          return document.location.protocol + '//' + document.location.host
        } else {
          return ''
        }
      },
      xmlUrl() {
        const route = this.$router.resolve({ name:'xml:searchResultsViewXml', params: { search: this.search } })
        return this.urlPrefix + route.href
      },
      freshXmlUrl() {
        const route = this.$router.resolve({ name:'xml:searchResultsFreshXml', params: { search: this.search } })
        return this.urlPrefix + route.href
      },
      downloadXmlUrl() {
        const route = this.$router.resolve({ name:'xml:searchResultsDownloadXml', params: { search: this.search } })
        return this.urlPrefix + route.href
      },
      dataSetSearchFields() {
        if(this.dataSetEntity && this.dataSetEntity.fields) return this.dataSetEntity.fields
      },
      dataSetParameter() {
        if(this.dataSetEntity && !this.dataSetEntity.url) return this.dataSetEntity.id
        return this.historyDataSet && this.historyDataSet.id || undefined
      }
    },
    methods: {
      handleDeleted() {
        if(typeof window == 'undefined') return
        if(this.redoingSearch) return

        //console.log("HANDLE RESULT DELETED")
        //this.redoSearch()
      },
      redoSearch(dataSet) {
        if(this.redoingSearch) return
        this.redoingSearch = true
        const searchParams = {
          url: this.searchEntity.url,
          query: this.searchEntity.query,
          dataSet,
          refresh: false
        }
        console.log("SEARCH PARAMS", searchParams)
        this.workingZone.addPromise('search xml',
          api.command(['xmlSearch', 'search'], searchParams).then(result => {
            router.push({ name: 'xml:searchResults', params: { search: result.search } })
            this.redoingSearch = false
          })
        )
      },
      validateUrl(value) {
        console.error("CUSTOM URL VALIDATOR!", value)
        if(value && !value.match(/^https?:\/\//)
         && value.match(/^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)) {
          this.$refs.form.setFieldValue('url', 'https://'+value)
        }
      },
      handleSearchStarted({ result , parameters }) {
        router.push({ name: 'xml:searchResults', params: { search: result.search } })
        this.editingUrl = false
      },
      editUrl() {
        this.editingUrl = true
      },
      toggleShare() {
        this.shareVisible = !this.shareVisible
      },
      toggleHistory() {
        this.historyVisible = !this.historyVisible
        if(!this.historyVisible) {
          this.historyDataSet = null
        }
      },
      copyXmlUrl() {
        copyToClipboard(this.freshXmlUrl)
        this.urlCopied = true
        setTimeout(() => this.urlCopied = false, 3020)
      },
      setViewAsXml(v) {
        this.viewAsXml = v
      },
      setRawXml(v) {
        this.rawXml = v
      },
      historyLabel(dataSet) {
        const date = this.$options.filters.date(dataSet.timestamp)
        const hour = this.$options.filters.hour(dataSet.timestamp)
        return `${date} ${hour}`
      },
      goToHistory(dataSet) {
        this.historyDataSet = dataSet
      },
      handleDisabledClick(ev) {
        if(!ev.target.disabled) return
        overlayModel.show({
          component: PremiumAlert
        })
      },
      createGenerator() {
        this.workingZone.addPromise('create generator',
          api.actions.xmlSearch.createGenerator({
            url: this.searchEntity.url,
            query: this.searchEntity.query
          })
        )
      },
      reloadSearch() {
        setTimeout(() => {
          const form = this.$refs.form
          form.submit()
        }, 100)
      },
      fixSearchFields() {
        const searchFields = this.dataSetSearchFields
        if(!searchFields) return
        //console.log("SEARCH FIELDS UPDATE", searchFields)
        const form = this.$refs.form
        if(!form) {
          setTimeout(() => {
            this.fixSearchFields()
          }, 100)
          return
        }
        const query = form.getFieldValue('query')
        console.log('query', query)
        if(!query) return
        let anyChanged = false
        for(const logicBlockId in query) {
          const logic = query[logicBlockId].logic
          for(const andBlockId in logic) {
            const andBlock = logic[andBlockId]
            for(const filterId in andBlock) {
              const field = `query.${logicBlockId}.logic.${andBlockId}.${filterId}`
              const fields = form.getFieldValue(field+'.fields')
              if(fields == 'all') continue
              let changed = false
              for(let i = 0; i < fields.length; i++) {
                const field = fields[i]
                if(searchFields.indexOf(field) != -1) // field found
                  continue; // ignore field
                if(field.slice(0, 2) == 'g:') {
                  const otherField = field.slice(2)
                  if(searchFields.indexOf(otherField) != -1) {
                    fields[i] = otherField
                    //console.log("CHANGE FIELD", field, 'TO', otherField, searchFields)
                    changed = true
                  }
                } else {
                  const otherField = 'g:' + field
                  if(searchFields.indexOf(otherField) != -1) {
                    fields[i] = otherField
                    //console.log("CHANGE FIELD", field, 'TO', otherField, searchFields)
                    changed = true
                  }
                }
              }
              if(changed) {
                form.setFieldValue(field + '.fields', fields.slice())
                anyChanged = true
              }
            }
          }
        }
        if(anyChanged) {
          console.log("RELOAD SEARCH!")
          this.reloadSearch()
        }
      }
    },
    watch: {
      dataSetSearchFields(searchFields) {
        this.fixSearchFields()
      },
      isDeleted(deleted) {
        if(deleted) this.handleDeleted()
      }
    },
    mounted() {
      if(this.isDeleted) this.handleDeleted()
    }
  }
</script>

<style scoped lang="scss">



</style>
