var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-result card main-card" },
    [
      _vm.viewAsXml
        ? _c(
            "vue-code-highlight",
            {
              key: _vm.rawXml ? "raw" : "xml",
              staticClass: "xml-code-view",
              attrs: { language: "xml" }
            },
            [
              _c("pre", [
                _vm._v(_vm._s(_vm.rawXml ? _vm.cleanedXml : _vm.formattedXml))
              ])
            ]
          )
        : _c("SearchResultData", { attrs: { data: _vm.preparedData } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }