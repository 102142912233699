<template>
  <FieldTemplate :errorText="errorText" :error="error" :label="label" :name="name" :groupClass="groupClass">
    <div :class="['select-input',inputClass,{ disabled }]" @click="showSelectMenu"  ref="select">
      <span class="select-input-value">
        <div v-if="liveValue">
          {{ summary }}
        </div>
        <div v-else class="select-input-placeholder">
          {{ placeholder }}
        </div>
      </span>
      <span class="select-input-more"><img src="/static/icons/arrow_drop_down.svg" /></span>
    </div>
  </FieldTemplate>
</template>

<script>
  import i18n from "i18n"

  import overlayModel from "common/components/utils/overlayModel.js"
  import MultiSelectPicker from "../inputs/MultiSelectPicker.vue"
  import FieldTemplate from "./FieldTemplate.vue"

  export default {
    name: "MultiSelectField",
    props: {
      name: {
        type: String,
        required: true
      },
      label: {
        type: String
      },
      options: {
        type: Array,
        required: true
      },
      text: {
        type: Function,
        default: (v) => v
      },
      pickerText: {
        type: Function,
        required: false
      },
      placeholder: {
        type: String
      },
      errorText: {
        type: Object
      },
      inputClass: {
        type: String,
        default: ""
      },
      groupClass: {
        type: String
      },
      allValue: {
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    components: { FieldTemplate },
    inject: ["form"],
    data() {
      return {
        error: null,
        liveValue: null,
        overlayStackId: null,
        lastCloseTime: 0
      }
    },
    computed: {
      summary() {
        const active = this.liveValue.filter(o =>
          this.options.find(n => JSON.stringify(o) == JSON.stringify(n))
        )
        const remaining = this.options.filter(o =>
          !this.liveValue.find(n => JSON.stringify(o) == JSON.stringify(n))
        )
        if(remaining.length == 0) {
          return "All"
        } else if(active.length > remaining.length) {
          return "All except " + remaining.map(v => this.text(v)).join(', ')
        } else if(active.length > 0) {
          return "Only " + active.map(v => this.text(v)).join(', ')
        } else {
          return "None"
        }
      }
    },
    watch: {
      options(newOptions, oldOptions) {
        /*console.log("OLD OPTIONS", oldOptions)
        console.log("NEW OPTIONS", newOptions)
        console.log("ADDED", newOptions.filter(o => oldOptions.indexOf(o) == -1))
        console.log("REMOVED", oldOptions.filter(o => newOptions.indexOf(o) == -1))
        console.log("WITH VALUE", this.liveValue)*/
        const wasAll = !oldOptions.filter(o => this.liveValue.indexOf(o) == -1).length
        if(wasAll) {
          this.liveValue.push(...(newOptions.filter(o => oldOptions.indexOf(o) == -1)))
        }
      }
    },
    methods: {
      showSelectMenu() {
        if(this.disabled) return
        if(this.overlayStackId === null &&  Date.now() - this.lastCloseTime > 230) {
          this.overlayStackId = overlayModel.show({
            component: MultiSelectPicker,
            props: {
              anchor: overlayModel.elementAnchor(this.$refs.select),
              value: this.liveValue,
              options: this.options,
              text: this.pickerText || this.text
            },
            on: {
              input: (value) => {
                this.liveValue = value.filter(o =>
                  this.options.find(n => JSON.stringify(o) == JSON.stringify(n))
                )
                console.log("INPUT", JSON.stringify(value),
                  "LIVE VALUE", JSON.stringify(this.liveValue),
                  "OPTIONS", JSON.stringify(this.options))
                if(this.allValue && this.liveValue.length == this.options.length) {
                  this.form.setFieldValue(this.name, this.allValue)
                } else {
                  this.form.setFieldValue(this.name, value)
                }
              },
              close: () => {
                this.overlayStackId = null
                this.lastCloseTime = Date.now()
              }
            }
          })
        } else {
          overlayModel.close(this.overlayStackId)
        }
      }
    },
    created() {
      this.valueObserver = (v) => {
        this.liveValue = this.allValue
            ? (JSON.stringify(v) == JSON.stringify(this.allValue) ? this.options.slice() : v)
            : v
      }
      this.errorObserver = (e) => {
        this.error = e
      }
      this.form.observe(this.name, this.valueObserver)
      this.form.observeError(this.name, this.errorObserver)
    },
    beforeDestroy() {
      this.form.unobserve(this.name, this.valueObserver)
      this.form.unobserveError(this.name, this.errorObserver)
    }
  }
</script>
