<template>
  <div class="messenger-view">
    <NavBar></NavBar>
    <ConversationsList></ConversationsList>
  </div>
</template>

<script>
  import i18n from "i18n"
  import analytics from "analytics"
  import api from "@/api"

  import NavBar from "@/segments/NavBar.vue"
  import PrivateConversation from "./PrivateConversation.vue"
  import ConversationsList from "./ConversationsList.vue"

  export default {
    name: "ConversationPage",
    components: { ConversationsList, NavBar },
  }
</script>

<style scoped>

</style>