var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page",
    [
      _c("h1", [_vm._v("XML ALERT!")]),
      _vm._v(" "),
      _vm.scanData
        ? _c("ScanCard", {
            staticClass: "main-card card full-width",
            attrs: { scan: _vm.scanData, scanner: _vm.scannerData }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("scroll-loader", {
        key: _vm.scan,
        staticClass: "full-width",
        attrs: {
          what: function(range) {
            return [
              "xmlAlert",
              "results",
              Object.assign({}, { scan: this$1.scan }, range)
            ]
          },
          noDebugLog: ""
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var result = ref.row
              return [_c("ScanResult", { attrs: { result: result } })]
            }
          },
          {
            key: "loadingBottom",
            fn: function(ref) {
              var connectionProblem = ref.connectionProblem
              return [
                _c("div", { staticClass: "search-bottom-loading-bar" }, [
                  _c("div", { staticClass: "card loading-card" }, [
                    _c("div", { staticClass: "cube-spinner" }, [
                      _c("div", { staticClass: "cube1" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "cube2" })
                    ]),
                    _vm._v(" "),
                    connectionProblem
                      ? _c("span", {
                          staticClass: "loading-text",
                          domProps: {
                            innerHTML: _vm._s(_vm.i18n.connectionProblem)
                          }
                        })
                      : _c("span", {
                          staticClass: "loading-text",
                          domProps: {
                            innerHTML: _vm._s(_vm.i18n.loadingBottom)
                          }
                        })
                  ])
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }