<template>
  <div class="search-results full-width">
    <div v-if="searchEntity && searchEntity.state == 'error'" class="card search-result-error">
      <h2>Error!</h2>
      <pre>{{ searchEntity.error }}</pre>
    </div>
    <scroll-loader v-else-if="search" :what="resultsDaoPath"
                   :key="search" noDebugLog>
      <template v-slot:default="{ row: result }">
        <SearchResult :data="result" :key="result.id" :viewAsXml="viewAsXml" :rawXml="rawXml">
        </SearchResult>
      </template>
      <template v-slot:loadingBottom="{ connectionProblem }">
        <div class="search-bottom-loading-bar">
          <div class="card loading-card">
            <div class="cube-spinner">
              <div class="cube1"></div>
              <div class="cube2"></div>
            </div>
            <span class="loading-text" v-if="connectionProblem" v-html="i18n.connectionProblem"></span>
            <span class="loading-text" v-else v-html="i18n.loadingBottom"></span>
          </div>
        </div>
      </template>
    </scroll-loader>
  </div>
</template>

<script>
  import i18n from 'i18n'

  import SearchResult from "./SearchResult.vue"
  import ScrollLoader from "common/components/ScrollLoader.vue"

  export default {
    name: "SearchResults",
    components: { SearchResult, ScrollLoader },
    props: {
      search: {
        type: String,
        default: null
      },
      viewAsXml: {
        type: Boolean,
        default: false
      },
      rawXml: {
        type: Boolean,
        default: false
      }
    },
    reactive: {
      searchEntity() { return this.search && ['xmlSearch', "search", { search: this.search } ] },
      dataSetEntity() { return this.searchEntity && ['xmlSearch', "dataSet", { dataSet: this.searchEntity.dataSet } ] },
    },
    computed: {
      i18n() {
        return i18n().xmlSearch
      }
    },
    methods: {
      resultsDaoPath({gt, limit, reverse}) {
        return ['xmlSearch', 'searchResults', { search: this.search, from: gt || '', limit: reverse ? 0 : limit }]
      }
    }
  }
</script>

<style scoped>

</style>