var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "conversations-list" }, [
    _c(
      "div",
      { staticClass: "conversations-view" },
      [
        _c("scroll-loader", {
          key: "conversations",
          staticClass: "conversations",
          attrs: {
            what: function(range) {
              return _vm.conversationsDaoPath(range)
            },
            bucketSize: 3,
            readMode: "id",
            hardClose: "",
            noDebugLog: ""
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var history = ref.row
                var rows = ref.rows
                return [
                  _c(
                    "router-link",
                    {
                      staticClass: "conversation-link",
                      attrs: {
                        tag: "div",
                        to: {
                          name: "messenger:conversation",
                          params: {
                            conversationType: history.toType,
                            conversation: history.toId
                          }
                        }
                      }
                    },
                    [
                      _c(
                        "loading-zone",
                        [
                          history.toType == "priv"
                            ? _c("PrivateConversationCard", {
                                attrs: {
                                  selected:
                                    _vm.selectedConversation == history.toId,
                                  history: history
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }