var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.path
    ? _c("div", { staticClass: "naviWrapper" }, [
        _c("nav", { attrs: { "aria-label": "breadcrumb" } }, [
          _c(
            "ol",
            { staticClass: "breadcrumb pl-0 pb-0 bg-transparent" },
            [
              _vm._l(_vm.path.slice(0, -1), function(part) {
                return _c(
                  "li",
                  { staticClass: "breadcrumb-item" },
                  [
                    _c("router-link", { attrs: { to: part } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.i18n[part.name]
                              ? _vm.i18n[part.name](part)
                              : "i18n:" + part.name
                          ) +
                          "\n        "
                      )
                    ])
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _vm._l(_vm.path.slice(-1), function(part) {
                return _c(
                  "li",
                  {
                    staticClass: "breadcrumb-item active",
                    attrs: { "aria-current": "page" }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.i18n[part.name]
                            ? _vm.i18n[part.name](part)
                            : "i18n:" + part.name
                        ) +
                        "\n      "
                    )
                  ]
                )
              })
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "breadcrumb-back-button col-12 d-block text-left pl-0 ml-0"
          },
          [
            _c(
              "a",
              {
                staticClass: "btn btn-primary btn-xs",
                attrs: { href: "#", role: "button" },
                on: {
                  click: function($event) {
                    return _vm.$router.go(-1)
                  }
                }
              },
              [
                _c("static-picture", {
                  attrs: { picture: "/static/ICO_chevron_left.svg" }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "pr-2" }, [
                  _vm._v(_vm._s(_vm.i18n.back))
                ])
              ],
              1
            )
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }