<template>
  <form-field-bind class="or-block" :name="name" v-slot="{ value }" v-if="!deleting">
    <div v-for="(item, index) in value">
      <div v-if="index>0" class="or-separator">OR</div>
      <SingleFilter :name="name+'.'+index" :key="name+'.'+index" :onlyOne="onlyOne && value.length <= 1"
                    :dataSet="dataSet" :disabled="disabled" :last="index == value.length - 1" :last-and="last"
                    @or="() => handleOr(index)" @delete="() => handleDelete(index)"
                    @and="$emit('and')"
                    @more="$emit('more')">
      </SingleFilter>
    </div>
  </form-field-bind>
</template>

<script>
  import SingleFilter from "./SingleFilter.vue"
  import emptyQuery from "./emptyQuery.js"

  export default {
    name: "OrBlock",
    components: { SingleFilter },
    inject: ['form'],
    props: {
      dataSet: {
        type: Object
      },
      disabled: {
        type: Boolean,
        default: false
      },
      name: {
        type: String,
        default: "query"
      },
      onlyOne: {
        type: Boolean,
        default: true
      },
      last: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        deleting: false
      }
    },
    methods: {
      handleOr(index) {
        const value = this.form.getFieldValue(this.name)
        const inserted = emptyQuery
        const updated = [ ...value.slice(0, index+1), inserted, ...value.slice(index+1) ]
        this.form.setFieldValue(this.name, updated)
      },
      handleDelete(index) {
        const value = this.form.getFieldValue(this.name)
        if(value.length == 1) return this.$emit('delete')
        this.deleting = true
        this.$nextTick(() => {
          const value = this.form.getFieldValue(this.name)
          const updated = [ ...value.slice(0, index), ...value.slice(index+1) ]
          this.form.setFieldValue(this.name, updated)
          this.deleting = false
        })
      }
    }
  }
</script>

<style scoped>

</style>