var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("page", { attrs: { footer: true } }, [
    _vm.state == "ready"
      ? _c(
          "div",
          { staticClass: "card half-width" },
          [
            _c("h2", {
              staticClass: "text-center registerPage-heading-title",
              domProps: { innerHTML: _vm._s(_vm.i18n.title) }
            }),
            _vm._v(" "),
            _c("SocialAuth"),
            _vm._v(" "),
            _c("h4", [_vm._v(_vm._s(_vm.i18n.orUseEmail))]),
            _vm._v(" "),
            _c(
              "section",
              { staticClass: "loginPage-email-section" },
              [
                _c(
                  "command-form",
                  {
                    key: "register",
                    attrs: {
                      service: "emailPassword",
                      action: "startRegister",
                      "external-errors-processing": true,
                      parameters: { lang: _vm.lang }
                    },
                    on: {
                      done: _vm.handleRegisterStarted,
                      error: function(v) {
                        return _vm.handleError(v)
                      }
                    }
                  },
                  [
                    _c("text-field", {
                      attrs: {
                        name: "userData.name",
                        label: _vm.i18n.name,
                        inputClass: "form-control-lg col-md-11",
                        groupClass: "mb-3 col-md-12"
                      }
                    }),
                    _vm._v(" "),
                    _c("text-field", {
                      attrs: {
                        name: "email",
                        label: _vm.i18n.email,
                        inputClass: "form-control-lg col-md-11",
                        groupClass: "mb-3 col-md-12"
                      }
                    }),
                    _vm._v(" "),
                    _vm.resendEmail
                      ? _c(
                          "div",
                          { staticClass: "custom-form-error text-danger" },
                          [
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.resendConfirmationEmail.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.i18n.resendButton))]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("double-password-field", {
                      attrs: {
                        name: "passwordHash",
                        label: _vm.i18n.password,
                        retypeLabel: _vm.i18n.retypePassword,
                        inputClass: "form-control-lg col-md-11",
                        groupClass: "mb-3 col-md-12"
                      }
                    }),
                    _vm._v(" "),
                    _c("boolean-field", {
                      attrs: { name: "userData.agreement1" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function() {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.i18n.agreement1) +
                                    " "
                                ),
                                _c("a", { attrs: { href: _vm.i18n.policy } }, [
                                  _vm._v("Privacy Policy")
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        1142994802
                      )
                    }),
                    _vm._v(" "),
                    _c("boolean-field", {
                      attrs: { name: "userData.agreement2" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function() {
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.i18n.agreement2) +
                                    "\n          "
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        3345449826
                      )
                    }),
                    _vm._v(" "),
                    _c("phone-field-with-confirm", {
                      key: "phoneField",
                      attrs: {
                        name: "userData.phone",
                        label: _vm.i18n.phone,
                        confirmName: "phoneConfirm",
                        confirmTexts: _vm.i18n.confirmSms,
                        comment: _vm.i18n.phoneComment
                      }
                    }),
                    _vm._v(" "),
                    _c("form-field-bind", {
                      attrs: { name: "userData.phone" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var phone = ref.value
                              return [
                                phone
                                  ? _c(
                                      "div",
                                      [
                                        _c("recaptcha-field", {
                                          attrs: {
                                            inputClass:
                                              "form-control-lg col-md-11",
                                            groupClass: "mb-3 col-md-12"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "buttons" }, [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "button",
                                              attrs: {
                                                type: "submit",
                                                role: "button"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.i18n.signUpButton)
                                                )
                                              ])
                                            ]
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3727930947
                      )
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm.state == "ready"
              ? _c(
                  "section",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { name: "user:login" }, tag: "a" } },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.i18n.loginButton) +
                            "\n      "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.state == "started"
      ? _c("div", { staticClass: "card half-width" }, [
          _vm._v("\n    " + _vm._s(_vm.i18n.linkSent) + "\n  ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.state == "emailSent"
      ? _c("div", { staticClass: "card half-width" }, [
          _vm._v("\n    " + _vm._s(_vm.i18n.linkSent) + "\n  ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }