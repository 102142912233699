<template>
  <div class="messages-view">
    <scroll-loader :what="range => messagesDaoPath(range)" class="messages"
                   :startPosition="'end'" stickyEnd trackVisibleRows shardClose
                   v-slot:default="{ row: message, rows, index }" :key="`${toType}_${toId}`"
                   :bucketSize="10" readMode="id" noDebugLog
                   @rowVisible="updateRead" ref="scrollLoader">
      <PrivateMessage v-if="messageVisible(message, rows)" :message="message"
                      :prev="prevMessage(rows, index)" :next="nextMessage(rows, index)"
                      :key="message.id" :readPositions="messageReadPositions(message)">
      </PrivateMessage>
      <!--<p v-else>HIDDEN {{ message.text }}</p>-->
    </scroll-loader>
  </div>
</template>

<script>

  import api from "@/api"

  import PrivateMessage from "./PrivateMessage.vue"
  import ScrollLoader from "common/components/ScrollLoader.vue"

  export default {
    name: "PrivateMessages",
    components: { PrivateMessage, ScrollLoader },
    props: {
      toType: {
        type: String,
        required: true
      },
      toId: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        lastRead: '',
        lastSentRead: ''
      }
    },
    reactive: {
      readHistories() {
        return ['readHistory', 'readHistories', { toType: this.toType, toId: this.toId }]
      }
    },
    computed: {
      otherUsersReadHistories() {
        return this.readHistories && this.readHistories.filter(history =>
            (history.user != api.session.session.user && history.session != api.session.publicSessionInfo.id)
        )
      },
      hidden() {
        return api.visibility.hidden
      }
    },
    watch: {
      hidden(v) {
        setTimeout(() => {
          if(!this.hidden) this.checkVisibleRows()
        }, 100)
      }
    },
    methods: {
      messageReadPositions(message) {
        return this.otherUsersReadHistories &&
            this.otherUsersReadHistories.filter(history => history.read == message.id)
      },
      messagesDaoPath(range) {
        return ['clientMessages', 'messages', {
          ...range,
          waitForAll: true,
          toType: this.toType,
          toId: this.toId,
        }]
      },
      updateRead(row) {
        if(this.hidden) return
        console.log("UPDATE READ", row.id, this.lastRead, JSON.stringify(row))
        if(row.id > this.lastRead) {
          this.lastRead = row.id
          setTimeout(() => this.sendRead(), 300)
        }
      },
      sendRead() {
        if(this.lastRead > this.lastSentRead) {
          this.lastSentRead = this.lastRead
          console.log("SEND READ!!!", this.lastRead)
          api.dao.requestWithSettings({ requestTimeout: 10000 }, ['readHistory', 'read', {
            toType: this.toType,
            toId: this.toId,
            eventId: this.lastRead
          }]).catch(error => {
            if(error == 'timeout') setTimeout(() => this.sendRead(), 1000)
          })
        }
      },
      messageVisible(message, allMessages) {
        if(message.sendState && message.id.slice(-4) == '.csp') {
          if(message.sendState == 'sync') {
            return false
          }
          for(let otherMessage of allMessages) {
            if(otherMessage.sent == message.sent
               && otherMessage != message
               && otherMessage.user == message.user
               && otherMessage.id.slice(-4) != '.csp'
               && otherMessage.text == message.text) {
              console.log("CSP MESSAGE", message.text, "HIDDEN", otherMessage)
              return false
            }
          }
          return true
        }
        return true
      },
      prevMessage(rows, index) {
        while(rows[index-1] && !this.messageVisible(rows[index-1], rows)) index--
        return rows[index-1]
      },
      nextMessage(rows, index) {
        while(rows[index+1] && !this.messageVisible(rows[index+1], rows)) index++
        return rows[index+1]
      },
      checkVisibleRows() {
        const scrollLoader = this.$refs.scrollLoader
        console.log("ALL ROWS VISIBLE ON MOUNTED", scrollLoader.visibleRows.length)
        let biggestId = '', biggestRow
        for(const row of scrollLoader.visibleRows) {
          if(row.id > biggestId
              && row.user != api.session.session.user && row.session != api.session.publicSessionInfo.id) {
            biggestId = row.id
            biggestRow = row
          }
        }
        if(biggestRow) this.updateRead(biggestRow)
      }
    },
    mounted() {
      setTimeout(() => {
        if(!this.hidden) this.checkVisibleRows()
      }, 100)
    }
  }
</script>

<style scoped>

</style>