var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Notification",
    { ref: "notification", attrs: { notification: _vm.notification } },
    [
      _c(
        "div",
        { staticClass: "notification-avatar", on: { click: _vm.goTo } },
        [
          _c("img", {
            staticClass: "avatar-addon",
            attrs: { src: "/static/icons/scanner.svg" }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "notification-content", on: { click: _vm.goTo } },
        [
          _c("div", { staticClass: "notification-title" }, [
            _vm._v(_vm._s(_vm.i18n.notifications.started.title))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "notification-description" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.i18n.notifications.started.text) +
                " " +
                _vm._s(_vm.notification.url || "unknown") +
                "\n    "
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }