<template>
  <img :src="url" :width="width" :height="height" @load="ev => $emit('load', ev)" :title="title">
</template>

<script>
  export default {
    name: "UploadedPictureImg",
    props: {
      picture: {
        required: true
      },
      circle: {
        type: Boolean,
        default: false
      },
      original: {
        type: Boolean,
        default: false
      },
      reactive: {
        type: Boolean,
        default: false
      },
      width: {
      },
      height: {
      },
      noResize: {
      },
      empty: {
        type: String,
        default: "/static/empty-photo.svg"
      },
      title: {
        type: String,
        default: null
      }
    },
    reactive: {
      pictureData() { return this.reactive && this.picture && [ 'pictures', 'PictureOne', { picture: this.picture } ] }
    },
    watch: {
      pictureData(v, ov) {
        if(!ov) return;
        console.log("RELOAD PICTURE")
        this.refreshSuffix=`?${Date.now()}`
      }
    },
    data() {
      return {
        refreshSuffix: '',
        dpr: (typeof window == 'undefined') ? 1.0 : window.devicePixelRatio
      }
    },
    computed: {
      suffix() {
        const dpr = this.dpr
        if(this.noResize) return ''
        if(this.width && this.height) return `/rect-${(this.width*dpr)|0}-${(this.height*dpr)|0}`
        if(this.width) return `/width-${(this.width*dpr)|0}`
        if(this.height) return `/height-${(this.height*dpr)|0}`

        if(!this.pictureData) return `/width-${1024 * dpr}`
        const { width, height } = this.original ? this.pictureData.original : this.pictureData.crop
        const ratio = Math.max(width / (1024*dpr), height / (1024 * dpr))
        if(ratio > 1.0) {
          return `/width-${(width/ratio)| 0}`
        }
        return ''
      },
      url() {
        if(!this.picture) return this.empty
        const base = `/pictures/${this.picture}`
        if(this.original) return `${base}/original${this.suffix}`+this.refreshSuffix
        return `${base}/crop${this.suffix}`+this.refreshSuffix
      }
    }
  }
</script>

<style scoped>
  .picture {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
</style>