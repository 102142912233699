var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["xml-alert-result", _vm.result.severity] }, [
    _c(
      "div",
      { staticClass: "card xml-alert-result-title" },
      [
        _c("div", { staticClass: "xml-alert-result-title-text" }, [
          _vm.result.code
            ? _c("span", { staticClass: "xml-alert-result-code" }, [
                _vm._v(_vm._s(_vm.result.code))
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.result.code ? _c("span", [_vm._v("-")]) : _vm._e(),
          _vm._v(" "),
          _c("span", { staticClass: "xml-alert-result-type" }, [
            _vm._v(
              _vm._s(_vm.i18n.resultType[_vm.result.type] || _vm.result.type)
            )
          ]),
          _vm._v(" "),
          _vm.result.field ? _c("span", [_vm._v("in")]) : _vm._e(),
          _vm._v(" "),
          _vm.result.field
            ? _c("span", { staticClass: "xml-alert-result-field" }, [
                _vm._v(_vm._s(_vm.fieldText))
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        !_vm.noMore
          ? _c(
              "router-link",
              {
                staticClass: "button",
                attrs: {
                  to: {
                    name: "xml:alert:scan:item",
                    params: {
                      scanner: _vm.$route.params.scanner,
                      scan: _vm.$route.params.scan,
                      position: _vm.position
                    }
                  },
                  tag: "a"
                }
              },
              [_vm._v("\n      " + _vm._s(_vm.i18n.more) + "\n    ")]
            )
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _vm.result.url ||
    _vm.result.at ||
    _vm.result.next ||
    _vm.result.content ||
    _vm.result.xmlData ||
    _vm.result.itemData ||
    _vm.result.fromData ||
    _vm.result.toData
      ? _c("div", { staticClass: "card xml-alert-result-content" }, [
          _c("table", { staticClass: "xml-alert-result-table" }, [
            _c("tbody", [
              _vm.result.url && _vm.result.at != _vm.result.url
                ? _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.i18n.srcUrl) + ":")]),
                    _c("td", [
                      _c(
                        "a",
                        { attrs: { href: _vm.result.url, target: "_blank" } },
                        [_vm._v(_vm._s(_vm.result.url))]
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.result.at
                ? _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.i18n.atUrl) + ":")]),
                    _c("td", [
                      _c(
                        "a",
                        { attrs: { href: _vm.result.at, target: "_blank" } },
                        [_vm._v(_vm._s(_vm.result.at))]
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.result.next
                ? _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.i18n.toUrl) + ":")]),
                    _c("td", [
                      _c(
                        "a",
                        { attrs: { href: _vm.result.next, target: "_blank" } },
                        [_vm._v(_vm._s(_vm.result.next))]
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.result.image
                ? _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.i18n.image) + ":")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", [_vm._v(_vm._s(_vm.result.image.type))]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.result.image.width))]),
                      _c("span", [_vm._v(_vm._s(_vm.result.image.wUnits))]),
                      _vm._v(" x\n            "),
                      _c("span", [_vm._v(_vm._s(_vm.result.image.height))]),
                      _c("span", [_vm._v(_vm._s(_vm.result.image.hUnits))])
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.result.xmlData
                ? _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.i18n.xml))]),
                    _c("td", [_vm._v(_vm._s(_vm.result.xmlData))])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.result.itemData
                ? _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.i18n.itemProp))]),
                    _c("td", [_vm._v(_vm._s(_vm.result.itemData))])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.result.fromData
                ? _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.i18n.resultFrom))]),
                    _c("td", [_vm._v(_vm._s(_vm.result.fromData))])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.result.toData
                ? _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.i18n.resultTo))]),
                    _c("td", [_vm._v(_vm._s(_vm.result.toData))])
                  ])
                : _vm._e()
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }