<template>
  <div class="peer-connection-debugger">
    <header class="navbar">
      <div class="navbar-content" ref="content">
        <span class="navbar-item navbar-brand">
          <router-link :to="{name: 'index' }" tag="a" class="navbar-brand-link">
            Peer Connection Debugger
          </router-link>
        </span>
      </div>
    </header>

    <div class="peer-connection-debugger-content">

      <h1>Peer Connection Debugger</h1>
      <div v-if="peer">
        <h2>Peer connection</h2>
        <pre>{{ JSON.stringify(peer.summary, null, "  ") }}</pre>
        <div class="buttons">
          <button type="button" role="button" class="button" @click="() => peer.setOnline(true)">Set Online</button>
          <button type="button" role="button" class="button" @click="() => peer.setOnline(false)">Set Offline</button>
          <button type="button" role="button" class="button" @click="sendTestMessage">Test Message</button>
        </div>
      </div>
      <div v-for="remoteStream in remoteStreams">
        <h2>Remote stream {{ remoteStream.stream.id }} from {{ remoteStream.from }}</h2>
        <video autoplay playsinline :src-object.prop.camel="remoteStream.stream">
        </video>
      </div>

      <!--<div>
        <h2>Devices</h2>
        <pre>{{ JSON.stringify(devices, null, "  ") }}</pre>
      </div>-->

      <div>
        <h2>Local data channels</h2>
        <input type="text" v-model="newDataChannelLabel">
        <button @click="createDataChannel">createDataChannel</button>
      </div>

      <div>
        <h2>User media</h2>

        <single-select-input v-if="videoInputDevices && videoInputDevices.length>0"
                             v-model="selectedVideoInput"
                             :options="videoInputDevices"
                             :text="option => option ? (option.label || 'unknown') : 'Browser default'"
                             :placeholder="'Select video device...'">
        </single-select-input>
        <single-select-input v-if="audioInputDevices && audioInputDevices.length>0"
                             v-model="selectedAudioInput"
                             :options="audioInputDevices"
                             :text="option => option ? (option.label || 'unknown') : 'Browser default'"
                             :placeholder="'Select audio device...'">
        </single-select-input>

        <div class="buttons" v-if="!userMedia">
          <button class="button" @click="getUserMedia">getUserMedia</button>
        </div>
        <div class="buttons" v-if="userMedia">
          <button class="button" @click="dropUserMedia">drop UserMedia</button>
          <button v-if="userMediaMuted" type="button" class="button" @click="() => userMediaMuted = false">
            Unmute user media
          </button>
          <button v-if="!userMediaMuted" type="button" class="button" @click="() => userMediaMuted = true">
            Mute user media
          </button>
        </div>
        <video v-if="userMedia" autoplay playsinline :muted="userMediaMuted"
               :src-object.prop.camel="userMedia">
        </video>
      </div>



      <div>
        <h2>Display media</h2>

        <div class="buttons" v-if="!displayMedia">
          <button class="button" @click="getDisplayMedia">getDisplayMedia</button>
        </div>
        <div class="buttons" v-if="displayMedia">
          <button class="button" @click="dropDisplayMedia">drop DisplayMedia</button>
        </div>
        <video v-if="displayMedia" autoplay playsinline muted
               :src-object.prop.camel="displayMedia">
        </video>
      </div>

      <div v-for="(track, index) in (peer ? peer.localTracks : [])">
        Track #{{ index }} {{ track.track.kind }} ({{ track.track.label }}) enabled: {{ track.enabled }}
        id: {{ track.track.id }}
        <div class="buttons">
          <button type="button" class="button" v-if="!track.enabled"
                  @click="() => peer.setTrackEnabled(track, true)">
            Enable Track
          </button>
          <button type="button" class="button" v-if="track.enabled"
                  @click="() => peer.setTrackEnabled(track, false)">
            Disable Track
          </button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { createPeer } from "./Peer.js"
import api from "api"
import { getUserMedia, getDisplayMedia, isUserMediaPermitted } from "./userMedia.js"
import Page from "@/segments/Page.vue"
import PermissionsModal from "./PermissionsModal.vue"
import overlayModel from "common/components/utils/overlayModel.js"
import MultiButtonDialog from "common/components/MultiButtonDialog.vue"
import SingleSelectInput from "common/components/inputs/SingleSelectInput.vue"
import DataChannel from "./DataChannel.js"

export default {
  name: "Debugger",
  props: {
    toType: {
      type: String,
      required: true
    },
    toId: {
      type: String,
      required: true
    }
  },
  components: { Page, SingleSelectInput },
  data() {
    return {
      peer: null,
      userMedia: null,
      displayMedia: null,
      userMediaMuted: true,
      devices: [],
      selectedVideoInput: null,
      selectedAudioInput: null,
      newDataChannelLabel: ""
    }
  },
  computed: {
    remoteStreams() {
      if(!this.peer) return []
      let remoteStreams = []
      for(const connection of this.peer.connections) {
        for(const remoteTrack of connection.remoteTracks) {
          if(remoteStreams.find(remoteStream => remoteStream.stream == remoteTrack.stream)) continue;
          remoteStreams.push({
            from: connection.to,
            stream: remoteTrack.stream
          })
        }
      }
      return remoteStreams
    },
    videoInputDevices() {
      return this.devices.filter(d => d.kind == 'videoinput')
    },
    audioInputDevices() {
      return this.devices.filter(d => d.kind == 'audioinput')
    },
    userMediaConstraints() {
      return {
        video: (this.selectedVideoInput && this.selectedVideoInput.deviceId)
            ? { deviceId: this.selectedVideoInput.deviceId }
            : true,
        audio: (this.selectedAudioInput && this.selectedAudioInput.deviceId)
            ? { deviceId: this.selectedAudioInput.deviceId }
            : true,
      }
    },
    localMediaStreams() {
      const userMedia = this.userMedia
      const displayMedia = this.displayMedia
      return (userMedia ? [userMedia] : []).concat(displayMedia ? [displayMedia] : [])
    }
  },
  watch: {
    async userMediaConstraints(value) {
      if(this.userMedia) {
        await this.dropUserMedia()
        await this.getUserMedia()
      }
    },
    userMedia(mediaStream, oldMediaStream) {
      console.log("USER MEDIA STREAM CHANGE:", mediaStream, oldMediaStream)
      this.readDevices()
      if(oldMediaStream) {
        console.log("OLD MEDIA STREAM", oldMediaStream)
        oldMediaStream.getTracks().forEach(track => { if (track.readyState == 'live') track.stop() })
      }
    },
    displayMedia(mediaStream, oldMediaStream) {
      console.log("DISPLAY MEDIA STREAM CHANGE:", mediaStream, oldMediaStream)
      if(oldMediaStream) {
        const track = oldMediaStream.getVideoTracks()[0]
        if(track) track.removeEventListener('ended', this.displayMediaEndedHandler)

        console.log("OLD MEDIA STREAM", oldMediaStream)
        oldMediaStream.getTracks().forEach(track => { if (track.readyState == 'live') track.stop() })
      }
      if(mediaStream) {
        const track = mediaStream.getVideoTracks()[0]
        if(track) track.addEventListener('ended', this.displayMediaEndedHandler)
      }
    },
    localMediaStreams(streams) {
      if(this.peer) {
        this.peer.localMediaStreams = [...streams]
      }
    }
  },
  mounted() {
    if(typeof window == 'undefined') return
    this.initPeer()
    this.readDevices()

    window.peer = this.peer

    this.displayMediaEndedHandler = () => this.displayMedia = null

    this.deviceChangeHandler = () => this.readDevices()
    if(navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices.addEventListener('devicechange', this.deviceChangeHandler)
    }
  },
  methods: {
    createDataChannel() {
      const label = this.newDataChannelLabel
      this.newDataChannelLabel = ""
      const channel = new DataChannel(label, { ordered: false })
      this.peer.localDataChannels.push(channel)
    },
    async readDevices() {
      if(navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        const devices = await navigator.mediaDevices.enumerateDevices()
        this.devices = devices.map(({ deviceId, groupId, kind, label }) => ({ deviceId, groupId, kind, label }))
      }
    },
    async initPeer() {
      let publicSessionInfo = api.session.publicSessionInfo
      if(!publicSessionInfo) {
        publicSessionInfo = await api.dao.get(['accessControl', 'myPublicSessionInfo'])
      }
      this.peer = createPeer(api,
          { toType: this.toType, toId: this.toId, publicSessionId: publicSessionInfo.id })
      this.peer.localMediaStreams = this.localMediaStreams
    },
    async getUserMedia() { // media stream retrival logic
      let constraints = { ...this.userMediaConstraints } // make a copy
      while(true) {
        try {
          console.log("TRY GET USER MEDIA", constraints)
          const mediaStream = await getUserMedia(constraints)
          const videoTracks = mediaStream.getVideoTracks()
          const audioTracks = mediaStream.getAudioTracks()
          console.log('Got stream with constraints:', constraints)
          if(constraints.video) console.log(`Using video device: ${videoTracks[0] && videoTracks[0].label}`)
          if(constraints.audio) console.log(`Using audio device: ${audioTracks[0] && audioTracks[0].label}`)
          this.userMedia = mediaStream
          return;
        } catch(error) {
          console.log("GET USER MEDIA ERROR", error)
          const permitted = await isUserMediaPermitted(constraints)
          if(permitted || error.code == error.NOT_FOUND_ERR) {
            constraints = await this.askToConnectCamera({ ...this.userMediaConstraints })
            if(!constraints) return
          } else { // if not permitted display dialog
            const permitted = await this.showPermissionsModal()
            console.log("CAMERA PERMITTED", permitted)
            if(!permitted) constraints.video = false
            if(!(constraints.video || constraints.audio)) {
              constraints = await this.askToConnectCamera({ ...this.userMediaConstraints })
              if(!constraints) return
            }
            continue // retry get user media with new constraints
          }
        }
      }
    },
    async dropUserMedia() {
      this.userMedia = null
    },
    async showPermissionsModal() {
      const micPermission = await navigator.permissions.query({ name: 'microphone' })

      return new Promise((resolve, reject) => {
        overlayModel.show({
          component: PermissionsModal,
          props: {
            title: "User media permissions",
            introduction: '<p>Please enable camera access:</p><img src="/static/cameraAccess/en.png">',
            closeable: false,
            requiredPermissions: [
              { name: "microphone" },
              { name: "camera" }
            ],
            buttons: [
              (micPermission.state == 'denied'
                  ? { name: "Cancel", event: "cancel", needPermissions: false }
                  : { name: "Disable camera", event: "disabled", needPermissions: false }),
              { name: "Ok", event: "ok", needPermissions: true },
            ]
          },
          on: {
            disabled: () => {
              resolve(false)
            },
            ok: () => {
              resolve(true)
            },
            cancel: () => {
              reject('canceled by user')
            }
          }
        })
      })
    },
    async askToConnectCamera(constraints) {
      return new Promise((resolve, reject) => {
        overlayModel.show({
          component: MultiButtonDialog,
          props: {
            title: `User media`,
            text: `Please connect camera and microphone or choose one of following options:`,
            buttons: [
              { event: 'connected', text: 'Ok, connected' },
              { event: 'camera', text: 'I will use only camera' },
              { event: 'microphone', text: 'I will use only microphone' },
              { event: 'cancel', text: 'Cancel' }
            ]
          },
          on: {
            connected: () => resolve({ ...constraints }),
            camera: () => resolve({ ...constraints, audio: false }),
            microphone: () => resolve({ ...constraints, video: false }),
            cancel: () => resolve(null)
          }
        })
      })
    },
    async getDisplayMedia() { // media stream retrival logic
      let initialConstraints = { video: true } // make a copy
      let constraints = { ...initialConstraints }
      while(true) {
        try {
          console.log("TRY GET DISPLAY MEDIA", constraints)
          const mediaStream = await getDisplayMedia(constraints)
          const videoTracks = mediaStream.getVideoTracks()
          const audioTracks = mediaStream.getAudioTracks()
          console.log('Got stream with constraints:', constraints)
          if(constraints.video) console.log(`Using video device: ${videoTracks[0] && videoTracks[0].label}`)
          if(constraints.audio) console.log(`Using audio device: ${audioTracks[0] && audioTracks[0].label}`)
          this.displayMedia = mediaStream
          return;
        } catch(error) {
          console.log("GET DISPLAY MEDIA ERROR", error)
          return;
          const permitted = await isUserMediaPermitted(constraints)
          if(permitted || error.code == error.NOT_FOUND_ERR) {
            constraints = await this.askToConnectCamera(initialConstraints)
            if(!constraints) return
          } else { // if not permitted display dialog
            const permitted = await this.showPermissionsModal()
            console.log("CAMERA PERMITTED", permitted)
            if(!permitted) constraints.video = false
            if(!(constraints.video || constraints.audio)) {
              constraints = await this.askToConnectCamera(initialConstraints)
              if(!constraints) return
            }
            continue // retry get user media with new constraints
          }
        }
      }
    },
    async dropDisplayMedia() {
      this.displayMedia = null
    },
    sendTestMessage() {
      for(const connection of this.peer.connections) {
        this.peer.sendMessage({
          to: connection.to,
          type: "ping",
          data: new Date().toISOString()
        })
      }
    }
  },
  beforeDestroy() {
    this.peer.$destroy()
  }
}
</script>

<style scoped lang="scss">
  .peer-connection-debugger {

    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: white;


    .peer-connection-debugger-content {
      position: absolute;
      left: 0;
      top: 50px;
      bottom: 0;
      width: 100%;
      height: auto;
      overflow: auto;
      background: white;
    }
  }
</style>