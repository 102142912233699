var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-field" },
    [
      _c(
        "command-form",
        {
          key: "phone",
          staticClass: "form-group",
          attrs: {
            service: "smsConfirm",
            action: "sendSmsConfirmationCode",
            initialValues: { phone: _vm.phone },
            parameters: { lang: _vm.lang },
            keepOnDone: true,
            formTag: "div"
          },
          on: { done: _vm.handleSent }
        },
        [
          _c("form-field-bind", {
            attrs: { name: "phone" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var value = ref.value
                  var setValue = ref.setValue
                  var error = ref.error
                  return [
                    _c(
                      "FieldTemplate",
                      {
                        attrs: {
                          errorText: _vm.errorText,
                          error: error,
                          label: _vm.label,
                          name: "phone"
                        }
                      },
                      [
                        _c("PhoneInput", {
                          staticClass: "phoneNumber",
                          attrs: {
                            disabled: _vm.sent,
                            countryNames: _vm.countryNames,
                            value: value,
                            invalid: !!error
                          },
                          on: { input: setValue }
                        }),
                        _vm._v(" "),
                        _vm.comment
                          ? _c("small", [_vm._v(_vm._s(_vm.comment))])
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "buttons" },
            [
              !_vm.sent
                ? _c("submit-button", [
                    _c("span", [_vm._v(_vm._s(_vm.confirmTexts.confirmButton))])
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.sent
        ? _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("text-field", {
                attrs: {
                  name: _vm.confirmName,
                  label: _vm.confirmTexts.phoneConfirm
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.sent && _vm.waitTime > 0
        ? _c("div", { staticClass: "form-info" }, [
            _c("p", [
              _vm._v(
                _vm._s(_vm.confirmTexts.resendWaitPart1) +
                  " " +
                  _vm._s(_vm.waitTimeFormatted) +
                  " " +
                  _vm._s(_vm.confirmTexts.resendWaitPart2)
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.sent && _vm.waitTime < 0
        ? _c("div", { staticClass: "form-info" }, [
            _c("p", [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.resendCode.apply(null, arguments)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.confirmTexts.resendButton))]
              ),
              _vm._v("\n      |\n      "),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.editPhone.apply(null, arguments)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.confirmTexts.editPhone))]
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }