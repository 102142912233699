<template>
  <div class="video-display-video" @click="ev => $emit('click', ev)">
    <video v-if="video.type == 'video' && video.srcObject" autoplay playsinline
           :src-object.prop.camel="video.srcObject"
           :volume.prop.camel="volume"
           :ref="video"
           @resize="handleVideoResize"
           v-bind="video.props">
    </video>
    <video v-else-if="video.type == 'video' && video.src" autoplay playsinline
           :src="video"
           :volume.prop.camel="volume"
           ref="video"
           @resize="handleVideoResize"
           v-bind="video.props">
    </video>
    <img v-else-if="video.type =='image'"
         :src="video.src"
         ref="image"
         @load="handleImageLoad"
         v-bind="video.props">
    <uploaded-picture-img v-else-if="video.type == 'audio' && publicUserData && publicUserData.picture"
                          @load="handleImageLoad"
                          width="400" height="400" :picture="publicUserData.picture">
    </uploaded-picture-img>
    <img v-else-if="video.type == 'audio'"
         @load="handleImageLoad"
         src="/static/empty-user-photo.svg" width="400" height="400">

    <audio v-if="video.type == 'audio' && video.srcObject && (!video.props || !video.props.muted)"
           autoplay playsinline
           :src-object.prop.camel="video.srcObject"
           :volume.prop.camel="volume"
           v-bind="video.props"></audio>
    <audio v-if="video.type == 'audio' && video.src && (!video.props || !video.props.muted)"
           autoplay playsinline
           :src="video.src"
           :volume.prop.camel="volume"></audio>

    <uploaded-picture class="muted-user-picture"
        v-else-if="(video.type == 'video' && (peerState && peerState.videoState == 'muted') || video.muted)
         && (publicUserData && publicUserData.picture)"
        width="400" height="400" :picture="this.publicUserData.picture">
    </uploaded-picture>

    <VolumeIndicator v-if="video.srcObject" :stream="video.srcObject"></VolumeIndicator>
    <div class="peer-state-icons" v-if="peerState">
      <img v-if="false" src="/static/icons/no_internet_red.svg">
      <img v-if="peerState.videoState == 'muted'" src="/static/icons/videocam_off_red.svg">
      <img v-if="peerState.audioState == 'muted'"src="/static/icons/mic_off_red.svg">
    </div>

  </div>
</template>

<script>
import VolumeIndicator from "./VolumeIndicator.vue"

export default {
  name: "VideoDisplayVideo",
  props: {
    video: {
      type: Object,
      required: true
    },
    volume: {
      type: Number,
      defaultValue: 1
    }
  },
  components: { VolumeIndicator },
  reactive: {
    publicSessionInfo() {
      return this.publicSessionId
          && ['accessControl', 'publicSessionInfo', { publicSessionInfo: this.publicSessionId }]
    },
    publicUserData() {
      return this.publicSessionInfo && this.publicSessionInfo.user
          && ['users', "publicUserData", { user: this.publicSessionInfo.user } ]
    },
    peerState() {
      return this.video && ['peerConnection', 'peerState', { peer: this.video.from }]
    }
  },
  computed: {
    publicSessionId() {
      return this.video && this.video.from && this.video.from.split('_')[2]
    }
  },
  methods: {
    handleVideoResize(event) {
      console.log("HANDLE VIDEO RESIZE EVENT", event.target.videoWidth, event.target.videoHeight)
      if(!event.target.videoWidth || !event.target.videoHeight) {
        console.error("WRONG VIDEO WIDTH OR HEIGHT")
        return
      }
      this.$emit('videoResize', { width: event.target.videoWidth, height: event.target.videoHeight })
    },
    handleImageLoad(event) {
      const img = new Image()
      img.onload = () => {
        console.log("HANDLE IMAGE LOAD RESIZE EVENT", img.width, img.height)
        if(!img.width || !img.height) {
          console.error("WRONG IMAGE WIDTH OR HEIGHT")
          return
        }
        this.$emit('videoResize', { width: img.width, height: img.height })
      }
      img.src = event.target.src
    }
  }
}

</script>

<style scoped>

</style>