var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FieldTemplate",
    {
      attrs: {
        errorText: _vm.errorText,
        error: _vm.error,
        label: _vm.label || _vm.name,
        name: _vm.name
      }
    },
    [
      _c(
        "span",
        { staticClass: "relation-input", on: { click: _vm.showMenu } },
        [
          _c("div", { ref: "relation" }, [
            _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.label))]),
            _vm._v(" "),
            _c("span", { staticClass: "value form-control" }, [
              _vm._v("\n        " + _vm._s(_vm.displayName) + "  \n      ")
            ])
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "more" }, [_vm._v("▾")])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }