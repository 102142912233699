var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "loading-zone",
    { staticClass: "chat" },
    [
      _c("Messages", { attrs: { toType: _vm.chatType, toId: _vm.chatId } }),
      _vm._v(" "),
      _c("MessageInput", {
        attrs: {
          "message-parameters": { toType: _vm.chatType, toId: _vm.chatId }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }