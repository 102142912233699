<template>
  <div class="exclusionsList-info-file">
    <a v-if="exclusionsList" :href="csvFileUrl" class="exclusionsList-filename">
      {{ exclusionsList.originalFileName }}
    </a>
    <div v-if="exclusionsList" class="exclusionsList-status">
      <div class="exclusionsList-size">{{ exclusionsList.wordsCount }} {{ i18n.entries }}</div>
    </div>
    <button v-if="deleteable" @click="$emit('delete')" class="button" type="button">
      <img src="/static/icons/close.svg">
    </button>
  </div>
</template>

<script>

  export default {
    name: "ExclusionsListInfo",
    props: {
      list: {
        type: String,
        required: true
      },
      index: {
        type: Number,
        required: true
      },
      deleteable: {
        type: Boolean,
        default: false
      }
    },
    reactive: {
      exclusionsList() {
        return ['xmlCsvExclusions', "exclusionsList", { exclusionsList: this.list } ]
      },
      exclusionsListOnline() {
        return ['online', 'object', "xmlCsvExclisions_ExclusionsList", this.list ]
      },
    },
    computed: {
      i18n() {
        return i18n().xmlExclusions
      },
      csvFileUrl() {
        if(!this.exclusionsList) return
        const route = this.$router.resolve({
          name: 'xml:exclusionsCsv',
          params: {
            listId: this.exclusionsList.id,
            fileName: this.exclusionsList.originalFileName
          }
        })
        return route.href
      },
    }
  }

</script>