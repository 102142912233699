var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "object", attrs: { label: _vm.label || _vm.name } },
    _vm._l(_vm.properties, function(prop) {
      return _c(
        "div",
        { key: _vm.name + ".e." + prop, staticClass: "prop" },
        [
          _vm.definition.properties[prop].editor !== false
            ? _c(_vm.editorComponent(prop), {
                tag: "component",
                attrs: { name: _vm.name + "." + prop, label: prop }
              })
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }