var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("page", [
    _c(
      "div",
      { staticClass: "card main-card" },
      [
        _c("h4", [_vm._v(_vm._s(_vm.i18n.loggedOut.title))]),
        _vm._v(" "),
        _c("p", {}, [_vm._v(_vm._s(_vm.i18n.loggedOut.message))]),
        _vm._v(" "),
        _c(
          "router-link",
          {
            staticClass: "button",
            attrs: { to: { name: "user:login" }, tag: "button" }
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.i18n.loggedOut.logInAgain) + "\n    "
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }