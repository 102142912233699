var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "country-picker" },
    [
      _c(
        "overlay-anchor",
        {
          attrs: { anchor: _vm.anchor, directions: _vm.directions },
          on: { positioned: _vm.updatePosition }
        },
        [
          _c(
            "div",
            {
              staticClass: "country-box card",
              style: Object.assign({}, _vm.maxDimensions),
              attrs: { ef: "box" }
            },
            [
              _c("div", { staticClass: "search form-field" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.search,
                      expression: "search"
                    }
                  ],
                  ref: "search",
                  staticClass: "input",
                  attrs: {
                    type: "text",
                    id: "search",
                    placeholder: "Country Search"
                  },
                  domProps: { value: _vm.search },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.search = $event.target.value
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "boxWrapper countries" },
                _vm._l(_vm.filteredCountries, function(country) {
                  return _c(
                    "div",
                    {
                      staticClass: "country",
                      on: {
                        click: function($event) {
                          return _vm.countrySelected(country)
                        }
                      }
                    },
                    [
                      _c("span", {
                        staticClass: "countryFlag cf-32",
                        class: "cf-" + country.code.toLowerCase()
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "countryName" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              (_vm.countryNames &&
                                _vm.countryNames[country.code.toLowerCase()]) ||
                                country.name
                            ) +
                            "\n          "
                        )
                      ])
                    ]
                  )
                }),
                0
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }