var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card main-card xml-alert-scan" },
    [
      _vm.scanner && !_vm.scan.to
        ? _c("div", { staticClass: "xml-alert-scan-url" }, [
            _c("a", { attrs: { href: _vm.scanner.url, target: "_blank" } }, [
              _vm._v(_vm._s(_vm.scanner.url))
            ]),
            _vm._v(" "),
            _vm.dataSetEntity && _vm.dataSetEntity.state == "deleted"
              ? _c("h3", { staticClass: "form-error" }, [
                  _vm._v(_vm._s(_vm.i18n.dataSetDeleted))
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "time",
        {
          staticClass: "xml-alert-scan-time",
          attrs: { datetime: _vm.scan.timestamp }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.i18n.scanStarted) + "\n    "),
          _c("span", { staticClass: "hours" }, [
            _vm._v(
              _vm._s(
                _vm._f("duration")(
                  (_vm.now - new Date(_vm.scan.timestamp).getTime()) / 1000
                )
              )
            )
          ]),
          _vm._v("\n    " + _vm._s(_vm.i18n.ago) + "\n  ")
        ]
      ),
      _vm._v(" "),
      _vm.scan.state != "done" && _vm.scan.state != "downloading"
        ? _c("div", { staticClass: "search-progress progress-bar" }, [
            _c("div", {
              staticClass: "progress-bar-fill",
              style: {
                width:
                  Math.floor(100 * (_vm.processedCount / _vm.allCount)) + "%"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "progress-text" }, [
              _c("span", [_vm._v(_vm._s(_vm.processedCount))]),
              _vm._v(" / "),
              _c("span", [_vm._v(_vm._s(_vm.allCount))])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "xml-alert-scan-state" }, [
        _vm._v(_vm._s(_vm.i18n.scanStage[_vm.scan.stage]))
      ]),
      _vm._v(" "),
      _vm.scan.stage == "download" &&
      _vm.dataSetEntity &&
      _vm.dataSetEntity.progress
        ? _c("div", { staticClass: "search-progress progress-bar" }, [
            _vm.dataSetEntity && _vm.dataSetEntity.progress
              ? _c("div", {
                  staticClass: "progress-bar-fill",
                  style: {
                    width:
                      Math.floor(
                        100 *
                          (_vm.dataSetEntity.progress.transferred /
                            _vm.dataSetEntity.progress.length)
                      ) + "%"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "progress-text" }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("bytes")(_vm.dataSetEntity.progress.transferred)
                  )
                )
              ]),
              _vm._v(" "),
              _vm.dataSetEntity.progress.length
                ? _c("span", [
                    _vm._v(
                      " / " +
                        _vm._s(
                          _vm._f("bytes")(_vm.dataSetEntity.progress.length)
                        )
                    )
                  ])
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      (_vm.scan.stage == "downloading" || _vm.scan.state == "done") &&
      _vm.dataSetEntity &&
      _vm.dataSetEntity.state == "error"
        ? _c("div", { staticClass: "download-error" }, [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.i18n.downloadError[_vm.dataSetEntity.error] ||
                    _vm.dataSetEntity.error
                ) +
                "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.scan.state != "done" &&
      _vm.scan.state != "downloading" &&
      _vm.currentStage
        ? _c("div", { staticClass: "search-progress progress-bar" }, [
            _c("div", {
              staticClass: "progress-bar-fill",
              style: {
                width:
                  Math.floor(
                    100 * (_vm.currentStage.processed / _vm.currentStage.count)
                  ) + "%"
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "progress-text" }, [
              _c("span", [_vm._v(_vm._s(_vm.currentStage.processed))]),
              _vm._v(" / "),
              _c("span", [_vm._v(_vm._s(_vm.currentStage.count))])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "xml-alert-scan-results-counters" },
        _vm._l(_vm.sortedResultsCounts, function(result) {
          return _c(
            "div",
            { class: ["xml-alert-scan-results-counter", result.severity] },
            [
              _c("span", { staticClass: "count" }, [
                _vm._v(_vm._s(result.count))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "severity" }, [
                _vm._v(
                  _vm._s(
                    _vm.i18n.severity[
                      _vm.$options.filters.plural(result.severity, result.count)
                    ]
                  )
                )
              ])
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.scan.to
        ? _c(
            "router-link",
            {
              staticClass: "button xml-alert-scan-view-results",
              attrs: {
                to: {
                  name: "xml:alert:scan",
                  params: { scan: _vm.scan.to, scanner: _vm.scan.scanner }
                },
                tag: "a"
              }
            },
            [
              _c("img", { attrs: { src: "/static/icons/preview.svg" } }),
              _c("span", [_vm._v(_vm._s(_vm.i18n.viewResults))])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }