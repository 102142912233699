var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Notification",
    { ref: "notification", attrs: { notification: _vm.notification } },
    [
      _c(
        "div",
        { staticClass: "notification-avatar", on: { click: _vm.goTo } },
        [
          _vm.notification.summary.error
            ? _c("img", {
                staticClass: "avatar-addon",
                attrs: { src: "/static/icons/error.svg" }
              })
            : _vm.notification.summary.warning
            ? _c("img", {
                staticClass: "avatar-addon",
                attrs: { src: "/static/icons/warning.svg" }
              })
            : _c("img", {
                staticClass: "avatar-addon",
                attrs: { src: "/static/icons/check.svg" }
              })
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "notification-content", on: { click: _vm.goTo } },
        [
          _c("div", { staticClass: "notification-title" }, [
            _vm._v(_vm._s(_vm.i18n.notifications.finished.title))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "notification-summary" }, [
            _vm.notification.summary.fatal
              ? _c("span", { staticClass: "fatal" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.notification.summary.fatal) +
                      "\n        " +
                      _vm._s(
                        _vm.notification.summary.fatal > 1
                          ? _vm.i18n.severity.fatals
                          : _vm.i18n.severity.fatal
                      ) +
                      "\n      "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.notification.summary.error
              ? _c("span", { staticClass: "error" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.notification.summary.error) +
                      "\n        " +
                      _vm._s(
                        _vm.notification.summary.error > 1
                          ? _vm.i18n.severity.errors
                          : _vm.i18n.severity.error
                      ) +
                      "\n      "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.notification.summary.warning
              ? _c("span", { staticClass: "warning" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.notification.summary.warning) +
                      "\n        " +
                      _vm._s(
                        _vm.notification.summary.warning > 1
                          ? _vm.i18n.severity.warnings
                          : _vm.i18n.severity.warning
                      ) +
                      "\n      "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.notification.summary.notice
              ? _c("span", { staticClass: "notice" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.notification.summary.notice) +
                      "\n        " +
                      _vm._s(
                        _vm.notification.summary.notice > 1
                          ? _vm.i18n.severity.notices
                          : _vm.i18n.severity.notice
                      ) +
                      "\n      "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "notification-description" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.notification.url || "unknown") + "\n    "
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }