<template>
  <p> connecting... </p>
</template>

<script>
  import api from "api"

  export default {
    name: "StartConversation",
    inject: ['workingZone'],
    props: {
      withType: {
        type: String
      },
      withId: {
        type: String
      }
    },
    mounted() {
      const myId = api.session.session.user || api.session.publicSessionInfo.id
      const amIFirst = myId < this.withId
      const params = amIFirst
          ?(api.session.session.user
                  ?(this.withType == 'user'
                          ? { user1: myId, user2: this.withId }
                          : { user1: myId, session2: this.withId }
                  )
                  :(this.withType == 'user'
                          ? { session1: myId, user2: this.withId }
                          : { session1: myId, session2: this.withId }
                  )
          )
          :(api.session.session.user
                  ?(this.withType == 'user'
                          ? { user2: myId, user1: this.withId }
                          : { user2: myId, session1: this.withId }
                  )
                  :(this.withType == 'user'
                          ? { session2: myId, user1: this.withId }
                          : { session2: myId, session1: this.withId }
                  )
          )
      const promise = api.command(['messages', 'getOrCreatePrivateConversation'], params).then( res => {
        this.$router.replace({ name: 'messenger:conversation', params: {
          conversationType: 'priv',
          conversation: res.id
        } })
      })
      this.workingZone.addPromise('creating conversation', promise)
    }
  }
</script>

<style scoped>

</style>