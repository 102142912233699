<template>
  <form-field-bind :name="name" v-slot="{ value, setValue, error }">
    <FieldTemplate :errorText="errorText" :error="error" :label="label" :name="name" :groupClass="groupClass">
      <DatePicker lang="en" type="date" placeholder="" :format="lang === 'en' ? 'MM-DD-YYYY' : 'DD.MM.YYYY'"
                  :placeholder="placeholder" :input-class="'date-picker '+inputClass" width="100%"
                  :value="value" @input="setValue" :minute-step="5">
        <img slot="calendar-icon" src="/static/icons/calendar-today.svg" />
      </DatePicker>
    </FieldTemplate>
  </form-field-bind>
</template>

<script>
  import FieldTemplate from "./FieldTemplate.vue"
  import DatePicker from "vue2-datepicker"
  import i18n from "i18n"

  export default {
    name: "DateField",
    components: { FieldTemplate, DatePicker },
    props: {
      name: {
        type: String,
        required: true
      },
      label: {
        type: String
      },
      errorText: {
        type: Object
      },
      inputClass: {
        type: String,
        default: ""
      },
      groupClass: {
        type: String
      },
      placeholder: {
        type: String
      }
    },
    computed: {
      lang() { return i18n.config.language }
    },
    methods: {
    }

  }

</script>

<style scoped>

</style>
