<template>
  <div :class="['xml-alert-result', result.severity]">
    <div class="card xml-alert-result-title">
      <div class="xml-alert-result-title-text">
        <span v-if="result.code" class="xml-alert-result-code">{{ result.code }}</span>
        <span v-if="result.code">-</span>
        <span class="xml-alert-result-type">{{ i18n.resultType[result.type] || result.type }}</span>
        <span v-if="result.field">in</span>
        <span v-if="result.field" class="xml-alert-result-field">{{ fieldText }}</span>
      </div>
      <router-link v-if="!noMore" :to="{ name: 'xml:alert:scan:item', params: {
        scanner: $route.params.scanner,
        scan: $route.params.scan,
        position
      } }" tag="a" class="button">
        {{ i18n.more }}
      </router-link>
    </div>
    <div v-if='result.url || result.at || result.next || result.content || result.xmlData || result.itemData ||
               result.fromData || result.toData'
         class="card xml-alert-result-content">
      <table class="xml-alert-result-table">
        <tbody>
          <tr v-if="result.url && result.at != result.url">
            <th>{{ i18n.srcUrl }}:</th><td><a :href="result.url" target="_blank">{{ result.url }}</a></td>
          </tr>
          <tr v-if="result.at">
            <th>{{ i18n.atUrl }}:</th><td><a :href="result.at" target="_blank">{{ result.at }}</a></td>
          </tr>
          <tr v-if="result.next">
            <th>{{ i18n.toUrl }}:</th><td><a :href="result.next" target="_blank">{{ result.next }}</a></td>
          </tr>
          <tr v-if="result.image">
            <th>{{ i18n.image }}:</th>
            <td>
              <span>{{ result.image.type }}</span>
              <span>{{ result.image.width}}</span><span>{{ result.image.wUnits }}</span> x
              <span>{{ result.image.height}}</span><span>{{ result.image.hUnits }}</span>
            </td>
          </tr>
          <tr v-if="result.xmlData"><th>{{ i18n.xml }}</th><td>{{ result.xmlData }}</td></tr>
          <tr v-if="result.itemData"><th>{{ i18n.itemProp }}</th><td>{{ result.itemData }}</td></tr>
          <tr v-if="result.fromData"><th>{{ i18n.resultFrom }}</th><td>{{ result.fromData }}</td></tr>
          <tr v-if="result.toData"><th>{{ i18n.resultTo }}</th><td>{{ result.toData }}</td></tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import i18n from 'i18n'

  export default {
    name: "ScanResult",
    props: {
      result: {
        type: Object,
        required: true
      },
      noMore: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      i18n() {
        return i18n().xmlAlert
      },
      position() {
        return this.result.item //(+this.result.to.split('_')[2]) + 1
      },
      fieldText() {
        const field = this.result.field
        if(typeof field == 'string') return field
        return field.join('\u00A0→\u00A0')
      }
    }
  }
</script>

<style scoped>

</style>