import { render, staticRenderFns } from "./TextAreaField.vue?vue&type=template&id=234d64e6&scoped=true&"
import script from "./TextAreaField.vue?vue&type=script&lang=js&"
export * from "./TextAreaField.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "234d64e6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/source/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('234d64e6')) {
      api.createRecord('234d64e6', component.options)
    } else {
      api.reload('234d64e6', component.options)
    }
    module.hot.accept("./TextAreaField.vue?vue&type=template&id=234d64e6&scoped=true&", function () {
      api.rerender('234d64e6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "common/components/fields/TextAreaField.vue"
export default component.exports