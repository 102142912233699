var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "form-field " + _vm.groupClass },
    [
      _c("vue-recaptcha", {
        ref: "recaptcha",
        class: _vm.inputClass,
        attrs: { sitekey: _vm.recaptchaSiteKey },
        on: { verify: _vm.verified }
      }),
      _vm._v(" "),
      _c("small", { staticClass: "field-error" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              (_vm.errorText && _vm.errorText[_vm.error]) ||
                _vm.i18n[_vm.error] ||
                _vm.error
            ) +
            "\n  "
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }