<template>
  <div>
    <overlay-anchor :anchor="anchor" :directions="directions" @positioned="updatePosition">
      <div class="navbar-dropdown dropdown-menu user-menu">
        <router-link :to="{name:'user:settings'}" @click.native="close" class="dropdown-item" tag="a" >
          {{ i18n.userSettings }}
        </router-link>
        <router-link :to="{name:'user:profile', params: { user: this.user.slug }}" @click.native="close"
                     class="dropdown-item" tag="a" >
          {{ i18n.yourPage }}
        </router-link>
        <div class="dropdown-divider"></div>
        <router-link :to="{name:'user:logout'}" @click.native="close" class="dropdown-item" tag="a">
          {{ i18n.logout }}
        </router-link>
      </div>
    </overlay-anchor>
  </div>
</template>

<script>
  import i18n from "i18n"
  import { OverlayAnchor, windowDimensions } from "common/components/utils/overlayLayer"

  export default {
    name: "UserMenu",
    props: ['anchor'],
    components: { OverlayAnchor },
    data () {
      return {
        directions: [{ x:-1, y:1, ax: 1, ay: 1}],
        position: {x:0, y:0},
        search: ""
      }
    },
    computed: {
      i18n() { return i18n().user.menu },
      windowDimensions() { return windowDimensions },
      user() { return api.session.user },
    },
    watch: {
      user(u) {
        if(!u) this.$emit('close')
      }
    },
    methods: {
      updatePosition(position) {
        this.position = position
      },
      close() {
        this.$emit('close')
      }
    }
  }
</script>

<style scoped>
</style>