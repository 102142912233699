var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form-field-bind", {
    attrs: { name: _vm.name },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var value = ref.value
          var setValue = ref.setValue
          var error = ref.error
          return [
            _c(
              "FieldTemplate",
              {
                attrs: {
                  errorText: _vm.errorText,
                  error: error,
                  label: _vm.label,
                  name: _vm.name,
                  groupClass: _vm.groupClass
                }
              },
              [
                _c(
                  "DatePicker",
                  {
                    attrs: {
                      lang: "en",
                      type: "date",
                      placeholder: "",
                      format: _vm.lang === "en" ? "MM-DD-YYYY" : "DD.MM.YYYY",
                      placeholder: _vm.placeholder,
                      "input-class": "date-picker " + _vm.inputClass,
                      width: "100%",
                      value: value,
                      "minute-step": 5
                    },
                    on: { input: setValue }
                  },
                  [
                    _c("img", {
                      attrs: {
                        slot: "calendar-icon",
                        src: "/static/icons/calendar-today.svg"
                      },
                      slot: "calendar-icon"
                    })
                  ]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }