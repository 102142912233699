import { Node, Plugin } from 'tiptap'
import { nodeInputRule } from 'tiptap-commands'

export default class HorizontalRule extends Node {
  get name() {
    return 'nbsp'
  }

  get schema() {
    return {
      group: 'inline',
      inline: true,
      selectable: false,
      atom: true,
      parseDOM: [{ tag: 'span[data-type=nbsp]' }],
      toDOM: () => ['span', { 'data-type': 'nbsp' }, '\u00A0'],
    }
  }

  commands({ type }) {
    return () => (state, dispatch) => dispatch(state.tr.replaceSelectionWith(type.create()))
  }

  inputRules({ type }) {
    return [
      nodeInputRule(/^(\u00A0)$/, type),
    ]
  }
}