module.exports = {
  more: "More",
  addComment: "Add comment",
  reply: "Reply",
  confirmDelete: "Are you sure you want to delete this comment?",
  delete: "Delete comment",
  edit: "Edit comment",
  send: "Add",
  cancel: "Cancel",
  save: "Save"
}