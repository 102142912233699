import sax from 'sax'

function parse(xml) {
  const parser = sax.parser({ trim: true,  position: false, lowercase: true })

  parser.onerror = function(error) {
    if(!finished) {
      console.error("xml parser error", error)
    }
    // clear the error

    /*_parser.error = null
    _parser.resume()*/
  }

  let results = []

  let finished = false
  let item = null
  let stack = null
  let text = ''

  parser.onopentag = (node) => {
    if(!item) {
      if((node.name == 'item' || node.name == 'entry') && !node.isSelfClosing) {
        item = {}
        stack = [item]
      }
    } else {
      stack.unshift({})
      text = ''
    }
  }

  parser.ontext = (t) => {
    text += t
  }

  parser.oncdata = (cdata) => {
    text += cdata
  }

  parser.onclosetag = (tagName) => {
    //console.log("CLOSETAG", tagName)
    if(item) {
      if(stack.length == 1) {
        if(tagName != 'item' && tagName != 'entry') throw new Error("stack underflow")
        results.push(item)
        item = null
      } else {
        //console.log("STACK", JSON.stringify(stack.slice(0, -1), null, "  "))
        const value = Object.keys(stack[0]).length > 0 ? stack[0] : text.trim()
        text = ''
        if(!stack[1][tagName]) {
          stack[1][tagName] = value
        } else if(Array.isArray(stack[1][tagName])) {
          stack[1][tagName].push(value)
        } else {
          stack[1][tagName] = [stack[1][tagName], value]
        }
        stack.shift()
      }
    }
  }

  parser.onend = () => {
    finished = true
  }
  
  parser.write(xml).close()

  return results
  
}

export default parse
