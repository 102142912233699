<template>
  <div :class="'form-field '+(groupClass||'')+(isRequired ? ' form-field-required' : '')">
    <label v-if="label!==''" :for="inputId">{{ label || name }}</label>
    <slot name="above"></slot>
    <slot></slot>
    <small class="field-error">
      {{ (errorText && errorText[error]) || i18n[error] || error }}
    </small>
    <slot name="below"></slot>
  </div>
</template>

<script>
  import i18n from "i18n"

  export default {
    name: "FieldTemplate",
    inject: ['form'],
    props: {
      error: {
        type: String
      },
      label: {
        type: String
      },
      name: {
        type: String,
        required: true
      },
      errorText: {
        type: Object
      },
      groupClass: {
        type: String
      },
      inputId: {
        type: String
      }
    },
    data() {
      return {
        validators: []
      }
    },
    computed: {
      i18n() { return i18n().validation },
      isRequired() {
        const validators = this.validators
        if(!validators) return false
        for(let validator of validators) {
          if(validator.isRequired && validator.isRequired({ props: this.form.getValue() })) return true
        }
        return false
      }
    },
    created() {
      this.validators = this.form.getFieldValidators(this.name)
    }
  }
</script>

<style scoped>

</style>