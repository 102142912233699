var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form-field-bind", {
    attrs: {
      name: _vm.name,
      valueFilter: function(n) {
        return n && n.trim()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var value = ref.value
          var setValue = ref.setValue
          var error = ref.error
          return [
            _c(
              "FieldTemplate",
              {
                attrs: {
                  errorText: _vm.errorText,
                  error: error,
                  label: _vm.label,
                  name: _vm.name,
                  groupClass: _vm.groupClass,
                  inputId: _vm.uid
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "above",
                      fn: function() {
                        return [_vm._t("above")]
                      },
                      proxy: true
                    },
                    {
                      key: "below",
                      fn: function() {
                        return [
                          _vm.charsLeft >= 0
                            ? _c("span", { staticClass: "field-tip" }, [
                                _vm._v(
                                  _vm._s(
                                    " " +
                                      _vm.i18n.characters +
                                      " " +
                                      _vm.charsLeft
                                  )
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._t("below")
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              },
              [
                _vm._v(" "),
                _c("input", {
                  ref: "field",
                  class: _vm.inputClass || "",
                  attrs: {
                    type: "text",
                    id: _vm.uid,
                    placeholder: _vm.placeholder,
                    name: _vm.name,
                    maxlength:
                      (_vm.maxLength && _vm.maxLength.length) ||
                      _vm.definition.maxLength,
                    disabled: _vm.disabled
                  },
                  domProps: { value: value },
                  on: {
                    input: function(e) {
                      return setValue(e.target.value)
                    },
                    focus: _vm.emit
                  }
                })
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }