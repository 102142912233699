var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("page", [
    _c(
      "div",
      { staticClass: "full-width" },
      [
        _vm.isLoggedIn && !_vm.deleted
          ? _c("observe", {
              attrs: {
                name: "myCheckers",
                what: ["urlChecker", "myCheckerByUrl", { url: _vm.decodedUrl }]
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(ref) {
                      var checker = ref.value
                      return [
                        checker
                          ? _c("UrlCard", {
                              attrs: { checker: checker },
                              on: { deleted: _vm.handleDeleted }
                            })
                          : _vm._e()
                      ]
                    }
                  }
                ],
                null,
                false,
                1925740565
              )
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isLoggedIn && _vm.deleted
          ? _c(
              "div",
              { staticClass: "card" },
              [
                _c("p", [_vm._v(_vm._s(_vm.i18n.urlDeleted))]),
                _vm._v(" "),
                _c(
                  "router-link",
                  { attrs: { to: { name: "urls" }, tag: "button" } },
                  [_vm._v(_vm._s(_vm.i18n.returnToList))]
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }