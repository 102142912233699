import Vue from "vue"


import TextField from "./TextField.vue"
Vue.component('text-field', TextField)
import TextAreaField from "./TextAreaField.vue"
Vue.component('text-area-field', TextAreaField)
import MultiLineTextField from "./MultiLineTextField.vue"
Vue.component('multi-line-text-field', MultiLineTextField)
import PasswordField from "./PasswordField.vue"
Vue.component('password-field', PasswordField)
import DoublePasswordField from "./DoublePasswordField.vue"
Vue.component('double-password-field', DoublePasswordField)

import RecaptchaField from "./RecaptchaField.vue"
Vue.component('recaptcha-field', RecaptchaField)

import PhoneField from "./PhoneField.vue"
Vue.component('phone-field', PhoneField)
import CountryField from "./CountryField.vue"
Vue.component('country-field', CountryField)
import CountryCityField from "./CountryCityField.vue"
Vue.component('country-city-field', CountryCityField)

import CountryPlaceField from "./CountryPlaceField.vue"
Vue.component('country-place-field', CountryPlaceField)
import PlaceField from "./PlaceField.vue"
Vue.component('place-field', PlaceField)


import DateField from "./DateField.vue"
Vue.component('date-field', DateField)
import TimeField from "./TimeField.vue"
Vue.component('time-field', TimeField)
import DateTimeField from "./DateTimeField.vue"
Vue.component('date-time-field', DateTimeField)

import BooleanField from "./BooleanField.vue"
Vue.component('boolean-field', BooleanField)

import SingleSelectField from "./SingleSelectField.vue"
Vue.component('single-select-field', SingleSelectField)

import MultiCheckboxField from "./MultiCheckboxField.vue"
Vue.component('multi-checkbox-field', MultiCheckboxField)

import OptionsField from "./OptionsField.vue"
Vue.component( 'options-field', OptionsField)

import PictureField from "./PictureField.vue"
Vue.component('picture-field', PictureField)

import PictureUploadField from "./PictureUploadField.vue"
Vue.component('picture-upload-field', PictureUploadField)

import CategoryField from "@/modules/categories/CategoryField.vue"
Vue.component('category-field', CategoryField)

import CategoriesField from "@/modules/categories/CategoriesField.vue"
Vue.component('categories-field', CategoriesField)

import WysiwygField from "./WysiwygField.vue"
Vue.component('wysiwyg-field', WysiwygField)

import ListField from "./ListField.vue"
import ObjectField from "./ObjectField.vue"
import RelationSingleSelect from "./RelationSingleSelect"


const editors = {
  byName: {
    "text": TextField,
    "textarea": TextAreaField,
    "password": PasswordField,
    "phone": PhoneField,
    "country": CountryField,
    "countryCity": CountryCityField,
    "doublePassword": DoublePasswordField,
    "relationSingleSelect": RelationSingleSelect,
    "date": DateField,
    "time": TimeField,
    "dateTime": DateTimeField,
    "boolean": BooleanField,
    "picture": PictureField,
    "category": CategoryField,
    "wysiwyg": WysiwygField,
    "multiline": MultiLineTextField
  },
  byType: {
    "String": TextField,
    "Number": TextField,
    "Array": ListField,
    "Object": ObjectField,
    "Date": DateTimeField,
    "Boolean": BooleanField,
    "pictures:Picture": PictureField,
  },
  defaultModel: RelationSingleSelect,
  /*defaultStruct: JsonEditor,*/
  byDefinition(fieldDefinition) {
    let editor
    if(fieldDefinition.editor) {
      editor = editors.byName[fieldDefinition.editor]
      if(!editor) throw new Error("unknown editor "+fieldDefinition.editor)
      return editor
    }
    editor = editors.byType[fieldDefinition.type]
    if(editor) return editor
    if(editors.defaultModel) return editors.defaultModel
    if(!editor) throw new Error("no editor for field type "+fieldDefinition.type)
    return editor
  }
}

Vue.prototype.$editors = editors