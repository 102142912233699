var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VideoDisplay", {
    attrs: {
      "main-videos": _vm.mainVideos,
      "top-videos": _vm.topVideos,
      "my-videos": _vm.myVideos,
      volume: _vm.volume
    },
    on: { videoClick: _vm.handleVideoClick }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }