var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("NavBar"),
      _vm._v(" "),
      _vm.versionMismatch && _vm.displayVersionMismatch
        ? _c(
            "div",
            { staticClass: "version-alert-bar", on: { click: _vm.reload } },
            [_vm._v(_vm._s(_vm.i18n.versionMismatch))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isOldBrowser
        ? _c(
            "div",
            {
              staticClass: "old-browser-alert-bar",
              on: {
                click: function() {
                  return (_vm.isOldBrowser = false)
                }
              }
            },
            [_vm._v(_vm._s(_vm.i18n.oldBrowser))]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.multiRow && !_vm.footer
        ? _c(
            "div",
            { staticClass: "content-container", attrs: { id: "main-scroll" } },
            [
              _c("div", { staticClass: "page-row" }, [
                _vm.hasLeftSlot ? _c("aside", [_vm._t("left")], 2) : _vm._e(),
                _vm._v(" "),
                _c("main", [_vm._t("default")], 2),
                _vm._v(" "),
                _vm.hasRightSlot ? _c("aside", [_vm._t("right")], 2) : _vm._e()
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.multiRow && _vm.footer
        ? _c(
            "div",
            { staticClass: "content-container", attrs: { id: "main-scroll" } },
            [
              _c("div", { staticClass: "page-over-footer" }, [
                _c("div", { staticClass: "page-row" }, [
                  _vm.hasLeftSlot
                    ? _c("aside", [_vm._t("left"), _vm._v("pm\n        ")], 2)
                    : _vm._e(),
                  _vm._v(" "),
                  _c("main", [_vm._t("default")], 2),
                  _vm._v(" "),
                  _vm.hasRightSlot
                    ? _c("aside", [_vm._t("right")], 2)
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("Footer")
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.multiRow && !_vm.footer
        ? _c(
            "div",
            { staticClass: "content-container", attrs: { id: "main-scroll" } },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.multiRow && _vm.footer
        ? _c(
            "div",
            { staticClass: "content-container", attrs: { id: "main-scroll" } },
            [
              _c(
                "div",
                { staticClass: "page-over-footer" },
                [_vm._t("default")],
                2
              ),
              _vm._v(" "),
              _c("Footer")
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }