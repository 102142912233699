<template>
  <VideoDisplay :main-videos="mainVideos" :top-videos="topVideos" :my-videos="myVideos"
                :volume="volume" @videoClick="handleVideoClick">
  </VideoDisplay>
</template>

<script>
import api from "api"
import VideoDisplay from "./VideoDisplay.vue"
import { createPeer } from "../peer-connection/Peer.js"
import { getUserMedia, isUserMediaPermitted } from "../peer-connection/userMedia.js"
import PermissionsModal from "../peer-connection/PermissionsModal.vue"
import overlayModel from "common/components/utils/overlayModel.js"
import MultiButtonDialog from "common/components/MultiButtonDialog.vue"

export default {
  name: "VideoChat",
  props: {
    toType: {
      type: String,
      required: true
    },
    toId: {
      type: String,
      required: true
    },
    localMediaStreams: {
      required: true
    },
    peers: {
      required: true,
      type: Array
    },
    volume: {
      type: Number,
      defaultValue: 1
    }
  },
  components: { VideoDisplay },
  data() {
    return {
      selectedVideoId: null
    }
  },
  computed: {
    remoteVideos() {
      if(!this.peers) return []
      let mainVideos = []
      for(const peer of this.peers) {
        if(!peer) continue
        for(const connection of peer.connections) {
          if(connection.remoteTracks.length > 0) {
            let remoteStreams = []
            for(const remoteTrack of connection.remoteTracks) {
              const stream = remoteTrack.stream
              if(remoteStreams.find(remoteStream => remoteStream == stream)) continue;
              remoteStreams.push(stream)
              if(stream.getVideoTracks().length > 0) {
                mainVideos.push({
                  type: 'video',
                  from: connection.to,
                  id: stream.id,
                  srcObject: stream
                })
              } else {
                mainVideos.push({ /// TODO: handle audio only with user image
                  type: 'audio',
                  from: connection.to,
                  id: stream.id,
                  srcObject: stream
                })
              }
            }
          }
        }
      }
      return mainVideos
    },
    localVideos() {
      const peer = this.peers && this.peers[0]
      return peer ? this.localMediaStreams.map(mediaStream => {
        if(mediaStream.getVideoTracks().length > 0) {
          return {
            type: 'video',
            from: peer.peerId,
            id: mediaStream.id,
            srcObject: mediaStream,
            props: { muted: true }
          }
        } else {
          return { /// TODO: handle audio only with user image
            type: 'audio',
            from: peer.peerId,
            id: mediaStream.id,
            srcObject: mediaStream,
            props: { muted: true }
          }
        }
      }) : []
    },
    selectedVideo() {
      const id = this.selectedVideoId
      if(!id) return;
      for(const video of this.remoteVideos) {
        if(video.id == id) return video
      }
      for(const video of this.localVideos) {
        if(video.id == id) return video
      }
    },
    mainVideos() {
      if(this.selectedVideo) return [ this.selectedVideo ]
      return this.remoteVideos
    },
    topVideos() {
      if(!this.selectedVideo) return []
      return this.remoteVideos.filter(video => video.id != this.selectedVideoId)
    },
    myVideos() {
      return this.localVideos.filter(vid => vid.id != this.selectedVideoId)
    }
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    handleVideoClick(event) {
      const video = event.video
      console.log("HANDLE VIDEO CLICK", video)
      if(this.selectedVideoId == video.id) {
        this.selectedVideoId = null
      } else {
        this.selectedVideoId = video.id
      }
    }
  }
}

</script>

<style scoped>

</style>