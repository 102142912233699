var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "loading",
    {
      staticClass: "ads-api-campaign-account",
      attrs: {
        name:
          "user " +
          _vm.userAccount.user +
          " ads account " +
          _vm.userAccount.account,
        what: _vm.account && _vm.campaigns && _vm.updateSettingsActionDefinition
      }
    },
    [
      _vm.account
        ? _c("div", { staticClass: "account-header" }, [
            _c("h3", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.account.accountName) +
                  " - " +
                  _vm._s(_vm.account.name) +
                  " ( " +
                  _vm._s(_vm.account.id) +
                  " )\n      "
              ),
              !_vm.campaigns || _vm.campaigns.length == 0
                ? _c("span", [_vm._v(" - no campaigns")])
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.hasInsufficient
              ? _c("p", [_vm._v("\n      insufficient funds\n    ")])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "buttons" }, [
              _vm.campaigns && _vm.campaigns.length > 0 && !_vm.expanded
                ? _c(
                    "button",
                    {
                      staticClass: "button",
                      attrs: { type: "button" },
                      on: {
                        click: function() {
                          return _vm.toggleCampaignVisibility()
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: { src: "/static/icons/expand_more.svg" }
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.i18n.expand))]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v("( " + _vm._s(_vm.campaigns.length) + " )")
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.campaigns && _vm.campaigns.length > 0 && _vm.expanded
                ? _c(
                    "button",
                    {
                      staticClass: "button",
                      attrs: { type: "button" },
                      on: {
                        click: function() {
                          return _vm.toggleCampaignVisibility()
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: { src: "/static/icons/expand_less.svg" }
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.i18n.collapse))]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v("( " + _vm._s(_vm.campaigns.length) + " )")
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button",
                  staticStyle: { width: "7em" },
                  attrs: { type: "button" },
                  on: {
                    click: function() {
                      return _vm.refresh()
                    }
                  }
                },
                [
                  _c("img", { attrs: { src: "/static/icons/refresh.svg" } }),
                  _c("span", [_vm._v(_vm._s(_vm.i18n.refresh))])
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.expanded && _vm.updateSettingsActionDefinition
        ? _c(
            "div",
            [
              _c(
                "defined-form",
                {
                  ref: "settingsForm",
                  staticClass: "ads-account-settings",
                  attrs: {
                    initialValues: Object.assign({}, _vm.userAccount.settings),
                    definition: _vm.updateSettingsActionDefinition
                  },
                  on: { init: _vm.handleSettingsFormInitialized }
                },
                [
                  _c("single-select-field", {
                    attrs: {
                      name: "checkInterval",
                      options: _vm.checkIntervals,
                      label: _vm.i18n.checkInterval,
                      text: function(interval) {
                        return _vm.i18n.checkIntervals[interval]
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("text-field", {
                    attrs: {
                      name: "insufficientFundsThreshold",
                      label: _vm.i18n.insufficientFundsThreshold
                    }
                  }),
                  _vm._v(" "),
                  _vm.settingsState != "initial"
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.i18n.settingsState[_vm.settingsState])
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm.campaigns.length > 0
                ? _c("table", { staticClass: "campaigns-table" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", { staticClass: "campaign-col-name" }, [
                          _vm._v("Campaign")
                        ]),
                        _vm._v(" "),
                        _c("th", { staticClass: "campaign-col-state" }, [
                          _vm._v("State")
                        ]),
                        _vm._v(" "),
                        _c("th", { staticClass: "campaign-col-budget" }, [
                          _vm._v("Budget")
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.campaigns, function(campaign) {
                        return _c("tr", { staticClass: "campaign" }, [
                          _c("td", [
                            _vm._v(
                              "\n          " +
                                _vm._s(campaign.name) +
                                " (" +
                                _vm._s(campaign.id) +
                                ")\n          "
                            ),
                            _c("br"),
                            _vm._v("\n          Last update:\n          "),
                            _c(
                              "time",
                              { attrs: { datetime: campaign.lastUpdate } },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("date")(campaign.lastUpdate)
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm._f("hour")(campaign.lastUpdate)
                                    ) +
                                    "\n          "
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", { class: _vm.campaignClass(campaign) }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.campaignStatus(campaign)) +
                                "\n        "
                            )
                          ]),
                          _vm._v(" "),
                          campaign.dailyMetrics
                            ? _c("td", [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      (
                                        campaign.dailyMetrics.cost_micros / 1e6
                                      ).toFixed(2)
                                    ) +
                                    "\n          /\n          " +
                                    _vm._s(
                                      (campaign.budget.amount / 1e6).toFixed(2)
                                    ) +
                                    "\n          " +
                                    _vm._s(_vm.account.currency) +
                                    "\n          " +
                                    _vm._s(
                                      _vm.i18n.periodAdjective[
                                        campaign.budget.period
                                      ]
                                    ) +
                                    "\n        "
                                )
                              ])
                            : _c("td", [_vm._v("NO-DATA")])
                        ])
                      }),
                      0
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _vm.userAccount.lastCheckTime
                ? _c("div", [
                    _c("b", [_vm._v("Last check time:")]),
                    _vm._v(" "),
                    _c(
                      "time",
                      { attrs: { datetime: _vm.userAccount.nextCheck } },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm._f("date")(_vm.userAccount.lastCheckTime)
                            ) +
                            " " +
                            _vm._s(
                              _vm._f("hour")(_vm.userAccount.lastCheckTime)
                            ) +
                            "\n      "
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.userAccount.nextCheckTime
                ? _c("div", [
                    _c("b", [_vm._v("Next check time:")]),
                    _vm._v(" "),
                    _c(
                      "time",
                      { attrs: { datetime: _vm.userAccount.nextCheck } },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm._f("date")(_vm.userAccount.nextCheckTime)
                            ) +
                            " " +
                            _vm._s(
                              _vm._f("hour")(_vm.userAccount.nextCheckTime)
                            ) +
                            "\n      "
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }