<template>
  <page :footer="true">
    <loading :what="keyState || state == 'notFound'" name="key state">
      <div class="card half-width main-card" v-if="keyState || state == 'notFound'">
        <div v-if="state=='used' || state=='expired' || state=='done' || state=='notFound' || state=='taken'">
          <h4>{{ i18n()[state].title }}</h4>
          <div class="info-message">
            <p>{{ i18n()[state].text }}</p>
          </div>
          <button v-if="state=='done'" v-on:click="exit" class="button">
            {{ i18n()[state].exitButton }}
          </button>
        </div>
      </div>
    </loading>
  </page>
</template>

<script>
  import api from "api"
  import i18n from "i18n"

  export default {
    name: 'ConfirmEmail',
    data () {
      return {
        notMatch: false,
        error: null,
        state: 'loading'
      }
    },
    inject: ['workingZone'],
    reactive: {
      keyState() { return ['emailPassword', 'emailKey', { key: this.$route.params.key }] }
    },
    reactivePreFetch(route) {
      return [
        { what: ['emailPassword', 'emailKey', { key: route.params.key }] }
      ]
    },
    computed: {
      loggedIn() {
        return api.session.loggedIn
      },
      key() {
        return this.$route.params.key
      }
    },
    watch: {
      keyState(v) {
        this.handleKeyState(v)
      }
    },
    mounted() {
      if(this.keyState) this.handleKeyState(this.keyState)
    },
    methods: {
      i18n() { return i18n().emailPassword.finishEmailChange },
      exit() {
        this.$router.replace({ name: 'index' })
      },
      handleKeyState(v) {
        if(this.state == 'loading') {
          if(!v) {
            this.state = 'notFound'
            return
          }
          if(v.used) {
            this.state = 'used'
          } else if(v.expire < Date.now()) {
            this.state = 'expired'
          } else if(this.state == 'loading' || this.state == 'notFound') {
            this.state = "working"
            this.workingZone.addPromise('Updating email',
                api.command(["emailPassword", "finishEmailChange"], {
                  key: this.key,
                  _commandId: this.key + "_" + api.windowId
                }).then(result => {
                  this.state = "done"
                }).catch(error => {
                  this.error = error
                  if(error != 'notFound' && error != 'taken') {
                    console.log("UNKNOWN ERROR", error)
                    throw error
                  } else {
                    this.state = error
                  }
                })
            )
          }
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  @media screen and (max-width: 1024px) {
    .mdl-layout__header {
      display: block;
      margin-bottom: 3em;
    }
  }

  .guest.confirm-email {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: 280%;
  }



  .guest button {
    color: white;
    font-weight: 300;
    float: right;
  }

  .guest button:hover {
    background: #2906bf;
    color: white;
    font-weight: 300;
  }

  form.blurred {
    filter: blur(2px);
  }

  .working {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.3);
  }
  .working div {
    margin: auto;
    height: 5em;
    width: 5em;
  }

  .error {
    height: 3em;
    font-size: 1.23em;
    color: #d00000;
  }

  .loading {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }


  .textWrapper {
    margin: auto;
  }
  .card {
    padding: 10px;
  }
</style>
