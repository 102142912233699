<template>
  <page>
    <div class="card full-width main-card">
      <h2>{{ i18n.title }}</h2>
      <div v-if="myApiAccess === null">
        <p>Access to google ads api is not configured.</p>
        <router-link :to="{ name: 'adsApi:access' }" tag="button" class="button">
          {{ i18n.connectAdsApi }}
        </router-link>
      </div>
      <div v-else-if="myApiState && myApiState.state == 'error'" class="campaigns-error">
        <h3>Error: {{ myApiState.error.type }}</h3>
        <p>{{ myApiState.error.message }}</p>
        <router-link v-if="myApiState.error.type == 'unauthenticated'" :to="{ name: 'adsApi:access' }"
                     tag="button" class="button">
          {{ i18n.connectAdsApi }}
        </router-link>
      </div>
      <div v-else-if="!myApiState || myApiState.state == 'ready'" class="buttons">
        <button type="button" class="button" @click="() => refresh()">
          <img src="/static/icons/refresh.svg"><span>{{ i18n.refresh }}</span>
        </button>
      </div>
      <div v-else>
        <p>Reading data from google ads...</p>
      </div>
      <div v-for="userAccount in myApiAccounts" :key="userAccount.id">
        <CampaignsAccount :userAccount="userAccount"/>
      </div>
    </div>
  </page>
</template>

<script>

  import api from "api"
  import i18n from "i18n"

  import CampaignsAccount from "./CampaignsAccount.vue"

  export default {
    name: "Campaigns",
    inject: ['workingZone'],
    components: { CampaignsAccount },
    reactive: {
      myApiAccess() { return ['adsApi', 'myApiAccess', { } ] },
      myApiAccounts() { return ['adsApi', 'myApiAccounts', { } ] },
      myApiState() { return ['adsApi', 'myApiState', { } ] },
    },
    reactivePreFetch(route) {
      return [
        {
          what: [ 'adsApi', 'myApiAccess', { } ]
        },
        {
          what: [ 'adsApi', 'myApiState', { } ]
        },
        {
          what: [ 'adsApi', 'myApiAccounts', { } ],
          more: [{
            schema: [[ 'adsApi', 'apiAccount', { account: { property: "account" } } ]]
          }]
        }
      ]
    },
    computed: {
      i18n() {
        console.log('I18N', i18n())
        return i18n().adsApi.campaigns
      },
      query() {
        return this.$route.query
      }
    },
    data() {
      return {
      }
    },
    methods: {
      refresh() {
        this.workingZone.addPromise('refreshAccounts', (async () => {
          const result = await api.command(['adsApi', 'refreshAccounts'], { })
          console.log( result )
        })())
      }
    },
    mounted() {
      console.error("MY API ACCESS", this.myApiAccess)
      if(this.myApiAccess === null) {
        this.$router.push({ name: 'adsApi:access' })
      }
    }
  }

</script>

<style scoped lang="scss">


</style>
