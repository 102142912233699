<template>
  <form-field-bind :name="name" v-slot="{ value, setValue, error }">
    <FieldTemplate :errorText="errorText" :error="error" :label="label" :name="name">
      <div v-if="value">
        <uploaded-picture-img :picture="value" class="picture-field-picture" reactive original></uploaded-picture-img>
      </div>
      <div class="picture-field-buttons buttons">
        <button v-if="value" type="button" class="button" @click="remove">
          <img src="/static/icons/delete_forever.svg" />
          <span>{{ i18n.pictDel }}</span>
        </button>
      </div>
      <div v-if="!value">
        <FileInput accept="image/*" @input="handleFile" class="button">{{ i18n.pictUp }}</FileInput>
      </div>
    </FieldTemplate>
  </form-field-bind>
</template>

<script>
import i18n from "i18n"
import api from "@/api"
import FieldTemplate from "./FieldTemplate.vue"
import FileInput from "../inputs/FileInput.vue"
import { PictureUpload, preProcessFile } from "../utils/PictureUpload.js"
import { blobToDataUrl } from "common/components/utils/imageUtils.js"

export default {
  name: "PictureUploadField",
  components: { FieldTemplate, FileInput },
  inject: ['form', 'workingZone'],
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String
    },
    errorText: {
      type: Object
    },
    width : {
      type: Number
    },
    height: {
      type: Number
    },
    pictureEditorType: {
      type: String,
      default: 'circle'
    }
  },
  data() {
    return {
      value: null,
    }
  },
  reactive: {
    picture() { return this.value && [ 'pictures', 'PictureOne', { picture: this.value } ] }
  },
  watch: {
  },
  computed: {
    i18n() { return i18n().pictureField }
  },
  methods: {
    async doHandleFile(file) {
      const name = file.name
      const pictureIdPromise = api.command(["pictures", "createEmptyPicture"], {
        name, purpose: 'upload'
      })
      const filePromise = preProcessFile(file)
      const [img, pictureId] = await Promise.all([filePromise, pictureIdPromise])
      const url = await blobToDataUrl(img.blob)

      this.pictureUpload = new PictureUpload(pictureId, url, img.blob, img.size)
      await this.pictureUpload.donePromise
      this.form.setFieldValue(this.name, pictureId)
      return { pictureId }
    },
    handleFile(file) {
      this.workingZone.addPromise('upload picture', this.doHandleFile(file))
    },
    remove() {
      this.form.setFieldValue(this.name, null)
      this.$emit('removed')
    }
  },
  created() {
    this.valueObserver = (v) => {
      this.value = v
      //console.log("V", v)
    }
    this.form.observe(this.name, this.valueObserver)
  },
  beforeDestroy() {
    this.form.unobserve(this.name, this.valueObserver)
  }
}

</script>
