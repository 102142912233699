var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page",
    { attrs: { footer: true } },
    [
      _c(
        "loading",
        { attrs: { what: _vm.state != "loading", name: "key state" } },
        [
          _vm.keyState || _vm.state == "notFound"
            ? _c("div", { staticClass: "card half-width main-card" }, [
                _vm.state == "used" ||
                _vm.state == "expired" ||
                _vm.state == "done" ||
                _vm.state == "notFound" ||
                _vm.state == "resent"
                  ? _c(
                      "div",
                      [
                        _c("h4", [
                          _vm._v(_vm._s(_vm.i18n[_vm.state].title)),
                          _vm.state === "done"
                            ? _c("span", [
                                _c("br"),
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.i18n[_vm.state].subtitle))
                                ])
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(_vm.i18n[_vm.state].text))]),
                        _vm._v(" "),
                        _vm.state == "used"
                          ? _c(
                              "router-link",
                              { attrs: { to: { name: "user:login" } } },
                              [_vm._v(_vm._s(_vm.i18n[_vm.state].button))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.state == "expired"
                          ? _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.resendConfirmationEmail.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.i18n.resendEmail))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.state == "done"
                          ? _c(
                              "button",
                              {
                                staticClass: "button",
                                on: { click: _vm.exit }
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.i18n[_vm.state].exitButton) +
                                    "\n        "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }