var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("page", [
    _c(
      "div",
      { staticClass: "card full-width main-card" },
      [
        _c("h2", [_vm._v(_vm._s(_vm.i18n.title))]),
        _vm._v(" "),
        _vm.myApiAccess === null
          ? _c(
              "div",
              [
                _c("p", [
                  _vm._v("Access to google ads api is not configured.")
                ]),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "button",
                    attrs: { to: { name: "adsApi:access" }, tag: "button" }
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.i18n.connectAdsApi) + "\n      "
                    )
                  ]
                )
              ],
              1
            )
          : _vm.myApiState && _vm.myApiState.state == "error"
          ? _c(
              "div",
              { staticClass: "campaigns-error" },
              [
                _c("h3", [
                  _vm._v("Error: " + _vm._s(_vm.myApiState.error.type))
                ]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.myApiState.error.message))]),
                _vm._v(" "),
                _vm.myApiState.error.type == "unauthenticated"
                  ? _c(
                      "router-link",
                      {
                        staticClass: "button",
                        attrs: { to: { name: "adsApi:access" }, tag: "button" }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.i18n.connectAdsApi) +
                            "\n      "
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          : !_vm.myApiState || _vm.myApiState.state == "ready"
          ? _c("div", { staticClass: "buttons" }, [
              _c(
                "button",
                {
                  staticClass: "button",
                  attrs: { type: "button" },
                  on: {
                    click: function() {
                      return _vm.refresh()
                    }
                  }
                },
                [
                  _c("img", { attrs: { src: "/static/icons/refresh.svg" } }),
                  _c("span", [_vm._v(_vm._s(_vm.i18n.refresh))])
                ]
              )
            ])
          : _c("div", [_c("p", [_vm._v("Reading data from google ads...")])]),
        _vm._v(" "),
        _vm._l(_vm.myApiAccounts, function(userAccount) {
          return _c(
            "div",
            { key: userAccount.id },
            [_c("CampaignsAccount", { attrs: { userAccount: userAccount } })],
            1
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }