import api from "api"
import autotrack from 'autotrack'
import analyticsConstants from './analytics-constants.js'

/*
/// GA with AUTOTRACK:
(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','https://www.google-analytics.com/analytics.js','ga')
ga('create', 'UA-145695518-1', 'auto')
//ga('require', 'eventTracker')
ga('require', 'outboundLinkTracker')
//ga('require', 'urlChangeTracker')
ga('require', 'pageVisibilityTracker')
ga('require', 'socialWidgetTracker')
ga('require', 'mediaQueryTracker')
//ga('require', 'impressionTracker')

/// FACEBOOK PIXEL:
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window,document,'script',
    'https://connect.facebook.net/en_US/fbevents.js')
fbq('init', '452935215327156')*/
let ga = () => {}
let fbq = () => {}

let isWriting = true
let queue = []
function sendQueue() {
  if(isWriting) return
  isWriting = true
  const data = queue.slice()
  queue = []
  api.request(['analytics', 'saveEvents'], data).then(result => {
    isWriting = false
    if(queue.length > 0) setTimeout(sendQueue, 100)
  }).catch(error => {
    console.error("COULD NOT WRITE EVENTS to ES!", error)
    queue = data.concat(queue)
    isWriting = false
  })
}

const connection = api.observable(['analytics','connection', analyticsConstants])
connection.observe((signal, value) => {
  if(value) {
    isWriting = false
    sendQueue()
  }
})

export function sendToServer(event) {
  //if(document.location.hostname == 'localhost') return
  event = {
    ...event,
    clientTs: (new Date().getTime())+(new Date().getTimezoneOffset()*60000)
  }
  queue.push(event)
  sendQueue()
}


let hidden, visibilityChange;
if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
  hidden = "hidden"
  visibilityChange = "visibilitychange"
} else if (typeof document.msHidden !== "undefined") {
  hidden = "msHidden"
  visibilityChange = "msvisibilitychange"
} else if (typeof document.webkitHidden !== "undefined") {
  hidden = "webkitHidden"
  visibilityChange = "webkitvisibilitychange"
}

function handleVisibilityChange() {
  if (document[hidden]) {
    sendToServer({ type: 'hidden' })
  } else {
    sendToServer({ type: 'visible' })
  }
}

if(hidden !== undefined) {
  document.addEventListener(visibilityChange, handleVisibilityChange, false)
}

export function pageView(path) {
  ga('set', 'page', path)
  ga('send', 'pageview')
  fbq('track', 'PageView')

  sendToServer({ type: 'pageView', path })
}

export function action(category, label, fields) {
  ga('send', {
    hitType: 'event',
    eventCategory: category,
    eventAction: 'action',
    eventLabel: label,
    fieldsObject: fields
  })
  const path = document.location.pathname
  sendToServer({ type: 'action', path, category, label, fields })
}

export function form(category, label, fields) {
  ga('send', {
    hitType: 'event',
    eventCategory: category,
    eventAction: 'form',
    eventLabel: label,
    fieldsObject: fields
  })
  const path = document.location.pathname
  sendToServer({ type: 'form', path, category, label, fields })
}


export function actionError(category, label, fields) {
  const path = document.location.pathname
  sendToServer({ type: 'actionError', path, category, label, fields })
}
export function actionDone(category, label, fields) {
  const path = document.location.pathname
  if(category == 'emailPassword' && label == "finishRegister") fbq('track', 'CompleteRegistration')
  sendToServer({ type: 'actionDone', path, category, label, fields })
}
export function formError(category, label, fields) {
  const path = document.location.pathname
  sendToServer({ type: 'formError', path, category, label, fields })
}
export function formDone(category, label, fields) {
  const path = document.location.pathname
  if(category == 'user' && label == 'completeUserData') fbq('track', 'CompleteRegistration')
  sendToServer({ type: 'formDone', path, category, label, fields })
}


let loadingStartedTs = Date.now()
export function loadingStarted() {
  loadingStartedTs = Date.now()
  const path = document.location.pathname
  sendToServer({ type: "loadingStarted", path })
}
export function loadingDone() {
  const path = document.location.pathname
  sendToServer({ type: "loadingDone", duration: Date.now() - loadingStartedTs, path })
}
export function loadingError(error) {
  const path = document.location.pathname
  sendToServer({ type: "loadingError", duration: Date.now() - loadingStartedTs, ...error, path })
}

let workingStartedTs = Date.now()
export function workingStarted() {
  workingStartedTs = Date.now()
  const path = document.location.pathname
  sendToServer({ type: "workingStarted" , path })
}
export function workingDone() {
  const path = document.location.pathname
  sendToServer({ type: "workingDone", duration: Date.now() - workingStartedTs, path })
}
export function workingError(error) {
  const path = document.location.pathname
  sendToServer({ type: "workingError", duration: Date.now() - workingStartedTs, ...error, path })
}

export function redirect(from, to) {
  const path = document.location.pathname
  sendToServer({ type: "redirect", from, to, path })
}

const command = api.command
api.command = function(method, args) {
  const statArgs = { ...args }
  delete statArgs.passwordHash
  action(method[0], method[1], statArgs)
  const promise = command(method, args)
  promise.then(result => actionDone(method[0], method[1], statArgs))
  promise.catch(result => actionError(method[0], method[1], statArgs))
  return promise
}


sendToServer({ type: "appStart" })
//pageView(document.location.pathname)

export default {
  pageView,
  form, formDone, formError,
  action, actionDone, actionError,
  loadingStarted, loadingDone, loadingError,
  workingStarted, workingDone, workingError,
  redirect
}
