module.exports = {
  form: {
    title: "Title",
    titlePlaceholder: "Enter title...",
    date: "Publication date",
    category: ["Category"],
    categoryPlaceholder: ["Select category..."],
    content: "Content",
    lang: "Language",
    tags: "Tags",
    langPlaceholder: "Select language...",
    picture: "Picture"
  },
  create: {
    header: "Create new post",
    createButton: "Create post"
  },
  update: {
    header: "Update post",
    updateButton: "Save changes"
  },
  landing: {
    topStories: 'Top <span class="yellow-lines women-purple">Our stories</span>',
    news: 'Vole Apps <span class="yellow-lines news-purple">news</span>'
  },
  readMore: "Read more",
  categories: 'Categories',
  popularTags: 'Popular tags',
  breadcrumb: {
    blog: 'Blog',
    category: 'Category: ',
    tag: 'Tag: '
  },
  share: 'Share',
  leaveComment: 'Leave a comment',
  dateFormat: "MMM D, YYYY",
  tagsListInput: {
    add: "Add a new tag",
    typeIn: "Type in a new tag",
    empty: "Tags empty",
    addNew: "Add new tag",
    alreadyExists: (tag) => `Tags "${tag}" already added.`
  },
  shareThis: 'Share this:',
  tagsBar: 'Tags:',


}
