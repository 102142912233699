<template>
  <page v-if="isAuthorized">
    <div class="full-width">
      <observe name="myCheckers" :what="['urlChecker', 'myCheckers', {}]" v-slot="{ value: checkers }">
        <UrlCard v-for="checker in checkers" :checker="checker" :key="checker.to"></UrlCard>
      </observe>
    </div>
    <div class="card main-card full-width">
      <h3>{{ i18n.addUrlTitle }}</h3>
      <command-form service="urlChecker" action="createChecker"
                    :keepOnDone="true" @done="handleCheckerAdded"
                    :fieldValidators="{ url: v => validateUrl(v) }"
                    reset-on-done
                    ref="form">

        <text-field name="url" :label="i18n.url" :fieldValidators="{ url: v => validateUrl(v) }">
        </text-field>

        <button type="submit" class="button" role="button">
          <img src="/static/icons/add_task.svg">
          <span>{{ i18n.createCheckerSubmit }}</span>
        </button>

      </command-form>
    </div>
  </page>
  <page v-else>
    <div class="full-width" v-html="partial && partial.data.content">

    </div>
  </page>
</template>

<script>
  import api from "api"
  import i18n from "i18n"
  import SingleSelectInput from "common/components/inputs/SingleSelectInput.vue"
  import currentTime from 'common/components/utils/currentTime.js'
  import UrlCard from "./UrlCard.vue"

  export default {
    name: "UrlsPage",
    components: { UrlCard, SingleSelectInput },
    inject: ['workingZone'],
    reactive: {
      partial: ['partials', 'partial', { partial: 'index' } ]
    },
    reactivePreFetch() {
      return [
        { what: ['partials', 'partial', { partial:'index' } ] }
      ]
    },
    computed: {
      i18n() {
        return i18n().urlChecker
      },
      isLoggedIn() {
        return api.session.loggedIn
      },
      isAuthorized() {
        if(!api.session.loggedIn) return false
        return api.session.roles.indexOf('member') != -1
      }
    },
    methods: {
      validateUrl(value) {
        console.error("CUSTOM URL VALIDATOR!", value)
        if(value && !value.match(/^https?:\/\//)
            && value.match(/^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)) {
          this.$refs.form.setFieldValue('url', 'https://'+value)
        }
      },
      handleCheckerAdded() {

      },
    }
  }
</script>

<style scoped>

</style>