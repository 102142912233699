<template>
  <div>
    <page v-if="!joined">
      <div v-if="isInApp" class="card half-width main-card">
        <h2>{{ i18n.inApp.title }}</h2>
        <p v-if="isApple">{{ i18n.inApp.apple_instruction }}</p>
        <p v-else>{{ i18n.inApp.android_instruction }}</p>
        <p>{{ this.roomUrl }} </p>
        <div class="buttons">
          <button class="button" type="button" @click="copyRoomUrl">
            <img src="/static/icons/content_copy.svg"><span>{{ i18n.copyLinkButton }}</span>
          </button>
        </div>
        <p v-if="linkCopied" class="linkCopied">{{ i18n.linkCopied }}</p>
      </div>
      <div v-else-if="afterWakeUp" class="card half-width main-card">
        <h2>{{ i18n.afterWakeUp.title }}</h2>
        <p>{{ i18n.afterWakeUp.returnText({ room: roomData && roomData.name }) }}</p>
        <div class="buttons">
          <button type="button" class="button" @click="reloadRoom">{{ i18n.afterWakeUp.returnButton }}</button>
          <button type="button" class="button" @click="goToCreate">{{ i18n.afterWakeUp.createButton }}</button>
        </div>
      </div>
      <div v-else class="card full-width main-card room-card">
        <loading name="roomData" :what="roomData && myStatus && audioLoaded">
          <h1>{{ i18n.room }} {{ roomData && roomData.name }}</h1>
          <div class="room-card-row" v-if="myStatus">
            <div class="room-card-self-video">
              <div class="video-holder">
                <video v-if="hasVideo" autoplay playsinline :src-object.prop.camel="userMedia" muted></video>
                <div v-else class="room-card-video-placeholder">
                  <span class="room-card-video-placeholder-text" v-if="loadingMedia">{{ i18n.cameraInit }}</span>
                  <span class="room-card-video-placeholder-text" v-else>{{ i18n.cameraDisabled }}</span>
                </div>
                <VolumeIndicator v-if="userMedia" :stream="userMedia"></VolumeIndicator>
                <div class="room-card-self-video-buttons buttons">
                  <button v-if="peer" class="button control-bar-item" type="button" @click="toggleAudioInput">
                    <img v-if="peer.anyLocalAudioEnabled" src="/static/icons/mic.svg" height="24">
                    <img v-else-if="peer.anyLocalAudioAvailable" src="/static/icons/mic_off.svg" height="24">
                    <img v-else src="/static/icons/mic_none.svg" alt="menu" height="24">
                  </button>
                  <button v-if="peer && peer.anyLocalVideoAvailable" class="button control-bar-item" type="button"
                          @click="toggleVideoInput">
                    <img v-if="peer.anyLocalVideoEnabled" src="/static/icons/videocam.svg" height="24">
                    <img v-else src="/static/icons/videocam_off.svg" height="24">
                  </button>
                </div>
              </div>
              <div class="device-select">
                <single-select-input v-if="videoInputDevices && videoInputDevices.length>0"
                                     v-model="selectedVideoInput"
                                     :options="videoInputDevices.concat(['default'])"
                                     :text="option => option !== 'default'
                                       ? (option.label || 'unknown')
                                       : i18n.browserDefaultVideo"
                                     :placeholder="i18n.selectVideoDevice">
                </single-select-input>
                <single-select-input v-if="audioInputDevices && audioInputDevices.length>0"
                                     v-model="selectedAudioInput"
                                     :options="audioInputDevices.concat(['default'])"
                                     :text="option => option !== 'default'
                                       ? (option.label || 'unknown')
                                       : i18n.browserDefaultAudio"
                                     :placeholder="i18n.selectAudioDevice">
                </single-select-input>
              </div>
            </div>

            <div class="video-card-info">
              <p v-if="myStatus.joined || myStatus.canJoin">{{ i18n.roomReady }}</p>
              <p v-else>{{ i18n.roomClosed }}</p>
              <p  v-if="myStatus.joined || myStatus.canJoin">
                {{ i18n.joiningLinkInfo }} <br>{{ roomUrl }}
              </p>
              <div class="buttons" v-if="myStatus.joined || myStatus.canJoin">
                <button class="button" type="button" @click="copyRoomUrl">
                  <img src="/static/icons/content_copy.svg"><span v-if="!linkCopied">{{ i18n.copyLinkButton }}</span>
                </button>
                <p v-if="linkCopied" class="linkCopied">{{ i18n.linkCopied }}</p>
              </div>
              <br>
              <div class="buttons">
                <button class="button" type="button" @click="join"><span>{{ i18n.joinButton }}</span></button>
              </div>
            </div>
          </div>
        </loading>
      </div>
    </page>
    <div v-else class="room-view room-view-video">
      <NavBar></NavBar>
      <div v-if="versionMismatch && displayVersionMismatch"
           class="version-alert-bar" @click="reload">{{ i18n.versionMismatch }}</div>
      <pre v-if="peer" style="display: none">{{ JSON.stringify(peer.summary, null, "  ") }}</pre>
      <div class="under-navbar" ref="underNavBar">
        <multipane class="main-area" ref="mainArea"
                   @paneResize="onResize"
                   @paneResizeStop="onResizeStop">

          <div class="content-area" ref="contentArea">
            <VideoChat v-if="roomSlug" :toType="'room'" :toId="roomSlug.to"
                       :localMediaStreams="localMediaStreams" :peers="[ peer, displayPeer ]" :volume="volume">
            </VideoChat>
          </div>
          <multipane-resizer>
            <div class="handleHolder">
              <div class="handle"></div>
            </div>
          </multipane-resizer>
          <div class="tool-bar" ref="toolBar">
  <!--          <AllMembers v-if="roomSlug && roomSlug.to"
                            :class="['room-members', { hidden: isToolBarWillBeHidden || toolBarTab != 'members' }]"
                            toType="room" :toId="roomSlug.to">
            </AllMembers>-->
            <Chat v-if="roomSlug && roomSlug.to"
                      :class="['room-chat', { hidden: isToolBarWillBeHidden || toolBarTab != 'chat'  }]"
                      chatType="room" :chatId="roomSlug.to">
            </Chat>
          </div>
        </multipane>
        <div class="control-bar">
          <div class="left-buttons buttons">
            <div class="button-space" v-if="isDisplayMediaPossible">
              <button v-if="!displayMedia" class="button control-bar-item" type="button" @click="getDisplayMedia">
                <img src="/static/icons/screen_share.svg" alt="screen" height="24">
              </button>
              <button v-else class="button control-bar-item" type="button" @click="dropDisplayMedia">
                <img src="/static/icons/stop_screen_share.svg" alt="screen" height="24">
              </button>
            </div>
            <div class="button-space" v-if="isFullscreenAvailable">
              <button v-if="!fullscreen" class="button control-bar-item" type="button" @click="startFullScreen">
                <img src="/static/icons/fullscreen.svg" alt="screen" height="24">
              </button>
              <button v-else class="button control-bar-item" type="button" @click="exitFullScreen">
                <img src="/static/icons/fullscreen_exit.svg" alt="screen" height="24">
              </button>
            </div>
            <!--<router-link v-if="roomData"
                         :to="{ name: 'peer-connection:debugger', params: { toType: 'room', toId: roomSlug.to } }">
              peer-connection:debugger
            </router-link>-->
          </div>
          <div class="middle-buttons buttons">
            <div class="button-space">
              <button v-if="peer" class="button control-bar-item" type="button" @click="toggleAudioInput">
                <img v-if="peer.anyLocalAudioEnabled" src="/static/icons/mic.svg" alt="menu" height="24">
                <img v-else-if="peer.anyLocalAudioAvailable" src="/static/icons/mic_off.svg" alt="menu" height="24">
                <img v-else src="/static/icons/mic_none.svg" alt="menu" height="24">
              </button>
            </div>
            <div class="button-space">
              <button class="button control-bar-item" type="button" @click="leaveCall">
                <img src="/static/icons/call_end.svg" alt="menu" height="24">
              </button>
            </div>
            <div class="button-space">
              <button v-if="peer && peer.anyLocalVideoAvailable" class="button control-bar-item" type="button"
                      @click="toggleVideoInput">
                <img v-if="peer.anyLocalVideoEnabled" src="/static/icons/videocam.svg" alt="menu" height="24">
                <img v-else src="/static/icons/videocam_off.svg" alt="menu" height="24">
              </button>
            </div>

            <volume-input v-model="volume"></volume-input>
          </div>
          <div class="right-buttons buttons">
            <div class="button-space">
              <button :class="['button','control-bar-item', { 'new-messages': hasNewMessages }]"
                      type="button" @click="showChat">
                <img src="/static/icons/chat.svg" alt="menu" height="24">
              </button>
            </div>
           <!-- <div class="button-space">
              <button class="button control-bar-item" type="button" @click="showMembers">
                <img src="/static/icons/group.svg" alt="menu" height="24">
              </button>
            </div>-->
          </div>
        </div>
      </div>
      <div class="rotate-device-screen">
        <div class="rotate-device-info">
          <img src="/static/icons/screen_rotation.svg">
          <p>{{ i18n.rotateDevice }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import api from "api"
  import i18n from "i18n"
  import { Multipane, MultipaneResizer } from 'vue-multipane'
  import analytics from "analytics"
  import copyToClipboard from 'copy-to-clipboard'
  import isMobile from 'ismobilejs'
  import isInApp from "common/components/utils/isInApp.js"

  import AllMembers from "@/modules/access/AllMembers.vue"
  import Chat from "@/modules/chat/Chat.vue"
  import NavBar from "@/segments/NavBar.vue"
  import VideoChat from "./VideoChat.vue"
  import { getDisplayMedia, getUserMedia, isUserMediaPermitted } from "../peer-connection/userMedia.js"
  import { createPeer } from "@/modules/peer-connection/Peer.js"
  import PermissionsModal from "@/modules/peer-connection/PermissionsModal.vue"
  import overlayModel from "common/components/utils/overlayModel.js"
  import MultiButtonDialog from "common/components/MultiButtonDialog.vue"
  import VolumeIndicator from "./VolumeIndicator.vue"
  import SingleSelectInput from "common/components/inputs/SingleSelectInput.vue"



  export default {
    name: "Room",
    components: {
      Multipane, MultipaneResizer, AllMembers, Chat, NavBar, VideoChat, VolumeIndicator, SingleSelectInput
    },
    inject: ['workingZone'],
    props: {
      room: {
        type: String,
        required: true
      }
    },
    reactive: {
      roomSlug() { return ['slugs', 'slugByPath', { group: "room", path: this.room }] },
      roomData() { return this.roomSlug && this.roomSlug.to
          && ['room', 'room', { room: this.roomSlug.to }] },
      myStatus() { return this.roomSlug && this.roomSlug.to
          && ['accessControl', 'myStatus', { toType: 'room', toId: this.roomSlug.to }] },
      roomOnline() { return this.roomSlug && this.roomSlug.to
          && ['online', 'self', "access", 'room', this.roomSlug.to ] },
      readHistory() { return this.roomSlug && this.roomSlug.to
          && ['readHistory', "readHistory", { toType: 'room', toId: this.roomSlug.to }] },
    },
    reactivePreFetch(route) {
      return [
        {
          what: ['slugs', 'slugByPath', { group: "room", path: route.params.room }],
        }
      ]
    },
    data() {
      return {
        joined: false,
        wasMounted: false,
        userMedia: null,
        displayMedia: null,
        loadingMedia: false,

        devices: [],
        selectedVideoInput: null,
        selectedAudioInput: null,

        peer: null,
        displayPeer: null,

        audio: null,
        audioLoaded: false,

        toolBarWidth: 0,
        savedToolBarWidth: 250,
        isToolBarHidden: true,
        toolBarTab: 'chat',

        joining: false,
        afterWakeUp: false,
        isInApp: false,
        isApple: false,
        isMobile: true,

        volume: 1,

        linkCopied: false,

        isFullscreenAvailable: false,
        fullscreen: false
      }
    },
    computed: {
      i18n() {
        return i18n().videoChat
      },
      isDisplayMediaPossible() {
        return (typeof window != "undefined") && !this.isMobile
      },
      videoInputDevices() {
        return this.devices.filter(d => d.kind == 'videoinput')
      },
      audioInputDevices() {
        return this.devices.filter(d => d.kind == 'audioinput')
      },
      userMediaConstraints() {
        return {
          video: (this.selectedVideoInput && this.selectedVideoInput.deviceId)
              ? { deviceId: this.selectedVideoInput.deviceId }
              : true,
          audio: (this.selectedAudioInput && this.selectedAudioInput.deviceId)
              ? { deviceId: this.selectedAudioInput.deviceId }
              : true,
        }
      },
      localMediaStreams() {
        const userMedia = this.userMedia
        const displayMedia = this.displayMedia
        return (userMedia ? [userMedia] : []).concat(displayMedia ? [displayMedia] : [])
      },
      hasNewMessages() {
        return this.readHistory && (this.readHistory.read || '') < (this.readHistory.last || '')
      },
      isToolBarWillBeHidden() {
        return this.toolBarWidth < 200
      },
      redirectWatcher() {
        const slug = this.roomSlug
        if(slug && slug.redirect) {
          analytics.redirect('room/'+slug.path, 'room/'+slug.redirect)
          this.$nextTick(() => {
            this.$router.replace({ ...this.$router.route, params: {
                ...this.$router.params,
                room: slug.redirect
              }})
          })
        }
      },
      hasVideo() {
        if(!this.peer) return false
        return this.peer.anyLocalVideoAvailable
      },
      roomUrl() {
        if(!this.roomData) return
        return ENV.BASE_HREF
            + this.$router.resolve({ name: 'video-chat:room', params: { room: this.roomData.slug } }).href
      },
      versionMismatch() {
        return api.metadata.versionMismatch
      }
    },
    watch: {
      joined(v) {
        if(this.peer) this.peer.setOnline(v)
        if(this.displayPeer) this.displayPeer.setOnline(v)
        //if(v) this.$nextTick(() => this.showChat())
      },
      roomSlug(slug, oldSlug) {
        console.log("ROOM SLUG CHANGE", JSON.stringify(slug), JSON.stringify(oldSlug))
        if(!slug) return
        if(this.peer) {
          this.peer.$destroy()
          this.peer = null
        }
        if(!this.peer && this.wasMounted) this.initPeer()
      },
      userMedia(mediaStream, oldMediaStream) {
        console.log("USER MEDIA STREAM CHANGE:", mediaStream, oldMediaStream)
        this.readDevices()
        if(oldMediaStream) {
          console.log("OLD MEDIA STREAM", oldMediaStream)
          oldMediaStream.getTracks().forEach(track => { if (track.readyState == 'live') track.stop() })
        }
        if(this.peer) {
          this.peer.localMediaStreams = this.userMedia ? [this.userMedia] : []
        }
      },
      displayMedia(mediaStream, oldMediaStream) {
        console.log("DISPLAY MEDIA STREAM CHANGE:", mediaStream, oldMediaStream)
        if(oldMediaStream) {
          const track = oldMediaStream.getVideoTracks()[0]
          if(track) track.removeEventListener('ended', this.displayMediaEndedHandler)

          console.log("OLD MEDIA STREAM", oldMediaStream)
          oldMediaStream.getTracks().forEach(track => { if (track.readyState == 'live') track.stop() })
        }
        if(mediaStream) {
          const track = mediaStream.getVideoTracks()[0]
          if(track) track.addEventListener('ended', this.displayMediaEndedHandler)
        }
        if(this.displayPeer) {
          this.displayPeer.localMediaStreams = this.displayMedia ? [this.displayMedia] : []
        }
      },
      async userMediaConstraints(value) {
        if(this.userMedia) {
          await this.dropUserMedia()
          await this.getUserMedia()
        }
      },
      /*localMediaStreams(streams) {
        if(this.peer) {
          this.peer.localMediaStreams = [...streams]
        }
      }*/
    },
    mounted() {
      if(typeof window == 'undefined') return
      this.isInApp = isInApp()
      this.isMobile = isMobile(window.navigator).any
      this.isApple = isMobile(window.navigator).apple.device

      console.log("IS IN APP", this.isInApp)
      if(this.isInApp) return

      this.audio = document.createElement('audio')
      window.aud = this.audio
      this.audio.addEventListener('canplaythrough', () => this.audioLoaded = true)
      if(this.audio.canPlayType('audio/mp4') || this.audio.canPlayType('audio/m4a')) {
        this.audio.src = '/static/sounds/encoded/click.m4a'
      } else if(this.audio.canPlayType('audio/ogg; codecs="vorbis"')) {
        this.audio.src = '/static/sounds/encoded/click.ogg'
      }
      this.audio.load()

      this.readDevices()

      this.displayMediaEndedHandler = () => this.displayMedia = null

      this.deviceChangeHandler = () => this.readDevices()
      if(typeof window != 'undefined' && navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        navigator.mediaDevices.addEventListener('devicechange', this.deviceChangeHandler)
      }

      console.log("ROOM SLUG", JSON.stringify(this.roomSlug), "PEER", this.peer)
      if(this.roomSlug && !this.peer) this.initPeer()

      this.getUserMedia()

      this.lastSleepCheck = Date.now()
      this.sleepInterval = setInterval(() => {
        if(Date.now() > this.lastSleepCheck + 6000) {
          this.afterWakeUp = true
          this.joined = false
          this.peer.$destroy()
          this.displayPeer.$destroy()
          if(this.userMedia) this.userMedia.getTracks().forEach(function(track) {
            track.stop()
          })
          if(this.displayMedia) this.displayMedia.getTracks().forEach(function(track) {
            track.stop()
          })
          this.userMedia = null
          this.displayMedia = null
        }
        this.lastSleepCheck = Date.now()
      }, 2000)

      this.isFullscreenAvailable = !!(document.fullscreenEnabled || document.webkitFullscreenEnabled
          || document.mozFullScreenEnabled)
      this.fullscreen = !!(document.fullscreenElement || document.webkitFullscreenElement
          || document.mozFullscreenElement)
      this.fullscreenChangeHandler = () => {
        this.fullscreen = !!(document.fullscreenElement || document.webkitFullscreenElement
            || document.mozFullscreenElement)
      }
      document.addEventListener("fullscreenchange", this.fullscreenChangeHandler)
      document.addEventListener("webkitfullscreenchange", this.fullscreenChangeHandler)
      document.addEventListener("mozfullscreenchange", this.fullscreenChangeHandler)

      this.wasMounted = true
    },
    methods: {
      async initPeer() {
        console.log("INIT PEER!")
        let publicSessionInfo = api.session.publicSessionInfo
        if(!publicSessionInfo) {
          publicSessionInfo = await api.get(['accessControl', 'myPublicSessionInfo'])
        }
        this.peer = createPeer(api,
            { toType: 'room', toId: this.roomSlug.to, publicSessionId: publicSessionInfo.id })
        this.peer.setOnline(this.joined)
        this.peer.localMediaStreams = this.userMedia ? [ this.userMedia ] : []

        this.displayPeer = createPeer(api,
            { toType: 'room.display', toId: this.roomSlug.to, publicSessionId: publicSessionInfo.id })
        this.displayPeer.setOnline(this.joined)
        this.displayPeer.localMediaStreams = this.displayMedia ? [ this.displayMedia ] : []
      },
      async readDevices() {
        console.log("READ DEVICES")
        if(navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
          const devices = await navigator.mediaDevices.enumerateDevices()
          this.devices = devices.map(({ deviceId, groupId, kind, label }) => ({ deviceId, groupId, kind, label }))
        }
      },
      copyRoomUrl() {
        copyToClipboard(this.roomUrl)
        this.linkCopied = true
        setTimeout(() => this.linkCopied = false, 5000)
      },
      toggleAudioInput() {
        if(!this.peer) return
        if(!this.peer.anyLocalAudioAvailable) return
        const state = this.peer.anyLocalAudioEnabled
        console.log("TOGGLE AUDIO STATE", state)
        for(const track of this.peer.localTracks) {
          if(track.track.kind == 'audio') this.peer.setTrackEnabled(track, !state)
        }
      },
      toggleVideoInput() {
        if(!this.peer) return
        if(!this.peer.anyLocalVideoAvailable) return
        const state = this.peer.anyLocalVideoEnabled
        console.log("TOGGLE AUDIO STATE", state)
        for(const track of this.peer.localTracks) {
          if(track.track.kind == 'video') this.peer.setTrackEnabled(track, !state)
        }
      },
      leaveCall() {
        this.joined = false
      },
      onResize(a, b, width) {
        if(!this.joined) return
        this.toolBarWidth = this.$refs.toolBar.clientWidth//+(width.slice(0,-2))
      },
      onResizeStop(a, b, width) {
        if(!this.joined) return
        const hidden = this.isToolBarWillBeHidden && !this.isToolBarHidden
        if(hidden) {
          this.hideToolBar()
        } else if(this.isToolBarWillBeHidden) {
          this.showToolBar()
        } else {
          this.savedToolBarWidth = this.toolBarWidth
          this.showToolBar()
        }
      },
      showToolBar() {
        this.toolBarWidth = this.savedToolBarWidth
        this.isToolBarHidden = false
        const w = (1 - this.toolBarWidth/this.$refs.mainArea.$el.clientWidth)*100
        console.log("BUMP", w)
        this.$refs.contentArea.style.width = w+'%'
      },
      hideToolBar() {
        this.toolBarWidth = 0
        this.isToolBarHidden = true
        console.log("HIDE")
        this.$refs.contentArea.style.width = "100%"
      },
      toggleTab(tab) {
        if(!this.isToolBarHidden && this.toolBarTab == tab) return this.hideToolBar()
        this.toolBarTab = tab
        this.showToolBar()
      },
      showMembers() {
        this.toggleTab('members')
      },
      showChat() {
        this.toggleTab('chat')
      },
      join() {
        if(!this.myStatus) return false
        this.audio.play()
        if(this.myStatus.joined) {
          this.joined = true
          return
        }
        if(!this.myStatus.canJoin || !this.roomSlug) throw new Error("cannot join")
        const to = this.roomSlug.to
        console.log("JOINING ROOM", to)
        this.workingZone.addPromise('join room',
          api.command(['accessControl', 'join'], { toType: 'room', toId: to })
            .then(result => {
              console.log("JOINED!", to)
              this.joined = true
            })
            .catch(error => {
              console.error("JOIN", to, "ERROR", error)
              throw error
            })
        )
      },
      async getUserMedia() { // media stream retrival logic
        let constraints = { ...this.userMediaConstraints } // make a copy
        this.loadingMedia = true
        while(true) {
          try {
            console.log("TRY GET USER MEDIA", constraints)
            const mediaStream = await getUserMedia(constraints)
            const videoTracks = mediaStream.getVideoTracks()
            const audioTracks = mediaStream.getAudioTracks()
            console.log('Got stream with constraints:', constraints)
            if(constraints.video) console.log(`Using video device: ${videoTracks[0] && videoTracks[0].label}`)
            if(constraints.audio) console.log(`Using audio device: ${audioTracks[0] && audioTracks[0].label}`)
            this.userMedia = mediaStream
            this.loadingMedia = false
            return;
          } catch(error) {
            console.log("GET USER MEDIA ERROR", error)
            const permitted = await isUserMediaPermitted(constraints)
            if(permitted || error.code == error.NOT_FOUND_ERR) {
              constraints = await this.askToConnectCamera({ ...this.userMediaConstraints })
              if(!constraints) {
                this.loadingMedia = false
                return
              }
            } else { // if not permitted display dialog
              const permitted = await this.showPermissionsModal()
              console.log("CAMERA PERMITTED", permitted)
              if(!permitted) constraints.video = false
              if(!(constraints.video || constraints.audio)) {
                constraints = await this.askToConnectCamera({ ...this.userMediaConstraints })
                if(!constraints) {
                  this.loadingMedia = false
                  return
                }
              }
              continue // retry get user media with new constraints
            }
          }
        }
      },
      async dropUserMedia() {
        if(this.userMedia) this.userMedia.getTracks().forEach(function(track) {
          track.stop()
        })
        this.userMedia = null
      },
      async showPermissionsModal() {
        const micPermission = await navigator.permissions.query({ name: 'microphone' })

        return new Promise((resolve, reject) => {
          overlayModel.show({
            component: PermissionsModal,
            props: {
              title: this.i18n.permissions.title,
              introduction: this.i18n.permissions.introduction,
              closeable: false,
              requiredPermissions: [
                { name: "microphone" },
                { name: "camera" }
              ],
              buttons: [
                (micPermission.state == 'denied'
                    ? { name: this.i18n.permissions.cancelButton, event: "cancel", needPermissions: false }
                    : { name: this.i18n.permissions.disableCameraButton, event: "disabled", needPermissions: false }),
                { name: "Ok", event: "ok", needPermissions: true },
              ]
            },
            on: {
              disabled: () => {
                resolve(false)
              },
              ok: () => {
                resolve(true)
              },
              cancel: () => {
                reject('canceled by user')
              }
            }
          })
        })
      },
      async askToConnectCamera(constraints) {
        console.trace("ASK TO CONNECT CAMERA")
        return new Promise((resolve, reject) => {
          overlayModel.show({
            component: MultiButtonDialog,
            props: {
              title: this.i18n.notConnected.title,
              text: this.i18n.notConnected.text,
              buttons: [
                { event: 'connected', text: this.i18n.notConnected.connectedButton },
                { event: 'camera', text: this.i18n.notConnected.onlyCamera },
                { event: 'microphone', text: this.i18n.notConnected.onlyMicrophone },
                { event: 'cancel', text: this.i18n.notConnected.cancelButton }
              ]
            },
            on: {
              connected: () => resolve({ ...constraints }),
              camera: () => resolve({ ...constraints, audio: false }),
              microphone: () => resolve({ ...constraints, video: false }),
              cancel: () => resolve(null)
            }
          })
        })
      },
      async getDisplayMedia() { // media stream retrival logic
        let initialConstraints = { video: true } // make a copy
        let constraints = { ...initialConstraints }
        while(true) {
          try {
            console.log("TRY GET DISPLAY MEDIA", constraints)
            const mediaStream = await getDisplayMedia(constraints)
            const videoTracks = mediaStream.getVideoTracks()
            const audioTracks = mediaStream.getAudioTracks()
            console.log('Got stream with constraints:', constraints)
            if(constraints.video) console.log(`Using video device: ${videoTracks[0] && videoTracks[0].label}`)
            if(constraints.audio) console.log(`Using audio device: ${audioTracks[0] && audioTracks[0].label}`)
            this.displayMedia = mediaStream
            return;
          } catch(error) {
            console.log("GET DISPLAY MEDIA ERROR", error)
            return;
            const permitted = await isUserMediaPermitted(constraints)
            if(permitted || error.code == error.NOT_FOUND_ERR) {
              constraints = await this.askToConnectCamera(initialConstraints)
              if(!constraints) return
            } else { // if not permitted display dialog
              const permitted = await this.showPermissionsModal()
              console.log("CAMERA PERMITTED", permitted)
              if(!permitted) constraints.video = false
              if(!(constraints.video || constraints.audio)) {
                constraints = await this.askToConnectCamera(initialConstraints)
                if(!constraints) return
              }
              continue // retry get user media with new constraints
            }
          }
        }
      },
      async dropDisplayMedia() {
        if(this.displayMedia) this.displayMedia.getTracks().forEach(function(track) {
          track.stop()
        })
        this.displayMedia = null
      },
      reloadRoom() {
        const route = this.$router.resolve({ name: 'video-chat:room', params: { room: this.room } })
        document.location.href = route.href
      },
      goToCreate() {
        const route = this.$router.resolve({ name: 'video-chat:createRoom' })
        document.location.href = route.href
      },
      async startFullScreen() {
        try {
          const el = this.$refs.underNavBar
          console.log("FS EL", el)
          await (el.requestFullscreen || el.webkitRequestFullscreen || el.mozRequestFullscreen).apply(el)
          this.fullscreen = true
        } catch(error) {
          console.error("FULLSCREEN ERROR", error)
        }
      },
      async exitFullScreen() {
        try {
          const el = document
          await (el.exitFullscreen || el.webkitExitFullscreen || el.mozExitFullscreen).apply(el)
          this.fullscreen = false
        } catch(error) {
          console.error("FULLSCREEN ERROR", error)
        }
      }
    },
    beforeDestroy() {
      clearInterval(this.sleepInterval)
      this.peer.$destroy()
      this.displayPeer.$destroy()

      if(this.userMedia) this.userMedia.getTracks().forEach(function(track) {
        track.stop()
      })
      if(this.displayMedia) this.displayMedia.getTracks().forEach(function(track) {
        track.stop()
      })

      if(typeof window != 'undefined' && navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        navigator.mediaDevices.removeEventListener('devicechange', this.deviceChangeHandler)
      }

      if(typeof window != 'undefined') {
        document.removeEventListener("fullscreenchange", this.fullscreenChangeHandler)
        document.removeEventListener("webkitfullscreenchange", this.fullscreenChangeHandler)
        document.removeEventListener("mozfullscreenchange", this.fullscreenChangeHandler)
      }
    }
  }
</script>

<style scoped>
  .hidden {
    display: none;
  }
</style>