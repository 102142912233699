var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("page", [
    _c(
      "div",
      { staticClass: "card half-width main-card" },
      [
        _c("h2", [_vm._v("Sign In")]),
        _vm._v(" "),
        !_vm.passwordResetSuccess
          ? _c("SocialAuth", {
              attrs: { "no-loading": "" },
              on: {
                started: _vm.handleSocialAuthStarted,
                canceled: _vm.handleSocialAuthCanceled,
                done: _vm.handleSocialAuthDone
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.passwordResetSuccess
          ? _c(
              "div",
              { staticClass: "alert alert-success passwordReset-success" },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.i18n.passwordResetSuccess) + "\n    "
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.waitingForSocialAuth
          ? _c(
              "div",
              { staticClass: "loginPage-email-section" },
              [
                _c("div", { staticClass: "dotted-line" }),
                _vm._v(" "),
                !_vm.passwordResetSuccess
                  ? _c("p", { staticClass: "standard-login-label" }, [
                      _vm._v(_vm._s(_vm.i18n.orUseEmail))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "command-form",
                  {
                    key: "login",
                    ref: "form",
                    attrs: {
                      service: "emailPassword",
                      action: "login",
                      ignoreError: [
                        "notFound",
                        "wrongPassword",
                        "registrationNotConfirmed"
                      ]
                    },
                    on: {
                      submit: _vm.handleSubmit,
                      done: _vm.handleLoginDone,
                      error: _vm.handleLoginError
                    }
                  },
                  [
                    _vm.error == "notFound" || _vm.error == "wrongPassword"
                      ? _c("div", { staticClass: "form-error" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.i18n.incorrectEmail) +
                              "\n        "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.error == "registrationNotConfirmed"
                      ? _c("div", { staticClass: "form-error" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.i18n.registrationNotConfirmed) +
                              "\n          "
                          ),
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.resendConfirmationEmail.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.i18n.resendEmail))]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.error == "emailSent"
                      ? _c("div", { staticClass: "form-error" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.i18n.emailSent) +
                              "\n        "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("text-field", {
                      ref: "email",
                      attrs: { name: "email", label: _vm.i18n.email }
                    }),
                    _vm._v(" "),
                    _c("password-field", {
                      attrs: { name: "passwordHash", label: _vm.i18n.password }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "buttons" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "button",
                            attrs: { type: "submit", role: "button" }
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.i18n.loginButton))])]
                        ),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "login-forgot-password-link",
                            attrs: {
                              to: {
                                name: "user:resetPassword",
                                params: { step: "email" }
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.i18n.forgotPassword) + "\n          "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.waitingForSocialAuth
          ? _c("div", [
              _c("p", { staticClass: "info-message" }, [
                _vm._v(_vm._s(_vm.i18n.waitingForSocialAuth))
              ])
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }