var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "navbar" }, [
    _c(
      "div",
      { ref: "content", staticClass: "navbar-content" },
      [
        _c(
          "span",
          { staticClass: "navbar-item navbar-brand" },
          [
            _c(
              "router-link",
              {
                staticClass: "navbar-brand-link",
                attrs: {
                  to: { name: _vm.loggedIn ? "userIndex" : "index" },
                  tag: "a"
                }
              },
              [_vm._v("\n        VoleApps Apps\n      ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        !_vm.loggedIn
          ? _c(
              "router-link",
              {
                staticClass:
                  "signUp-button navbar-link navbar-item navbar-item-desktop",
                attrs: { tag: "a", to: { name: "user:register" } }
              },
              [_vm._v("\n      " + _vm._s(_vm.i18n.register) + "\n    ")]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.loggedIn
          ? _c(
              "router-link",
              {
                staticClass:
                  "signIn-button navbar-link navbar-item navbar-item-desktop",
                attrs: { tag: "a", to: { name: "user:login" } }
              },
              [_vm._v("\n      " + _vm._s(_vm.i18n.login) + "\n    ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.query,
              expression: "query"
            }
          ],
          staticClass: "input navbar-item navbar-item-desktop",
          attrs: { type: "search", placeholder: _vm.i18n.search },
          domProps: { value: _vm.query },
          on: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.search()
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.query = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            ref: "mobileMenu",
            staticClass: "button navbar-item-mobile navbar-item",
            attrs: { type: "button" },
            on: { click: _vm.showMobileMenu }
          },
          [
            _c("img", {
              attrs: {
                src: "/static/icons/search.svg",
                alt: "search",
                height: "24"
              }
            })
          ]
        ),
        _vm._v(" "),
        _vm.notificationsInfo
          ? _c(
              "button",
              {
                ref: "notificationsMenu",
                staticClass: "button navbar-item notifications-button",
                attrs: { type: "button" },
                on: { click: _vm.showNotificationsList }
              },
              [
                _vm.notificationsCount > 0
                  ? _c("img", {
                      attrs: {
                        src: "/static/icons/notifications_active.svg",
                        alt: "messages",
                        height: "24"
                      }
                    })
                  : _c("img", {
                      attrs: {
                        src: "/static/icons/notifications.svg",
                        alt: "messages",
                        height: "24"
                      }
                    }),
                _vm._v(" "),
                _vm.notificationsCount
                  ? _c("div", { staticClass: "unreadCount" }, [
                      _vm._v(_vm._s(_vm.notificationsCount))
                    ])
                  : _vm._e()
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            ref: "mobileMenu",
            staticClass: "button navbar-item-mobile navbar-item",
            attrs: { type: "button" },
            on: { click: _vm.showMobileMenu }
          },
          [
            _c("img", {
              attrs: {
                src: "/static/icons/menu.svg",
                alt: "menu",
                height: "24"
              }
            })
          ]
        ),
        _vm._v(" "),
        _vm.user && _vm.loggedIn && _vm.unreadInfo && _vm.readCount > 0
          ? _c(
              "button",
              {
                ref: "messagesMenu",
                staticClass: "button navbar-item messages-button",
                attrs: { type: "button" },
                on: { click: _vm.showMessagesList }
              },
              [
                _c("img", {
                  attrs: {
                    src: "/static/icons/message.svg",
                    alt: "messages",
                    height: "24"
                  }
                }),
                _vm._v(" "),
                _vm.unreadCount
                  ? _c("div", { staticClass: "unreadCount" }, [
                      _vm._v(_vm._s(_vm.unreadCount))
                    ])
                  : _vm._e()
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.user && _vm.loggedIn
          ? _c(
              "button",
              {
                ref: "user",
                staticClass: "user-button navbar-item",
                on: { click: _vm.showUserMenu }
              },
              [
                _c("uploaded-picture-img", {
                  staticClass: "user-picture",
                  attrs: {
                    width: "30",
                    height: "30",
                    picture: _vm.user.userData.picture,
                    empty: "/static/empty-user-photo.svg",
                    alt: "Profile"
                  }
                })
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }