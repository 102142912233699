<template>
  <loading class="conversation-view" name="conversation view" :what="allLoaded">
    <div class="conversation-view-content" v-if="allLoaded">
      <div class="conversation-header" v-if="userData === null">
        <div class="conversation-header-picture">
          <img src="/static/deleted-user-photo.svg" width="45" height="45">
        </div>
        <div class="conversation-info">
          <div class="conversation-header-user-name">
            {{ i18n.userDeleted }}
          </div>
        </div>
      </div>
      <div class="conversation-header" v-if="userData">
        <div class="conversation-header-picture">
          <uploaded-picture-img v-if="userData && userData.picture"
                                width="45" height="45" :picture="userData.picture" :reactive="true">
          </uploaded-picture-img>
          <img v-else src="/static/empty-user-photo.svg" width="45" height="45">
          <div v-if="(userData && userData.online) || (otherSession && otherSession.online)" class="onlineDot"></div>
        </div>
        <div class="conversation-info">
          <div class="conversation-header-user-name" v-if="userData">{{ userData.display }}</div>
          <div class="conversation-header-user-name" v-else>
            {{ (sessionData && sessionData.name) || i18n.anonymous }}
          </div>
          <div v-if="userData.online" class="conversation-header-last-online">
            {{ i18n.onlineNow }}
          </div>
          <div v-else class="conversation-header-last-online">
            {{ i18n.lastSeen }} {{ userData.lastOnline | timeAgo }} {{ i18n.ago }}
          </div>
        </div>
      </div>
      <PrivateMessages :toType="'priv'" :toId="conversation" :key="`priv_messages_${conversation}`"></PrivateMessages>
      <MessageInput :message-parameters="{ toType:'priv', toId:conversation }":key="`priv_input_${conversation}`"
                    :mobileSendButton="i18n.mobileSendButton">
      </MessageInput>
    </div>
  </loading>
</template>

<script>
  import i18n from "i18n"
  import analytics from "analytics"
  import api from "@/api"
  import MessageInput from "@/modules/chat/MessageInput.vue"
  import PrivateMessages from "./PrivateMessages.vue"

  export default {
    name: "PrivateConversation",
    components: { MessageInput, PrivateMessages },
    props: {
      conversation: {
        type: String,
        required: true
      }
    },
    reactive: {
      conversationData() { return ['messages', 'privateConversation', { privateConversation: this.conversation } ]},
      userData() { return this.otherUser && ['users', "publicUserData", { user: this.otherUser } ] },
      sessionData() { return this.otherSession
          && ['accessControl', "publicSessionInfo", { publicSessionInfo: this.otherSession } ] },
    },
    computed: {
      i18n() {
        return i18n().messenger
      },
      allLoaded() {
        if(!this.conversationData) return false
        if(this.userData == null) return true
        if(this.sessionData == null) return true
        if(!(this.userData || this.sessionData)) return false
        return true
      },
      otherUser() {
        const convData = this.conversationData
        if(!convData) return
        if(!api.session.session) return
        const me = api.session.session.user
        if(!me) return convData.user1 || convData.user2
        if(convData.user1 == me) return convData.user2
        if(convData.user2 == me) return convData.user1
      },
      otherSession() {
        const convData = this.conversationData
        if(!convData) return
        if(!api.session.publicSessionInfo) return
        const me = api.session.publicSessionInfo.id
        if(!me) return convData.session1 || convData.session2
        if(convData.session1 == me) return convData.session2
        if(convData.session2 == me) return convData.session1
      }
    }
  }
</script>

<style scoped>

</style>