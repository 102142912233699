var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page",
    [
      _vm.item
        ? _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "checkbox-field form-field" }, [
              _c("span", { staticClass: "checkbox" }, [
                _c("input", {
                  staticClass: "checkbox-input",
                  attrs: { type: "checkbox", id: "viewAsXml" },
                  domProps: { checked: _vm.viewAsXml },
                  on: {
                    input: function(ev) {
                      return (_vm.viewAsXml = ev.target.checked)
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "checkbox-mark" })
              ]),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "custom-control-label",
                  attrs: { for: "viewAsXml" }
                },
                [
                  _vm._t("label", function() {
                    return [_vm._v(_vm._s(_vm.i18n.viewAsXml))]
                  })
                ],
                2
              )
            ]),
            _vm._v(" "),
            _vm.viewAsXml
              ? _c("div", { staticClass: "checkbox-field form-field" }, [
                  _c("span", { staticClass: "checkbox" }, [
                    _c("input", {
                      staticClass: "checkbox-input",
                      attrs: { type: "checkbox", id: "rawXml" },
                      domProps: { checked: _vm.rawXml },
                      on: {
                        input: function(ev) {
                          return (_vm.rawXml = ev.target.checked)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "checkbox-mark" })
                  ]),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "custom-control-label",
                      attrs: { for: "rawXml" }
                    },
                    [
                      _vm._t("label", function() {
                        return [_vm._v(_vm._s(_vm.i18n.rawXml))]
                      })
                    ],
                    2
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.item
        ? _c("SearchResult", {
            attrs: {
              data: _vm.item,
              viewAsXml: _vm.viewAsXml,
              rawXml: _vm.rawXml
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.item && _vm.itemDataError == "not_found"
        ? _c("div", { staticClass: "card" }, [
            _c("h3", { staticClass: "form-error" }, [
              _vm._v(_vm._s(_vm.i18n.dataSetDeleted))
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("scroll-loader", {
        key: _vm.scan,
        staticClass: "full-width",
        attrs: {
          what: function(range) {
            return [
              "xmlAlert",
              "itemResults",
              Object.assign(
                {},
                { item: +this$1.position, scan: this$1.scan },
                range
              )
            ]
          },
          debugLog: ""
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var result = ref.row
              return [
                _c("ScanResult", { attrs: { result: result, "no-more": "" } })
              ]
            }
          },
          {
            key: "loadingBottom",
            fn: function(ref) {
              var connectionProblem = ref.connectionProblem
              return [
                _c("div", { staticClass: "search-bottom-loading-bar" }, [
                  _c("div", { staticClass: "card loading-card" }, [
                    _c("div", { staticClass: "cube-spinner" }, [
                      _c("div", { staticClass: "cube1" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "cube2" })
                    ]),
                    _vm._v(" "),
                    connectionProblem
                      ? _c("span", {
                          staticClass: "loading-text",
                          domProps: {
                            innerHTML: _vm._s(_vm.i18n.connectionProblem)
                          }
                        })
                      : _c("span", {
                          staticClass: "loading-text",
                          domProps: {
                            innerHTML: _vm._s(_vm.i18n.loadingBottom)
                          }
                        })
                  ])
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }