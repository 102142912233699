module.exports = {
  url: "XML URL",
  upload: "XML upload",
  phrase: "Search phrase",
  operator: 'Operator',
  fields: "Fields",
  searchButton: 'Search',
  entries: "entries",
  results: "results",
  ago: "ago",
  viewAsXml: "View as xml",
  rawXml: "Raw xml",
  loadingBottom: "Loading...",
  connectionProblem: "Connection problem :/",
  generator: "XML generator",
  generatorLastUse: "Last use",
  generationDuration: "took",
  urlCopied: "The address has been copied",
  logicBlockType: {
    include: "Include",
    exclude: "Exclude"
  },
  operators: {
    'contains': "contain",
    '!contains': "any not contain",
    '^contains': "not contain",
    'exists': "exists",
    '^exists': "not exists",
    'regex': "regex match",
    '!regex': "any regex not match",
    '^regex': "regex not match",

    '>': ">",
    '>=': "⩾",
    '==': "=",
    '<=': "⩽",
    '<': "<",
    '!=': "≠",

    'number': 'as number',
    'number.>': "number >",
    'number.>=': "number ⩾",
    'number.==': "number ==",
    'number.<=': "number ⩽",
    'number.<': "number <",
    'number.!=': "number ≠",

    'words': 'words count',
    'words.>': "words >",
    'words.>=': "words ⩾",
    'words.==': "words =",
    'words.<=': "words ⩽",
    'words.<': "words <",
    'words.!=': "words ≠",

    'characters': 'characters count',
    'characters.>': "characters >",
    'characters.>=': "characters ⩾",
    'characters.==': "characters =",
    'characters.<=': "characters ⩽",
    'characters.<': "characters <",
    'characters.!=': "characters ≠",
  },
  deleted: {
    title: "Deleted",
    text: "Search results and dataset are deleted. Choose another dataset or do search with fresh data.",
    fresh: "Download fresh data",
    search: "Search",
    selectHistory: "Select existing dataset"
  }
}
