var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("page", [
    _c(
      "div",
      { staticClass: "card main-card full-width" },
      [
        _c(
          "command-form",
          {
            attrs: {
              service: "echo",
              action: "echo",
              keepOnDone: true,
              initialValues: { data: "test", delay: 500 }
            },
            on: { done: _vm.handleDone }
          },
          [
            _c("text-area-field", { attrs: { name: "data" } }),
            _vm._v(" "),
            _c("text-field", { attrs: { name: "delay" } }),
            _vm._v(" "),
            _c("div", { staticClass: "buttons" }, [
              _c(
                "button",
                { staticClass: "button", attrs: { type: "submit" } },
                [_vm._v("Send")]
              )
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("pre", [_vm._v(_vm._s(JSON.stringify(_vm.result, null, "  ")))])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }