export default ['g:id', 'title', 'description', 'link', 'g:image_link', 'g:additional_image_link',
  'g:mobile_link', 'g:availability', 'g:availability_date', 'g:cost_of_goods_sold', 'g:expiration_date', 'g:price',
  'g:sale_price', 'g:sale_price_effective_date', 'g:unit_pricing_measure', 'g:unit_pricing_base_measure',
  'g:installment', 'g:subscription_cost', 'g:loyalty_points', 'g:google_product_category', 'g:product_type',
  'g:brand', 'g:gtin', 'g:MPN', 'g:identifier_exists', 'g:condition', 'g:adult', 'g:multipack', 'g:is_bundle',
  'g:energy_efficiency_class', 'g:min_energy_efficiency_class', 'g:max_energy_efficiency_class', 'g:age_group',
  'g:color', 'g:gender', 'g:material', 'g:pattern', 'g:size', 'g:size_type', 'g:size_system', 'g:item_group_id',
  'g:adwords_redirect', 'g:custom_label_0', 'g:promotion_id', 'g:excluded_destination', 'g:included_destination',
  'g:shipping', ["g:shipping","g:price"],["g:shipping","g:country"],["g:shipping","g:max_handling_time"],
  'g:shipping_label', 'g:shipping_weight', 'g:shipping_length', 'g:shipping_width',
  'g:shipping_height', 'g:transit_time_label', 'g:max_handling_time', 'g:min_handling_time', 'g:tax',
  'g:tax_category'].map(field => Array.isArray(field) ? field : [field])
