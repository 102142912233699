<template>
  <page :footer="true">
    <!-- search -->
    <div class="card half-width" v-if="state=='ready'">
      <h2 class="text-center registerPage-heading-title" v-html="i18n.title"></h2>
      <SocialAuth />
      <h4>{{ i18n.orUseEmail }}</h4>
      <section class="loginPage-email-section">
        <command-form service="emailPassword" action="startRegister" @done="handleRegisterStarted" key="register"
                      :external-errors-processing="true"
                      :parameters="{ lang }" @error="v => handleError(v)">
          <text-field name="userData.name" :label="i18n.name"
                      inputClass="form-control-lg col-md-11" groupClass="mb-3 col-md-12"></text-field>

          <text-field name="email" :label="i18n.email"
                      inputClass="form-control-lg col-md-11" groupClass="mb-3 col-md-12"></text-field>

          <div class="custom-form-error text-danger" v-if="resendEmail">
            <a href="#" @click.prevent="resendConfirmationEmail">{{ i18n.resendButton }}</a>
          </div>

          <double-password-field name="passwordHash" :label="i18n.password" :retypeLabel="i18n.retypePassword"
                      inputClass="form-control-lg col-md-11" groupClass="mb-3 col-md-12"></double-password-field>

          <boolean-field name="userData.agreement1">
            <template v-slot:label>
              {{ i18n.agreement1 }} <a :href="i18n.policy">Privacy Policy</a>
            </template>
          </boolean-field>

          <boolean-field name="userData.agreement2">
            <template v-slot:label>
              {{ i18n.agreement2 }}
            </template>
          </boolean-field>

          <phone-field-with-confirm name="userData.phone" :label="i18n.phone" key="phoneField"
                                    confirmName="phoneConfirm" :confirmTexts="i18n.confirmSms"
                                    :comment="i18n.phoneComment">
          </phone-field-with-confirm>

          <form-field-bind name="userData.phone" v-slot="{ value: phone }">
            <div v-if="phone">
              <recaptcha-field inputClass="form-control-lg col-md-11" groupClass="mb-3 col-md-12"></recaptcha-field>

              <div class="buttons">
                <button type="submit" class="button" role="button">
                  <span>{{ i18n.signUpButton }}</span>
                </button>
              </div>
            </div>
          </form-field-bind>
        </command-form>
      </section>
      <section v-if="state=='ready'">
        <router-link :to="{ name:'user:login' }" tag="a">
          {{ i18n.loginButton }}
        </router-link>
      </section>
    </div>
    <div class="card half-width" v-if="state=='started'">
      {{ i18n.linkSent }}
    </div>
    <div class="card half-width" v-if="state=='emailSent'">
      {{ i18n.linkSent }}
    </div>
  </page>
</template>

<script>
  import i18n from "i18n"
  import api from "@/api"
  import Page from "@/segments/Page.vue";
  import SocialAuth from "./SocialAuth.vue";
  import PhoneFieldWithConfirm from "common/components/fields/PhoneFieldWithConfirm.vue"

  export default {
    name: "RegisterPage",
    inject: ['loadingZone', 'workingZone'],
    components: { Page, SocialAuth, PhoneFieldWithConfirm },
    data() {
      return {
        facebookAvailable: true,
        googleAvailable: true,
        state: 'ready',
        resendEmail: null
      }
    },
    computed: {
      lang() { return i18n.config.language },
      i18n() { return i18n().user.register },
    },
    reactivePreFetch() {
      return [

      ]
    },
    methods: {
      handleError({ error, parameters }){
        if(error.properties && error.properties.email === 'registrationNotConfirmed'){
          this.resendEmail = parameters.email
        }
      },
      handleLoginDone() {
        if(this.finished) return
        this.afterLoginTask = this.workingZone.started({ name: 'waiting for login' })
      },
      handleRegisterStarted() {
        this.state = 'started'
      },
      resendConfirmationEmail() {
        this.workingZone.addPromise('resendEmail',
          api.command(['emailPassword', 'resendRegisterKey'], { email: this.resendEmail, lang: i18n.config.language })
              .then(result => this.state = 'emailSent' )
        )
      }
    },
    beforeDestroy() {
      this.finished = true
      if(this.afterLoginTask) this.workingZone.finished(this.afterLoginTask)
    }
  }
</script>

<style scoped>

</style>
