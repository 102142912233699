import ReactiveDao from "@live-change/dao"
import api from "api"

function merge(reverse, ...lists) {
  //console.log("MERGE", reverse, ...lists)
  const all = []
  const keys = new Set()
  for(let sourceId = lists.length - 1; sourceId >= 0; sourceId--) {
    const list = lists[sourceId]
    for(const element of list) {
      if(!keys.has(element.id)) {
        keys.add(element.id)
        all.push(element)
      }
    }
  }
  if(!reverse) {
    all.sort((a, b) => a.id < b.id ? -1 : (a.id > b.id ? 1 : 0))
  } else {
    all.sort((b, a) => a.id < b.id ? -1 : (a.id > b.id ? 1 : 0))
  }
  return all
}

class ObservableStoredMessages extends ReactiveDao.ObservableList {
  constructor(dao, range) {
    super()
    this.dao = dao
    this.range = range
    this.dbPath = ['memDb', 'tableRange', 'messages', 'storedMessages', this.range]
    this.serverPath = ['messages', 'messages', this.range]
    this.serverObservable = this.dao.observable(this.serverPath)
    this.dbObservable = this.dao.observable(this.dbPath)
    this.merger = new ObservableListMerge(range.reverse, false, this.dbObservable, this.serverObservable)
    this.serverObserver = () => {
      if(this.serverObservable.list) this.updateStore(range, this.serverObservable.list)
    }
    this.start()
  }
  start() {
    this.merger.observe(this)
    this.serverObservable.observe(this.serverObserver)
  }

  dispose() {
    super.dispose()
    this.merger.unobserve(this)
    this.serverObservable.unobserve(this.serverObserver)
  }
  respawn() {
    this.start()
  }
  async updateStore(range, messages) {
    const storedMessages = await this.dao.get(this.dbPath)
    for(const storedMessage of storedMessages) {
      if(!messages.find(m => m.id == storedMessage.id)) {
        this.dao.request(['memDb', 'delete'], 'messages', 'storedMessages', storedMessage.id)
      }
    }
    for(const message of messages) {
      this.dao.request(['memDb', 'put'], 'messages', 'storedMessages', message)
    }
  }
}

class ObservableListMerge extends ReactiveDao.ObservableList {
  constructor(reverse, waitForAll, ...sources) {
    super(undefined)
    this.reverse = reverse
    this.waitForAll = waitForAll
    this.sources = sources
    this.connected = false
    this.sourceObservers = sources.map( sourceId => (signal, ...args) => {
      if(this.waitForAll) for(const source of this.sources) if(!source.list) return
      if(!this.connected) {
        this.connected = true
        this.merge()
      } else {
        //console.log("MERGE SIGNAL", signal, JSON.stringify(args))
        switch(signal) {
          case 'set' : return this.merge()
          case 'putByField': {
            const next = this.#getIfExists(args[1], sourceId + 1 )
            if(next) return
            return this.putByField(...args)
          }
          case 'removeByField': {
            const next = this.#getIfExists(args[1], sourceId + 1 )
            if(next) return
            const prev = this.#getIfExists(args[1], sourceId)
            if(prev) return this.putByField('id', args[1], prev, this.reverse, argv[2])
            return this.removeByField(...args)
          }
          case 'push' : {
            const next = this.#getIfExists(args[0].id, sourceId + 1 )
            if(next) return
            return this.putByField('id', args[0].id, args[0], this.reverse, null)
          }
          case 'error': break;
          default: throw new Error(`not supported signal ${signal}`)
        }
      }
    })
    for(let i = 0; i < this.sources.length; i++) {
      this.sources[i].observe(this.sourceObservers[i])
    }
  }
  #getIfExists(id, from = 0, to = this.sources.length) {
    for(let i = from; i < to; i++) {
      const source = this.sources[i]
      for(const element of source.list) {
        if(element.id == id) return element
      }
    }
  }
  dispose() {
    super.dispose()
    for(let i = 0; i < this.sources.length; i++) {
      this.sources[i].unobserve(this.sourceObservers[i])
    }
  }
  respawn() {
    super.respawn()
    for(let i = 0; i < this.sources.length; i++) {
      this.sources[i].observe(this.sourceObservers[i])
    }
  }
  merge() {
    if(this.waitForAll) for(const source of this.sources) if(!source.list) return
    const merged = merge(this.reverse, ...this.sources.map(s => s.list || []))
    //console.log("MERGED", this.sources.map(s => s.list || []), "RESULT", merged)
    this.set(merged)
  }
}

class ObservableListLimit extends ReactiveDao.ObservableList {
  constructor(source, limit, reverse = false) {
    super(undefined)
    this.source = source
    this.limit = limit
    this.reverse = reverse
    this.source.observe(this)
  }

  set(list) {
    list = list.slice(0, this.limit)
    if(list === this.list) return
    try {
      if (JSON.stringify(list) == JSON.stringify(this.list)) return
    } catch(e) {}
    this.list = list
    this.fireObservers('set', list)
    for(const [object, property] of this.properties) {
      object[property] = list
    }
  }
  putByField(field, value, element, reverse = false, oldElement) {
    if(reverse != this.reverse) throw new Error("list direction does not match")
    if(!reverse) {
      let i, l
      for(i = 0, l = this.list.length; i < l; i++) {
        if(i >= this.limit - 1) return
        if(this.list[i][field] == value) {
          this.list.splice(i, 1, element)
          break
        } else if(this.list[i][field] > value) {
          this.list.splice(i, 0, element)
          break
        }
      }
      if(this.list.length >= this.limit) return
      if(i == l) this.list.push(element)
    } else {
      let i
      for(i = this.list.length-1; i >= 0; i--) {
        if(i >= this.limit - 1) return
        if(this.list[i][field] == value) {
          this.list.splice(i, 1, element)
          break
        } else if(this.list[i][field] > value) {
          this.list.splice(i + 1, 0, element)
          break
        }
      }
      if(this.list.length >= this.limit) return
      if(i < 0) this.list.splice(0, 0, element)
    }
    while(this.list.length > this.limit) {
      const removed = this.list.pop()
      this.fireObservers('removeByField', field, removed[field], removed)
    }
    this.fireObservers('putByField', field, value, element, reverse, oldElement)
  }
  removeByField(field, value, oldElement) {
    let json = JSON.stringify(value)
    for(let i = 0, l = this.list.length; i < l; i++) {
      if(JSON.stringify(this.list[i][field]) == json) {
        this.list.splice(i, 1)
        i--
        l--
      }
    }
    this.fireObservers('removeByField', field, value, oldElement)
    while(this.list.length < this.limit && this.source.list.length > this.list.length) {
      const added = this.source.list[this.list.length]
      this.list.push(added)
      this.fireObservers('putByField', field, added[field], added, this.reverse, null)
    }
  }
  dispose() {
    super.dispose()
    this.source.unobserve(this)
  }
  respawn() {
    super.respawn()
    this.source.observe(this)
  }
}

class MessagesObservable extends ReactiveDao.ObservableList {
  constructor(dao, reverse, waitForAll, limit, clientMessages, serverMessages) {
    super()
    this.dao = dao
    this.reverse = reverse
    this.waitForAll = waitForAll
    this.limit = limit
    this.clientMessages = clientMessages
    this.serverMessages = serverMessages

    this.serverMessagesCspCatcher = (signal, ...args) => {
      if(signal == 'set' && args[0]) {
        for(const element of args[0]) {
          setTimeout(() => this.tryRemoveCspMessage(element), 2000)
        }
      } else if(signal == 'putByField') {
        setTimeout(() => this.tryRemoveCspMessage(args[2]), 2000)
      }
    }
    this.serverMessages.observe(this.serverMessagesCspCatcher)

    this.allMessages = new ObservableListMerge(this.reverse, this.waitForAll, this.clientMessages, this.serverMessages)
    this.limitedMessages = new ObservableListLimit(this.allMessages, this.limit, this.reverse)
    this.limitedMessages.observe(this)
  }

  async tryRemoveCspMessage(serverMessage) {
    if(serverMessage.user == api.session.session.user
        || (api.publicSessionInfo && serverMessage.session == api.publicSessionInfo.id)) {
      const cspMessageId = `${serverMessage.toType}_${serverMessage.toId}_${serverMessage.sent}.csp`
      ///console.log("DELETE CSP MESSAGE", cspMessageId)
      this.dao.request(['memDb', 'update'], 'messages', 'clientMessages', cspMessageId, [
        { op: 'set', property: 'sendState', value: 'sync' }
      ], { ifExists: true })
      //const res = await (this.dao.request(['memDb', 'delete'], 'messages', 'clientMessages', cspMessageId).catch(e => null))
      //console.log("CSP DELETE RES", res)
    }
  }
}

class ClientMessages {
  constructor() {
    this.dao = null
    this.messagesObservables = []
    this.resetCounter = 0
  }

  clientMessagesDaoPath({ toType, toId, gt, lt, gte, lte, limit, reverse }) {
    const channelId = `${toType}_${toId}`
    if(!Number.isSafeInteger(limit)) limit = 100
    const range = {
      gt: gt ? `${channelId}_${gt.split('_').pop()}` : (gte ? undefined : `${channelId}_`),
      lt: lt ? `${channelId}_${lt.split('_').pop()}` : undefined,
      gte: gte ? `${channelId}_${gte.split('_').pop()}` : undefined,
      lte: lte ? `${channelId}_${lte.split('_').pop()}` : ( lt ? undefined : `${channelId}_\xFF\xFF\xFF\xFF`),
      limit,
      reverse
    }
    const daoPath = ['memDb', 'tableRange', 'messages', 'clientMessages', range]
    //console.log("CLIENT MESSAGES DAO PATH", JSON.stringify(daoPath))
    return daoPath
  }

  async asyncObservable(what) {
    await this.startPromise
    switch(what[1]) {
      case 'messages': {
        const range = what[2]
        const serverMessages = this.dao.observable(['messages', 'messages', range])
        //const storedMessages = new ObservableStoredMessages(this.dao, range)
        const clientMessagesPath = this.clientMessagesDaoPath(range)
        const clientMessages = this.dao.observable(clientMessagesPath)

        const waitForAll = !!range.waitForAll
        return new MessagesObservable(this.dao, range.reverse, waitForAll, range.limit || 100,
            clientMessages, serverMessages)
      }
      default: return this.dao.observable(what)
    }
  }

  observable(what) {
    const list = new ReactiveDao.ObservableList()
    list.proxy = new ReactiveDao.ObservablePromiseProxy(this.asyncObservable(what))
    /// TODO: reset on login/logout
    list.proxy.observe(list)
    const oldRespawn = list.respawn
    const oldDispose = list.dispose
    let resetCounter = this.resetCounter
    list.dispose = () => {
      oldDispose.call(list)
      list.proxy.unobserve(list)
      const pos = this.messagesObservables.indexOf(list)
      if(pos != -1) this.messagesObservables.splice(pos, 1)
    }
    list.respawn = () => {
      oldRespawn.call(list)
      if(resetCounter != this.resetCounter) {
        resetCounter = this.resetCounter
        list.proxy = new ReactiveDao.ObservablePromiseProxy(this.asyncObservable(what))
      }
      this.messagesObservables.push(list)
      list.proxy.observe(list)
    }
    list.reset = () => {
      if(!list.isDisposed()) list.proxy.unobserve(list)
      list.proxy = new ReactiveDao.ObservablePromiseProxy(this.asyncObservable(what))
      if(!list.isDisposed()) list.proxy.observe(list)
    }
    this.messagesObservables.push(list)
    return list
  }

  async get(what) {
    await this.startPromise
    switch(what[1]) {
      case 'messages': {
        const range = what[2]
        const serverMessages = await this.dao.get(['messages', 'messages', range])
        //return serverMessages
        const clientMessages = await this.dao.get(this.clientMessagesDaoPath(range))
        const mergedMessages = merge(range.reverse, clientMessages, serverMessages)
        const limitedMessages = mergedMessages.slice(0, range.limit || 100)
        return limitedMessages
      }
      default: return this.dao.get(what)
    }
  }

  async request(path, ...args) {
    if(path[1] == 'postMessage') {
      let src = args[0]
      let data = {}
      const time = (new Date()).toISOString()
      const messageFields = this.serviceDefinition.models.Message.userFields
      for(const field of messageFields) data[field] = src[field]
      data.user = api.session.session.user || null
      if(!data.user) {
        data.session = api.session.publicSessionInfo && api.session.publicSessionInfo.id || null
      }
      data.timestamp = time
      data.sent = time
      data.id = `${src.toType}_${src.toId}_${time}.csp`
      data.sendState = 'sending'
      data._commandId = src._commandId
      data.src = src
      const requestTimeout = 5000
      this.dao.requestWithSettings({ requestTimeout }, ['messages', 'postMessage'], { ...src, sent: time })
          .catch(error => {
            console.log("MESSAGE ERROR", error)
            if(error == 'timeout') {
              console.log("UPDATE MESSAGE", data.id)
              return this.dao.request(['memDb', 'update'], 'messages', 'clientMessages', data.id, [
                  { op: 'set', property: 'sendState', value: 'failed' }
                ], { ifExists: true })
            }
            throw error
          })
      return this.dao.request(['memDb', 'put'], 'messages', 'clientMessages', data)
    }
    if(path[1] == 'retry') {
      const data = await this.dao.get(['memDb', 'tableObject', 'messages', 'clientMessages', args[0]])
      if(!data) return null
      this.dao.request(['memDb', 'update'], 'messages', 'clientMessages', data.id, [
        { op: 'set', property: 'sendState', value: 'sending' }
      ], { ifExists: true })
      const requestTimeout = 5000
      this.dao.requestWithSettings({ requestTimeout }, ['messages', 'postMessage'], { ...data.src, sent: data.sent })
          .catch(error => {
            console.log("MESSAGE ERROR", error)
            if(error == 'timeout') {
              console.log("UPDATE MESSAGE", data.id)
              return this.dao.request(['memDb', 'update'], 'messages', 'clientMessages', data.id, [
                { op: 'set', property: 'sendState', value: 'failed' }
              ], { ifExists: true })
            }
            throw error
          })
    }
    if(path[1] == 'cancel') {
      return this.dao.request(['memDb', 'delete'], 'messages', 'clientMessages', args[0]).catch(e => null)
    }
    this.dao.request(['messages', ...path.slice(1)], ...args)
  }

  dispose() {
    this.sessionObservable.unobserve(this.sessionObserver)
  }

  init(dao) {
    this.dao = dao
    this.session = null
    this.user = null

    this.sessionObserver = (signal, value) => {
      if(signal != 'set') throw new Error("UNKNOWN SIGNAL "+signal)
      if(!value) return
      if(value.id != this.session || value.user != this.user) {
        this.user = value.user
        this.session = value.session
        this.resetCounter ++
        /// TODO: reset observables
      }
    }
    this.sessionObservable = this.dao.observable(['session', 'currentSession'])
    this.sessionObservable.observe(this.sessionObserver)
    this.startPromise = (async () => {
      const serviceDefinitions = await this.dao.get(['metadata', 'serviceDefinitions'])
      this.serviceDefinition = serviceDefinitions.find(svc => svc.name == 'messages')
      await this.dao.request(['memDb', 'createDatabase'], 'messages').catch(e=>{})
      await this.dao.request(['memDb', 'createTable'], 'messages', 'clientMessages').catch(e=>{})
      await this.dao.request(['memDb', 'createTable'], 'messages', 'storedMessages').catch(e=>{})
    })()
  }
}

export default ClientMessages
