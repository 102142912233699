const i18n = {
  language: "English",

  navBar: require('./navBar.js'),
  system: require('./system.js'),
  emailPassword: require('./emailPassword/index.js'),
  smsConfirm: require('./smsConfirm/index.js'),
  user: require('./user.js'),
  accountSettings: require('./accountSettings.js'),

  validation: require('./validation.js'),
  routeLeaveConfirm: require('./routeLeaveConfirm.js'),
  pageNotFound: require('./pageNotFound.js'),
  internalServerError: require('./internalServerError.js'),

  pictureField: require('./pictureField.js'),
  pictureEditor: require('./pictureEditor.js'),

  comments: require('./comments.js'),

  blog: require('./blog.js'),

  xmlSearch: require('./xmlSearch.js'),
  xmlExclusions: require('./xmlExclusions.js'),
  xmlAlert: require('./xmlAlert/index.js'),
  urlChecker: require('./urlChecker/index.js'),
  adsApi: require('./adsApi/index.js'),

  videoChat: require('./videoChat.js'),
  messenger: require('./messenger.js'),

  premium: require('./premium.js'),

  notifications: require('./notifications.js'),

  permissions: require('./permissions.js'),

  emailNotifications: require('./emailNotifications/index.js'),

  orphanWords: []
}

module.exports = i18n
