<template>
  <FieldTemplate :errorText="errorText" :error="error" :label="label" :name="name" :groupClass="groupClass">
    <div :class="['select-input',inputClass,{ disabled }]" @click="showSelectMenu"  ref="select">
      <span class="select-input-value">
        <div v-if="liveValue">
          {{ text(liveValue)  }}
        </div>
        <div v-else class="select-input-placeholder">
          {{ placeholder }}
        </div>
      </span>
      <span class="select-input-more">
        <img src="/static/icons/arrow_drop_down.svg" />
      </span>
    </div>
  </FieldTemplate>
</template>

<script>
  import i18n from "i18n"

  import overlayModel from "common/components/utils/overlayModel.js"
  import SingleSelectPicker from "../inputs/SingleSelectPicker.vue"
  import FieldTemplate from "./FieldTemplate.vue"

  export default {
    name: "SingleSelectField",
    props: {
      name: {
        type: String,
        required: true
      },
      label: {
        type: String
      },
      options: {
        type: Array,
        required: true
      },
      text: {
        type: Function,
        default: (v) => v
      },
      more: {
        type: Function,
        default: (v) => false
      },
      internalValue: {
        type: Function,
        default: (v) => v
      },
      placeholder: {
        type: String
      },
      errorText: {
        type: Object
      },
      inputClass: {
        type: String,
        default: ""
      },
      groupClass: {
        type: String
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    components: { FieldTemplate },
    inject: ["form"],
    data() {
      return {
        error: null,
        liveValue: null,
        overlayStackId: null,
        lastCloseTime: 0
      }
    },
    computed: {
    },
    methods: {
      showSelectMenu() {
        if(this.disabled) return
        if(this.overlayStackId === null &&  Date.now() - this.lastCloseTime > 230) {
          this.overlayStackId = overlayModel.show({
            component: SingleSelectPicker,
            props: {
              anchor: overlayModel.elementAnchor(this.$refs.select),
              options: this.options,
              text: this.text,
              more: this.more
            },
            on: {
              selected: (value) => {
                const v = this.internalValue(value)
                console.log("SELECTED", value, "=", v)
                this.liveValue = v
                this.form.setFieldValue(this.name, v)
                this.$emit('select', { value: v })
              },
              close: () => {
                this.overlayStackId = null
                this.lastCloseTime = Date.now()
              }
            }
          })
        } else {
          overlayModel.close(this.overlayStackId)
        }
      }
    },
    created() {
      this.valueObserver = (v) => {
        this.liveValue = v
      }
      this.errorObserver = (e) => {
        this.error = e
      }
      this.form.observe(this.name, this.valueObserver)
      this.form.observeError(this.name, this.errorObserver)
    },
    beforeDestroy() {
      this.form.unobserve(this.name, this.valueObserver)
      this.form.unobserveError(this.name, this.errorObserver)
    }
  }
</script>
