<template>
  <div class="not-found-page">
    <h2>Page not found 404</h2>
  </div>
</template>
<script>
  export default {
    name: "PageNotFound",
    components: { }
  }

</script>
<style lang="scss">

</style>
