const appSpecificUserAgents = ["FBAN", "FBAV"]

function isInApp() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  for (let i = 0; i <= appSpecificUserAgents.length; i++) {
    if (userAgent.indexOf(appSpecificUserAgents[i]) > -1) return true
  }
}

export default isInApp
