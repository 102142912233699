var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form-field-bind", {
    attrs: { name: _vm.name },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(ref) {
            var value = ref.value
            var setValue = ref.setValue
            var error = ref.error
            return [
              _c(
                "FieldTemplate",
                {
                  attrs: {
                    errorText: _vm.errorText,
                    error: error,
                    label: _vm.label,
                    name: _vm.name
                  }
                },
                [
                  _c("br"),
                  _vm._v(" "),
                  _vm._l(_vm.options, function(option) {
                    return _c(
                      "span",
                      {
                        on: {
                          click: function($event) {
                            option == value
                              ? setValue(undefined)
                              : setValue(option)
                          }
                        }
                      },
                      [
                        option == value
                          ? _vm._t("selected", null, { option: option })
                          : _vm._t("default", null, { option: option })
                      ],
                      2
                    )
                  })
                ],
                2
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }