<template>
  <div class="select-picker">
    <overlay-anchor :anchor="anchor" :directions="directions" @positioned="updatePosition">
      <div class="select-picker-card" ef="box"
           :style="{ ...maxDimensions }">
        <div class="options">

          <div class="select">
            <span class="checkbox">
              <input type="checkbox" class="checkbox-input" :checked="isAllSelected"
                     @input="(ev) => setAll(ev.target.checked)"/>
              <span class="checkbox-mark"></span>
            </span>
            <span class="selectName" @click="setValue(options.slice())">
              {{ allText }}
            </span>
          </div>

          <div class="select" v-for="option in options">
            <span class="checkbox">
              <input type="checkbox" class="checkbox-input" :checked="isSelected(option)"
                     @input="(ev) => setSelected(option, ev.target.checked)"/>
              <span class="checkbox-mark"></span>
            </span>
            <span class="selectName" @click="setValue([option])">
              {{ text(option) }}
            </span>
          </div>
        </div>
      </div>
    </overlay-anchor>
  </div>
</template>

<script>
  import i18n from "i18n"
  import Vue from "vue"
  import { OverlayAnchor, windowDimensions } from "common/components/utils/overlayLayer"

  export default {
    name: 'MultiSelectPicker',
    props: {
      anchor: {
      },
      value: {
        type: Array,
        default: () => []
      },
      options: {
        type: Array
      },
      text: {
        type: Function,
        required: true
      },
      allText: {
        type: String,
        default: 'All'
      }
    },
    components: { OverlayAnchor },
    data () {
      return {
        directions: [{ x:1, y:1, ax: -1, ay: 1}, { x:1, y:-1, ax: -1, ay: -1 }],
        position: { x:0, y:0 },
        liveValue: this.value,
        search: ""
      }
    },
    watch: {
      value(v) {
        this.liveValue = v
      }
    },
    computed: {
      windowDimensions() { return windowDimensions },
      maxDimensions() {
        if(this.position.hidden) return { 'max-width':0, 'max-height':0 }
        return {
          'width': this.anchor.element.clientWidth + 'px',
          'max-width': windowDimensions.width - this.position.x - 20 + 'px',
          'max-height': windowDimensions.height - this.position.y - 20 + 'px'
        }
      },
      isAllSelected() {
        const remaining = this.options.filter(o =>
          !this.liveValue.find(n => JSON.stringify(o) == JSON.stringify(n))
        )
        return remaining.length == 0
      }
    },
    methods: {
      updatePosition(position) {
        this.position = position
      },
      isSelected(option) {
        return this.liveValue.find(n => JSON.stringify(n) == JSON.stringify(option))
      },
      setSelected(option, selected) {
        const filteredOptions = this.liveValue.filter(o => JSON.stringify(o) != JSON.stringify(option))
        //console.log("F", filteredOptions)
        //console.log("A", option, selected)
        if(selected) {
          if(filteredOptions.length == this.liveValue.length)
            this.liveValue = [ ...filteredOptions, option ]
          console.log("LV", this.liveValue)
          this.$emit("input", this.liveValue)
        } else {
          if(filteredOptions.length != this.liveValue.length)
            this.liveValue = filteredOptions
          this.$emit("input", this.liveValue)
        }
      },
      setAll(selected) {
        if(selected) {
          this.liveValue = this.options.slice()
          this.$emit("input", this.liveValue)
        } else {
          this.liveValue = []
          this.$emit("input", this.liveValue)
        }
      },
      setValue(options) {
        this.liveValue = options
        this.$emit("input", this.liveValue)
        this.$emit("close")
      }
    },
    mounted() {
    }
  }
</script>
