var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form-field-bind", {
    attrs: { name: _vm.name },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var error = ref.error
          return [
            _c(
              "FieldTemplate",
              {
                attrs: {
                  errorText: _vm.errorText,
                  error: error,
                  label: _vm.label,
                  name: _vm.name
                }
              },
              [
                _c("br"),
                _vm._v(" "),
                _c(
                  "DatePicker",
                  {
                    attrs: {
                      lang: "en",
                      type: "datetime",
                      format:
                        _vm.lang === "en"
                          ? "MM-DD-YYYY HH:mm"
                          : "DD.MM.YYYY HH:mm",
                      placeholder: _vm.placeholder,
                      "input-class": "form-control",
                      width: "100%",
                      value: _vm.inputValue,
                      "minute-step": 5
                    },
                    on: { input: _vm.handleInput }
                  },
                  [
                    _c("img", {
                      attrs: {
                        slot: "calendar-icon",
                        src: "/static/ICO_calendar.svg"
                      },
                      slot: "calendar-icon"
                    })
                  ]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }