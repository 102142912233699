<template>
  <header class="navbar">
    <div class="navbar-content" ref="content">
      <span class="navbar-item navbar-brand">
        <router-link :to="{name: loggedIn ? 'userIndex' : 'index'}" tag="a" class="navbar-brand-link">
          VoleApps Apps
        </router-link>
      </span>
      <router-link tag="a" :to="{name:'user:register'}"
                   class="signUp-button navbar-link navbar-item navbar-item-desktop"
                   v-if="!loggedIn">
        {{ i18n.register }}
      </router-link>
      <router-link tag="a" :to="{name:'user:login'}"
                   class="signIn-button navbar-link navbar-item navbar-item-desktop"
                   v-if="!loggedIn">
        {{ i18n.login }}
      </router-link>
      <input type="search" class="input navbar-item navbar-item-desktop" :placeholder="i18n.search"
             v-model="query" v-on:keyup.enter="search()" />
      <button @click="showMobileMenu" class="button navbar-item-mobile navbar-item" type="button" ref="mobileMenu">
        <img src="/static/icons/search.svg" alt="search" height="24">
      </button>
  <!--    <button v-if="loggedIn" class="notifications-button button navbar-item"
              @click="ev => showNotifications(ev,'mobileMenu')" ref="notifications">
        <img :src="`/static/notifications${notificationStackId ? '_full' : '_empty'}.svg`"
             alt="notifications" height="24">
        <span v-if="notificationsCount" class="navbar-notifications-count">
          {{ notificationsCount }}
        </span>
      </button>-->
      <button @click="showNotificationsList" v-if="notificationsInfo" class="button navbar-item notifications-button"
              type="button" ref="notificationsMenu">
        <img v-if="notificationsCount > 0" src="/static/icons/notifications_active.svg" alt="messages" height="24">
        <img v-else src="/static/icons/notifications.svg" alt="messages" height="24">
        <div class="unreadCount" v-if="notificationsCount">{{ notificationsCount }}</div>
      </button>
      <button @click="showMobileMenu" class="button navbar-item-mobile navbar-item" type="button" ref="mobileMenu">
        <img src="/static/icons/menu.svg" alt="menu" height="24">
      </button>
      <button @click="showMessagesList" v-if="user && loggedIn && unreadInfo && readCount>0"
              class="button navbar-item messages-button"
              type="button" ref="messagesMenu">
        <img src="/static/icons/message.svg" alt="messages" height="24">
        <div class="unreadCount" v-if="unreadCount">{{ unreadCount }}</div>
      </button>
      <button @click="showUserMenu" v-if="user && loggedIn" ref="user" class="user-button navbar-item">
        <uploaded-picture-img width="30" height="30" :picture="user.userData.picture" class="user-picture"
                              empty="/static/empty-user-photo.svg"
                              alt="Profile">
        </uploaded-picture-img>
      </button>
    </div>
  </header>
</template>

<script>
  import i18n from "i18n"
  import api from "@/api"
  import overlayModel from "common/components/utils/overlayModel.js"
  import NotificationsMenu from "@/modules/notifications/NotificationsMenu.vue"
  import UserMenu from "@/modules/user/UserMenu.vue"
  import MobileMenu from "./MobileMenu.vue"
  import ConversationsMenu from "@/modules/messenger/ConversationsMenu.vue"

  export default {
    name: "NavBar",
    reactive: {
      user() {
        //return api.session && api.session.user && ['users', 'UserOne', { user: api.session.session.user }]
        return this.$session.user && this.$views.users.UserOne({ user: this.$session.session.user })
      },
      notificationsInfo() {
        return ['notifications', 'myUnreadCount', {}]
      },
      unreadInfo() {
        //return this.$views.readHistory.myUnreadCount({})
        return this.$views.readHistory.myUnreadCountByType({ toType: 'priv' })
      },
      readInfo() {
        //return this.$views.readHistory.myReadCount({})
        return this.$views.readHistory.myReadCountByType({ toType: 'priv' })
      }
    },
    data() {
      return {
        notificationsStackId: null,
        notificationsLastCloseTime: 0,
        userStackId: null,
        userLastCloseTime: 0,
        mobileStackId: null,
        mobileLastCloseTime: 0,
        messagesStackId: null,
        messagesLastCloseTime: 0,
        query: '',//this.$route.params.query,
        searchBarVisible: false
      }
    },
    computed: {
      i18n() { return i18n().navBar },
      notificationsCount() {
        if(!this.notificationsInfo) return 0
        return this.notificationsInfo.count
      },
      lang() { return i18n.config.language },
      loggedIn() {
        return api.session.loggedIn
      },
      unreadCount() {
        if(!this.unreadInfo) return 0
        return this.unreadInfo.unread
      },
      readCount() {
        if(!this.readInfo) return 0
        return this.readInfo.read
      }
    },
    mounted() {
      this.actionOutsideSearchbar = ev => {
        let tg = ev.target
        let clickedInsideSearchbar = false
        let togglerClicked = ev.target.getAttribute('class') === 'mainNavbar-searchbar-toggler'
        console.log(tg)
        console.log(togglerClicked)

        while(tg) {
          clickedInsideSearchbar = tg.getAttribute('id') === 'mainNavbar-searchbar'
          if (clickedInsideSearchbar) break
          tg = tg.parentElement
        }

        if(!clickedInsideSearchbar && !togglerClicked) {
          this.searchBarVisible = false
        } else if (togglerClicked) {
          this.searchBarVisible = true
          typeof document !== 'undefined' && document.querySelector('.search-box input[aria-describedby="searchInput"]').focus()
        }
      }
      document.body.addEventListener("click", this.actionOutsideSearchbar)
      document.body.addEventListener("touch", this.actionOutsideSearchbar)
    },
    beforeDestroy() {
      document.body.removeEventListener("touch", this.actionOutsideSearchbar)
      document.body.removeEventListener("click", this.actionOutsideSearchbar)
    },
    watch: {
    /*  '$route.params.query': {
        handler: function(search) {
          this.query = search
        },
        deep: true,
        immediate: true
      }*/
    },
    methods: {
      search() {
        if(this.query) {

          /// TODO: full site search

          this.query = ''
        }
      },
      showNotifications(ev) {
        ev.preventDefault()
        if(this.notificationStackId === null && Date.now() - this.notificationLastCloseTime > 230) {
          this.notificationStackId = overlayModel.show({
            component: NotificationsMenu,
            props: {
              anchor: overlayModel.elementAnchor(this.$refs.content)
            },
            on: {
              close: () => {
                this.notificationStackId = null
                this.notificationLastCloseTime = Date.now()
              }
            }
          })
        } else {
          console.log("SECOND CLICK")
          overlayModel.close(this.notificationStackId)
          this.notificationStackId = null
          this.notificationLastCloseTime = Date.now()
        }
      },
      showUserMenu(ev) {
        ev.preventDefault()
        if(this.userStackId === null && Date.now() - this.userLastCloseTime > 230) {
          this.userStackId = overlayModel.show({
            component: UserMenu,
            props: {
              anchor: overlayModel.elementAnchor(this.$refs.content)
            },
            on: {
              close: () => {
                this.userStackId = null
                this.userLastCloseTime = Date.now()
              }
            }
          })
        } else {
          console.log("SECOND CLICK")
          overlayModel.close(this.userStackId)
          this.userStackId = null
          this.userLastCloseTime = Date.now()
        }
      },
      showMobileMenu(ev) {
        ev.preventDefault()
        if(this.mobileStackId === null && Date.now() - this.mobileLastCloseTime > 230) {
          this.mobileStackId = overlayModel.show({
            component: MobileMenu,
            props: {
              anchor: overlayModel.elementAnchor(this.$refs.content)
            },
            on: {
              close: () => {
                this.mobileStackId = null
                this.mobileLastCloseTime = Date.now()
              }
            }
          })
        } else {
          console.log("SECOND CLICK")
          overlayModel.close(this.mobileStackId)
          this.mobileStackId = null
          this.mobileLastCloseTime = Date.now()
        }
      },
      showMessagesList(ev) {
        ev.preventDefault()
        if(this.messagesStackId === null && Date.now() - this.messagesLastCloseTime > 230) {
          this.messagesStackId = overlayModel.show({
            component: ConversationsMenu,
            props: {
              anchor: overlayModel.elementAnchor(this.$refs.content)
            },
            on: {
              close: () => {
                this.messagesStackId = null
                this.messagesLastCloseTime = Date.now()
              }
            }
          })
        } else {
          console.log("SECOND CLICK")
          overlayModel.close(this.messagesStackId)
          this.messagesStackId = null
          this.messagesLastCloseTime = Date.now()
        }
      },
      showNotificationsList(ev) {
        ev.preventDefault()
        if(this.notificationsStackId === null && Date.now() - this.notificationsLastCloseTime > 230) {
          this.notificationsStackId = overlayModel.show({
            component: NotificationsMenu,
            props: {
              anchor: overlayModel.elementAnchor(this.$refs.content)
            },
            on: {
              close: () => {
                this.notificationsStackId = null
                this.notificationsLastCloseTime = Date.now()
              }
            }
          })
        } else {
          console.log("SECOND CLICK")
          overlayModel.close(this.notificationsStackId)
          this.notificationsStackId = null
          this.notificationsLastCloseTime = Date.now()
        }
      },
      setLanguage(lang) {
        console.log("SET LANG", lang)
        i18n.config.language = lang
        document.cookie = `language=${lang}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
      }
    }
  }
</script>

<style scoped>
</style>
