<template>
  <section class="card half-width">
    <loading :what="user" name="user data">
      <command-form v-if="user" name="user" service="users" action="updateUserData" :initialValues="user.userData">
        <template v-slot:done>
          <h4>{{ i18n.title }}</h4>
          <p>{{ i18n.done.text }}</p>
        </template>
        <template v-slot:default>
          <h4>{{ i18n.title }}</h4>
          <div>
            <text-field name="name" :label="i18n.firstName" :placeholder="i18n.firstNamePlaceholder"></text-field>
            <div class="buttons">
              <button type="submit" class="button" role="button">{{ i18n.save }}</button>
            </div>
          </div>
        </template>
      </command-form>
    </loading>
  </section>
</template>

<script>
  import api from "api"
  import i18n from "i18n"
  import PhoneInput from "common/components/inputs/PhoneInput.vue"
  import CountryInput from "common/components/inputs/CountryInput.vue"
  import countries from "@/../../data/countries"

  export default {
    name: 'UpdateUserData',
    components: { PhoneInput, CountryInput },
    data () {
      return {
        sexOptions: ["man", "woman"]
      }
    },
    reactive: {
      user() { return api.session.session.user && api.session.user
          && ['users', 'UserOne', { user: api.session.user.id } ] }
    },
    computed: {
      i18n() { return i18n().user.changeUserData }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
