var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "messages-view" },
    [
      _c("scroll-loader", {
        key: _vm.toType + "_" + _vm.toId,
        ref: "scrollLoader",
        staticClass: "messages",
        attrs: {
          what: function(range) {
            return _vm.messagesDaoPath(range)
          },
          startPosition: "end",
          stickyEnd: "",
          trackVisibleRows: "",
          shardClose: "",
          bucketSize: 10,
          readMode: "id",
          noDebugLog: ""
        },
        on: { rowVisible: _vm.updateRead },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var message = ref.row
              var rows = ref.rows
              var index = ref.index
              return [
                _vm.messageVisible(message, rows)
                  ? _c("Message", {
                      key: message.id,
                      attrs: {
                        message: message,
                        prev: _vm.prevMessage(rows, index),
                        next: _vm.nextMessage(rows, index),
                        readPositions: _vm.messageReadPositions(message)
                      }
                    })
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }