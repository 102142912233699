const env = require('../../env.js')
const moment = require('moment')
require('moment-timezone')

module.exports = {
  url: "XML URL",
  createScannerSubmit: "Add URL",
  scanStarted: "Scan started",
  ago: "ago",
  deleteScanner: "Delete scanner",
  nextScan: "Next scan:",
  severity: {
    never: "never",
    fatal: "fatal error",
    fatals: "fatal errors",
    error: "error",
    errors: "errors",
    warning: "warning",
    warnings: "warnings",
    notice: "notice",
    notices: "notices"
  },
  scannerState: {
    starting: "Starting...",
    scanning: "Scanning...",
    waiting: "Waiting"
  },
  scanState: {
    starting: "Starting...",
    downloading: "Downloading...",
    checkingLinks: "Checking links...",
    checkingUrls: "Checking other URLs...",
    done: "Scan done"
  },
  scanStage: {
    download: "Downloading...",
    links: "Checking links...",
    urls: "Checking other URLs...",
    images: "Checking images...",
    itemprops: "Checking itemprop attributes...",
    duplicates: "Checking duplicates...",
    changes: "Checking changes...",
    done: "Scan done"
  },
  viewResults: "View Results",
  loadingBottom: "Loading...",
  connectionProblem: "Connection problem :/",
  resultType: {
    notFound: "Not found",
    redirectToHome: "Redirect to home page",
    titleTooLong: 'Title is longer than 140 characters',
    parseError: 'Item is impossible to parse',
    fieldNotFound: 'Field not found',
    titleDuplicated: 'Title is duplicated',
    imageCheckError: 'Image error',
    imageTooSmall: 'Image dimensions too small',
    duplicatedItem: 'Item duplicated',
    couldNotReadItemProps: 'Could not read itemprops',
    priceNotFoundInXml: 'Price not found in xml',
    priceNotFoundInItemProps: 'Price not found in itemprop',
    pricesNotMatch: 'Prices don\'t match',
    unknownPriceFormatInXml: 'Unknown price format in xml',
    unknownPriceFormatInItemProps: 'Unknown price format in itemprop',
    titleNotFoundInXml: 'Title not found in xml',
    nameNotFoundInItemProps: 'Name not found in itemprop',
    nameNotMatchTitle: 'Name does not match title',
    dataChange: 'Data changed'
  },
  srcUrl: "Base URL",
  atUrl: "URL",
  toUrl: "To URL",
  field: "Field",
  image: 'Image',
  downloadError: {
    404 : "404 - File not found!"
  },
  notifications: {
    started: {
      title: "Scan started",
      text: "Started analysis of"
    },
    finished: {
      title: "Scan done",
      text: "Checked"
    }
  },
  smsSeverity: "Send sms on",
  emailSeverity: "Send email on",

  scanInterval: "Scan interval",
  scanIntervals: {
    '2m': '2m',
    "1h": "1h",
    "4h": "4h",
    "12h": "12h",
    "24h": "24h",
    "2d": "2d",
    "7d": "7d",
    "2w": "2w"
  },

  emailNotifications: {
    started: ({ user, notification, purify }) => ({
      text: require('./scanStarted.text.ejs')({ user, env, moment, notification, purify }),
      html: require('./scanStarted.html.ejs')({ user, env, moment, notification, purify })
    }),
    finished: ({ user, notification, purify }) => ({
      text: require('./scanFinished.text.ejs')({ user, env, moment, notification, purify }),
      html: require('./scanFinished.html.ejs')({ user, env, moment, notification, purify })
    }),
    finishedEmail: ({ user, email, url, summary, scanner, scan }) => ({
      from: 'Vole Apps <hello@apps.vole.pl>',
      to: `${user.display} <${email}>`,
      subject: `Scan finished!`,
      text: require('./scanFinishedEmail.text.ejs')({ user, env, moment, url, summary, scanner, scan }),
      html: require('./scanFinishedEmail.html.ejs')({ user, env, moment, url, summary, scanner, scan })
    })
  },
  smsNotifications: {
    alertSms: ({ user, email, url, summary, scanner, scan }) =>
        require('./smsAlert.text.ejs')({ user, env, moment, url, summary, scanner, scan })
  },
  more: 'more',
  viewAsXml: "View as xml",
  rawXml: "Raw xml",
  xml: 'XML',
  itemProp: 'ItemProp',
  resultFrom: 'From',
  resultTo: 'To',
  dataSetDeleted: "The xml file has already been deleted!"
}