var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.path, function(category, index) {
      return _c(
        "div",
        [
          category
            ? _c("observe", {
                key: category,
                attrs: {
                  name: "category " + category,
                  what: ["categories", "CategoryOne", { category: category }]
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var categoryData = ref.value
                        return [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "categories:category",
                                  params: { category: category }
                                }
                              }
                            },
                            [
                              index > 0 ? _c("span", [_vm._v("≫")]) : _vm._e(),
                              _vm._v(
                                "\n        " +
                                  _vm._s(
                                    (categoryData.translations[_vm.lang] &&
                                      categoryData.translations[_vm.lang]
                                        .name) ||
                                      categoryData.name
                                  ) +
                                  "\n      "
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }