<template>
  <div class="messenger-view">
    <NavBar></NavBar>
    <div class="messenger-columns">
      <ConversationsList :selectedConversation="conversation"></ConversationsList>
      <PrivateConversation v-if="conversationType == 'priv'" :conversation="conversation"></PrivateConversation>
    </div>
  </div>
</template>

<script>
  import i18n from "i18n"
  import analytics from "analytics"
  import api from "@/api"

  import NavBar from "@/segments/NavBar.vue"
  import PrivateConversation from "./PrivateConversation.vue"
  import ConversationsList from "./ConversationsList.vue"

  export default {
    name: "ConversationPage",
    components: { PrivateConversation, ConversationsList, NavBar },
    props: {
      conversationType: {
        type: String,
        required: true
      },
      conversation: {
        type: String,
        required: true
      }
    },
    computed: {
    }
  }
</script>

<style scoped>

</style>