var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form-field-bind", {
    attrs: {
      name: _vm.name,
      valueFilter: function(n) {
        return n && n.trim()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var value = ref.value
          var setValue = ref.setValue
          var error = ref.error
          return [
            _c(
              "FieldTemplate",
              {
                attrs: {
                  errorText: _vm.errorText,
                  error: error,
                  label: _vm.label,
                  name: _vm.name
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "below",
                      fn: function() {
                        return [
                          _vm.charsLeft >= 0
                            ? _c("span", { staticClass: "field-tip" }, [
                                _vm._v(
                                  _vm._s(
                                    " " +
                                      _vm.i18n.characters +
                                      " " +
                                      _vm.charsLeft
                                  )
                                )
                              ])
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              },
              [
                _c("textarea", {
                  ref: "field",
                  staticClass: "form-control",
                  attrs: {
                    id: "" + _vm._uid,
                    rows: _vm.rows,
                    placeholder: _vm.placeholder
                  },
                  domProps: { value: value },
                  on: {
                    input: function(ev) {
                      return setValue(ev.target.value)
                    },
                    focus: _vm.emitOpenOnFocus,
                    blur: _vm.emitClose
                  }
                })
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }