<template>
  <page>
    <div class="full-width">
      <observe v-if="isLoggedIn && !deleted" name="myCheckers"
               :what="['urlChecker', 'myCheckerByUrl', { url: decodedUrl }]"
               v-slot="{ value: checker }">
        <UrlCard v-if="checker" :checker="checker" @deleted="handleDeleted"></UrlCard>
      </observe>
      <div v-if="isLoggedIn && deleted" class="card">
        <p>{{ i18n.urlDeleted }}</p>
        <router-link :to="{ name: 'urls' }" tag="button">{{ i18n.returnToList }}</router-link>
      </div>
    </div>
  </page>
</template>

<script>

  import api from "api"
  import i18n from "i18n"
  import SingleSelectInput from "common/components/inputs/SingleSelectInput.vue"
  import UrlCard from "./UrlCard.vue"

  export default {
    name: "UrlPage",
    components: { UrlCard, SingleSelectInput },
    inject: ['workingZone'],
    props: {
      url: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        deleted: false
      }
    },
    computed: {
      i18n() {
        return i18n().urlChecker
      },
      isLoggedIn() {
        return api.session.loggedIn
      },
      decodedUrl() {
        return decodeURIComponent(this.url)
      }
    },
    methods: {
      handleDeleted() {
        this.deleted = true
      }
    }
  }

</script>

<style scoped>

</style>