<template>

  <loading :name="`user ${userAccount.user} ads account ${userAccount.account}`"
           :what="account && campaigns && updateSettingsActionDefinition"
           class="ads-api-campaign-account">

    <div class="account-header" v-if="account">
      <h3>
        {{ account.accountName }} - {{ account.name }} ( {{ account.id }} )
        <span v-if="(!campaigns) || campaigns.length == 0"> - no campaigns</span>
      </h3>
      <p v-if="hasInsufficient">
        insufficient funds
      </p>
      <div class="buttons">
        <button v-if="campaigns && campaigns.length > 0 && !expanded"
                type="button" class="button"
                @click="() => toggleCampaignVisibility()">
          <img src="/static/icons/expand_more.svg">
          <span>{{ i18n.expand }}</span>
          <span>( {{ campaigns.length }} )</span>
        </button>
        <button v-if="campaigns && campaigns.length > 0 && expanded"
                type="button" class="button"
                @click="() => toggleCampaignVisibility()">
          <img src="/static/icons/expand_less.svg">
          <span>{{ i18n.collapse }}</span>
          <span>( {{ campaigns.length }} )</span>
        </button>
        <button type="button" class="button" @click="() => refresh()" style="width: 7em">
          <img src="/static/icons/refresh.svg"><span>{{ i18n.refresh }}</span>
        </button>
      </div>
    </div>



    <!--        <div v-for="campaign in campaigns" class="campaign">
                  <pre>{{ JSON.stringify(campaign, null, '  ') }}</pre>
                </div>-->
    <div v-if="expanded && updateSettingsActionDefinition">

      <defined-form ref="settingsForm"
                    :initialValues="{ ...userAccount.settings }"
                    :definition="updateSettingsActionDefinition"
                    @init="handleSettingsFormInitialized"
                    class="ads-account-settings">

        <single-select-field name="checkInterval" :options="checkIntervals" :label="i18n.checkInterval"
                           :text="interval => i18n.checkIntervals[interval]" />

        <text-field name="insufficientFundsThreshold" :label="i18n.insufficientFundsThreshold" />

        <span v-if="settingsState != 'initial'">{{ i18n.settingsState[settingsState] }}</span>

      </defined-form>

      <table v-if="campaigns.length > 0" class="campaigns-table">
        <thead>
        <tr>
          <th class="campaign-col-name">Campaign</th>
          <th class="campaign-col-state">State</th>
          <th class="campaign-col-budget">Budget</th>
          <!--                  <td class="campaign-col-actions"></td>-->
        </tr>
        </thead>
        <tbody>
        <tr v-for="campaign in campaigns" class="campaign">
          <td>
            {{ campaign.name }} ({{ campaign.id }})
            <br>
            Last update:
            <time :datetime="campaign.lastUpdate">
              {{ campaign.lastUpdate | date }} {{ campaign.lastUpdate | hour }}
            </time>
          </td>
          <td :class="campaignClass(campaign)">
            {{ campaignStatus(campaign) }}
          </td>
          <td v-if="campaign.dailyMetrics">
            {{ (campaign.dailyMetrics.cost_micros/1e6).toFixed(2) }}
            /
            {{ (campaign.budget.amount/1e6).toFixed(2) }}
            {{ account.currency }}
            {{ i18n.periodAdjective[campaign.budget.period] }}
          </td>
          <td v-else>NO-DATA</td>
          <!--                  <td>
                                <div class="buttons">
                                  <button class="button" @click="() => setCampaignPaused(campaign, true)"
                                          v-if="campaign.status == 'enabled'">
                                    Pause
                                  </button>
                                  <button class="button" @click="() => setCampaignPaused(campaign, false)"
                                          v-if="campaign.status == 'paused'">
                                    Enable
                                  </button>
                                </div>
                              </td>-->
        </tr>
        </tbody>
      </table>

      <br />
      <div v-if="userAccount.lastCheckTime">
        <b>Last check time:</b>
        <time :datetime="userAccount.nextCheck">
          {{ userAccount.lastCheckTime | date }} {{ userAccount.lastCheckTime | hour }}
        </time>
      </div>
      <div v-if="userAccount.nextCheckTime">
        <b>Next check time:</b>
        <time :datetime="userAccount.nextCheck">
          {{ userAccount.nextCheckTime | date }} {{ userAccount.nextCheckTime | hour }}
        </time>
      </div>

    </div>

  </loading>

</template>

<script>

  import api from "api"
  import i18n from "i18n"
  import DefinedForm from "common/components/DefinedForm.vue"

  export default {
    name: "CampaignsAccount",
    props: {
      userAccount: {
        type: Object,
        required: true
      }
    },
    components: { DefinedForm },
    inject: [ 'workingZone' ],
    reactive: {
      account() { return ['adsApi', 'apiAccount', { account: this.userAccount.account }] },
      campaigns() { return ['adsApi', 'campaigns', { account: this.userAccount.account }] },
    },
    data() {
      return {
        expanded: false,
        saveSettingsTimeout: null,
        settingsState: 'initial',
        settingsStateTimeout: null,
      }
    },
    computed: {
      i18n() {
        console.log('I18N', i18n())
        return i18n().adsApi.campaigns
      },
      serviceDefinition() {
        return api.metadata.serviceDefinitions.find(service => service.name == 'adsApi')
      },
      updateSettingsActionDefinition() {
        return this.serviceDefinition && this.serviceDefinition.actions.updateMyAccountSettings
      },
      checkIntervals() {
        return this.updateSettingsActionDefinition.properties.checkInterval.options
      },
      hasInsufficient() {
        if(!this.campaigns) return false
        for(const campaign of this.campaigns) {
          if(campaign.status == 'enabled') {
            const metrics = this.userAccount.settings.checkInterval == 'daily'
              ? campaign.dailyMetrics
              : campaign.weeklyMetrics
            const lost = metrics && metrics.search_budget_lost_impression_share
            if(lost > 0) return true
          }
        }
      }
    },
    created() {
      this.settingsFormObserver = (value) => this.handleSettingsFormUpdate(value)
    },
    methods: {
      toggleCampaignVisibility() {
        this.expanded = !this.expanded
      },
      campaignStatus(campaign) {
        if(campaign.status == 'enabled') {
          const metrics = this.userAccount.settings.checkInterval == 'daily'
            ? campaign.dailyMetrics
            : campaign.weeklyMetrics
          const lost = metrics && metrics.search_budget_lost_impression_share
          if(lost > 0) return this.i18n.insufficientFunds + ' (' + (lost * 100).toFixed(2) + '%)'
          return this.i18n.servingStatus[campaign.servingStatus]
        }
        return this.i18n.status[campaign.status]
      },
      campaignClass(campaign) {
        if(campaign.status == 'enabled') {
          const metrics = this.userAccount.settings.checkInterval == 'daily'
            ? campaign.dailyMetrics
            : campaign.weeklyMetrics
          const lost = metrics && metrics.search_budget_lost_impression_share
          if(lost > 0) return 'campaign-status-insufficient-funds'
          return 'campaign-status-' + campaign.servingStatus
        }
        return 'campaign-status-' + campaign.status
      },
      setCampaignPaused(campaign, paused) {
        this.workingZone.addPromise('pauseCampaign', (async () => {
          await api.command(['adsApi', 'setCampaignPaused'], { campaign: campaign.id, paused })
        })())
      },
      async saveSettings(settings) {
        this.settingsState = 'saving'
        try {
          console.log("UPDATE ACCOUNT SETTINGS", {
            ...settings, account: this.userAccount.account
          })
          await api.command(['adsApi', 'updateMyAccountSettings'], {
            ...settings, account: this.userAccount.account
          })
          this.settingsState = 'saved'
          if(this.settingsStateTimeout) clearTimeout(this.settingsStateTimeout)
          this.settingsStateTimeout = setTimeout(() => this.settingsState = 'initial', 3000)
          for(const propName in settings) {
            this.$refs.settingsForm.setFieldError(propName, null)
          }
        } catch(error) {
          if(error.properties) {
            for(let propName in error.properties) {
              let node = this.$refs.settingsForm.setFieldError(propName, error.properties[propName])
              this.settingsState = 'initial'
            }
            return
          }
          console.error('Error saving settings', error)
          this.saveSettingsTimeout = setTimeout(() => this.saveSettings(settings), 1000)
        }
      },
      handleSettingsFormInitialized() {
        this.$nextTick(() => {
          this.$refs.settingsForm.formRoot.observe(this.settingsFormObserver)
        })
      },
      handleSettingsFormUpdate(newValue) {
        const { account, _commandId, ...currentSettings } = this.userAccount.settings
        const { account: _, ...newSettings } = newValue
        console.log('CMP', JSON.stringify(newSettings), JSON.stringify(currentSettings))
        if(JSON.stringify(newSettings) == JSON.stringify(currentSettings)) return
        console.log("UPDATING SETTINGS", newSettings)
        if(this.saveSettingsTimeout) clearTimeout(this.saveSettingsTimeout)
        this.saveSettingsTimeout = setTimeout(() => this.saveSettings(newSettings), 300)
      },
      refresh() {
        this.workingZone.addPromise('refresh', (async () => {
          await api.command(['adsApi', 'refreshAccountCampaigns'], { account: this.userAccount.account })
        })())
      }
    }
  }

</script>

<style scoped>

</style>
