var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page",
    [
      _c("loading", {
        attrs: { name: "search and dataSet", what: _vm.allLoaded }
      }),
      _vm._v(" "),
      _vm.allLoaded
        ? _c(
            "div",
            {
              staticClass: "card main-card full-width",
              on: {
                "!click": function($event) {
                  return _vm.handleDisabledClick.apply(null, arguments)
                }
              }
            },
            [
              _vm.isDeleted ? _c("div", [_vm._v("Deleted")]) : _vm._e(),
              _vm._v(" "),
              _c(
                "command-form",
                {
                  ref: "form",
                  attrs: {
                    service: "xmlCsvExclusions",
                    action: "checkExclusions",
                    initialValues: _vm.formInitialValues,
                    parameters: {
                      dataSet:
                        (_vm.historyDataSet && _vm.historyDataSet.id) ||
                        undefined
                    },
                    keepOnDone: true,
                    fieldValidators: {
                      url: function(v) {
                        return _vm.validateUrl(v)
                      }
                    }
                  },
                  on: { done: _vm.handleSearchStarted }
                },
                [
                  _vm.editingUrl ||
                  !_vm.dataSetEntity ||
                  _vm.dataSetEntity.state == "deleted"
                    ? _c("text-field", {
                        attrs: {
                          name: "url",
                          label: _vm.i18n.url,
                          disabled: !_vm.canEdit
                        }
                      })
                    : _c("div", { staticClass: "form-group dataSet" }, [
                        _vm.dataSetEntity.state == "downloading" ||
                        _vm.dataSetEntity.state == "connecting"
                          ? _c("div", { staticClass: "loading-animation" }, [
                              _c("div", { staticClass: "cube-spinner" }, [
                                _c("div", { staticClass: "cube1" }),
                                _vm._v(" "),
                                _c("div", { staticClass: "cube2" })
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "dataSet-buttons buttons" },
                          [
                            _vm.dataSetEntity.state != "downloading" &&
                            _vm.dataSetEntity.state != "connecting"
                              ? _c(
                                  "submit-button",
                                  {
                                    staticClass: "button",
                                    attrs: {
                                      parameters: { refresh: true },
                                      disabled: !_vm.canEdit
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/static/icons/refresh.svg"
                                      }
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "button",
                                attrs: {
                                  type: "button",
                                  disabled: !_vm.canEdit
                                },
                                on: { click: _vm.editUrl }
                              },
                              [
                                _c("img", {
                                  attrs: { src: "/static/icons/edit.svg" }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _vm.dataSetsHistory &&
                            _vm.dataSetsHistory.length > 0
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "button",
                                    attrs: {
                                      type: "button",
                                      disabled: !_vm.canEdit
                                    },
                                    on: { click: _vm.toggleHistory }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/static/icons/history.svg"
                                      }
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.historyVisible && _vm.dataSetsHistory
                              ? _c("SingleSelectInput", {
                                  staticClass: "form-field history-select",
                                  attrs: {
                                    value: _vm.dataSetEntity,
                                    options: _vm.dataSetsHistory,
                                    text: function(dataSet) {
                                      return _vm.historyLabel(dataSet)
                                    }
                                  },
                                  on: { input: _vm.goToHistory }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.historyVisible
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "button",
                                    attrs: { type: "button" },
                                    on: { click: _vm.toggleHistory }
                                  },
                                  [
                                    _c("img", {
                                      attrs: { src: "/static/icons/clear.svg" }
                                    })
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        !_vm.historyVisible
                          ? _c("div", { staticClass: "dataSet-info" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "dataSet-url",
                                  attrs: { href: _vm.dataSetEntity.url }
                                },
                                [_vm._v(_vm._s(_vm.dataSetEntity.url))]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "dataSet-status" }, [
                                _c("div", { staticClass: "dataSet-state" }, [
                                  _vm._v(_vm._s(_vm.dataSetEntity.state))
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "dataSet-timestamp" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm._f("durationShort")(
                                            (_vm.currentTime -
                                              new Date(
                                                _vm.dataSetEntity.timestamp
                                              ).getTime()) /
                                              1000
                                          )
                                        ) +
                                        "\n              -\n              " +
                                        _vm._s(
                                          _vm._f("date")(
                                            new Date(
                                              _vm.dataSetEntity.timestamp
                                            )
                                          )
                                        ) +
                                        "\n              " +
                                        _vm._s(
                                          _vm._f("hour")(
                                            new Date(
                                              _vm.dataSetEntity.timestamp
                                            )
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "dataSet-size" }, [
                                  _vm._v(
                                    _vm._s(_vm.dataSetEntity.entries) +
                                      " " +
                                      _vm._s(_vm.i18n.entries)
                                  )
                                ]),
                                _vm._v(" "),
                                _vm.dataSetEntity.progress
                                  ? _c(
                                      "div",
                                      { staticClass: "dataSet-progress" },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("bytes")(
                                                _vm.dataSetEntity.progress
                                                  .transferred
                                              )
                                            )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _vm.dataSetEntity.progress.length &&
                                        _vm.dataSetEntity.progress.length >
                                          _vm.dataSetEntity.progress
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                / " +
                                                  _vm._s(
                                                    _vm._f("bytes")(
                                                      _vm.dataSetEntity.progress
                                                        .length
                                                    )
                                                  ) +
                                                  "\n              "
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.historyVisible
                          ? _c(
                              "div",
                              { staticClass: "dataSet-buttons buttons" },
                              [
                                _vm.checker
                                  ? _c(
                                      "router-link",
                                      {
                                        staticClass: "button",
                                        attrs: {
                                          to: {
                                            name: "urls:url",
                                            params: {
                                              url: encodeURIComponent(
                                                _vm.dataSetEntity.url
                                              )
                                            }
                                          },
                                          tag: "button",
                                          type: "button"
                                        }
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "/static/icons/link.svg"
                                          }
                                        })
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ]),
                  _vm._v(" "),
                  _vm.editingList || !this.exclusionsEntity
                    ? _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "FieldTemplate",
                            {
                              attrs: {
                                label: _vm.i18n.uploadedFileLabel,
                                name: "exclusionsLists"
                              }
                            },
                            [
                              _c("form-field-bind", {
                                staticClass: "exclusions-lists-fields",
                                attrs: { name: "exclusionsLists" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var value = ref.value
                                        var setValue = ref.setValue
                                        var error = ref.error
                                        return _vm._l(value, function(
                                          list,
                                          index
                                        ) {
                                          return _c("ExclusionsListInfo", {
                                            key: list,
                                            attrs: {
                                              index: index,
                                              list: list,
                                              deleteable: ""
                                            },
                                            on: {
                                              delete: function($event) {
                                                return _vm.deleteExclusionsList(
                                                  index
                                                )
                                              }
                                            }
                                          })
                                        })
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3170399327
                                )
                              }),
                              _vm._v(" "),
                              _c("multi-file-field", {
                                attrs: {
                                  name: "uploadedFiles",
                                  label: "",
                                  button: _vm.i18n.uploadedFileButton,
                                  accept:
                                    "text/csv,text/tab-separated-values,text/tsv,.csv,.tsv,.tab"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c("div", { staticClass: "form-group exclusionsList" }, [
                        _c(
                          "div",
                          { staticClass: "exclusionsList-buttons buttons" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "button",
                                attrs: {
                                  type: "button",
                                  disabled: !_vm.canEdit
                                },
                                on: { click: _vm.editList }
                              },
                              [
                                _c("img", {
                                  attrs: { src: "/static/icons/edit.svg" }
                                })
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "exclusionsList-info" },
                          _vm._l(
                            _vm.exclusionsEntity.exclusionsLists || [
                              _vm.exclusionsEntity.exclusionsList
                            ],
                            function(list, index) {
                              return _c("ExclusionsListInfo", {
                                key: list,
                                attrs: { index: index, list: list }
                              })
                            }
                          ),
                          1
                        )
                      ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "buttons" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "button",
                          attrs: {
                            type: "submit",
                            role: "button",
                            disabled: !_vm.canEdit
                          }
                        },
                        [_vm._v(_vm._s(_vm.i18n.checkButton))]
                      ),
                      _vm._v(" "),
                      _c("boolean-field", {
                        attrs: {
                          name: "useDescription",
                          label: _vm.i18n.useDescription
                        }
                      }),
                      _vm._v(" "),
                      _c("boolean-field", {
                        attrs: { name: "useStrict", label: _vm.i18n.useStrict }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.exclusions && _vm.allLoaded
        ? _c("div", { staticClass: "exclusions-info card full-width" }, [
            _vm.exclusionsEntity.state != "done"
              ? _c("div", { staticClass: "exclusions-progress progress-bar" }, [
                  _vm.dataSetEntity.progress &&
                  _vm.dataSetEntity.progress.length
                    ? _c("div", {
                        staticClass: "progress-bar-fill",
                        style: {
                          width:
                            Math.floor(
                              100 *
                                (_vm.exclusionsEntity.processed /
                                  _vm.dataSetEntity.entries) *
                                (+_vm.dataSetEntity.progress.transferred /
                                  +_vm.dataSetEntity.progress.length)
                            ) + "%"
                        }
                      })
                    : _c("div", {
                        staticClass: "progress-bar-fill",
                        style: {
                          width:
                            Math.floor(
                              100 *
                                (_vm.exclusionsEntity.processed /
                                  _vm.dataSetEntity.entries)
                            ) + "%"
                        }
                      }),
                  _vm._v(" "),
                  _c("div", { staticClass: "progress-text" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.exclusionsEntity.processed))
                    ]),
                    _vm._v(" / "),
                    _c("span", [_vm._v(_vm._s(_vm.dataSetEntity.entries))])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.exclusionsEntity.state == "done"
              ? _c("div", { staticClass: "search-status form-group" }, [
                  _c("div", { staticClass: "search-state" }, [
                    _vm._v(_vm._s(_vm.exclusionsEntity.state))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "search-results-count" }, [
                    _vm._v(
                      _vm._s(_vm.exclusionsEntity.results) +
                        " " +
                        _vm._s(_vm.i18n.results)
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "search-timestamp" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("duration")(
                            (_vm.currentTime -
                              new Date(
                                _vm.exclusionsEntity.timestamp
                              ).getTime()) /
                              1000
                          )
                        ) +
                        "\n        " +
                        _vm._s(_vm.i18n.ago) +
                        "\n      "
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "search-view-options buttons" }, [
              _c("div", { staticClass: "checkbox-field form-field" }, [
                _c("span", { staticClass: "checkbox" }, [
                  _c("input", {
                    staticClass: "checkbox-input",
                    attrs: { type: "checkbox", id: "groupResults" },
                    domProps: { checked: _vm.groupResults },
                    on: {
                      input: function(ev) {
                        return _vm.setGroupResults(ev.target.checked)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkbox-mark" })
                ]),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: "groupResults" }
                  },
                  [
                    _vm._t("label", function() {
                      return [_vm._v(_vm._s(_vm.i18n.groupResults))]
                    })
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "checkbox-field form-field" }, [
                _c("span", { staticClass: "checkbox" }, [
                  _c("input", {
                    staticClass: "checkbox-input",
                    attrs: { type: "checkbox", id: "viewAsXml" },
                    domProps: { checked: _vm.viewAsXml },
                    on: {
                      input: function(ev) {
                        return _vm.setViewAsXml(ev.target.checked)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkbox-mark" })
                ]),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: "viewAsXml" }
                  },
                  [
                    _vm._t("label", function() {
                      return [_vm._v(_vm._s(_vm.i18n.viewAsXml))]
                    })
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _vm.viewAsXml
                ? _c("div", { staticClass: "checkbox-field form-field" }, [
                    _c("span", { staticClass: "checkbox" }, [
                      _c("input", {
                        staticClass: "checkbox-input",
                        attrs: { type: "checkbox", id: "rawXml" },
                        domProps: { checked: _vm.rawXml },
                        on: {
                          input: function(ev) {
                            return _vm.setRawXml(ev.target.checked)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "checkbox-mark" })
                    ]),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "custom-control-label",
                        attrs: { for: "rawXml" }
                      },
                      [
                        _vm._t("label", function() {
                          return [_vm._v(_vm._s(_vm.i18n.rawXml))]
                        })
                      ],
                      2
                    )
                  ])
                : _vm._e()
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.groupResults && _vm.exclusions && _vm.allLoaded
        ? _c("ExclusionsGroups", {
            attrs: {
              exclusions: _vm.exclusions,
              viewAsXml: _vm.viewAsXml,
              rawXml: _vm.rawXml
            }
          })
        : _vm.exclusions && _vm.allLoaded
        ? _c("ExclusionsResults", {
            attrs: {
              exclusions: _vm.exclusions,
              viewAsXml: _vm.viewAsXml,
              rawXml: _vm.rawXml
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }