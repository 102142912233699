var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.joined
        ? _c("page", [
            _vm.isInApp
              ? _c("div", { staticClass: "card half-width main-card" }, [
                  _c("h2", [_vm._v(_vm._s(_vm.i18n.inApp.title))]),
                  _vm._v(" "),
                  _vm.isApple
                    ? _c("p", [
                        _vm._v(_vm._s(_vm.i18n.inApp.apple_instruction))
                      ])
                    : _c("p", [
                        _vm._v(_vm._s(_vm.i18n.inApp.android_instruction))
                      ]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(this.roomUrl) + " ")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttons" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button",
                        attrs: { type: "button" },
                        on: { click: _vm.copyRoomUrl }
                      },
                      [
                        _c("img", {
                          attrs: { src: "/static/icons/content_copy.svg" }
                        }),
                        _c("span", [_vm._v(_vm._s(_vm.i18n.copyLinkButton))])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _vm.linkCopied
                    ? _c("p", { staticClass: "linkCopied" }, [
                        _vm._v(_vm._s(_vm.i18n.linkCopied))
                      ])
                    : _vm._e()
                ])
              : _vm.afterWakeUp
              ? _c("div", { staticClass: "card half-width main-card" }, [
                  _c("h2", [_vm._v(_vm._s(_vm.i18n.afterWakeUp.title))]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.i18n.afterWakeUp.returnText({
                          room: _vm.roomData && _vm.roomData.name
                        })
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttons" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button",
                        attrs: { type: "button" },
                        on: { click: _vm.reloadRoom }
                      },
                      [_vm._v(_vm._s(_vm.i18n.afterWakeUp.returnButton))]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button",
                        attrs: { type: "button" },
                        on: { click: _vm.goToCreate }
                      },
                      [_vm._v(_vm._s(_vm.i18n.afterWakeUp.createButton))]
                    )
                  ])
                ])
              : _c(
                  "div",
                  { staticClass: "card full-width main-card room-card" },
                  [
                    _c(
                      "loading",
                      {
                        attrs: {
                          name: "roomData",
                          what: _vm.roomData && _vm.myStatus && _vm.audioLoaded
                        }
                      },
                      [
                        _c("h1", [
                          _vm._v(
                            _vm._s(_vm.i18n.room) +
                              " " +
                              _vm._s(_vm.roomData && _vm.roomData.name)
                          )
                        ]),
                        _vm._v(" "),
                        _vm.myStatus
                          ? _c("div", { staticClass: "room-card-row" }, [
                              _c(
                                "div",
                                { staticClass: "room-card-self-video" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "video-holder" },
                                    [
                                      _vm.hasVideo
                                        ? _c("video", {
                                            attrs: {
                                              autoplay: "",
                                              playsinline: "",
                                              muted: ""
                                            },
                                            domProps: {
                                              srcObject: _vm.userMedia,
                                              muted: true
                                            }
                                          })
                                        : _c(
                                            "div",
                                            {
                                              staticClass:
                                                "room-card-video-placeholder"
                                            },
                                            [
                                              _vm.loadingMedia
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "room-card-video-placeholder-text"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.i18n.cameraInit
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "room-card-video-placeholder-text"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.i18n
                                                            .cameraDisabled
                                                        )
                                                      )
                                                    ]
                                                  )
                                            ]
                                          ),
                                      _vm._v(" "),
                                      _vm.userMedia
                                        ? _c("VolumeIndicator", {
                                            attrs: { stream: _vm.userMedia }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "room-card-self-video-buttons buttons"
                                        },
                                        [
                                          _vm.peer
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "button control-bar-item",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: _vm.toggleAudioInput
                                                  }
                                                },
                                                [
                                                  _vm.peer.anyLocalAudioEnabled
                                                    ? _c("img", {
                                                        attrs: {
                                                          src:
                                                            "/static/icons/mic.svg",
                                                          height: "24"
                                                        }
                                                      })
                                                    : _vm.peer
                                                        .anyLocalAudioAvailable
                                                    ? _c("img", {
                                                        attrs: {
                                                          src:
                                                            "/static/icons/mic_off.svg",
                                                          height: "24"
                                                        }
                                                      })
                                                    : _c("img", {
                                                        attrs: {
                                                          src:
                                                            "/static/icons/mic_none.svg",
                                                          alt: "menu",
                                                          height: "24"
                                                        }
                                                      })
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.peer &&
                                          _vm.peer.anyLocalVideoAvailable
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "button control-bar-item",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: _vm.toggleVideoInput
                                                  }
                                                },
                                                [
                                                  _vm.peer.anyLocalVideoEnabled
                                                    ? _c("img", {
                                                        attrs: {
                                                          src:
                                                            "/static/icons/videocam.svg",
                                                          height: "24"
                                                        }
                                                      })
                                                    : _c("img", {
                                                        attrs: {
                                                          src:
                                                            "/static/icons/videocam_off.svg",
                                                          height: "24"
                                                        }
                                                      })
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "device-select" },
                                    [
                                      _vm.videoInputDevices &&
                                      _vm.videoInputDevices.length > 0
                                        ? _c("single-select-input", {
                                            attrs: {
                                              options: _vm.videoInputDevices.concat(
                                                ["default"]
                                              ),
                                              text: function(option) {
                                                return option !== "default"
                                                  ? option.label || "unknown"
                                                  : _vm.i18n.browserDefaultVideo
                                              },
                                              placeholder:
                                                _vm.i18n.selectVideoDevice
                                            },
                                            model: {
                                              value: _vm.selectedVideoInput,
                                              callback: function($$v) {
                                                _vm.selectedVideoInput = $$v
                                              },
                                              expression: "selectedVideoInput"
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.audioInputDevices &&
                                      _vm.audioInputDevices.length > 0
                                        ? _c("single-select-input", {
                                            attrs: {
                                              options: _vm.audioInputDevices.concat(
                                                ["default"]
                                              ),
                                              text: function(option) {
                                                return option !== "default"
                                                  ? option.label || "unknown"
                                                  : _vm.i18n.browserDefaultAudio
                                              },
                                              placeholder:
                                                _vm.i18n.selectAudioDevice
                                            },
                                            model: {
                                              value: _vm.selectedAudioInput,
                                              callback: function($$v) {
                                                _vm.selectedAudioInput = $$v
                                              },
                                              expression: "selectedAudioInput"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "video-card-info" }, [
                                _vm.myStatus.joined || _vm.myStatus.canJoin
                                  ? _c("p", [
                                      _vm._v(_vm._s(_vm.i18n.roomReady))
                                    ])
                                  : _c("p", [
                                      _vm._v(_vm._s(_vm.i18n.roomClosed))
                                    ]),
                                _vm._v(" "),
                                _vm.myStatus.joined || _vm.myStatus.canJoin
                                  ? _c("p", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.i18n.joiningLinkInfo) +
                                          " "
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        _vm._s(_vm.roomUrl) + "\n            "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.myStatus.joined || _vm.myStatus.canJoin
                                  ? _c("div", { staticClass: "buttons" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "button",
                                          attrs: { type: "button" },
                                          on: { click: _vm.copyRoomUrl }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                "/static/icons/content_copy.svg"
                                            }
                                          }),
                                          !_vm.linkCopied
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.i18n.copyLinkButton
                                                  )
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.linkCopied
                                        ? _c(
                                            "p",
                                            { staticClass: "linkCopied" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.i18n.linkCopied)
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c("div", { staticClass: "buttons" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "button",
                                      attrs: { type: "button" },
                                      on: { click: _vm.join }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.i18n.joinButton))
                                      ])
                                    ]
                                  )
                                ])
                              ])
                            ])
                          : _vm._e()
                      ]
                    )
                  ],
                  1
                )
          ])
        : _c(
            "div",
            { staticClass: "room-view room-view-video" },
            [
              _c("NavBar"),
              _vm._v(" "),
              _vm.versionMismatch && _vm.displayVersionMismatch
                ? _c(
                    "div",
                    {
                      staticClass: "version-alert-bar",
                      on: { click: _vm.reload }
                    },
                    [_vm._v(_vm._s(_vm.i18n.versionMismatch))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.peer
                ? _c("pre", { staticStyle: { display: "none" } }, [
                    _vm._v(_vm._s(JSON.stringify(_vm.peer.summary, null, "  ")))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { ref: "underNavBar", staticClass: "under-navbar" },
                [
                  _c(
                    "multipane",
                    {
                      ref: "mainArea",
                      staticClass: "main-area",
                      on: {
                        paneResize: _vm.onResize,
                        paneResizeStop: _vm.onResizeStop
                      }
                    },
                    [
                      _c(
                        "div",
                        { ref: "contentArea", staticClass: "content-area" },
                        [
                          _vm.roomSlug
                            ? _c("VideoChat", {
                                attrs: {
                                  toType: "room",
                                  toId: _vm.roomSlug.to,
                                  localMediaStreams: _vm.localMediaStreams,
                                  peers: [_vm.peer, _vm.displayPeer],
                                  volume: _vm.volume
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("multipane-resizer", [
                        _c("div", { staticClass: "handleHolder" }, [
                          _c("div", { staticClass: "handle" })
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { ref: "toolBar", staticClass: "tool-bar" },
                        [
                          _vm.roomSlug && _vm.roomSlug.to
                            ? _c("Chat", {
                                class: [
                                  "room-chat",
                                  {
                                    hidden:
                                      _vm.isToolBarWillBeHidden ||
                                      _vm.toolBarTab != "chat"
                                  }
                                ],
                                attrs: {
                                  chatType: "room",
                                  chatId: _vm.roomSlug.to
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "control-bar" }, [
                    _c("div", { staticClass: "left-buttons buttons" }, [
                      _vm.isDisplayMediaPossible
                        ? _c("div", { staticClass: "button-space" }, [
                            !_vm.displayMedia
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "button control-bar-item",
                                    attrs: { type: "button" },
                                    on: { click: _vm.getDisplayMedia }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/static/icons/screen_share.svg",
                                        alt: "screen",
                                        height: "24"
                                      }
                                    })
                                  ]
                                )
                              : _c(
                                  "button",
                                  {
                                    staticClass: "button control-bar-item",
                                    attrs: { type: "button" },
                                    on: { click: _vm.dropDisplayMedia }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          "/static/icons/stop_screen_share.svg",
                                        alt: "screen",
                                        height: "24"
                                      }
                                    })
                                  ]
                                )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isFullscreenAvailable
                        ? _c("div", { staticClass: "button-space" }, [
                            !_vm.fullscreen
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "button control-bar-item",
                                    attrs: { type: "button" },
                                    on: { click: _vm.startFullScreen }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/static/icons/fullscreen.svg",
                                        alt: "screen",
                                        height: "24"
                                      }
                                    })
                                  ]
                                )
                              : _c(
                                  "button",
                                  {
                                    staticClass: "button control-bar-item",
                                    attrs: { type: "button" },
                                    on: { click: _vm.exitFullScreen }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          "/static/icons/fullscreen_exit.svg",
                                        alt: "screen",
                                        height: "24"
                                      }
                                    })
                                  ]
                                )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "middle-buttons buttons" },
                      [
                        _c("div", { staticClass: "button-space" }, [
                          _vm.peer
                            ? _c(
                                "button",
                                {
                                  staticClass: "button control-bar-item",
                                  attrs: { type: "button" },
                                  on: { click: _vm.toggleAudioInput }
                                },
                                [
                                  _vm.peer.anyLocalAudioEnabled
                                    ? _c("img", {
                                        attrs: {
                                          src: "/static/icons/mic.svg",
                                          alt: "menu",
                                          height: "24"
                                        }
                                      })
                                    : _vm.peer.anyLocalAudioAvailable
                                    ? _c("img", {
                                        attrs: {
                                          src: "/static/icons/mic_off.svg",
                                          alt: "menu",
                                          height: "24"
                                        }
                                      })
                                    : _c("img", {
                                        attrs: {
                                          src: "/static/icons/mic_none.svg",
                                          alt: "menu",
                                          height: "24"
                                        }
                                      })
                                ]
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "button-space" }, [
                          _c(
                            "button",
                            {
                              staticClass: "button control-bar-item",
                              attrs: { type: "button" },
                              on: { click: _vm.leaveCall }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "/static/icons/call_end.svg",
                                  alt: "menu",
                                  height: "24"
                                }
                              })
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "button-space" }, [
                          _vm.peer && _vm.peer.anyLocalVideoAvailable
                            ? _c(
                                "button",
                                {
                                  staticClass: "button control-bar-item",
                                  attrs: { type: "button" },
                                  on: { click: _vm.toggleVideoInput }
                                },
                                [
                                  _vm.peer.anyLocalVideoEnabled
                                    ? _c("img", {
                                        attrs: {
                                          src: "/static/icons/videocam.svg",
                                          alt: "menu",
                                          height: "24"
                                        }
                                      })
                                    : _c("img", {
                                        attrs: {
                                          src: "/static/icons/videocam_off.svg",
                                          alt: "menu",
                                          height: "24"
                                        }
                                      })
                                ]
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("volume-input", {
                          model: {
                            value: _vm.volume,
                            callback: function($$v) {
                              _vm.volume = $$v
                            },
                            expression: "volume"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "right-buttons buttons" }, [
                      _c("div", { staticClass: "button-space" }, [
                        _c(
                          "button",
                          {
                            class: [
                              "button",
                              "control-bar-item",
                              { "new-messages": _vm.hasNewMessages }
                            ],
                            attrs: { type: "button" },
                            on: { click: _vm.showChat }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "/static/icons/chat.svg",
                                alt: "menu",
                                height: "24"
                              }
                            })
                          ]
                        )
                      ])
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "rotate-device-screen" }, [
                _c("div", { staticClass: "rotate-device-info" }, [
                  _c("img", {
                    attrs: { src: "/static/icons/screen_rotation.svg" }
                  }),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.i18n.rotateDevice))])
                ])
              ])
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }