<template>
  <div class="object-value">
    <component v-if="data !== undefined" :is="viewComponent(viewDefinition, viewConfig)" :data="data"
               :viewDefinition="viewDefinition" :type="type">
    </component>
  </div>
</template>

<script>
  export default {
    name: "ObjectValue",
    props: {
      viewDefinition: {
        type: Object,
        required: true
      },
      data: {

      },
      type: {
        type: String,
        default: 'view'
      }
    },
    computed: {
      viewConfig() {
        return this.viewDefinition[this.type] || {}
      }
    },
    methods: {
      viewComponent(definition, config) {
        return this.$dataViews.byDefinition(definition, config)
      }
    }
  }
</script>

<style scoped>

</style>