var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("page", { attrs: { multiRow: true } }, [
    _c(
      "div",
      {
        staticClass: "callout-wrapper callout-register callout-register-student"
      },
      [
        _c("div", { staticClass: "callout" }, [
          _c("h2", [_vm._v("Przykro nam, że od nas odchodzisz")])
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "content-wrapper" }, [
      _c(
        "div",
        { staticClass: "card" },
        [
          _c("h4", [_vm._v("Przykro nam, że od nas odchodzisz")]),
          _vm._v(" "),
          _c("p", [_vm._v("Będziemy wdzięczni za podanie przyczyny.")]),
          _vm._v(" "),
          _c(
            "command-form",
            {
              ref: "form",
              attrs: {
                service: "email",
                action: "sendContactFormMail",
                parameters: _vm.formParameters
              },
              on: {
                done: function($event) {
                  return _vm.$emit("done")
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "done",
                  fn: function() {
                    return [
                      _c("div", { staticClass: "alert alert-success" }, [
                        _vm._v(
                          "\n            Dziękujemy za wiadomość!\n          "
                        )
                      ])
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("SingleSelectField", {
                attrs: {
                  name: "subject",
                  options: _vm.feedbackOptions,
                  label: ""
                }
              }),
              _vm._v(" "),
              _c("TextAreaField", {
                attrs: {
                  name: "text",
                  rows: 6,
                  placeholder: "Wiadomość",
                  label: ""
                }
              }),
              _vm._v(" "),
              _vm._v(" "),
              _c(
                "button",
                { staticClass: "button", attrs: { type: "submit" } },
                [_c("span", [_vm._v("Wyślij")])]
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }