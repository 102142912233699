var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-holder" }, [
    _c("div", { staticClass: "card full-width", attrs: { role: "document" } }, [
      _c("h2", [_vm._v(_vm._s(_vm.i18n.title))]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.i18n.description))]),
      _vm._v(" "),
      _c("h5", [_vm._v(_vm._s(_vm.i18n.devInfo))]),
      _vm._v(" "),
      _c(
        "ol",
        _vm._l(_vm.errors, function(error) {
          return _c(
            "li",
            { key: error.task.name + ":" + error.reason, staticClass: "error" },
            [
              _vm._v("\n        " + _vm._s(_vm.taskType) + " of "),
              _c("b", [_vm._v(_vm._s(error.task.name))]),
              _vm._v(" failed because of error "),
              _c("b", [_vm._v(_vm._s(error.reason))])
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "buttons" }, [
        _c(
          "button",
          {
            staticClass: "button",
            attrs: { type: "button" },
            on: { click: _vm.reloadPage }
          },
          [_vm._v(_vm._s(_vm.i18n.closeButton))]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }