import api from "api"
import analyticsConstants from "@/analytics-constants.js"
import Vue from "vue"
import { createPeerConnection } from "./PeerConnection.js"

const messagesBucketSize = 32

let lastInstanceId = 0

const createPeer = (dao, { toType, toId, instanceId, publicSessionId }) => {
  if(!instanceId) instanceId = analyticsConstants.windowId + '.' + (++lastInstanceId)
  const peerId = toType+'_'+toId+'_'+publicSessionId+'_'+instanceId
  return new Vue({
    reactive: {
      peers: [ 'peerConnection', 'peers', { toType, toId } ],
      peerOnline() {
        return this.online && [ 'online', 'session', 'peer', peerId ]
      },
      turnConfiguration() {
        return [ 'peerConnection', 'turnConfiguration', { peer: peerId } ]
      },
     /* peerState() {
        return [ 'peerConnection', 'peerState', { peer: peerId } ]
      }*/
    },
    data: {
      peerId,
      localPeerState: null,
      online: false,
      finished: false,
      lastProcessedMessage: '',
      connections: [],
      waitingConnections: [], // connections that are not initialized, but messages are received
      localTracks: [],
      localMediaStreams: [],
      localDataChannels: [],
    },
    computed: {
      otherPeers() {
        return this.peers && this.peers.filter(peer => peer.id != peerId)
      },
      summary() {
        return {
          peerId: this.peerId, online: this.online, finished: this.finished,
          //peerState: this.peerState,
          computedLocalPeerState: this.computedLocalPeerState,
          lastProcessedMessage: this.lastProcessedMessage,
          peers: this.peers && this.peers.length,
          otherPeers: this.otherPeers && this.otherPeers.map(p => p.id),
          connections: this.connections && this.connections.map(connection => connection.summary),
          tracks: this.localTracks.map(({ track, stream }) => {
            const { id, kind, label, muted, enabled } = track
            return { id, kind, label, muted, enabled, stream: stream.id }
          }),
          localDataChannels: this.localDataChannels.map(channel => channel.label),
          turnConfiguration: this.turnConfiguration && {
            ...this.turnConfiguration,
            expire: new Date((+this.turnConfiguration.username.split(':')[0])*1000).toLocaleString()
          },
          isConnectionPossible: this.isConnectionPossible
        }
      },
      isConnectionPossible() {
        return this.online && (!!this.turnConfiguration)
      },
      rtcConfiguration() {
        return {
          iceServers: [
            this.turnConfiguration
          ],
          iceTransportPolicy: 'all', // 'all' or 'relay',
          bundlePolicy: 'balanced'
        }
      },
      clientIp() {
        return this.turnConfiguration && this.turnConfiguration.clientIp
      },
      anyLocalAudioEnabled() {
        for(const trackInfo of this.localTracks) {
          if(trackInfo.track.kind == 'audio' && trackInfo.enabled) return true
        }
        return false
      },
      anyLocalVideoEnabled() {
        for(const trackInfo of this.localTracks) {
          if(trackInfo.track.kind == 'video' && trackInfo.enabled) return true
        }
        return false
      },
      anyLocalAudioAvailable() {
        for(const trackInfo of this.localTracks) {
          if(trackInfo.track.kind == 'audio') return true
        }
        return false
      },
      anyLocalVideoAvailable() {
        for(const trackInfo of this.localTracks) {
          if(trackInfo.track.kind == 'video') return true
        }
        return false
      },
      computedLocalPeerState() {
        return {
          audioState: this.anyLocalAudioAvailable
              ? (this.anyLocalAudioEnabled ? "enabled" : "muted")
              : "none",
          videoState: this.anyLocalVideoAvailable
              ? (this.anyLocalVideoEnabled ? "enabled" : "muted")
              : "none"
        }
      }
    },
    watch: {
      otherPeers(peers) {
        this.updateConnections()
      },
      isConnectionPossible(online) {
        this.updateConnections()
      },
      computedLocalPeerState(newState) {
        this.updatePeerState(newState)
      },
      localMediaStreams(newStreams, oldStreams) {
        console.log("LOCAL MEDIA STREAMS CHANGE",
            newStreams.map(stream => ({ id: stream.id, tracks: stream.getTracks().map(tr => tr.kind).join('/') })),
            oldStreams.map(stream => ({ id: stream.id, tracks: stream.getTracks().map(tr => tr.kind).join('/') })))

        let deletedTracks = []
        let addedTracks = []
        for(const oldStream of oldStreams) {
          if(newStreams.indexOf(oldStream) != -1) continue; // existing stream
          deletedTracks.push(...(oldStream.getTracks().map( track => ({ track, stream: oldStream }) )))
          oldStream.removeEventListener('addtrack', this.mediaStreamAddTrackHandler)
          oldStream.removeEventListener('removetrack', this.mediaStreamRemoveTrackHandler)
        }
        for(const newStream of newStreams) {
          if(oldStreams.indexOf(newStream) != -1) continue; // existing stream
          addedTracks.push(...(newStream.getTracks().map(track => {
            const trackInfo = {
              track, stream: newStream, muted: track.muted, enabled: track.enabled,
              muteHandler: () => trackInfo.muted = track.muted,
              unmuteHandler: () => trackInfo.muted = track.muted
            }
            return trackInfo
          })))
          newStream.addEventListener('addtrack', this.mediaStreamAddTrackHandler)
          newStream.addEventListener('removetrack', this.mediaStreamRemoveTrackHandler)
        }
        for(const deletedTrack of deletedTracks) {
          const trackIndex = this.localTracks.findIndex(track => track.track == deletedTrack.track)
          if(trackIndex == -1) return console.error(`removal of non existing track ${deletedTrack.id}`)
          const trackInfo = this.localTracks[trackIndex]
          trackInfo.track.removeEventListener('mute', deletedTrack.muteHandler)
          trackInfo.track.removeEventListener('unmute', deletedTrack.unmuteHandler)
          this.localTracks.splice(trackIndex, 1)
        }
        for(const addedTrack of addedTracks) {
          addedTrack.track.addEventListener('mute', addedTrack.muteHandler)
          addedTrack.track.addEventListener('unmute', addedTrack.unmuteHandler)
          this.localTracks.push(addedTrack)
        }
      }
    },
    methods: {
      setOnline(value) {
        //console.log("SET PEER ONLINE", peerId, value)
        this.online = value
      },
      setTrackEnabled(track, v) {
        track.enabled = v
        track.track.enabled = v
      },
      updatePeerState(newState) {
        const updated = { ...this.localPeerState, ...newState }
        if(JSON.stringify(updated) != JSON.stringify(this.localPeerState)) {
          this.localPeerState = updated
          const update = { ...updated, peer: peerId, _commandId: api.guid() }
          this.sendPeerStateUpdate(update)
        }
      },
      sendPeerStateUpdate(update) {
        const requestTimeout = 10000
        dao.requestWithSettings({ requestTimeout },
          ['peerConnection', 'setPeerState'], update)
          .catch(error => {
            console.log("SET PEER STATE ERROR", error)
            if(error == 'timeout' && !this.finished
                && JSON.stringify({ ...this.localPeerState, ...update }) === JSON.stringify(this.localPeerState)
            ) {
              console.log("RETRYING")
              this.sendPeerStateUpdate()
            }
          })
      },
      observeMore() {
        if(this.messagesObservable) {
          this.messagesObservable.unobserve(this.messagesObserver)
          this.messagesObservable = null
        }
        const path = ['peerConnection', 'messages', {
          peer: peerId,
          gt: this.lastProcessedMessage,
          limit: messagesBucketSize
        }]
        this.messagesObservable = api.observable(path).observable
        //console.log("MESSAGES OBSERVABLE", path, this.messagesObservable, this.messagesObservable.observable)
        this.messagesObservable.observe(this.messagesObserver)
        //this.messagesObservable.observe(this.messagesObserver)
      },
      handleMessagesSignal(signal, ...args) {
        //console.log("HANDLE MESSAGE SIGNAL", signal, args)
        if(signal == 'error') {
          const error = args[0]
          console.error("PEER MESSAGE ERROR", error.stack || error)
          return
        }
        if(signal == 'putByField') {
          const [field, id, message] = args
          this.handleMessage(message)
        } else if(signal == 'set') {
          const value = args[0]
          for(const message of value) {
            this.handleMessage(message)
          }
        } else {
          console.error("SIGNAL NOT HANDLED", signal)
          /*for(const message of this.messagesObservable.list) {
            this.handleMessage(message)
          }*/
        }
        //console.log("PEER MESSAGES OBSERVABLE", this.messagesObservable)
        if(this.messagesObservable.list.length >= messagesBucketSize) {
          this.observeMore()
        }
      },
      handleMessage(message) {
        if(message.id <= this.lastProcessedMessage) {
          console.log("IGNORE OLD MESSAGE", message.id)
          return
        }
        this.lastProcessedMessage = message.id
        //console.log("HANDLE PEER MESSAGE", message)
        if(message.from) {
          let connection = this.connections.find(c => c.to == message.from)
          if(!connection) connection = this.waitingConnections.find(c => c.to == message.from)
          if(!connection) {
            connection = createPeerConnection(this, message.from)
            this.waitingConnections.push(connection)
          }
          connection.handleMessage(message)
        } else {

        }
      },
      sendMessage(message) {
        message.from = peerId
        message.sent = message.sent || new Date().toISOString()
        message._commandId = message._commandId || api.guid()
        const requestTimeout = 10000
        //console.log("SENDING PEER MESSAGE", message)
        dao.requestWithSettings({ requestTimeout }, ['peerConnection', 'postMessage'], message)
          .catch(error => {
            console.log("PEER MESSAGE ERROR", error)
            if(error == 'timeout' && !this.finished) {
              console.log("RETRYING")
              this.sendMessage(message)
            }
          })
      },
      updateConnections() {
        const peers = this.isConnectionPossible ? this.otherPeers : []
        for(let connectionId = 0; connectionId < this.connections.length; connectionId++) {
          const connection = this.connections[connectionId]
          const connectionPeer = peers.find(peer => peer.id == connection.to)
          if(!connectionPeer) {
            connection.close()
            connection.$destroy()
            this.connections.splice(connectionId, 1)
            connectionId --
          }
        }
        for(const peer of peers) {
          let peerConnection = this.connections.find(connection => connection.to == peer.id)
          if(peerConnection) continue;
          const peerConnectionId = this.waitingConnections.findIndex(connection => connection.to == peer.id)
          if(peerConnectionId != -1) { // use waiting connection with cached messages
            peerConnection = this.waitingConnections[peerConnectionId]
            this.waitingConnections.splice(peerConnectionId, 1)
          } else { // create connection
            peerConnection = createPeerConnection(this, peer.id)
          }
          this.connections.push(peerConnection)
          peerConnection.connect()
        }
      }
    },
    created() {
      this.messagesObserver = (...args) => this.handleMessagesSignal(...args)
      this.observeMore()
      this.disconnectHandler = () => this.observeMore() // to avoid redownloading processed messages
      api.on('disconnect', this.disconnectHandler)

      this.mediaStreamAddTrackHandler = (event) => {
        const track = event.track
        const trackInfo = {
          track, stream: newStream, muted: track.muted, enabled: track.enabled,
          muteHandler: () => trackInfo.muted = track.muted,
          unmuteHandler: () => trackInfo.muted = track.muted
        }
        console.log("MEDIA STREAM ADD TRACK!", trackInfo)
        trackInfo.track.addEventListener('mute', trackInfo.muteHandler)
        trackInfo.track.addEventListener('unmute', trackInfo.unmuteHandler)
        this.localTracks.push(trackInfo)
      }
      this.mediaStreamRemoveTrackHandler = (event) => {
        const trackIndex = this.localTracks.indexOf(event.track)
        if(trackIndex == -1) return console.error(`removal of non existing track ${event.track.id}`)
        const trackInfo = this.localTracks[trackIndex]
        console.log("MEDIA STREAM REMOVE TRACK!", trackInfo)
        trackInfo.track.removeEventListener('mute', trackInfo.muteHandler)
        trackInfo.track.removeEventListener('unmute', trackInfo.unmuteHandler)
        this.localTracks.splice(trackIndex, 1)
      }
    },
    beforeDestroy() {
      this.finished = true
      if(this.messagesObservable) {
        this.messagesObservable.unobserve(this.messagesObserver)
        this.messagesObservable = null
      }
      for(const connection of this.waitingConnections) {
        connection.$destroy()
      }
      for(const connection of this.connections) {
        connection.$destroy()
      }
      api.removeListener('disconnect', this.disconnectHandler)
    }
  })
}

export { createPeer }