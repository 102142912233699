import Room from '@/modules/video-chat/Room.vue'
import CreateRoom from '@/modules/video-chat/CreateRoom.vue'

export default function(prefix) {
  return [
    {
      path: prefix+'/room/:room',
      name: 'video-chat:room',
      component: Room,
      props: true
    },
    {
      path: prefix+'/createRoom',
      name: 'video-chat:createRoom',
      component: CreateRoom,
      props: true
    },
  ]
}
