var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page",
    [
      _c("loading", {
        attrs: {
          name: "search and dataSet",
          what: _vm.allLoaded || _vm.notFound
        }
      }),
      _vm._v(" "),
      _vm.notFound ? _c("PageNotFound") : _vm._e(),
      _vm._v(" "),
      _vm.allLoaded && _vm.isDeleted
        ? _c(
            "div",
            { staticClass: "card main-card half-width" },
            [
              _c("h2", [_vm._v(_vm._s(_vm.i18n.deleted.title))]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.i18n.deleted.text))]),
              _vm._v(" "),
              _c("loading", {
                attrs: { name: "dataSetsHistory", what: _vm.dataSetsHistory }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "buttons", attrs: { if: "dataSetsHistory" } },
                [
                  _vm.dataSetsHistory
                    ? _c("SingleSelectInput", {
                        staticClass: "form-field history-select",
                        attrs: {
                          value: null,
                          options: _vm.dataSetsHistory.filter(function(h) {
                            return h.state == "done"
                          }),
                          text: function(dataSet) {
                            return _vm.historyLabel(dataSet)
                          },
                          placeholder: _vm.i18n.deleted.selectHistory
                        },
                        on: {
                          input: function(dataSet) {
                            return _vm.redoSearch(dataSet.to)
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", [_vm._v("  or  ")]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      on: {
                        click: function() {
                          return _vm.redoSearch()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.i18n.deleted.fresh))]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.allLoaded && !_vm.isDeleted
        ? _c(
            "div",
            {
              staticClass: "card main-card full-width",
              on: {
                "!click": function($event) {
                  return _vm.handleDisabledClick.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "command-form",
                {
                  ref: "form",
                  attrs: {
                    service: "xmlSearch",
                    action: "search",
                    initialValues: _vm.formInitialValues,
                    parameters: { dataSet: _vm.dataSetParameter },
                    keepOnDone: true,
                    fieldValidators: {
                      url: function(v) {
                        return _vm.validateUrl(v)
                      }
                    }
                  },
                  on: { done: _vm.handleSearchStarted }
                },
                [
                  _vm.editingUrl ||
                  !_vm.dataSetEntity ||
                  _vm.dataSetEntity.state == "deleted"
                    ? _c("form-field-bind", {
                        attrs: { name: "upload" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var upload = ref.value
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "form-group buttons" },
                                    [
                                      !upload
                                        ? _c("text-field", {
                                            staticClass: "search-url-field",
                                            attrs: {
                                              name: "url",
                                              label: _vm.i18n.url,
                                              disabled: !_vm.canEdit
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("file-field", {
                                        class: { "search-url-field": !!upload },
                                        attrs: {
                                          name: "upload",
                                          label: _vm.i18n.upload,
                                          disable: !_vm.canEdit,
                                          accept: ".xml,application/xml"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3264021596
                        )
                      })
                    : _c("div", { staticClass: "form-group dataSet" }, [
                        _vm.dataSetEntity.state == "downloading" ||
                        _vm.dataSetEntity.state == "connecting"
                          ? _c("div", { staticClass: "loading-animation" }, [
                              _c("div", { staticClass: "cube-spinner" }, [
                                _c("div", { staticClass: "cube1" }),
                                _vm._v(" "),
                                _c("div", { staticClass: "cube2" })
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "dataSet-buttons buttons" },
                          [
                            !["downloading", "connecting", "uploaded"].includes(
                              _vm.dataSetEntity.state
                            )
                              ? _c(
                                  "submit-button",
                                  {
                                    staticClass: "button",
                                    attrs: {
                                      parameters: { refresh: true },
                                      disabled: !_vm.canEdit
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/static/icons/refresh.svg"
                                      }
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "button",
                                attrs: {
                                  type: "button",
                                  disabled: !_vm.canEdit
                                },
                                on: { click: _vm.editUrl }
                              },
                              [
                                _c("img", {
                                  attrs: { src: "/static/icons/edit.svg" }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _vm.dataSetsHistory &&
                            _vm.dataSetsHistory.length > 0
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "button",
                                    attrs: {
                                      type: "button",
                                      disabled: !_vm.canEdit
                                    },
                                    on: { click: _vm.toggleHistory }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/static/icons/history.svg"
                                      }
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.historyVisible && _vm.dataSetsHistory
                              ? _c("SingleSelectInput", {
                                  staticClass: "form-field history-select",
                                  attrs: {
                                    value: _vm.dataSetEntity,
                                    options: _vm.dataSetsHistory.filter(
                                      function(h) {
                                        return h.state == "done"
                                      }
                                    ),
                                    text: function(dataSet) {
                                      return _vm.historyLabel(dataSet)
                                    }
                                  },
                                  on: { input: _vm.goToHistory }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.historyVisible
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "button",
                                    attrs: { type: "button" },
                                    on: { click: _vm.toggleHistory }
                                  },
                                  [
                                    _c("img", {
                                      attrs: { src: "/static/icons/clear.svg" }
                                    })
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        !_vm.historyVisible
                          ? _c("div", { staticClass: "dataSet-info" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "dataSet-url",
                                  attrs: { href: _vm.dataSetEntity.url }
                                },
                                [_vm._v(_vm._s(_vm.dataSetEntity.url))]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "dataSet-status" }, [
                                _c("div", { staticClass: "dataSet-state" }, [
                                  _vm._v(_vm._s(_vm.dataSetEntity.state))
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "dataSet-timestamp" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm._f("durationShort")(
                                            (_vm.currentTime -
                                              new Date(
                                                _vm.dataSetEntity.timestamp
                                              ).getTime()) /
                                              1000
                                          )
                                        ) +
                                        "\n              -\n              " +
                                        _vm._s(
                                          _vm._f("date")(
                                            new Date(
                                              _vm.dataSetEntity.timestamp
                                            )
                                          )
                                        ) +
                                        "\n              " +
                                        _vm._s(
                                          _vm._f("hour")(
                                            new Date(
                                              _vm.dataSetEntity.timestamp
                                            )
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "dataSet-size" }, [
                                  _vm._v(
                                    _vm._s(_vm.dataSetEntity.entries) +
                                      " " +
                                      _vm._s(_vm.i18n.entries)
                                  )
                                ]),
                                _vm._v(" "),
                                _vm.dataSetEntity.progress
                                  ? _c(
                                      "div",
                                      { staticClass: "dataSet-progress" },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("bytes")(
                                                _vm.dataSetEntity.progress
                                                  .transferred
                                              )
                                            )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _vm.dataSetEntity.progress.length &&
                                        _vm.dataSetEntity.progress.length >
                                          _vm.dataSetEntity.progress
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                / " +
                                                  _vm._s(
                                                    _vm._f("bytes")(
                                                      _vm.dataSetEntity.progress
                                                        .length
                                                    )
                                                  ) +
                                                  "\n              "
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.historyVisible
                          ? _c(
                              "div",
                              { staticClass: "dataSet-buttons buttons" },
                              [
                                _vm.checker
                                  ? _c(
                                      "router-link",
                                      {
                                        staticClass: "button",
                                        attrs: {
                                          to: {
                                            name: "urls:url",
                                            params: {
                                              url: encodeURIComponent(
                                                _vm.dataSetEntity.url
                                              )
                                            }
                                          },
                                          tag: "button",
                                          type: "button"
                                        }
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "/static/icons/link.svg"
                                          }
                                        })
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ]),
                  _vm._v(" "),
                  _c("QueryFields", {
                    attrs: {
                      "data-set": _vm.dataSetEntity,
                      disabled: !_vm.canEdit
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttons" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button",
                        attrs: {
                          type: "submit",
                          role: "button",
                          disabled: !_vm.canEdit
                        }
                      },
                      [_vm._v(_vm._s(_vm.i18n.searchButton))]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "checkbox-field form-field" }, [
                      _c("span", { staticClass: "checkbox" }, [
                        _c("input", {
                          staticClass: "checkbox-input",
                          attrs: { type: "checkbox", id: "viewAsXml" },
                          domProps: { checked: _vm.viewAsXml },
                          on: {
                            input: function(ev) {
                              return _vm.setViewAsXml(ev.target.checked)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkbox-mark" })
                      ]),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "custom-control-label",
                          attrs: { for: "viewAsXml" }
                        },
                        [
                          _vm._t("label", function() {
                            return [_vm._v(_vm._s(_vm.i18n.viewAsXml))]
                          })
                        ],
                        2
                      )
                    ]),
                    _vm._v(" "),
                    _vm.viewAsXml
                      ? _c(
                          "div",
                          { staticClass: "checkbox-field form-field" },
                          [
                            _c("span", { staticClass: "checkbox" }, [
                              _c("input", {
                                staticClass: "checkbox-input",
                                attrs: { type: "checkbox", id: "rawXml" },
                                domProps: { checked: _vm.rawXml },
                                on: {
                                  input: function(ev) {
                                    return _vm.setRawXml(ev.target.checked)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "checkbox-mark" })
                            ]),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "custom-control-label",
                                attrs: { for: "rawXml" }
                              },
                              [
                                _vm._t("label", function() {
                                  return [_vm._v(_vm._s(_vm.i18n.rawXml))]
                                })
                              ],
                              2
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.search && _vm.allLoaded && !_vm.isDeleted
        ? _c("div", { staticClass: "search-info card full-width" }, [
            _vm.searchEntity.state != "done"
              ? _c("div", { staticClass: "search-progress progress-bar" }, [
                  _vm.dataSetEntity.progress &&
                  _vm.dataSetEntity.progress.length
                    ? _c("div", {
                        staticClass: "progress-bar-fill",
                        style: {
                          width:
                            Math.floor(
                              100 *
                                (_vm.searchEntity.processed /
                                  _vm.dataSetEntity.entries) *
                                (+_vm.dataSetEntity.progress.transferred /
                                  +_vm.dataSetEntity.progress.length)
                            ) + "%"
                        }
                      })
                    : _c("div", {
                        staticClass: "progress-bar-fill",
                        style: {
                          width:
                            Math.floor(
                              100 *
                                (_vm.searchEntity.processed /
                                  _vm.dataSetEntity.entries)
                            ) + "%"
                        }
                      }),
                  _vm._v(" "),
                  _c("div", { staticClass: "progress-text" }, [
                    _c("span", [_vm._v(_vm._s(_vm.searchEntity.processed))]),
                    _vm._v(" / "),
                    _c("span", [_vm._v(_vm._s(_vm.dataSetEntity.entries))])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.searchEntity.state == "done"
              ? _c("div", { staticClass: "search-status form-group" }, [
                  _c("div", { staticClass: "search-state" }, [
                    _vm._v(_vm._s(_vm.searchEntity.state))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "search-results-count" }, [
                    _vm._v(
                      _vm._s(_vm.searchEntity.results) +
                        " " +
                        _vm._s(_vm.i18n.results)
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "search-timestamp" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("duration")(
                            (_vm.currentTime -
                              new Date(_vm.searchEntity.timestamp).getTime()) /
                              1000
                          )
                        ) +
                        "\n        " +
                        _vm._s(_vm.i18n.ago) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "search-share-buttons" }, [
                    !_vm.generator && _vm.dataSetEntity && _vm.dataSetEntity.url
                      ? _c(
                          "button",
                          {
                            staticClass: "button",
                            attrs: { type: "button" },
                            on: { click: _vm.createGenerator }
                          },
                          [
                            _c("img", {
                              attrs: { src: "/static/icons/cloud_upload.svg" }
                            })
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button",
                        attrs: { type: "button" },
                        on: { click: _vm.toggleShare }
                      },
                      [_c("img", { attrs: { src: "/static/icons/share.svg" } })]
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.shareVisible
              ? _c("div", { staticClass: "search-share buttons" }, [
                  _c("div", { staticClass: "form-field copy-area" }, [
                    _c("input", {
                      staticClass: "input",
                      attrs: { readonly: "" },
                      domProps: { value: _vm.freshXmlUrl }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      attrs: { type: "button" },
                      on: { click: _vm.copyXmlUrl }
                    },
                    [
                      _c("img", {
                        attrs: { src: "/static/icons/content_copy.svg" }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "button",
                      attrs: { type: "button", href: _vm.downloadXmlUrl }
                    },
                    [
                      _c("img", {
                        attrs: { src: "/static/icons/save_alt.svg" }
                      })
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.urlCopied
              ? _c("h4", [_vm._v(_vm._s(_vm.i18n.urlCopied))])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "search-view-options" })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.search && _vm.generator && _vm.allLoaded && !_vm.isDeleted
        ? _c("GeneratorCard", { attrs: { generator: _vm.generator } })
        : _vm._e(),
      _vm._v(" "),
      _vm.search && _vm.allLoaded && !_vm.isDeleted
        ? _c("SearchResults", {
            attrs: {
              search: _vm.search,
              viewAsXml: _vm.viewAsXml,
              rawXml: _vm.rawXml
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }