<template>
  <div class="notification-read-toggles">
    <button v-if="notification.readState === 'new'" :title="i18n.markAsRead"
            @click="toggleReadState(notification.readState)"
            class="button notification-read-button">
      <img src="/static/icons/visibility.svg">
    </button>
    <button v-if="notification.readState === 'read'" :title="i18n.markAsUnread"
            @click="toggleReadState(notification.readState)"
            class="button notification-read-button">
      <img src="/static/icons/visibility_off.svg">
    </button>
    <button :title="i18n.remove"
            @click="remove()"
            class="button notification-read-button">
      <img src="/static/icons/delete_forever.svg">
    </button>
  </div>
</template>

<script>
  import i18n from "i18n"
  import api from "@/api"
  import StaticPicture from "common/components/StaticPicture";

  export default {
    name: "ReadStateToggles",
    components: {},
    props: {
      notification: {
        type: Object,
        required: true
      }
    },
    inject: ["workingZone"],
    computed: {
      i18n() {
        return i18n().notifications
      },
    },
    methods: {
      async toggleReadState(readState) {
        const newReadState = readState === 'read' ? 'new' : 'read';
        return await api.command(["notifications", "toggleReadStatus"], {
          notification: this.notification.to,
          readState: newReadState
        })
      },
      async remove() {
        return await api.command(["notifications", "remove"], {
          notification: this.notification.to
        })
      }
    }
  }
</script>

<style scoped>

</style>
