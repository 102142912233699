import Access from "./Access.vue"
import AccessReturn from "./AccessReturn.vue"
import Campaigns from "./Campaigns.vue"

export default function(prefix) {
  const routes = [
    {
      path: prefix+'/access',
      name: 'adsApi:access',
      component: Access
    },
    {
      path: prefix+'/access-return/',
      name: 'adsApi:accessReturn',
      component: AccessReturn
    },
    {
      path: prefix+'/',
      name: 'adsApi:campaigns',
      component: Campaigns
    }
  ]

  routes.sitemap = async (route, api) => {

  }
  return routes
}
