var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form-field-bind", {
    attrs: {
      name: _vm.name,
      valueFilter: function(n) {
        return n && n.trim()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var value = ref.value
          var setValue = ref.setValue
          var error = ref.error
          return [
            _c(
              "FieldTemplate",
              {
                attrs: {
                  errorText: _vm.errorText,
                  error: error,
                  label: _vm.label,
                  name: _vm.name,
                  groupClass: _vm.groupClass
                }
              },
              [
                _c("div", { staticClass: "show-password-wrapper" }, [
                  _c("input", {
                    ref: "field",
                    staticClass: "{ 'is-invalid': !!error }",
                    class: "form-control " + _vm.inputClass,
                    attrs: {
                      type: _vm.showPassword ? "text" : "password",
                      id: "" + _vm._uid,
                      placeholder: _vm.placeholder
                    },
                    domProps: { value: value },
                    on: {
                      input: function(e) {
                        return setValue(e.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.enableTogglePassword && _vm.showPassword === false
                    ? _c(
                        "div",
                        {
                          staticClass: "show-password",
                          on: {
                            click: function($event) {
                              _vm.showPassword = true
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "show-password-icon",
                            attrs: { src: "/static/icons/visibility.svg" }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "show-password-text" }, [
                            _vm._v(_vm._s(_vm.i18n.showPassword))
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.enableTogglePassword && _vm.showPassword === true
                    ? _c(
                        "div",
                        {
                          staticClass: "show-password",
                          on: {
                            click: function($event) {
                              _vm.showPassword = false
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "show-password-icon",
                            attrs: { src: "/static/icons/visibility_off.svg" }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "show-password-text" }, [
                            _vm._v(_vm._s(_vm.i18n.hidePassword))
                          ])
                        ]
                      )
                    : _vm._e()
                ])
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }