function findTiptapText(node) {
  if(node.type == 'text') return node.text
  if(!node.content) return ""
  return node.content.map(n => findTiptapText(n)).join('')
}

function nonEmpty(value) {
  if(!value) return 'empty'
  if(typeof value == 'string') {
    if(!value.trim()) return 'empty'
  }
  if(Array.isArray(value)) {
    if(value.length == 0) return 'empty'
  } else if(value instanceof Date) {
    return
  } if(typeof value == 'object') {
    if(Object.keys(value).length == 0) return 'empty'
  }
}

nonEmpty.isRequired = () => true

function getField(context, fieldName) {
  const propPath = context.propName ? context.propName.split('.') : []
  propPath.pop()
  let path
  if(fieldName[0] == '/') {
    path = fieldName.slice(1).split('.')
  } else {
    path = propPath.concat(fieldName.split('.'))
  }
  let p = context.parameters
  for(let part of path) p = p && p[part]
  if(p) return p
  p = context.props
  for(let part of path) p = p && p[part]
  return p
}

module.exports = {

  email: (settings) => (email) => {
    if(!email || !email.trim()) return
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!re.test(String(email).toLowerCase())) return "wrongEmail"
  },

  safePassword: (settings) => (pass) => {
    let digits = /\d/.test(pass)
    let lower = /[a-z]/.test(pass)
    let upper = /[A-Z]/.test(pass)
    let safe = pass.length >= 8 && digits && lower && upper
    if (pass.length >= 15) safe = true
    if (!safe) return "unsafePassword"
  },

  phone: (settings) => (phone) => {
    const digits = phone.match(/\d/g)
    if (!digits) return "wrongPhone"
    if (digits.length < 9) return "wrongPhone"
    if (digits.length > 11) return "wrongPhone"
    if (!phone.match(/^[0-9 +-]{0,20}$/g)) return "wrongPhone"
  },

  recaptcha: (settings) => (code) => {
  },

  nonEmpty: (settings) => nonEmpty,

  elementsNonEmpty: (settings) => (value) => {
    if(!value) return
    for(let el of value) {
      if(nonEmpty(el)) return 'someEmpty'
    }
  },

  minLength: ({ length }) => (value) => value.length < length ? 'tooShort' : undefined,
  maxLength: ({ length }) => (value) => value.length > length ? 'tooLong' : undefined,

  number: ({  }) => (value) => Number.isNaN(+value) ? 'notNumber' : undefined,

  clientSideMaxLength: ({ length }) => (value) => value.length > length ? 'tooLong' : undefined,

  minTextLength: ({ length }) =>
      (value) => (typeof value == 'string') && value.replace(/<[^>]*>/g,'').length < length ? 'tooShort' : undefined,
  maxTextLength: ({ length }) =>
      (value) => value && value.replace(/<[^>]*>/g,'').length > length ? 'tooLong' : undefined,

  tiptapNonEmpty: (settings) => (value) => {
    if(!value) return 'empty'
    let text = findTiptapText(value)
    if(!text.trim()) return 'empty'
  },

  htmlNonEmpty: (settings) => (value) => {
    if(!value) return 'empty'
    if(typeof value != 'string') return 'empty'
    // value = value.replace(/<[^>]*>/g, "")
    if(!value.trim()) return 'empty'
  },

  geo: (settings) => (value) => {
    if(!value.name) return "wrongPlace"
    if(!value.address) return "wrongPlace"
    if(!value.id) return "wrongPlace"
    if(!value.point) return "wrongPlace"
    if(!value.point.lat) return "wrongPlace"
    if(!value.point.lon) return "wrongPlace"
  },

  newUserEmail: (settings) => (value) => {}, /// Can be validated only on server-side

  ifEq: ({ prop, to, then }, { getValidator }) => {
    let validators = then.map(getValidator)
    const validator = (value, context) => {
      console.error("VIF", getField(context, prop), to, getField(context, prop) == to)
      if(getField(context, prop) == to) {
        console.log("V", validators)
        for(let v of validators) {
          const err = v(value, context)
          if(err) return err
        }
      }
    }
    validator.isRequired = (context) => {
      if(getField(context.props, prop) == to) {
        for(let v of validators) {
          if(v.isRequired && v.isRequired(context)) return true
        }
        return false
      }
    }
    return validator
  },

  ifNotEq: ({ prop, to, then }, { getValidator }) => {
    let validators = then.map(getValidator)
    const validator = (value, context) => {
      console.error("VIF NO", getField(context, prop), to, getField(context, prop) != to)
      if(getField(context, prop) != to) {
        console.log("V", validators)
        for(let v of validators) {
          const err = v(value, context)
          if(err) return err
        }
      }
    }
    validator.isRequired = (context) => {
      if(getField(context, prop) != to) {
        for(let v of validators) {
          if(v.isRequired && v.isRequired(context)) return true
        }
        return false
      }
    }
    return validator
  },

  ifNotOneOf: ({ prop, what, then }, { getValidator }) => {
    let validators = then.map(getValidator)
    const validator = (value, context) => {
      console.error("VIF NOT ONE OF", getField(context, prop), what, what.includes(getField(context, prop)))
      if(!what.includes(getField(context, prop))) {
        console.log("V", validators)
        for(let v of validators) {
          const err = v(value, context)
          if(err) return err
        }
      }
    }
    validator.isRequired = (context) => {
      if(!what.includes(getField(context, prop))) {
        for(let v of validators) {
          if(v.isRequired && v.isRequired(context)) return true
        }
        return false
      }
    }
    return validator
  },

  ifEmpty: ({ prop, then }, { getValidator }) => {
    let validators = then.map(getValidator)
    const validator = (value, context) => {
      console.log("IFEMPTY CTX", context, "FIELD", prop, "VALUE", getField(context, prop))
      if(!getField(context, prop)) {
        console.log("V", validators)
        for(let v of validators) {
          const err = v(value, context)
          if(err) return err
        }
      }
    }
    validator.isRequired = (context) => {
      if(!getField(context, prop)) {
        for(let v of validators) {
          if(v.isRequired && v.isRequired(context)) return true
        }
        return false
      }
    }
    return validator
  },

  smsConfirmCode: (settings) => (value) => {}, /// Can be validated only on server-side

  httpUrl: (settings) => (value) => {
    if(!value) return false // ignore empty
    const match = value.match(
        /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._%~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
    )
    if(!match) return 'wrongUrl'
  }
}
