var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-field" },
    _vm._l(_vm.value, function(element, index) {
      return _c(
        "FieldTemplate",
        {
          key: index,
          staticClass: "subcategory",
          style: { minWidth: 100 / _vm.maxDepth + "%" },
          attrs: {
            error: (!index && _vm.error) || null,
            errorText: _vm.errorText,
            label:
              (_vm.levelLabels && _vm.levelLabels[index]) || "" + (1 + index),
            name: _vm.name
          }
        },
        [
          _c("observe", {
            attrs: {
              what: [
                "categories",
                "subcategories",
                { category: index ? _vm.value[index - 1] : _vm.root }
              ],
              noLoadingZone: true
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var categories = ref.value
                    return [
                      _c("SingleSelectInput", {
                        attrs: {
                          value: element,
                          options: (_vm.deleteOption && element
                            ? [{}]
                            : []
                          ).concat(
                            _vm
                              .sortCategories(categories)
                              .map(function(c) {
                                return c.id
                              })
                              .filter(_vm.filter)
                          ),
                          placeholder: Array.isArray(_vm.placeholder)
                            ? _vm.placeholder[index]
                            : _vm.placeholder,
                          text: function(c) {
                            return _vm.categoryText(c, categories)
                          }
                        },
                        on: {
                          input: function(v) {
                            return _vm.handleSelected(index, v)
                          }
                        }
                      })
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }