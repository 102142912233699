var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "area", staticClass: "visible-area" },
    [_vm._t("default", null, null, _vm.visibleArea)],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }