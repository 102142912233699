var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "loading-zone",
        [
          _c(
            "working-zone",
            [
              _c("router-view"),
              _vm._v(" "),
              _c("overlay-component", {
                attrs: { overlayModel: _vm.overlayModel }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }