var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "loading",
    {
      class: [
        "conversation-card",
        "card",
        { unread: _vm.isUnread, selected: _vm.selected }
      ],
      attrs: { name: "conversation card", what: _vm.allLoaded }
    },
    [
      _c(
        "div",
        { staticClass: "conversation-card-picture" },
        [
          _vm.userData && _vm.userData.picture
            ? _c("uploaded-picture-img", {
                attrs: {
                  width: "45",
                  height: "45",
                  picture: _vm.userData.picture,
                  reactive: true
                }
              })
            : _vm.userData === null
            ? _c("img", {
                attrs: {
                  src: "/static/deleted-user-photo.svg",
                  width: "45",
                  height: "45"
                }
              })
            : _c("img", {
                attrs: {
                  src: "/static/empty-user-photo.svg",
                  width: "45",
                  height: "45"
                }
              }),
          _vm._v(" "),
          (_vm.userData && _vm.userData.online) ||
          (_vm.otherSession && _vm.otherSession.online)
            ? _c("div", { staticClass: "onlineDot" })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "conversation-info" }, [
        _vm.userData
          ? _c("div", { staticClass: "conversation-card-user-name" }, [
              _vm._v(_vm._s(_vm.userData.display))
            ])
          : _vm.userData === null
          ? _c("div", { staticClass: "conversation-card-user-name" }, [
              _vm._v("\n      " + _vm._s(_vm.i18n.userDeleted) + "\n    ")
            ])
          : _c("div", { staticClass: "conversation-card-user-name" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    (_vm.sessionData && _vm.sessionData.name) ||
                      _vm.i18n.anonymous
                  ) +
                  "\n    "
              )
            ]),
        _vm._v(" "),
        (_vm.lastMessageTimestamp || "") > (_vm.writeMessageTimestamp || "")
          ? _c("div", { staticClass: "conversation-card-message" }, [
              _vm.lastMessage
                ? _c("div", { staticClass: "message-text" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$sanitize(_vm.lastMessage.text, {
                            ALLOWED_TAGS: []
                          })
                        )
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "time",
                {
                  staticClass: "conversation-card-timestamp",
                  attrs: { datetime: "lastMessageTimestamp" }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm._f("hourOrDayShort")(_vm.lastMessageTimestamp)
                      ) +
                      "\n      "
                  )
                ]
              )
            ])
          : _c("div", { staticClass: "conversation-card-message" }, [
              _c("div", { staticClass: "message-you" }, [
                _vm._v(_vm._s(_vm.i18n.you))
              ]),
              _vm._v(" "),
              _vm.writeMessage
                ? _c("div", { staticClass: "message-text" }, [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$sanitize(_vm.writeMessage.text, {
                            ALLOWED_TAGS: []
                          })
                        )
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "time",
                {
                  staticClass: "conversation-card-timestamp",
                  attrs: { datetime: "lastMessageTimestamp" }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm._f("hourOrDayShort")(_vm.writeMessageTimestamp)
                      ) +
                      "\n      "
                  )
                ]
              )
            ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }