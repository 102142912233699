<template>
  <input :class="inputClass" ref="input" v-model="placeText" placeholder="Enter place" type="text">
</template>

<script>
  import { loadGoogleMaps } from "common/thirdParty/googleMapsApi.js"

  export default {
    name: "PlaceInput",
    inject: ['loadingZone', 'workingZone'],
    props: {
      country: {
        type: String
      },
      types: {
        type: Array
      },
      value: {
      },
      inputClass: {
        type: String
      }
    },

    data() {
      return {
        place: {},
        placeText: ""
      }
    },

    watch: {
      placeText(text) {
        if(!text || text.length == 0) {
          this.place = null
          console.log("PLACE CLEARED")
          this.$emit('input', this.place)
          this.$emit('place', this.place)
          return
        }
        if(!this.place) this.place = {}
        this.place.name = text
        this.$emit('input', this.place)
      },
      value(p) {
        console.log("SET PLACE", p)
        this.place = p
        const text = (p && (p.address || p.name)) || ''
        if(this.placeText != text) {
          console.log(`SET TEXT "${text}"`)
          this.placeText = text
        }
      },
      country(c) {
        if(this.autocomplete) this.autocomplete.setComponentRestrictions({
          country: c.toLowerCase()
        })
      }
    },

    created() {
      this.mapsPromise = loadGoogleMaps()
      this.mapsPromise.then(maps => this.maps = maps)
      this.loadingZone.addPromise("google maps api for PlaceInput", this.mapsPromise)
      if(this.value) {
        if(typeof this.value == 'string') {
          this.placeText = this.value
        } else {
          this.placeText = this.value.address
          this.place = this.value
        }
      }
    },
    mounted() {
      this.mapsPromise.then(maps => {
        let opts = {}
        if(this.country) {
          opts.componentRestrictions = { country : this.country.toLowerCase() }
        }
        this.autocomplete = new maps.places.Autocomplete(this.$refs.input, {
          types: this.types,
         // fields: ['vicinity']
          ...opts
        })
        this.autocomplete.addListener('place_changed', () => {
          const gplace = this.autocomplete.getPlace()
          //window.ggplace = gplace
          this.place = {
            id: gplace.place_id,
            name: gplace.name,
            address: this.$refs.input.value,
            point: {
              lat: gplace.geometry.location.lat(),
              lon: gplace.geometry.location.lng()
            }
          }
          //console.log("THPLACE", this.place)
          this.$emit('place', this.place)
          this.$emit('input', this.place)
        })
        this.$refs.input.addEventListener('keydown', function(e) {
          const pac =  document.querySelector('.pac-container')
          const pacVisible = pac && pac.style.display != "none"
          if (e.which == 13 && pacVisible) {
            console.log("CANCEL ENTER")
            e.preventDefault()
            return false
          }
        })
      })
    }
  }
</script>

<style scoped>

</style>
