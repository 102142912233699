module.exports = {
  states: {
    denied: "Access denied",
    prompt: "Ask every time",
    granted: "Access granted",
    unknown: "Unknown"
  },
  permissions: {
    "geolocation": "Your location",
    "notifications": "Notifications",
    "push": "Push",
    "midi": "Midi",
    "camera": "Camera",
    "microphone": "Microphone",
    "speaker-selection": "Speaker selection",
    "device-info": "Device info",
    "background-fetch": "Background fetch",
    "background-sync": "Background sync",
    "bluetooth": "Bluetooth devices",
    "persistent-storage": "Persistent storage",
    "ambient-light-sensor": "Ambient light sensor",
    "accelerometer": "Accelerometer",
    "gyroscope": "Gyroscope",
    "magnetometer": "Magnetometer",
    "clipboard-read": "Read clipboard",
    "clipboard-write": "Write clipboard",
    "display-capture": "Screen capture",
    "nfc": "Near field communication"
  },
  state: "State:"
}