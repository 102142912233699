import BlockMenuPlugin from './BlockMenuPlugin.js'

export default {

  props: {
    editor: {
      default: null,
      type: Object,
    },
    blockTags: {
      type: Array,
      default() { return ['P', 'DIV', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'] }
    },
    addMargins: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      block: {
        isActive: false,
        top: 0,
        left: 0,
        width: 0,
        height: 0,
      }
    }
  },

  watch: {
    editor: {
      immediate: true,
      handler(editor) {
        if (editor) {
          this.$nextTick(() => {
            editor.registerPlugin(BlockMenuPlugin({
              editor,
              element: this.$el,
              blockTags: this.blockTags,
              addMargins: this.addMargins,
              onUpdate: block => {
                // the second check ensures event is fired only once
                if (block.isActive && this.block.isActive === false) {
                  this.$emit('show', block)
                } else if (!block.isActive && this.block.isActive === true) {
                  this.$emit('hide', block)
                }
                this.block = block
              },
            }))
          })
        }
      },
    },
  },

  render() {
    if (!this.editor) {
      return null
    }

    return this.$scopedSlots.default({
      focused: this.editor.view.focused,
      focus: this.editor.focus,
      commands: this.editor.commands,
      isActive: this.editor.isActive,
      getMarkAttrs: this.editor.getMarkAttrs.bind(this.editor),
      block: this.block,
    })
  },

  beforeDestroy() {
    this.editor.unregisterPlugin('block_menu')
  },

}