<template>
  <div class="buttons file-upload-field">
    <div class="file-name" v-if="file">{{ file.name }}</div>
    <div class="upload-info" v-if="serverUpload">
      {{ serverUpload.progress.percentage }}%
      {{ serverUpload.progress.transferred | bytes }} / {{ serverUpload.progress.length | bytes }}
    </div>
    <button class="button" type="button" @click="$emit('delete')"><img src="/static/icons/close.svg"></button>
  </div>
</template>

<script>
  export default {
    name: 'FileUploadField',
    components: {  },
    props: {
      name: {
        type: String,
        required: true
      },
      file: {
        required: true
      },
    },
    inject: ['form'],
    data () {
      return {
        clientUpload: null,
        liveValue: null,
        uploadPromise: null
      }
    },
    reactive: {
      serverUpload() {
        return this.clientUpload && ['uploads', 'upload', { upload: this.clientUpload.id }]
      }
    },
    computed: {
      uid() { return ''+this._uid }
    },
    methods: {
      startUpload() {
        this.uploadPromise = new Promise((resolve, reject) => {
          if (this.uploadLocalObservable) this.uploadLocalObservable.unobserve(this.uploadObserver)
          this.uploadLocalObservable = api.uploadFile(`${this.purpose}`, this.file.name, this.file)
          this.uploadObserver = (signal, ...args) => {
            console.log(`UPLOAD ${signal} ( ${args.map(x => JSON.stringify(x)).join(', ')} )`)
            if (signal != 'set') throw new Error("unknown signal")
            this.clientUpload = args[0]
            if (this.clientUpload.state == 'done') {
              resolve(this.uploadLocalObservable.value.id)
            }
            if (this.clientUpload.state == 'failed') {
              reject("uploadFailed")
            }
          }
          this.uploadLocalObservable.observe(this.uploadObserver)
        }).then(async (uploadId) => {
          //if(!upload) throw new Error("race-condition - server not found")
          console.log("UPLOADED", uploadId)
          this.liveValue = uploadId
          console.log("SET UPLOAD", this.name, uploadId)
          this.form.setFieldValue(this.name, uploadId)
        }).catch(error => {
          console.error("UPLOAD ERROR", error)
          this.setFieldError(this.name, error)
        })
        console.log("STARTED UPLOAD")
        this.uploadPromise.then((pictureId) => {
          console.log("UPLOAD FINISHED", pictureId, " == ", this.livePicture)
        })
      }
    },
    created() {
      this.barrier = async () => {
        if(this.uploadPromise) await this.uploadPromise
      }
      this.form.addBarrier(this.name, this.barrier)
    },
    mounted() {
      this.startUpload()
    },
    beforeDestroy() {
      this.form.removeBarrier(this.name, this.barrier)
    }
  }
</script>
