<template>
  <div class="url-group">
    <div class="card main-card url-checker" v-if="allLoaded">
      <div class="url-checker-info">
        <div class="url-checker-url">
          <a class="dataSet-url" :href="checker.url">{{ checker.url }}</a>
        </div>
        <div v-if="checker.interval != 'never'" class="url-checker-state">{{ i18n.checkerState[checker.state] }}</div>
      </div>
      <div v-if="checker.interval != 'never'" class="last-url-checks">
        <time v-if="checker.nextCheck" :datetime="checker.nextCheck" class="url-next-check-time">
          {{ i18n.nextCheckTime }}
          <span class="seconds" v-if="(new Date(checker.nextCheck)).getTime() - now > 0">
            {{ ((new Date(checker.nextCheck)).getTime() - now)/1000 | durationSecondsShort }}
          </span>
          <span v-else>{{ i18n.now }}</span>
        </time>
        <div v-for="check in checks" class="url-check">
          <div v-if="check.ok" class="url-check-status url-check-status-ok">{{ i18n.checkStatus.ok }}</div>
          <div v-else class="url-check-status url-check-status-error">{{ i18n.checkStatus.error }}</div>
          <div class="url-checks-status" v-if="check.statusCode">{{ check.statusCode }} - {{ check.status }}</div>
          <div class="url-checks-duration">
            in
            {{ check.duration/1000 | durationMillisShort }}
          </div>
          <time :datetime="check.timestamp" class="url-last-check-time">
            {{ i18n.lastCheckTime }}
            <span class="seconds">{{ (now - (new Date(check.timestamp)).getTime())/1000 | durationSecondsShort }}</span>
            {{ i18n.ago }}
          </time>
        </div>
      </div>
        <!--            <pre>{{ JSON.stringify(checks, null, 2) }}</pre>-->
      <div class="buttons checker-buttons">

        <div class="interval-select form-field">
          <label>{{ i18n.checkInterval }}</label>
          <SingleSelectInput :options="checkIntervals" :value="checker.interval"
                             :text="interval => i18n.checkIntervals[interval]"
                             @input="value => setCheckInterval(value)">
          </SingleSelectInput>
        </div>

      </div>
      <div class="buttons checker-buttons">

        <!--<button type="button" class="button" disabled>
          <img src="/static/icons/history.svg"><span>History</span>
        </button>-->

        <button type="button" class="button" @click="() => deleteChecker()">
          <img src="/static/icons/delete_forever.svg"><span>{{ i18n.deleteChecker }}</span>
        </button>

        <button type="button" class="button" v-if="!scanners || scanners.length == 0" @click="() => addScanner()">
          <img src="/static/icons/add_task.svg"><span>{{ i18n.addScanner }}</span>
        </button>

        <router-link v-if="dataSets && dataSets[0]" :to="{ name: 'xml:searchDataSet', params: {
              dataSet: dataSets[0].to
            } }"
            tag="button" type="button" class="button">
          <img src="/static/icons/search.svg"><span>{{ i18n.search }}</span>
        </router-link>
        <router-link v-else :to="{ name: 'xml:searchUrl', params: { searchUrl: encodeURIComponent(checker.url) } }"
            tag="button" type="button" class="button">
          <img src="/static/icons/search.svg"><span>{{ i18n.search }}</span>
        </router-link>

        <router-link v-if="dataSets && dataSets[0]" :to="{ name: 'xml:exclusionsDataSet', params: {
              dataSet: dataSets[0].to
            } }"
            tag="button" type="button" class="button">
          <img src="/static/icons/fact_check.svg"><span>{{ i18n.checkExclusions }}</span>
        </router-link>
        <router-link v-else :to="{ name: 'xml:exclusionsUrl', params: {
              exclusionsUrl: encodeURIComponent(checker.url)
            } }"
            tag="button" type="button" class="button">
          <img src="/static/icons/fact_check.svg"><span>{{ i18n.checkExclusions }}</span>
        </router-link>

      </div>
    </div>
    <ScannerCard v-if="allLoaded && scanners" v-for="scanner in scanners" :scanner="scanner" :key="scanner.id"
                 :delete-scanner-button="i18n.deleteScanner">
    </ScannerCard>
    <div v-if="dataSets && dataSets.length > 0" class="card main-card search-data-sets">
      <div v-for="(dataSet, index) in dataSets" class="data-set-info">
        <div>
          <span>{{ index ? i18n.oldDataSet : i18n.newDataSet }}</span>
          <time :datetime="dataSet.timestamp">{{ dataSet.timestamp | date }} {{ dataSet.timestamp | hour }}</time>
          <span>{{ i18n.downloadedIn }}</span>
          <span>{{ dataSet.downloadTime/1000 | durationMillis }}</span>
        </div>
      </div>
    </div>
    <observe name="generators"
             :what="$views.xmlSearch.myGeneratorsByUrl({ url: checker.url })"
             v-slot="{ value: generators }">
      <GeneratorCard v-for="generator in generators" :key="generator.to || generator.id"
                     :generator="generator" can-edit show-query show-search-button></GeneratorCard>
    </observe>
  </div>
</template>

<script>
import api from "api"
import SingleSelectInput from "common/components/inputs/SingleSelectInput.vue"
import currentTime from "common/components/utils/currentTime.js"
import ScannerCard from "../xmlAlert/ScannerCard.vue"
import GeneratorCard from "../xmlSearch/GeneratorCard.vue"

export default {
  name: "UrlCard",
  components: { GeneratorCard, ScannerCard, SingleSelectInput },
  inject: ['workingZone'],
  props: {
    checker: {
      type: Object,
      required: true
    }
  },
  reactive: {
    scanners() {
      return ['xmlAlert', 'myScannersByUrl', { url: this.checker.url }]
    },
    checks() {
      return ['urlChecker', 'checksByChecker', { checker: this.checker.to, reverse: true, limit: 2 }]
    },
    dataSets() {
      return this.$views.xmlSearch.dataSetsByUrl({ url: this.checker.url, limit: 2, reverse: true })
    }
  },
  computed: {
    checkIntervals() {
      return api.actions.urlChecker.setInterval.definition.properties.interval.options
    },
    i18n() {
      return i18n().urlChecker
    },
    now() {
      return currentTime.now
    },
    allLoaded() {
      return this.scanners !== undefined
    }
  },
  methods: {
    deleteChecker() {
      const checker = this.checker.to || this.checker.id
      this.workingZone.addPromise('delete checker ' + checker,
          api.command(['urlChecker', 'deleteChecker'], { checker })
            .then(this.$emit('deleted'))
        )
    },
    setCheckInterval(interval) {
      const checker = this.checker.to || this.checker.id
      this.workingZone.addPromise('set checker ' + checker + ' interval to ' + checker,
          api.command(['urlChecker', 'setInterval'], {
            checker,
            interval
          }))
    },
    addScanner() {
      this.workingZone.addPromise('add scanner ' + this.checker.url,
          api.actions.xmlAlert.createScanner({ url: this.checker.url }))
    }
  }
}
</script>

<style scoped>

</style>