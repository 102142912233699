<template>
  <loading-zone class="access-members">
    <observe v-for="member in members" :key="member.user"
             :what="['users', 'publicUserData', { user: member.user }]"
             v-slot="{ value: userData }">
      <div :class="['access-member', { online: userData.online, offline: !userData.online }]">
        <uploaded-picture-img width="25" height="25" :picture="userData.picture">
        </uploaded-picture-img>
        <div class="access-member-name">{{ userData.display }}</div>
        <observe name="session online"
                 :what="['accessControl', 'userAccessOnline', {
                   access: toType+'_'+toId, user: member.user
                 }]"
                 v-slot="{ value: online }">
          {{ online.online ? '+' : '-' }}
        </observe>
      </div>
    </observe>
    <observe v-for="session in sessions" :key="session.id"
             name="public session info"
             :what="['accessControl', 'publicSessionInfo', { publicSessionInfo: session.id }]"
             v-slot="{ value: info }">
      <div :class="['access-member', { online: info.online, offline: !info.online }]">
        <img src="/static/empty-user-photo.svg" width="25" height="25">
        <div class="access-member-name">{{ info.name || 'Anonymous' }}</div>
        <observe name="session online"
                 :what="['accessControl', 'sessionAccessOnline', {
                   access: toType+'_'+toId, publicSessionInfo: session.id
                 }]"
                 v-slot="{ value: online }">
          {{ online.online ? '+' : '-' }}
        </observe>
      </div>
    </observe>
  </loading-zone>
</template>

<script>
  export default {
    name: "AllMembers",
    props: {
      toType: {
        type: String,
        required: true
      },
      toId: {
        type: String,
        required: true
      }
    },
    reactive: {
      sessions() { return ['accessControl', 'sessionAccesses', { toType: this.toType, toId: this.toId }] },
      members() { return ['members', 'listMembers', { listType: this.toType, list: this.toId }] }
    }
  }
</script>

<style scoped>

</style>