<template>
  <div class="select-input" @click="showSelectMenu"  ref="select">
    <span :class="'select-input-value '+inputClass">
      <div v-if="liveValue">
        {{ text(liveValue)  }}
      </div>
      <div v-else class="select-input-placeholder">
        {{ placeholder }}
      </div>
    </span>
    <span class="select-input-more"><img src="/static/icons/arrow_drop_down.svg" /></span>
  </div>
</template>

<script>
  import i18n from "i18n"

  import overlayModel from "common/components/utils/overlayModel.js"
  import SingleSelectPicker from "./SingleSelectPicker.vue"

  export default {
    name: "SingleSelectInput",
    props: {
      options: {
        type: Array,
        required: true
      },
      value: {
      },
      text: {
        type: Function,
        default: (v) => v
      },
      placeholder: {
        type: String
      },
      errorText: {
        type: Object
      },
      inputClass: {
        type: String,
        default: ""
      },
      groupClass: {
        type: String
      }
    },
    components: { },
    data() {
      return {
        liveValue: this.value,
        overlayStackId: null,
        lastCloseTime: 0
      }
    },
    computed: {
    },
    methods: {
      showSelectMenu() {
        if(this.overlayStackId === null &&  Date.now() - this.lastCloseTime > 230) {
          this.overlayStackId = overlayModel.show({
            component: SingleSelectPicker,
            props: {
              anchor: overlayModel.elementAnchor(this.$refs.select),
              options: this.options,
              text: this.text
            },
            on: {
              selected: (value) => {
                this.liveValue = value
                this.$emit('input', value)
              },
              close: () => {
                this.overlayStackId = null
                this.lastCloseTime = Date.now()
              }
            }
          })
        } else {
          overlayModel.close(this.overlayStackId)
        }
      }
    },
    watch: {
      value(v) {
        if(JSON.stringify(v) != JSON.stringify(this.liveValue)) {
          this.liveValue = v
        }
      }
    },
    created() {
    },
    beforeDestroy() {
    }
  }
</script>

<style scoped>
  .select-input {
    position: relative;
  }
  .select-input .more {
    position: absolute;
    right: 0px;
    top: 0px;
  }
</style>
