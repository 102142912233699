var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "url-group" },
    [
      _vm.allLoaded
        ? _c("div", { staticClass: "card main-card url-checker" }, [
            _c("div", { staticClass: "url-checker-info" }, [
              _c("div", { staticClass: "url-checker-url" }, [
                _c(
                  "a",
                  {
                    staticClass: "dataSet-url",
                    attrs: { href: _vm.checker.url }
                  },
                  [_vm._v(_vm._s(_vm.checker.url))]
                )
              ]),
              _vm._v(" "),
              _vm.checker.interval != "never"
                ? _c("div", { staticClass: "url-checker-state" }, [
                    _vm._v(_vm._s(_vm.i18n.checkerState[_vm.checker.state]))
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.checker.interval != "never"
              ? _c(
                  "div",
                  { staticClass: "last-url-checks" },
                  [
                    _vm.checker.nextCheck
                      ? _c(
                          "time",
                          {
                            staticClass: "url-next-check-time",
                            attrs: { datetime: _vm.checker.nextCheck }
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.i18n.nextCheckTime) +
                                "\n        "
                            ),
                            new Date(_vm.checker.nextCheck).getTime() -
                              _vm.now >
                            0
                              ? _c("span", { staticClass: "seconds" }, [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        _vm._f("durationSecondsShort")(
                                          (new Date(
                                            _vm.checker.nextCheck
                                          ).getTime() -
                                            _vm.now) /
                                            1000
                                        )
                                      ) +
                                      "\n        "
                                  )
                                ])
                              : _c("span", [_vm._v(_vm._s(_vm.i18n.now))])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.checks, function(check) {
                      return _c("div", { staticClass: "url-check" }, [
                        check.ok
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "url-check-status url-check-status-ok"
                              },
                              [_vm._v(_vm._s(_vm.i18n.checkStatus.ok))]
                            )
                          : _c(
                              "div",
                              {
                                staticClass:
                                  "url-check-status url-check-status-error"
                              },
                              [_vm._v(_vm._s(_vm.i18n.checkStatus.error))]
                            ),
                        _vm._v(" "),
                        check.statusCode
                          ? _c("div", { staticClass: "url-checks-status" }, [
                              _vm._v(
                                _vm._s(check.statusCode) +
                                  " - " +
                                  _vm._s(check.status)
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "url-checks-duration" }, [
                          _vm._v(
                            "\n          in\n          " +
                              _vm._s(
                                _vm._f("durationMillisShort")(
                                  check.duration / 1000
                                )
                              ) +
                              "\n        "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "time",
                          {
                            staticClass: "url-last-check-time",
                            attrs: { datetime: check.timestamp }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.i18n.lastCheckTime) +
                                "\n          "
                            ),
                            _c("span", { staticClass: "seconds" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("durationSecondsShort")(
                                    (_vm.now -
                                      new Date(check.timestamp).getTime()) /
                                      1000
                                  )
                                )
                              )
                            ]),
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.i18n.ago) +
                                "\n        "
                            )
                          ]
                        )
                      ])
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "buttons checker-buttons" }, [
              _c(
                "div",
                { staticClass: "interval-select form-field" },
                [
                  _c("label", [_vm._v(_vm._s(_vm.i18n.checkInterval))]),
                  _vm._v(" "),
                  _c("SingleSelectInput", {
                    attrs: {
                      options: _vm.checkIntervals,
                      value: _vm.checker.interval,
                      text: function(interval) {
                        return _vm.i18n.checkIntervals[interval]
                      }
                    },
                    on: {
                      input: function(value) {
                        return _vm.setCheckInterval(value)
                      }
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "buttons checker-buttons" },
              [
                _c(
                  "button",
                  {
                    staticClass: "button",
                    attrs: { type: "button" },
                    on: {
                      click: function() {
                        return _vm.deleteChecker()
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: "/static/icons/delete_forever.svg" }
                    }),
                    _c("span", [_vm._v(_vm._s(_vm.i18n.deleteChecker))])
                  ]
                ),
                _vm._v(" "),
                !_vm.scanners || _vm.scanners.length == 0
                  ? _c(
                      "button",
                      {
                        staticClass: "button",
                        attrs: { type: "button" },
                        on: {
                          click: function() {
                            return _vm.addScanner()
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: { src: "/static/icons/add_task.svg" }
                        }),
                        _c("span", [_vm._v(_vm._s(_vm.i18n.addScanner))])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.dataSets && _vm.dataSets[0]
                  ? _c(
                      "router-link",
                      {
                        staticClass: "button",
                        attrs: {
                          to: {
                            name: "xml:searchDataSet",
                            params: {
                              dataSet: _vm.dataSets[0].to
                            }
                          },
                          tag: "button",
                          type: "button"
                        }
                      },
                      [
                        _c("img", {
                          attrs: { src: "/static/icons/search.svg" }
                        }),
                        _c("span", [_vm._v(_vm._s(_vm.i18n.search))])
                      ]
                    )
                  : _c(
                      "router-link",
                      {
                        staticClass: "button",
                        attrs: {
                          to: {
                            name: "xml:searchUrl",
                            params: {
                              searchUrl: encodeURIComponent(_vm.checker.url)
                            }
                          },
                          tag: "button",
                          type: "button"
                        }
                      },
                      [
                        _c("img", {
                          attrs: { src: "/static/icons/search.svg" }
                        }),
                        _c("span", [_vm._v(_vm._s(_vm.i18n.search))])
                      ]
                    ),
                _vm._v(" "),
                _vm.dataSets && _vm.dataSets[0]
                  ? _c(
                      "router-link",
                      {
                        staticClass: "button",
                        attrs: {
                          to: {
                            name: "xml:exclusionsDataSet",
                            params: {
                              dataSet: _vm.dataSets[0].to
                            }
                          },
                          tag: "button",
                          type: "button"
                        }
                      },
                      [
                        _c("img", {
                          attrs: { src: "/static/icons/fact_check.svg" }
                        }),
                        _c("span", [_vm._v(_vm._s(_vm.i18n.checkExclusions))])
                      ]
                    )
                  : _c(
                      "router-link",
                      {
                        staticClass: "button",
                        attrs: {
                          to: {
                            name: "xml:exclusionsUrl",
                            params: {
                              exclusionsUrl: encodeURIComponent(_vm.checker.url)
                            }
                          },
                          tag: "button",
                          type: "button"
                        }
                      },
                      [
                        _c("img", {
                          attrs: { src: "/static/icons/fact_check.svg" }
                        }),
                        _c("span", [_vm._v(_vm._s(_vm.i18n.checkExclusions))])
                      ]
                    )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.scanners, function(scanner) {
        return _vm.allLoaded && _vm.scanners
          ? _c("ScannerCard", {
              key: scanner.id,
              attrs: {
                scanner: scanner,
                "delete-scanner-button": _vm.i18n.deleteScanner
              }
            })
          : _vm._e()
      }),
      _vm._v(" "),
      _vm.dataSets && _vm.dataSets.length > 0
        ? _c(
            "div",
            { staticClass: "card main-card search-data-sets" },
            _vm._l(_vm.dataSets, function(dataSet, index) {
              return _c("div", { staticClass: "data-set-info" }, [
                _c("div", [
                  _c("span", [
                    _vm._v(
                      _vm._s(index ? _vm.i18n.oldDataSet : _vm.i18n.newDataSet)
                    )
                  ]),
                  _vm._v(" "),
                  _c("time", { attrs: { datetime: dataSet.timestamp } }, [
                    _vm._v(
                      _vm._s(_vm._f("date")(dataSet.timestamp)) +
                        " " +
                        _vm._s(_vm._f("hour")(dataSet.timestamp))
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.i18n.downloadedIn))]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm._f("durationMillis")(dataSet.downloadTime / 1000)
                      )
                    )
                  ])
                ])
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c("observe", {
        attrs: {
          name: "generators",
          what: _vm.$views.xmlSearch.myGeneratorsByUrl({ url: _vm.checker.url })
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var generators = ref.value
              return _vm._l(generators, function(generator) {
                return _c("GeneratorCard", {
                  key: generator.to || generator.id,
                  attrs: {
                    generator: generator,
                    "can-edit": "",
                    "show-query": "",
                    "show-search-button": ""
                  }
                })
              })
            }
          }
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }