<template>
  <div class="tooltipModal modalHolder">
    <loading-zone>
      <loading :what="parent && subCategories" name="parentData">
        <div class="tooltipModal-dialog categories-submodal" v-if="parent && subCategories">

          <div class="modal-header text-center tooltipModal-header">
            <h5 class="modal-title bold-font">
              {{ (parent.translations[lang] && parent.translations[lang].name) || parent.name }}
            </h5>
            <button class="close" @click="close">×</button>
          </div>
          <div class="tooltipModal-body modal-body justify-content-center">
            <p>{{i18n.description}}</p>

            <div class="subcategories-container">
              <div class="subcategories-image">
                <img src="/static/category_customization.svg"/>
              </div>
              <div class="subcategories-selection">
                <div class="subcategories-selection-title">{{ i18n.subcategoriesIn }} "{{ (parent.translations[lang] && parent.translations[lang].name) || parent.name }}" {{ i18n.category }}</div>
                <div class="custom-control custom-checkbox mt-2" v-for="subCategory in subCategories">
                  <input type="checkbox" class="custom-control-input mt-2" :id="'cat_'+subCategory.id"
                         :checked="value.find(c => c[1] == subCategory.id)"
                         @input="(ev) => handleChecked(subCategory.id, ev.target.checked)"/>
                  <label class="custom-control-label" :for="'cat_'+subCategory.id">
                    {{ (subCategory.translations[lang] && subCategory.translations[lang].name) || subCategory.name }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer pb-0">
            <button class="btn btn-secondary" @click="close()">{{ i18n.saveChanges }}</button>
          </div>

        </div>
      </loading>
    </loading-zone>
  </div>
</template>

<script>
  import i18n from "i18n"

  export default {
    name: "CategoriesSubModal",
    computed: {
      lang() {
        return i18n.config.language
      },
      i18n() {
        return i18n().categories.field.modal
      },
    },
    reactive: {
      subCategories() {
        return ['categories', 'subcategories', {category: this.parent.id}]
      }
    },
    props: {
      parent: {
        type: Object,
        required: true
      },
      value: {
        type: Array,
        required: true
      }
    },
    methods: {
      close() {
        this.$emit('close')
      },
      handleChecked(subCategory, checked) {
        if (checked) this.$emit('selected', subCategory)
        else this.$emit('deselected', subCategory)
      }
    }
  }
</script>

<style scoped>

</style>
