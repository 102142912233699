<template>
  <div>{{ data }}</div>
</template>

<script>
  export default {
    name: "JsonView",
    props: {
      data: {
        required: true
      }
    }
  }
</script>

<style scoped>

</style>