module.exports = {

  createRoom: {
    title: "Create video conference",
    name: "Room name (Optional)",
    createButton: "Create Room",
  },

  room: "Room",
  roomReady: "Room is ready to join",
  roomClosed: "Room is closed",
  cameraDisabled: "Camera disabled",
  cameraInit: "Camera initialization",
  joinButton: "Join now",

  selectVideoDevice: 'Select camera...',
  browserDefaultVideo: 'Default Camera',
  selectAudioDevice: 'Select microphone...',
  browserDefaultAudio: 'Default Microphone',

  permissions: {
    title: "User media permissions",
    introduction: '<p>Please enable camera access:</p><img src="/static/cameraAccess/en.png">',
    cancelButton: "Cancel",
    disableCameraButton: "Disable camera"
  },

  notConnected: {
    title: `User media`,
    text: `Please connect camera and microphone or choose one of following options:`,
    connectedButton: 'Ok, connected',
    onlyCamera: 'I will use only camera',
    onlyMicrophone: 'I will use only microphone',
    cancelButton: "Cancel"
  },

  joiningLinkInfo: "Room address:",
  copyLinkButton: "Copy link",
  linkCopied: "Link copied to clipboard",

  afterWakeUp: {
    title: "The video call ended because the computer went to sleep",
    returnText: ({ room }) => `You can rejoin the room ${room} or create new one.`,
    returnButton: 'Rejoin room',
    createButton: 'Create new room',
  },

  inApp: {
    title: "This application does not support facebook webview",
    apple_instruction: "Copy the link to the Safari browser to enter the room",
    android_instruction: "Copy the link to the Chrome browser to enter the room"
  }

}
