var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Notification",
    { ref: "notification", attrs: { notification: _vm.notification } },
    [
      _c(
        "div",
        { staticClass: "notification-avatar", on: { click: _vm.goTo } },
        [
          _vm.notification.results.length
            ? _c("img", {
                staticClass: "avatar-addon",
                attrs: { src: "/static/icons/error.svg" }
              })
            : _c("img", {
                staticClass: "avatar-addon",
                attrs: { src: "/static/icons/check.svg" }
              })
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "notification-content", on: { click: _vm.goTo } },
        [
          _c("div", { staticClass: "notification-title" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.notification.results.length
                    ? _vm.i18n.notifications.checkError
                    : _vm.i18n.notifications.checkDone
                ) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "notification-summary" }, [
            _vm._v(
              "\n      Account: " +
                _vm._s(_vm.notification.account.accountName) +
                " (" +
                _vm._s(_vm.notification.account.accountId) +
                ")"
            ),
            _c("br"),
            _vm._v(
              "\n      " + _vm._s(_vm.notification.account.name) + "\n    "
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "notification-description" },
            _vm._l(_vm.notification.results, function(result) {
              return _c("div", { key: result.id }, [
                result.type === "searchBudgetLostImpressionShare"
                  ? _c("div", [
                      _vm._v(
                        "\n          Campaign: " +
                          _vm._s(result.campaign.name) +
                          " (" +
                          _vm._s(result.campaign.id) +
                          ") "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n          Ads Limited by budget: " +
                          _vm._s(
                            (
                              result.metrics
                                .search_budget_lost_impression_share * 100
                            ).toFixed(2)
                          ) +
                          "%\n        "
                      )
                    ])
                  : _vm._e()
              ])
            }),
            0
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }