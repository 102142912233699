<template>
  <component :is="tag" v-on:submit.prevent="ev => $emit('submit', ev)">
    <slot></slot>
  </component>
</template>

<script>
  import api from "@/api"
  import validators from "#/validation"
  import guid from "@/api/guid.js"

  import { getElementPositionInDocument } from './utils/dom.js'

  function setByPath(obj, path, value) {
    if(path.length > 1) {
      if(typeof obj[path[0]] != 'object' || obj[path[0]] == null) obj[path[0]] = {}
      setByPath(obj[path[0]], path.slice(1), value)
    } else {
      obj[path[0]] = value
    }
  }

  class FormValue {
    constructor(definition) {
      this.definition = definition

      this.value = null
      this.error = null

      this.errorObservers = []
      this.valueObservers = []
      this.validators = []
      this.barriers = []

      if(definition.validation) {
        let validations = Array.isArray(definition.validation) ? definition.validation : [definition.validation]
        const context = {
          service: this.serviceDefinition,
          action: this.actionDefinition,
          property: definition,
          validators
        }
        const getValidator = validation => {
          if(typeof validation == 'string') {
            const validator = validators[validation]
            if(typeof validator != 'function') throw new Error(`Validator ${validation} not found`)
            return validator({}, context)
          } else {
            const validator = validators[validation.name]
            if(typeof validator != 'function') throw new Error(`Validator ${JSON.stringify(validation)} not found`)
            return validator(validation, context)
          }
        }
        context.getValidator = getValidator
        this.validators = this.validators.concat(
            validations.map(validation => getValidator(validation))
        )
      }
    }

    setValue(value) {
      this.value = value
      for(let observer of this.valueObservers) observer(value)
    }
    setError(error) {
      this.error = error
      for(let observer of this.errorObservers) observer(error)
    }
    getAnalyticsValue() {
      if(this.definition.secret) return undefined
      return this.value
    }
    getValue() {
      return this.value
    }

    reset(initialValue) {
      if(this.definition.type) {
        let defaultValue = this.definition.defaulValue || null
        if(!defaultValue) {
          switch(this.definition.type) {
            case "String" : defaultValue = ""; break;
            case "Object" : defaultValue = {}; break;
            case "Number" : defaultValue = 0; break;
            case "Array"  : defaultValue = []; break;
          }
        }
        this.setValue(initialValue || defaultValue)
      } else {
        this.setValue(initialValue)
      }
      this.setError(null)
    }

    afterError(initialValue) {
      if(this.definition.singleUse) this.reset(initialValue)
    }

    observe(observer) {
      this.valueObservers.push(observer)
      observer(this.getValue())
    }

    unobserve(observer) {
      const id = this.valueObservers.indexOf(observer)
      if(id == -1) throw new Error("Observer not found")
      this.valueObservers.splice(id, 1)
    }

    observeError(observer) {
      this.errorObservers.push(observer)
      observer(this.error)
    }

    unobserveError(observer) {
      const id = this.errorObservers.indexOf(observer)
      if(id == -1) throw new Error("Observer not found")
      this.errorObservers.splice(id, 1)
    }

    validate(context) {
      let promises = []
      for(let validator of this.validators) {
        promises.push(validator(this.value, context))
      }
      return Promise.all(promises).then(results => {
        for(let error of results) {
          if(error) {
            if(this.error == error) return error
            this.setError(error)
            return error
          }
        }
      })
    }

    async waitForBarriers(context) {
      let promises = []
      for(let barrier of this.barriers) {
        promises.push(barrier(this.value, context))
      }
      await Promise.all(promises)
    }

    clearValidation() {
      this.setError(null)
    }
  }

  class FormObject extends FormValue {
    constructor(definition) {
      super(definition)

      this.value = {}
      this.properties = {}

      for(let propName in definition.properties) {
        let propDefn = definition.properties[propName]

        if(propDefn.type == "Object") {
          this.properties[propName] = new FormObject(definition.properties[propName])
        } else if(propDefn.type == 'Array') {
          this.properties[propName] = new FormArray(definition.properties[propName])
        } else {
          this.properties[propName] = new FormValue(definition.properties[propName])
        }
        this.value[propName] = this.properties[propName].getValue()
        this.properties[propName].observe((value) => {
          if(!this.value) return
          this.value[propName] = value
          for(let observer of this.valueObservers) observer(this.value)
        })
      }
    }

    reset(initialValue) {
      if(this.definition.type == 'Object') {
        this.value = JSON.parse(JSON.stringify(initialValue ||
          (this.definition.hasOwnProperty('defaultValue') ? this.definition.defaultValue : {} )))
        if(this.value) {
          for(const key in this.value) {
            if(!this.value[key]) delete this.value[key]
          }
        }
      }
      if(this.value) {
        for(let propName in this.properties) {
          this.properties[propName].reset(initialValue && initialValue[propName])
        }
      }
      for(let observer of this.valueObservers) observer(this.value)
    }
    afterError(initialValue) {
      if(this.definition.singleUse) return this.reset()
      for(let propName in this.properties) {
        this.properties[propName].afterError(initialValue && initialValue[propName])
      }
    }

    validate(context) {
      let promises = [ super.validate(context).then(error => ['root', error]) ]
      for(let propName in this.properties) {
        if(context.parameters && context.parameters[propName]) continue;
        promises.push(
          this.properties[propName].validate({
            ...context,
            propName: context.propName ? context.propName+'.'+propName : propName
          }).then(error => [propName, error])
        )
      }
      return Promise.all(promises).then(results => {
        //console.error("RESULTS", results)
        let anyError = false
        let errors = {}
        for(let [propName, error] of results) {
          if(error) {
            anyError = true
            errors[propName] = errors[propName] || error
          }
        }
        return anyError && errors
      })
    }

    async waitForBarriers(context) {
      let promises = [ super.waitForBarriers(context).then(error => ['root', error]) ]
      for(let propName in this.properties) {
        if(context.parameters && context.parameters[propName]) continue;
        promises.push(
            this.properties[propName].waitForBarriers({
              ...context,
              propName: context.propName ? context.propName+'.'+propName : propName
            })
        )
      }
      await Promise.all(promises)
    }

    clearValidation() {
      super.clearValidation()
      for(let propName in this.properties) {
        this.properties[propName].clearValidation()
      }
    }

    getValue() {
      if(this.value == null) return null
      let obj = { ...this.value }
      for(let propName in this.properties) {
        obj[propName] = this.properties[propName].getValue()
      }
      return obj
    }

    getAnalyticsValue() {
      if(this.definition.secret) return {}
      let obj = { ...this.value }
      for(let propName in this.properties) {
        obj[propName] = this.properties[propName].getAnalyticsValue()
      }
      return obj
    }

    setValue(value) {
      this.value = value
      for(let propName in this.properties) {
        this.properties[propName].setValue(value && value[propName])
      }
      for(let observer of this.valueObservers) observer(value)
    }

    setDefinition(propName, defn) {
      const oldData = this.value[propName]
      this.definition = JSON.parse(JSON.stringify(this.definition))
      const propDefn = JSON.parse(JSON.stringify(defn))
      this.definition.properties[propName] = propDefn
      const definition = this.definition
      if(propDefn.type == "Object") {
        this.properties[propName] = new FormObject(definition.properties[propName])
      } else if(propDefn.type == 'Array') {
        this.properties[propName] = new FormArray(definition.properties[propName])
      } else {
        this.properties[propName] = new FormValue(definition.properties[propName])
      }
      this.properties[propName].reset(oldData)
      this.value[propName] = this.properties[propName].getValue()
      this.properties[propName].observe((value) => {
        this.value[propName] = value
        for(let observer of this.valueObservers) observer(this.value)
      })
    }

  }

  class FormArray extends FormValue {
    constructor(definition) {
      super(definition)
      this.elementDefinition = definition.of
      this.elements = []
    }
    newElement() {
      if(this.elementDefinition.type == "Object") {
        return new FormObject(this.elementDefinition)
      } else if(this.elementDefinition.type == 'Array') {
        return new FormArray(this.elementDefinition)
      } else {
        return new FormValue(this.elementDefinition)
      }
    }
    reset(initialValue) {
      initialValue = initialValue || this.definition.defaultValue || []
      this.value = new Array(initialValue.length)
      for(let i = 0; i < initialValue.length; i++) {
        let n = this.newElement()
        n.reset(initialValue[i])
        n.observe((value) => {
          this.value[i] = value
          for(let observer of this.valueObservers) observer(this.value)
        })
        this.elements.push(n)
      }
      super.setValue(initialValue)
    }
    afterError(initialValue) {
      if(this.definition.singleUse) return this.reset()
      for(let i = 0; i < this.elements.length; i++) {
        this.elements[i].afterError(initialValue && initialValue[i])
      }
    }
    validate(context) {
      let promises = [super.validate(context)]
      for(let propName in this.elements) {
        promises.push(this.elements[propName].validate({ ...context, propName: context.propName+'.'+propName }))
      }
      return Promise.all(promises).then(results => {
        let errors = {}
        let anyError = false
        for(let i = 0; i < results.length; i++) {
          const error = results[i]
          if(error) {
            errors[i] = error
            anyError = true
          }
        }
        return anyError && results
      })
    }

    async waitForBarriers(context) {
      let promises = [super.waitForBarriers(context)]
      for(let propName in this.elements) {
        promises.push(this.elements[propName].waitForBarriers({ ...context, propName: context.propName+'.'+propName }))
      }
      await Promise.all(promises)
    }

    clearValidation() {
      super.clearValidation()
      for(let element of this.elements) {
        element.clearValidation()
      }
    }

    getValue() {
      let arr = new Array(this.elements.length)
      arr.length = this.elements.length
      for(let i = 0; i < this.elements.length; i++) {
        arr[i] = this.elements[i].getValue()
      }
      return arr
    }

    getAnalyticsValue() {
      if(this.definition.secret) return []
      let arr = new Array(this.elements.length)
      arr.length = this.elements.length
      for(let i = 0; i < this.elements.length; i++) {
        arr[i] = this.elements[i].getAnalyticsValue()
      }
      return arr
    }

    setValue(value) {
      this.value = value
      if(!value) return;
      for(let i = 0; i < value.length; i++) {
        if (this.elements[i]) {
          this.elements[i].setValue(value[i])
        } else {
          let n = this.newElement()
          n.reset(value[i])
          n.observe((value) => {
            this.value[i] = value
            for(let observer of this.valueObservers) observer(this.value)
          })
          this.elements.push(n)
        }
      }
      this.elements = this.elements.slice(0, value.length)
      for(let observer of this.valueObservers) observer(value)
    }

    addElement(initialValue) {
      let el = this.newElement()
      el.reset(initialValue)
      this.elements.push(el)
      this.value = this.getValue()
      el.observe((value) => {
        this.value = this.getValue()
        for(let observer of this.valueObservers) observer(this.value)
      })
      for(let observer of this.valueObservers) observer(this.value)
    }

    removeElement(i) {
      this.elements.splice(i, 1)
      this.value = this.getValue()
      for(let observer of this.valueObservers) observer(this.value)
    }
  }

  export default {
    name: "DefinedForm",
    props: {
      tag: {
        type: String,
        required: false,
        default: 'form'
      },
      definition: {
        type: Object,
        required: true
      },
      initialValues: {
        type: Object,
        default: null
      },
      provided: {
        type: Object,
        default: null
      },
      parameters: {
        type: Object,
        default: null
      },
      fieldValidators: {
        type: Object,
        default: () => ({})
      }
    },
    provide() {
      return {
        form: {
          ...this.provided,
          getFieldDefinition: (name) => this.getFieldDefinition(name),
          setFieldDefinition: (name, definition) => this.setFieldDefinition(name, definition),
          getFieldValidators: (name) => this.getFieldValidators(name),
          getFieldValue: (name) => this.getFieldValue(name),
          setFieldValue: (name, value) => this.setFieldValue(name, value),
          getFieldError: (name) => this.getFieldError(name),
          setFieldError: (name, value) => this.setFieldError(name, value),
          getValue: () => this.formRoot.getValue(),
          reset: () => this.reset(),
          observe: (propName, observer) => this.observe(propName, observer),
          unobserve: (propName, observer) => this.unobserve(propName, observer),
          observeError: (propName, observer) => this.observeError(propName, observer),
          unobserveError: (propName, observer) => this.unobserveError(propName, observer),
          addValidator: (propName, validator) => this.addValidator(propName, validator),
          removeValidator: (propName, validator) => this.removeValidator(propName, validator),
          addBarrier: (propName, validator) => this.addBarrier(propName, validator),
          removeBarrier: (propName, validator) => this.removeBarrier(propName, validator),
          validateField: (propName) => this.validateField(propName),
          validate: () => this.validate(),
          waitForFieldBarriers: (propName) => this.waitForFieldBarriers(propName),
          waitForBarriers: (propName) => this.waitForBarriers(),
          clearFieldValidation: (propName) => this.clearFieldValidation(propName),
          clearValidation: () => this.clearValidation(),

          addElementToArray: (propName, initialValue) => this.addElementToArray(propName, initialValue),
          removeElementFromArray: (propName, index) => this.removeElementFromArray(propName, index)
        }
      }
    },
    inject: ['loadingZone', 'workingZone'],
    data() {
      return {
        formRoot: {}
      }
    },
    computed: {
      rootValue() {
        return this.formRoot && this.formRoot.value
      }
    },
    methods: {
      getNode(name) {
        let np = name.split('.')
        let node = this.formRoot
        for(let p of np) {
          if(node.properties) node = node.properties[p]
            else if(node.elements) node = node.elements[p]
          if(!node) throw new Error(`form field ${name} not found`)//return null
        }
        return node
      },
      getNodeIfExists(name) {
        let np = name.split('.')
        let node = this.formRoot
        for(let p of np) {
          if(node.properties) node = node.properties[p]
          else if(node.elements) node = node.elements[p]
          if(!node) return node
        }
        return node
      },
      getFieldDefinition(name) {
        return this.getNode(name).definition
      },
      setFieldDefinition(name, definition) {
        const sep = name.lastIndexOf('.')
        const parentName = sep > 0 ? name.slice(0, sep) : null
        const propName = sep > 0 ? name.slice(sep+1) : name
        return (parentName ? this.getNode(parentName) : this.formRoot).setDefinition(propName, definition)
      },
      getFieldValidators(name) {
        return this.getNode(name).validators
      },
      getFieldValue(name) {
        return this.getNode(name).getValue()
      },
      setFieldValue(name, value) {
        this.getNode(name).setValue(value)
      },
      getFieldError(name) {
        return this.getNode(name).error
      },
      setFieldError(name, error) {
        return this.getNode(name).setError(error)
      },
      initForm() {
        this.formRoot = new FormObject(this.definition)
        this.reset()
        for(const key in this.fieldValidators) {
          const node = this.getNode(key)
          const keyValidators = this.fieldValidators[key]
          node.validators = (Array.isArray(keyValidators) ? keyValidators : [keyValidators]).concat(node.validators)
        }
      },
      reset() {
        this.formRoot.reset(this.initialValues)
        //console.log("Form after reset", JSON.stringify(this.formRoot.getValue(), null, '  '))
      },
      observe(name, observer) {
        this.getNode(name).observe(observer)
      },
      unobserve(name, observer) {
        const node = this.getNodeIfExists(name)
        if(node) node.unobserve(observer)
      },
      observeError(name, observer) {
        this.getNode(name).observeError(observer)
      },
      unobserveError(name, observer) {
        const node = this.getNodeIfExists(name)
        if(node) node.unobserveError(observer)
      },
      addValidator(name, validator) {
        this.getNode(name).validators.push(validator)
      },
      removeValidator(name, validator) {
        let validators = this.getNode(name).validators
        let id = validators.indexOf(validator)
        if(id == -1) throw new Error("validator not found")
        validators.splice(id)
      },
      getValidationContext(srcContext) {
        const context = {
          ...(srcContext || { parameters: this.parameters }),
          ...this.provided,
          source: this.definition,
          props: this.formRoot.getValue(),
          form: this
        }
        return context
      },
      validateField(name, context) {
        return this.getNode(name).validate(this.getValidationContext(context))
      },
      waitForFieldBarriers(name, context) {
        return this.getNode(name).waitForBarriers(this.getValidationContext(context))
      },
      validate(context) {
        return this.formRoot.validate(this.getValidationContext(context))
      },
      waitForBarriers(context) {
        return this.formRoot.waitForBarriers(this.getValidationContext(context))
      },
      addBarrier(name, barrier) {
        this.getNode(name).barriers.push(barrier)
      },
      removeBarrier(name, barrier) {
        let barriers = this.getNode(name).barriers
        let id = barriers.indexOf(barrier)
        if(id == -1) throw new Error("barrier not found")
        barriers.splice(id)
      },
      clearFieldValidation(name) {
        this.getNode(name).clearValidation()
      },
      clearValidation() {
        this.formRoot.clearValidation()
      },
      addElementToArray(propName, initialValue) {
        this.getNode(propName).addElement(initialValue)
      },
      removeElementFromArray(propName, index) {
        this.getNode(propName).removeElement(index)
      },
      scrollToError() {
        let errorFieldElement = this.$el.querySelector(".formFieldError")
        if(!errorFieldElement) return
        let position = getElementPositionInDocument(errorFieldElement)
        window.scrollTo(0, position.y - 100) /// TODO: remove fixed nav-bar and do it properly.
      },
    },
    created() {
      this.initForm()
      this.state = 'ready'
      this.$emit('init')
    },
    destroyed() {
    },
    watch: {
      rootValue(newValue) {
        this.$emit('update', newValue)
      }
    }
  }
</script>

<style scoped>

</style>
