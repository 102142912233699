import "@babel/polyfill"
/*import { prebootClient } from "preboot/__build/src/browser/preboot_browser.js"
window.preboot = prebootClient()*/
import './vueInit.js'

import { createApp } from './app'
import api from "./api"
api.generateServicesApi()

const { app, store, router } = createApp()


// actually mount to DOM
app.$mount('#app')

// service worker
if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js')
}
