import moment from "moment"
import i18n from 'i18n'

export function host (url) {
  const host = url.replace(/^https?:\/\//, '').replace(/\/.*$/, '')
  const parts = host.split('.').slice(-3)
  if (parts[0] === 'www') parts.shift()
  return parts.join('.')
}

export function duration (between) {
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute')
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour')
  } else {
    return pluralize(~~(between / 86400), ' day')
  }
}

export function durationShort (between) {
  if (between < 3600) {
    return (~~(between / 60) + 'm')
  } else if (between < 86400) {
    return (~~(between / 3600) + 'h')
  } else {
    return (~~(between / 86400) + 'd')
  }
}

export function durationSeconds (between) {
  if (between < 60) {
    return pluralize(~~(between), ' second')
  } else if (between < 3600) {
    return pluralize(~~(between / 60), ' minute')
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour')
  } else {
    return pluralize(~~(between / 86400), ' day')
  }
}

export function durationSecondsShort (between) {
  if (between < 60) {
    return (~~(between) + 's')
  } if (between < 3600) {
    return (~~(between / 60) + 'm')
  } else if (between < 86400) {
    return (~~(between / 3600) + 'h')
  } else {
    return (~~(between / 86400) + 'd')
  }
}

export function durationMillis (between) {
  if (between < 1) {
    return pluralize(~~(between*1000), ' millisecond')
  } else if (between < 60) {
    return pluralize(~~(between), ' second')
  } else if (between < 3600) {
    return pluralize(~~(between / 60), ' minute')
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour')
  } else {
    return pluralize(~~(between / 86400), ' day')
  }
}

export function durationMillisShort (between) {
  if (between < 1) {
    return (~~(between*1000) + 'ms')
  } else if (between < 60) {
    return (~~(between) + 's')
  } if (between < 3600) {
    return (~~(between / 60) + 'm')
  } else if (between < 86400) {
    return (~~(between / 3600) + 'h')
  } else {
    return (~~(between / 86400) + 'd')
  }
}


export function timeAgo (time, now) {
  const between =  ((now || Date.now()) - Number(new Date(time).getTime())) / 1000
  return duration(between)
}

export function timeAgoShort (time, now) {
  const between =  ((now || Date.now()) - Number(new Date(time).getTime())) / 1000
  return duration(between)
}

export function hourOrDayShort(time, now = new Date) {
  const d = new Date(time)
  if(now.getYear() != d.getYear()) {
    return moment(d).locale(i18n.config.language).format(i18n.config.language === 'en' ? 'MM/DD/YY' : 'DD.MM.YY')
  } else if( now.getTime() - d.getTime() > 24*60*60*1000 ) {
    return moment(d).locale(i18n.config.language).format(i18n.config.language === 'en' ? 'MMM D' : 'D MMM')
  } else {
    return moment(d).locale(i18n.config.language).format("HH:mm")
  }
}

function pluralize (time, label) {
  if (time === 1) {
    return time + label
  }
  return time + label + 's'
}

export function plural(label, times) {
  if (times === 1) {
    return label
  }
  return label + 's'
}


export function date (value) {
  return moment(new Date(value)).format(i18n.config.language === 'en' ? 'MM-DD-YYYY' : 'DD.MM.YYYY')
}
export function hour (value) {
  return moment(new Date(value)).format('HH:mm')
}

export function typography(text, lang = i18n.config.language) {
  text = widont(text, 'html')
  text = text.replace(/(\s)([\S])[\s]+/g,"$1$2&nbsp;")
  text = text.replace(/(\s)([^<][\S]{1})[\s]+/g,"$1$2&nbsp;")
  const orphanWords = i18n().orphanWords
  for(const word of orphanWords) {
    const pattern = `(\\s)(${word})[\\s]`
    const regex = new RegExp(pattern, 'g')
    text = text.replace(regex, "$1$2&nbsp;")
  }
  return text
}



export function bytes(nums) {
  const num = +nums
  const units = ['B', 'kiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  const neg = num < 0;
  const anum = Math.abs(num)
  if (anum < 1) {
    return (neg ? '-' : '') + num + ' B';
  }
  const exponent = Math.min(Math.floor(Math.log(anum) / Math.log(1024)), units.length - 1);
  const fnum = (anum / Math.pow(1024, exponent)).toFixed(2) * 1;
  const unit = units[exponent];

  return (neg ? '-' : '') + fnum + ' ' + unit;
}
