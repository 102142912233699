<template>
  <page>
    <loading name="search and dataSet" :what="allLoaded"></loading>
    <div class="card main-card full-width" v-if="allLoaded" @click.capture="handleDisabledClick">
      <div v-if="isDeleted">Deleted</div>
      <command-form service="xmlCsvExclusions" action="checkExclusions" :initialValues="formInitialValues"
                    :parameters="{
                      dataSet: historyDataSet && historyDataSet.id || undefined
                    }"
                    :keepOnDone="true" @done="handleSearchStarted"
                    :fieldValidators="{ url: v => validateUrl(v) }" ref="form">

        <text-field v-if="editingUrl || !dataSetEntity || dataSetEntity.state=='deleted'" name="url"
          :label="i18n.url" :disabled="!canEdit">
        </text-field>
        <div v-else class="form-group dataSet">
          <div class="loading-animation"
               v-if="dataSetEntity.state == 'downloading' || dataSetEntity.state == 'connecting'">
            <div class="cube-spinner">
              <div class="cube1"></div>
              <div class="cube2"></div>
            </div>
          </div>
          <div class="dataSet-buttons buttons">
            <submit-button v-if="dataSetEntity.state != 'downloading' && dataSetEntity.state != 'connecting'"
                class="button" :parameters="{ refresh: true }" :disabled="!canEdit">
              <img src="/static/icons/refresh.svg">
            </submit-button>
            <button type="button" class="button" @click="editUrl" :disabled="!canEdit">
              <img src="/static/icons/edit.svg">
            </button>
            <button type="button" v-if="dataSetsHistory && dataSetsHistory.length > 0"
                    class="button" @click="toggleHistory" :disabled="!canEdit">
              <img src="/static/icons/history.svg">
            </button>
            <SingleSelectInput v-if="historyVisible && dataSetsHistory" :value="dataSetEntity"
                               :options="dataSetsHistory"
                               :text="dataSet => historyLabel(dataSet)"
                               class="form-field history-select"
                               @input="goToHistory" />
            <button type="button" v-if="historyVisible"
                    class="button" @click="toggleHistory">
              <img src="/static/icons/clear.svg">
            </button>
          </div>
          <div class="dataSet-info" v-if="!historyVisible">
            <a class="dataSet-url" :href="dataSetEntity.url">{{ dataSetEntity.url }}</a>
            <div class="dataSet-status">
              <div class="dataSet-state">{{ dataSetEntity.state }}</div>
              <div class="dataSet-timestamp">
                {{ (currentTime - (new Date(dataSetEntity.timestamp)).getTime()) / 1000 | durationShort }}
                -
                {{ new Date(dataSetEntity.timestamp) | date }}
                {{ new Date(dataSetEntity.timestamp) | hour }}
              </div>
              <div class="dataSet-size">{{ dataSetEntity.entries }} {{ i18n.entries }}</div>
              <div class="dataSet-progress" v-if="dataSetEntity.progress">
                <span>{{ dataSetEntity.progress.transferred | bytes }}</span>
                <span v-if="dataSetEntity.progress.length && dataSetEntity.progress.length > dataSetEntity.progress">
                  / {{ dataSetEntity.progress.length | bytes }}
                </span>
              </div>
            </div>
          </div>
          <div class="dataSet-buttons buttons" v-if="!historyVisible">
            <router-link v-if="checker"
                         :to="{ name: 'urls:url', params: { url: encodeURIComponent(dataSetEntity.url)} }"
                         tag="button" type="button" class="button">
              <img src="/static/icons/link.svg">
            </router-link>
          </div>
        </div>

        <div v-if="editingList || !this.exclusionsEntity" class="form-group">
          <FieldTemplate :label="i18n.uploadedFileLabel" name="exclusionsLists">
            <form-field-bind name="exclusionsLists" v-slot="{ value, setValue, error }"
                            class="exclusions-lists-fields">
              <ExclusionsListInfo
                v-for="(list, index) in value"
                :index="index" :list="list" :key="list" deleteable @delete="deleteExclusionsList(index)">
              </ExclusionsListInfo>
            </form-field-bind>
            <multi-file-field name="uploadedFiles" :label="''" :button="i18n.uploadedFileButton"
                              accept="text/csv,text/tab-separated-values,text/tsv,.csv,.tsv,.tab">
            </multi-file-field>
          </FieldTemplate>
        </div>
        <div v-else class="form-group exclusionsList">
          <div class="exclusionsList-buttons buttons">
            <button type="button" class="button" @click="editList" :disabled="!canEdit">
              <img src="/static/icons/edit.svg">
            </button>
          </div>
          <div class="exclusionsList-info">
            <ExclusionsListInfo
              v-for="(list, index) in exclusionsEntity.exclusionsLists || [ exclusionsEntity.exclusionsList ]"
              :index="index"
              :list="list"
              :key="list">
            </ExclusionsListInfo>
          </div>
        </div>

        <div class="buttons">
          <button type="submit" class="button" role="button" :disabled="!canEdit">{{ i18n.checkButton }}</button>
          <boolean-field name="useDescription" :label="i18n.useDescription"></boolean-field>
          <boolean-field name="useStrict" :label="i18n.useStrict"></boolean-field>
        </div>
      </command-form>
    </div>
    <div v-if="exclusions && allLoaded" class="exclusions-info card full-width">
      <div class="exclusions-progress progress-bar" v-if="exclusionsEntity.state != 'done'">
        <div class="progress-bar-fill" v-if="dataSetEntity.progress && dataSetEntity.progress.length"
             :style="{ width: Math.floor(
                 100 * (exclusionsEntity.processed / dataSetEntity.entries)
                  * ( + dataSetEntity.progress.transferred / +dataSetEntity.progress.length)
                 ) + '%' }">
        </div>
        <div class="progress-bar-fill" v-else
             :style="{ width: Math.floor(100 * (exclusionsEntity.processed / dataSetEntity.entries)) + '%' }">
        </div>
        <div class="progress-text">
          <span>{{ exclusionsEntity.processed }}</span>&nbsp;/&nbsp;<span>{{ dataSetEntity.entries }}</span>
        </div>
      </div>
      <div class="search-status form-group" v-if="exclusionsEntity.state == 'done'">
        <div class="search-state">{{ exclusionsEntity.state }}</div>
        <div class="search-results-count">{{ exclusionsEntity.results }} {{ i18n.results }}</div>
        <div class="search-timestamp">
          {{ (currentTime - (new Date(exclusionsEntity.timestamp)).getTime()) / 1000 | duration }}
          {{ i18n.ago }}
        </div>
        <!-- <div class="search-share-buttons">
          <button v-if="!generator" type="button" class="button" @click="createGenerator">
            <img src="/static/icons/cloud_upload.svg">
          </button>
          <button type="button" class="button" @click="toggleShare">
            <img src="/static/icons/share.svg">
          </button>
        </div> -->
      </div>
      <div class="search-view-options buttons">
        <div class="checkbox-field form-field">
          <span class="checkbox">
            <input type="checkbox" class="checkbox-input" id="groupResults" :checked="groupResults"
                    @input="(ev) => setGroupResults(ev.target.checked)" />
            <span class="checkbox-mark"></span>
          </span>
          <label class="custom-control-label" for="groupResults">
            <slot name="label">{{ i18n.groupResults }}</slot>
          </label>
        </div>
        <div class="checkbox-field form-field">
          <span class="checkbox">
            <input type="checkbox" class="checkbox-input" id="viewAsXml" :checked="viewAsXml"
                    @input="(ev) => setViewAsXml(ev.target.checked)" />
            <span class="checkbox-mark"></span>
          </span>
          <label class="custom-control-label" for="viewAsXml">
            <slot name="label">{{ i18n.viewAsXml }}</slot>
          </label>
        </div>
        <div class="checkbox-field form-field" v-if="viewAsXml">
          <span class="checkbox">
            <input type="checkbox" class="checkbox-input" id="rawXml" :checked="rawXml"
                    @input="(ev) => setRawXml(ev.target.checked)" />
            <span class="checkbox-mark"></span>
          </span>
          <label class="custom-control-label" for="rawXml">
            <slot name="label">{{ i18n.rawXml }}</slot>
          </label>
        </div>
      </div>
    </div>
    <!-- <GeneratorCard v-if="exclusions && generator && allLoaded" :generator="generator">
    </GeneratorCard> -->
    <ExclusionsGroups v-if="groupResults && exclusions && allLoaded" :exclusions="exclusions"
      :viewAsXml="viewAsXml" :rawXml="rawXml"></ExclusionsGroups>
    <ExclusionsResults v-else-if="exclusions && allLoaded" :exclusions="exclusions"
      :viewAsXml="viewAsXml" :rawXml="rawXml"></ExclusionsResults>
  </page>
</template>

<script>
  import i18n from "i18n"
  import api from 'api'
  import copyToClipboard from 'copy-to-clipboard'

  import overlayModel from "common/components/utils/overlayModel.js"
  import currentTime from "common/components/utils/currentTime.js"
  import SingleSelectInput from "common/components/inputs/SingleSelectInput.vue"
  import FileField from "common/components/fields/FileField.vue"
  import MultiFileField from "common/components/fields/MultiFileField.vue"
  import PremiumAlert from "common/components/PremiumAlert.vue"
  import FieldTemplate from 'common/components/fields/FieldTemplate.vue'

  import GeneratorCard from "../xmlSearch/GeneratorCard.vue"
  import ExclusionsResults from "./ExclusionsResults.vue"
  import ExclusionsGroups from "./ExclusionsGroups.vue"
  import ExclusionsListInfo from "./ExclusionsListInfo.vue"

  export default {
    name: "ExclusionsPage",
    components: {
      SingleSelectInput, ExclusionsResults, ExclusionsGroups, GeneratorCard, FileField, MultiFileField,
      ExclusionsListInfo, FieldTemplate
    },
    props: {
      exclusions: {
        type: String,
        default: null
      },
      exclusionsUrl: {
        type: String,
        default: ''
      },
      dataSet: {
        type: String,
        default: null
      }
    },
    inject: ['loadingZone', 'workingZone'],
    data() {
      return {
        editingUrl: false,
        editingList: false,
        shareVisible: false,
        historyVisible: false,
        viewAsXml: false,
        rawXml: false,
        groupResults: true,
        historyDataSet: null
      }
    },
    reactive: {
      exclusionsEntity() {
        return this.exclusions &&
          ['xmlCsvExclusions', "exclusionsCheck", { exclusionsCheck: this.exclusions } ] },
      exclusionsOnline() {
        return this.exclusions &&
          ['online', 'object', "xmlCsvExclisions_ExclusionsCheck", this.exclusions ]
      },

      dataSetEntity() { return (this.exclusionsEntity || this.dataSet)
          && ['xmlSearch', "dataSet", {
               dataSet: this.exclusionsEntity ? this.exclusionsEntity.dataSet : this.dataSet
             } ] },
      dataSetOnline() { return (this.exclusionsEntity || this.dataSet)
          && ['online', 'object', "xmlSearch_DataSet",
               this.exclusionsEntity ? this.exclusionsEntity.dataSet : this.dataSet
             ] },
      dataSetsHistory() { return this.dataSetEntity
          && ['xmlSearch', 'dataSetsByUrl', { url: this.dataSetEntity.url }]},

      // generator() { return this.searchEntity && this.searchEntity.url && this.searchEntity.query
      //     && ['xmlSearch', 'myGeneratorByUrlQuery', { url: this.searchEntity.url, query: this.searchEntity.query }]
      // },
      checker() {
        return this.dataSetEntity && ['urlChecker', 'myCheckerByUrl', { url: this.dataSetEntity.url }]
      }
    },
    reactivePreFetch(route) {
      return [
        {
          what: ['xmlSearch', 'search', { search: route.params.search }],
          more: [
            {
              schema: [['xmlSearch', 'dataSet', { object: { dataSet: { property: 'dataSet' } } }]]
            }
          ]
        }
      ]
    },
    computed: {
      i18n() {
        return i18n().xmlExclusions
      },
      allLoaded() {
        if(this.dataSet && !this.dataSetEntity) return false
        return this.exclusions ? (this.exclusionsEntity && this.dataSetEntity) : true
      },
      canEdit() {
        if(api.session.roles.indexOf('member') != -1) return true
        return false
      },
      currentTime() {
        return currentTime.now
      },
      isDeleted() {
        if(this.dataSetEntity && this.searchEntity) {
          if(this.dataSetEntity.state == 'deleted' || this.searchEntity.state == 'deleted') {
            if(this.doingNewSearch) return true
            if(typeof window == 'undefined') return true
            this.doingNewSearch = true
            this.workingZone.addPromise('search xml',
              api.command(['xmlSearch', 'search'], {
                url: this.dataSetEntity.url,
                query: this.searchEntity.query,
                refresh: false
              }).then(result => {
                router.push({ name: 'xml:exclusionsResults', params: { search: result.search } })
                this.doingNewSearch = false
              })
            )
            return true
          }
        }
        return false
      },
      formInitialValues() {
        return {
          url: (this.dataSetEntity && this.dataSetEntity.url) || decodeURIComponent(this.exclusionsUrl),
          exclusionsLists: this.exclusionsEntity &&
            (this.exclusionsEntity.exclusionsLists || [this.exclusionsEntity.exclusionsList]),
          useStrict: this.exclusionsEntity && this.exclusionsEntity.useStrict,
          useDescription: this.exclusionsEntity && this.exclusionsEntity.useDescription
        }
      },
      urlPrefix() {
        if(typeof window != 'undefined') {
          return document.location.protocol + '//' + document.location.host
        } else {
          return ''
        }
      },
      xmlUrl() {
        const route = this.$router.resolve({ name:'xml:exclusionsResultsViewXml', params: { search: this.search } })
        return this.urlPrefix + route.href
      },
      freshXmlUrl() {
        const route = this.$router.resolve({ name:'xml:exclusionsResultsFreshXml', params: { search: this.search } })
        return this.urlPrefix + route.href
      },
      downloadXmlUrl() {
        const route = this.$router.resolve({ name:'xml:exclusionsResultsDownloadXml', params: { search: this.search } })
        return this.urlPrefix + route.href
      },
      dataSetSearchFields() {
        if(this.dataSetEntity && this.dataSetEntity.fields) return this.dataSetEntity.fields
      },
    },
    watch: {
      exclusionsEntity(ee) {
        if(!ee) return
        if(!this.$refs.form) return
        console.log("SET DATA", this.exclusionsEntity)
        this.$refs.form.setFieldValue('useStrict', this.exclusionsEntity.useStrict)
        this.$refs.form.setFieldValue('useDescription', this.exclusionsEntity.useDescription)
      }
    },
    methods: {
      validateUrl(value) {
        console.error("CUSTOM URL VALIDATOR!", value)
        if(value && !value.match(/^https?:\/\//)
            && value.match(/^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)) {
          this.$refs.form.setFieldValue('url', 'https://'+value)
        }
      },
      handleSearchStarted({ result , parameters }) {
        router.push({ name: 'xml:exclusionsResults', params: { exclusions: result.check } })
        this.editingUrl = false
        this.editingList = false
      },
      editUrl() {
        if(!this.canEdit) return
        this.editingUrl = true
      },
      editList() {
        if(!this.canEdit) return
        this.editingList = true
      },
      toggleShare() {
        this.shareVisible = !this.shareVisible
      },
      toggleHistory() {
        this.historyVisible = !this.historyVisible
        if(!this.historyVisible) {
          this.historyDataSet = null
        }
      },
      copyXmlUrl() {
        copyToClipboard(this.freshXmlUrl)
      },
      setViewAsXml(v) {
        this.viewAsXml = v
      },
      setRawXml(v) {
        this.rawXml = v
      },
      setGroupResults(v) {
        this.groupResults = v
      },
      historyLabel(dataSet) {
        const date = this.$options.filters.date(dataSet.timestamp)
        const hour = this.$options.filters.hour(dataSet.timestamp)
        return `${date} ${hour}`
      },
      goToHistory(dataSet) {
        this.historyDataSet = dataSet
      },
      handleDisabledClick(ev) {
        if(!ev.target.disabled) return
        overlayModel.show({
          component: PremiumAlert
        })
      },
      createGenerator() {
        this.workingZone.addPromise('create generator',
          api.actions.xmlSearch.createGenerator({
            url: this.searchEntity.url,
            query: this.searchEntity.query
          })
        )
      },
      reloadSearch() {
        setTimeout(() => {
          const form = this.$refs.form
          form.submit()
        }, 100)
      },
      deleteExclusionsList(index) {
        console.log("DELETE", index)
        this.$refs.form.removeElementFromArray("exclusionsLists", index)
      }
    }
  }
</script>

<style scoped lang="scss">

</style>
