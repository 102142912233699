var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "notifications-list" }, [
    _c(
      "div",
      { staticClass: "notifications-view" },
      [
        _c("scroll-loader", {
          key: "notifications",
          staticClass: "notifications",
          attrs: {
            what: function(range) {
              return _vm.notificationsDaoPath(range)
            },
            bucketSize: 3,
            readMode: "id",
            hardClose: "",
            noDebugLog: ""
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var notification = ref.row
                var rows = ref.rows
                return [
                  _c(
                    "loading-zone",
                    [
                      _c(_vm.notificationComponent(notification), {
                        tag: "component",
                        attrs: { notification: notification }
                      })
                    ],
                    1
                  )
                ]
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }