var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("page", [
    _c("div", { staticClass: "card half-width main-card" }, [
      _c("h2", [_vm._v("Connecting to API")]),
      _vm._v(" "),
      _c("p", [_vm._v("Please wait...")])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }