<template>
  <Notification :notification="notification" ref="notification">
    <div class="notification-avatar" @click="goTo">
      <img src="/static/icons/scanner.svg" class="avatar-addon"/>
    </div>
    <div class="notification-content" @click="goTo">
      <div class="notification-title">{{ i18n.notifications.started.title }}</div>
      <div class="notification-description">
        {{ i18n.notifications.started.text }} {{ notification.url || 'unknown' }}
      </div>
    </div>
  </Notification>
</template>

<script>
import i18n from 'i18n'
import Notification from '@/modules/notifications/Notification.vue'

export default {
  name: "ScanStarted",
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  components: { Notification },
  computed: {
    i18n() {
      return i18n().xmlAlert
    }
  },
  methods: {
    async goTo(ev) {
      console.log("GOTO!")
      this.$refs.notificiation.mark('read')
      ev.preventDefault()
      const { scanner, scan } = this.notification
      this.$router.push({ name: "xml:alert:scan", params: { scanner, scan } })
    }
  }
}
</script>

<style scoped>

</style>