<template>
  <div class="volume-indicator">
    <div class="volume-indicator-bar" ref="low"></div>
    <div class="volume-indicator-bar" ref="mid"></div>
    <div class="volume-indicator-bar" ref="high"></div>
  </div>
</template>

<script>

const speed = 0.46
const maxVolume = 0.123
const maxLogVolume = -2
const minLogVolume = -7
const lowFrequency = 300
const midFrequency = 500
const highFrequency = 1500
const filterQ = 0.1
const detune = 100


function measureAmplitudeProcess(cb) {
  return function(event) {
    const numberOfChannels = event.inputBuffer.numberOfChannels
    let instant = 0
    for(let channelId = 0; channelId < numberOfChannels; channelId++) {
      let sum = 0
      const input = event.inputBuffer.getChannelData(channelId)
      for (let i = 0; i < input.length; ++i) {
        sum += input[i] * input[i]
      }
      instant += sum / input.length
    }
    cb(Math.sqrt(instant))
  }
}

export default {
  name: "VolumeIndicator",
  props: {
    stream: {
      required: true
    }
  },
  data() {
    return {
      started : false
    }
  },
  watch: {
    stream(newStream, oldStream) {
      oldStream.removeEventListener('addtrack', this.streamChangeHandler)
      oldStream.removeEventListener('removetrack', this.streamChangeHandler)
      this.updateSource()
      newStream.addEventListener('addtrack', this.streamChangeHandler)
      newStream.addEventListener('removetrack', this.streamChangeHandler)
    },
  },
  methods: {
    updateSource() {
      if(this.mediaStreamSource) {
        this.mediaStreamSource.disconnect()
        this.mediaStreamSource = null
      }

      if(this.started && this.stream && this.stream.getAudioTracks().length > 0) {
        this.mediaStreamSource = this.audioContext.createMediaStreamSource(this.stream)
        this.mediaStreamSource.connect(this.lowFilter)
        this.mediaStreamSource.connect(this.midFilter)
        this.mediaStreamSource.connect(this.highFilter)
      }
    },
    handleLow(instant) {
      instant = Math.log(instant)
      let value = this.lowSlow
      if(!Number.isFinite(value)) value = -1000
      value = instant * speed + value * (1 - speed)
      if(value > maxLogVolume) value = maxLogVolume
      if(value < minLogVolume) value = minLogVolume
      const height = ((value - minLogVolume)/(maxLogVolume - minLogVolume))
          * (this.maxHeight - this.minHeight) + this.minHeight
      this.lowSlow = value
      this.lowElement.style.height = height + 'px'
    },
    handleMid(instant) {
      instant = Math.log(instant)
      let value = this.midSlow
      if(!Number.isFinite(value)) value = -1000
      value = instant * speed + value * (1 - speed)
      if(value > maxLogVolume) value = maxLogVolume
      if(value < minLogVolume) value = minLogVolume
      const height = ((value - minLogVolume)/(maxLogVolume - minLogVolume))
          * (this.maxHeight - this.minHeight) + this.minHeight
      this.midSlow = value
      this.midElement.style.height = height + 'px'
    },
    handleHigh(instant) {
      instant = Math.log(instant)
      let value = this.highSlow
      if(!Number.isFinite(value)) value = -1000
      value = instant * speed + value * (1 - speed)
      if(value > maxLogVolume) value = maxLogVolume
      if(value < minLogVolume) value = minLogVolume
      const height = ((value - minLogVolume)/(maxLogVolume - minLogVolume))
          * (this.maxHeight - this.minHeight) + this.minHeight
      this.highSlow = value
      this.highElement.style.height = height + 'px'
    }
  },
  created() {
    this.streamChangeHandler = () => this.updateSource()
  },
  mounted() {

    this.minHeight = 3
    this.maxHeight = 15
    this.lowElement = this.$refs.low
    this.midElement = this.$refs.mid
    this.highElement = this.$refs.high

    this.audioContext = new (window.AudioContext || window.webkitAudioContext)()
    this.lowFilter = this.audioContext.createBiquadFilter()
    this.lowFilter.type = 'lowpass'
    this.lowFilter.frequency.value = lowFrequency
    this.lowFilter.detune.value = detune
    this.lowFilter.Q.value = filterQ

    this.midFilter = this.audioContext.createBiquadFilter()
    this.midFilter.type = 'bandpass'
    this.midFilter.frequency.value = midFrequency
    this.midFilter.detune.value = detune
    this.midFilter.Q.value = filterQ

    this.highFilter = this.audioContext.createBiquadFilter()
    this.highFilter.type = 'highpass'
    this.highFilter.frequency.value = highFrequency
    this.highFilter.detune.value = detune
    this.highFilter.Q.value = filterQ

    this.lowProcessor = this.audioContext.createScriptProcessor(
        2*2048, 1, 1)
    this.lowProcessor.addEventListener('audioprocess', measureAmplitudeProcess(a => this.handleLow(a)))

    this.midProcessor = this.audioContext.createScriptProcessor(
        2*2048, 1, 1)
    this.midProcessor.addEventListener('audioprocess', measureAmplitudeProcess(a => this.handleMid(a)))

    this.highProcessor = this.audioContext.createScriptProcessor(
        2*2048, 1, 1)
    this.highProcessor.addEventListener('audioprocess', measureAmplitudeProcess(a => this.handleHigh(a)))

    this.lowFilter.connect(this.lowProcessor)
    this.midFilter.connect(this.midProcessor)
    this.highFilter.connect(this.highProcessor)

    this.lowSlow = 0
    this.midSlow = 0
    this.highSlow = 0

    this.lowProcessor.connect(this.audioContext.destination)
    this.midProcessor.connect(this.audioContext.destination)
    this.highProcessor.connect(this.audioContext.destination)

    if(this.stream) {
      this.stream.addEventListener('addtrack', this.streamChangeHandler)
      this.stream.addEventListener('removetrack', this.streamChangeHandler)
    }
    this.started = true
    this.updateSource()
  },
  beforeDestroy() {
    if(this.stream) {
      this.stream.removeEventListener('addtrack', this.streamChangeHandler)
      this.stream.removeEventListener('removetrack', this.streamChangeHandler)
    }
    if(this.audioContext) this.audioContext.close()
  }
}
</script>

<style scoped>

</style>