<template>
  <div class="select-picker">
    <overlay-anchor :anchor="anchor" :directions="directions" @positioned="updatePosition">
      <div class="select-picker-card card" ef="box" :style="{ ...maxDimensions }">
        <div class="options">
          <div class="select" v-for="(option, index) in options" @click="selectSelected(option, index)"
               :ref="'option_'+index">
            <div v-if="more(option)" class="select-with-more">
              <div class="selectName">{{ text(option) }}</div>
              <span class="select-input-more"><img src="/static/icons/arrow_drop_down.svg" /></span>
            </div>
            <div v-else class="selectName">{{ text(option) }}</div>
          </div>
        </div>
      </div>
    </overlay-anchor>
  </div>
</template>

<script>
  import { OverlayAnchor, windowDimensions } from "common/components/utils/overlayLayer"
  import overlayModel from "../utils/overlayModel.js"

  export default {
    name: 'SingleSelectPicker',
    props: {
      anchor: {
      },
      options: {
        type: Array
      },
      text: {
        type: Function,
        required: true
      },
      more: {
        type: Function,
        default: v => false
      },
      directions: {
        type: Array,
        default: () => [{ x:1, y:1, ax: -1, ay: 1}, { x:1, y:-1, ax: -1, ay: -1 }]
      },
      stackId: {
        type: Number
      }
    },
    components: { OverlayAnchor },
    data () {
      return {
        position: { x:0, y:0 },
        direction: { ax:0, ay: 0 },
        search: "",
        overlayStackId: null,
        submenuIndex: -1,
        lastCloseTime: 0
      }
    },
    computed: {
      windowDimensions() { return windowDimensions },
      maxDimensions() {
        if(this.position.hidden) return { 'max-width': 0, 'max-height': 0 }
        //console.log("UPDATE MAX DIMMENSIONS", JSON.stringify(this.position), JSON.stringify(this.direction))
        //console.log("HEIGHT", windowDimensions.height)
        const result = {
          'min-width': this.anchor.element.clientWidth + 'px',
          'max-width': windowDimensions.width - this.position.x - 20 + 'px',
          'max-height': this.direction.y > 0
              ? windowDimensions.height - this.position.y - 20 + 'px'
              : windowDimensions.height - 20 + 'px'
        }
        //console.log("RESULT", result)
        return result
      }
    },
    methods: {
      updatePosition(position, direction) {
        this.position = position
        this.direction = direction
      },
      focusSearch() {
        if(this.$refs.search) {
          //this.$refs.search.parentNode.classList.add('is-dirty');
          this.$refs.search.focus()
          // this.$refs.search.select()
        }
        if(document.activeElement != this.$refs.search) {
          setTimeout(() => this.focusSearch(), 50)
        }
      },
      showSelectMenu(index, options) {
        if((this.overlayStackId === null && Date.now() - this.lastCloseTime > 230)
           || (index != this.submenuIndex)) {
          const anchorElement = this.$refs['option_'+index][0]
          console.log("ANCHOR ELEMENT", anchorElement)
          this.overlayStackId = overlayModel.show({
            parentId: this.stackId,
            component: this.constructor,
            props: {
              anchor: overlayModel.elementAnchor(anchorElement),
              options: options,
              text: this.text,
              more: this.more,
              directions: [
                { x: 1, y: 1, ax: 1, ay: -1}, { x: -1, y: 1, ax: -1, ay: -1 },
                { x: 1, y: -1, ax: 1, ay: 1}, { x: -1, y: -1, ax: -1, ay: 1 },
              ]
            },
            on: {
              selected: (value) => {
                this.overlayStackId = null
                this.lastCloseTime = Date.now()
                this.$emit("selected", value)
                this.$emit("close")
              },
              close: () => {
                this.overlayStackId = null
                this.submenuIndex = -1
                this.lastCloseTime = Date.now()
              }
            }
          })
        } else if(this.overlayStackId) {
          console.log("CLOSE SUBMENU", this.overlayStackId, "IN MENU", this.stackId)
          this.submenuIndex = -1
          overlayModel.close(this.overlayStackId)
        }
      },
      selectSelected(select, index) {
        const more = this.more(select)
        if(more) {
          this.showSelectMenu(index, more)
        } else {
          this.$emit("selected", select)
          this.$emit("close")
        }
      }
    },
    mounted() {
      setTimeout(()=>this.focusSearch(), 50)
    }
  }
</script>

<style>
  .select-picker-card {
    overflow-y: auto;
  }
</style>
