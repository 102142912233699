<template>
  <div :class="'form-field '+groupClass">
    <vue-recaptcha :class="inputClass" :sitekey="recaptchaSiteKey" @verify="verified" ref="recaptcha"></vue-recaptcha>
    <small class="field-error">
      {{ (errorText && errorText[error]) || i18n[error] || error }}
    </small>
  </div>
</template>

<script>
  import i18n from "i18n"

  export default {
    name: "RecaptchaField",
    props: {
      name: {
        type: String,
        default: "recaptcha"
      },
      inputClass: {
        type: String
      },
      groupClass: {
        type: String
      },
      errorText: {
        type: Object
      },
    },
    inject: ["form"],
    data() {
      return {
        recaptchaSiteKey: ENV.GRECAPTCHA_SITE_KEY,
        error: null
      }
    },
    computed: {
      i18n() { return i18n().validation },
    },
    methods: {
      verified(response) {
        this.form.setFieldValue(this.name, response)
      }
    },
    mounted() {
      this.valueObserver = (v) => {
        if(!v) this.$refs.recaptcha.reset()
      }
      this.errorObserver = (e) => {
        this.error = e
      }
      this.form.observe(this.name, this.valueObserver)
      this.form.observeError(this.name, this.errorObserver)
    },
    beforeDestroy() {
      this.form.unobserve(this.name, this.valueObserver)
      this.form.unobserveError(this.name, this.errorObserver)
    }
  }
</script>

<style scoped>

</style>