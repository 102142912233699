<template>
  <page :multiRow="true">
    <div class="callout-wrapper callout-register callout-register-student">
      <div class="callout">
        <h2>Przykro nam, że od nas odchodzisz</h2>
      </div>
    </div>
    <div class="content-wrapper">
      <div class="card">
        <h4>Przykro nam, że od nas odchodzisz</h4>
        <p>Będziemy wdzięczni za podanie przyczyny.</p>
        <command-form service="email" action="sendContactFormMail"
                      @done="$emit('done')" ref="form" :parameters="formParameters">
          <SingleSelectField name="subject" :options="feedbackOptions" label=""></SingleSelectField>
          <TextAreaField name="text" :rows="6" placeholder="Wiadomość" label=""></TextAreaField>

          <template v-slot:done>
            <div class="alert alert-success">
              Dziękujemy za wiadomość!
            </div>
          </template>
          <button class="button" type="submit"><span>Wyślij</span></button>
        </command-form>
      </div>
    </div>
  </page>
</template>
<script>
  import i18n from "../../../../i18n";
  import TextField from "common/components/fields/TextField";
  import TextAreaField from "common/components/fields/TextAreaField";
  import SingleSelectField from "common/components/fields/SingleSelectField";

  export default {
    name: "RemoveAccountFeedback",
    components: { TextAreaField, TextField, SingleSelectField },
    data() {
      return {
        feedbackOptions: [
          'Portal nie spełnia moich oczekiwań',
          'Nie jestem już zainteresowana/y udzielaniem ani korzystaniem z korepetycji',
          'Inne powody'
        ]
      }
    },
    computed: {
      i18n() {
        return i18n().page.rules
      },
      formParameters() {
        return {
          from: 'account-removed@jasne.online',
          name: 'Uzytkownik Usuniety',
        }
      }
    }
  }

</script>
<style lang="scss">

</style>
