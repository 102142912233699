<template>
  <section class="card half-width" v-if="user && user.loginMethods.find(lm=>lm.type=='emailPassword')">
    <command-form service="emailPassword" action="startEmailChange" :parameters="{ lang }">
      <template v-slot:done>
        <h4>{{ i18n.done.title }}</h4>
        <p>
          {{ i18n.done.text }}
        </p>
      </template>
      <template v-slot:default>
        <h4>{{ i18n.title }}</h4>
        <p>{{ i18n.currentEmail }} <b>{{ user && user.userData.email }}</b></p>
        <p>{{ i18n.instruction }}</p>
        <text-field name="newEmail" :label="i18n.newEmail" :errorText="i18n.errors"
                    :placeholder="i18n.newEmailPlaceholder">
        </text-field>
        <password-field name="passwordHash" :label="i18n.currentPassword" :errorText="i18n.errors"
                        :placeholder="i18n.currentPasswordPlaceholder"></password-field>
        <div class="buttons">
          <button type="submit" class="button" role="button"><span>{{ i18n.submit }}</span></button>
        </div>
      </template>
    </command-form>
  </section>
</template>

<script>
  import api from "api"
  import i18n from "i18n"

  export default {
    name: 'UpdateEmail',
    data () {
      return {
        newEmail: '',
        currentPassword: '',
        state: 'ready',
        required: false,
        error: null
      }
    },
    reactive: {
      user() { return ['users', 'UserOne', { user: api.session.session.user } ] }
    },
    computed: {
      lang() { return i18n.config.language },
      i18n() { return i18n().emailPassword.startEmailChange }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .change-email {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .blurred {
    filter: blur(2px) grayscale(50%);
  }

  .working {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.3);
  }
  .working div {
    margin: auto;
    height: 5em;
    width: 5em;
  }

  .serverError {
    color: darkred;
  }
  .card {
    padding: 10px;
    margin-bottom: 15px;
  }

</style>
